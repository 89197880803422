import React, {memo, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {getGameStartTime} from '../../../../Utils/TimeConvertors';
import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {getCombinations} from '../../../../Utils/CombinationsGenerator';

import Logo from '../../../../Assets/Layout/HeaderLogo.png';
import currencyImage from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as CupIcon} from '../../../../Assets/Icons/Globals/cup.svg';
import {ReactComponent as UpArrowsIcon} from '../../../../Assets/Icons/Globals/upArrows.svg';
import {ReactComponent as TriangleTop} from '../../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as TriangleDown} from '../../../../Assets/Icons/Globals/downTriangleWhite.svg';

import BetHistoryItem from '../BetHistoryItem';
import CashOutActions from '../CashOutActions';
import CalculationRow from '../CalculationRow';
import CashOutCalculations from '../CashOutCalculations';

import {AppButton} from '../../../UI';
import BetSocialShare from '../BetSocialShare';

const BetHistoryContainer = ({
  type,
  stake,
  betId,
  index,
  bonus,
  events,
  payout,
  outcome,
  setIndex,
  date_time,
  totalOdds,
  tax_amount,
  finalPayout,
  eventsCount,
  possibleWin,
  cashOutAmount,
  systemMinCount,
  cashOutedAmountRef,
  setIsSuccessModalOpen,
  rowType = false,
  setIsBetHistoryDataLoading,
}) => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);

  const [isExpanded, setExpanded] = useState(index === 0 || rowType);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const betType = useMemo(
    () =>
      type === 1
        ? 'singleUpper'
        : type === 2
        ? 'multipleUpper'
        : type === 3
        ? 'systemUpper'
        : 'chainUpper',
    [type],
  );

  const betOutComeBackground = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? '#FFDFD3'
        : outcome === 2
        ? '#fad85a'
        : outcome === 3
        ? '#D7FFDD'
        : '#fad85a'),
    [outcome],
  );

  const betOutComeTitleWithAmount = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? t('lostUpper')
        : outcome === 2
        ? t('returnedWithAmount', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })
        : outcome === 3
        ? t('wonUpper', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })
        : t('cashedOutWithAmount', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })),
    [outcome, t, currency, payout],
  );

  const betOutComeType = useMemo(
    () =>
      outcome &&
      (outcome === 1 ? 5 : outcome === 2 ? 13 : outcome === 3 ? 3 : 1),
    [outcome],
  );

  const TriangleIcon = isExpanded ? TriangleTop : TriangleDown;

  return (
    <div
      className={`betHistoryContainer ${
        rowType ? 'rowType col-xs-12 col-md-6 col-lg-4 col-xl-3' : ''
      }`}
      {...getToggleProps({
        onClick: () => setExpanded(prevExpanded => !prevExpanded),
      })}>
      <div
        className="column rounded-borders"
        style={{border: !isExpanded ? '1px solid white' : ''}}>
        <div
          className={`${
            isExpanded
              ? 'yellowBackground blueText'
              : 'blueBackground whiteText'
          } rounded-borders pa-md`}>
          <div className="row items-center justify-between gap-5">
            <div className="row items-center gap-5 overflow-hidden">
              <span className="font-12 bold-600 flex-shrink text-capitalize">
                {t(betType)?.toLowerCase()} ({eventsCount})
              </span>
              {type === 3 && !!systemMinCount && (
                <span className="font-12 bold-600 flex-shrink text-capitalize">{`${systemMinCount}/${eventsCount} (${
                  getCombinations(eventsCount, systemMinCount)?.length
                } opt.)`}</span>
              )}
            </div>

            {outcome !== 1 && outcome !== 2 && outcome ? (
              <div>
                <AppButton
                  fontSize={11}
                  disabledWithoutLayer
                  type={betOutComeType}
                  titleClassName="font-11"
                  className="py-xs px-sm"
                  title={t(
                    outcome === 1
                      ? 'lost'
                      : outcome === 2
                      ? 'returned'
                      : outcome === 3
                      ? 'won'
                      : 'cashedOut',
                  )}
                />
              </div>
            ) : (
              <span className="font-9  ellipsis text-capitalize">
                {getGameStartTime(date_time)?.toLowerCase()}
              </span>
            )}
            <div className="row items-center gap-10 flex-shrink">
              {/*{!isExpanded && (*/}
              {/*  <div className="row items-center gap-5">*/}
              {/*    <img height={12} alt="currency" src={currencyImage} />*/}
              {/*    <span className="text-caption  bold-700">*/}
              {/*      {currency} {bigNumbersPrettier(stake)}*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*)}*/}
              {/*{outcome ? (*/}
              {/*  <AppButton*/}
              {/*    fontSize={11}*/}
              {/*    disabledWithoutLayer*/}
              {/*    type={betOutComeType}*/}
              {/*    className="px-md py-xs"*/}
              {/*    title={t(betOutComeTitle)}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  */}
              {/*)}*/}
              <div className="row items-center gap-8">
                <span className="font-9 text-capitalize">
                  {t(isExpanded ? 'hide' : 'seeMore').toLowerCase()}
                </span>
                <UpArrowsIcon
                  width={9}
                  height={14}
                  className="flex-shrink"
                  fill={isExpanded ? 'var(--appBlue)' : 'white'}
                  style={!isExpanded ? {transform: 'rotate(180deg)'} : {}}
                />
              </div>
            </div>
          </div>
        </div>
        {!isExpanded && (
          <div className="column">
            <div
              style={{background: outcome ? betOutComeBackground : 'white'}}
              className={`gap-10 column`}>
              <div className="pa-md column gap-10">
                {/*{outcome && outcome === 3 && (*/}
                <CalculationRow
                  hideCurrency
                  amount={totalOdds}
                  title={t('totalOdds')}
                  titleClassName="blueText bold-700 font-12"
                  amountClassName="blueText bold-700 font-12"
                />
                {/*)}*/}
                {/*{outcome && (*/}
                <CalculationRow
                  title={t('stake')}
                  titleClassName="blueText bold-700 font-12"
                  amountClassName="blueText bold-700 font-12"
                  amount={bigNumbersPrettier(stake)}
                />

                {/*{outcome === 5 && (*/}
                {!!possibleWin && (
                  <CalculationRow
                    title={t('possibleWin')}
                    titleClassName="blueText bold-700 font-12"
                    amountClassName="blueText bold-700 font-12"
                    amount={bigNumbersPrettier(possibleWin)}
                  />
                )}
                {/*)}*/}
                {/*)}*/}

                {/*{(outcome === 3 || !outcome) && (*/}
                {!!finalPayout && (
                  <CalculationRow
                    title={t('finalPayout')}
                    amount={fixedNumberWithoutRound(finalPayout)}
                    titleClassName={
                      outcome ? 'blueText bold-700 font-12' : 'blueText'
                    }
                    amountClassName={
                      outcome
                        ? 'blueText bold-700 font-12'
                        : 'blueText bold-700 font-12'
                    }
                  />
                )}
                {/*)}*/}
              </div>
              {!outcome && (
                <CashOutActions
                  betId={betId}
                  cashOutAmount={cashOutAmount}
                  cashOutedAmountRef={cashOutedAmountRef}
                  setIsSuccessModalOpen={setIsSuccessModalOpen}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        {isExpanded && (
          <div
            className="column rounded-borders mt-sm"
            style={{border: '1px solid white'}}>
            <div className="pa-md row justify-between items-center">
              <CupIcon width={12} height={12} />
              <span className="whiteText bold-600 font-12">
                {t('ticket')} #{index}
              </span>
              <div style={{width: 15}} />
            </div>
            <div
              className={`lightGrayBackground rounded-borders column ${
                rowType ? 'rowTypeBody' : ''
              }`}>
              <div className="scroll-1 scroll-auto-y bodyContainer">
                {(events || [])?.map((event, i) => (
                  <BetHistoryItem
                    betOutCome={outcome}
                    gameId={event?.game_id}
                    team1Name={event?.team1}
                    team2Name={event?.team2}
                    key={event?.selection_id}
                    team1_name={event?.team1}
                    team2_name={event?.team2}
                    eventOutCome={event?.outcome}
                    event_name={event?.event_name}
                    coeficient={event?.coeficient}
                    sportAlias={event?.sport_index}
                    market_name={event?.market_name}
                    isLast={i === events?.length - 1}
                    game_start_date={event?.game_start_date}
                    setIsBetHistoryDataLoading={setIsBetHistoryDataLoading}
                  />
                ))}
              </div>
              <CashOutCalculations
                stake={stake}
                betId={betId}
                bonus={bonus}
                payout={payout}
                tax={tax_amount}
                outcome={outcome}
                totalOdds={totalOdds}
                finalPayout={finalPayout}
              />
              <div className="pt-md">
                {!outcome && (
                  <CashOutActions
                    betId={betId}
                    cashOutAmount={cashOutAmount}
                    cashOutedAmountRef={cashOutedAmountRef}
                    setIsSuccessModalOpen={setIsSuccessModalOpen}
                  />
                )}
              </div>
              <div
                style={{borderTop: '1px solid white'}}
                className={`column blueBackground items-center px-md flex justify-end logoContainer rounded-bottom-borders ${
                  rowType ? 'rowType' : ''
                }`}>
                <img src={Logo} alt="logo" width={140} className="py-lg" />
                <BetSocialShare betId={betId} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(BetHistoryContainer);
