// import {
//   CASINO_SCREEN,
//   POKER_SCREEN,
//   JACKPOT_SCREEN,
//   SPORTS_SCREEN,
//   BET_SLIP_SCREEN,
//   LIVE_CHAT_SCREEN,
//   LAST_PLAYED_SCREEN,
//   TOP_WINNERS_SCREEN,
//   CASINO_HOME_SCREEN,
//   LIVE_CASINO_SCREEN,
// } from '../Navigation/ScreenNames';
import {
  FILTERS_LIVE_NAME,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_MULTIPLE_DAY_NAME,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_POPULAR_COMPETITIONS_NAME,
} from '../Constants/MatchFilters';

export const swa = {
  // [CASINO_SCREEN]: 'Kasino',
  // [SPORTS_SCREEN]: 'Michezo',
  [FILTERS_LIVE_NAME]: 'Mubashara',
  // [CASINO_HOME_SCREEN]: 'Nyumbani',
  // [BET_SLIP_SCREEN]: 'Mkeka',
  // [JACKPOT_SCREEN]: '13 Jackpot',
  // [LIVE_CHAT_SCREEN]: 'Msaada',
  // [LIVE_CASINO_SCREEN]: 'Kasino Mubashara',
  // [LAST_PLAYED_SCREEN]: 'Ulizocheza',
  // [POKER_SCREEN]: 'Poka',
  // [TOP_WINNERS_SCREEN]: 'Washindi wa Juu',
  [FILTERS_BOOSTED_ODDS_NAME]: 'Odi Imara',
  [FILTERS_POPULAR_MATCHES_NAME]: 'Mechi Maarufu',
  [FILTERS_UPCOMING_MATCHES_NAME]: 'Mechi Zijazo',
  [FILTERS_MULTIPLE_DAY_NAME]: 'Mikeka ya Siku',
  [FILTERS_POPULAR_COMPETITIONS_NAME]: 'Mashindano Maarufu',
  searchTeam: 'Tafuta timu',
  search: 'Tafuta',
  home: 'NYUMBANI',
  draw: 'SARE',
  drawLowerCase: 'Sare',
  away: 'UGENINI',
  reset: 'RESET',
  league: 'Ligi',
  promos: 'PROMO',
  signIn: 'INGIA',
  register: 'JISAJILI',
  registerLower: 'Jisajili',
  chooseLanguage: 'Chagua Lugha',
  ok: 'SAWA',
  seeMore: 'ZAIDI',
  seeMoreOptions: 'MACHAGUO ZAIDI',
  forgotPassword: 'UMESAHAU NENOSIRI?',
  continue: 'ENDELEA',
  depositNow: 'WEKA PESA',
  logIn: 'INGIA',
  alreadyHaveAnAccount: 'Tayari una akaunti?',
  signInWaiting: 'INGIA, TUNAKUSUBIRI',
  signInLower: 'Ingia',
  rememberMe: 'Nikumbuke',
  newMember: 'MGENI PIGABET?',
  affiliates: 'WASHIRIKA',
  passwordPlaceholder: 'Nenosiri',
  registerAnAccount: 'Jisajili',
  phoneCode: 'Code:',
  jackpotPool: 'Ujumla wa Jackpot',
  phoneNumberPlaceholder: 'Namba ya simu',
  firstName: 'Jina la kwanza',
  lastName: 'Jina la Mwisho',
  day: 'Siku',
  month: 'Mwezi',
  year: 'Mwaka',
  affiliateCode: 'Namba ya ushirika (sio lazima)',
  agreement: 'MAKUBALIANO (TIKI KUKUBALI)',
  iConfirm: 'Ninakubali kuwa nina miaka 18 au zaidi.',
  iAgree: 'Ninakubaliana na PigaBet ',
  andThe: ' pamoja na ',
  privacyPolicy: 'Sera za Faragha',
  termsConditions: 'Vigezo na Masharti.',
  congratulations: 'Hongera!',
  youAreRegistered: 'Usajili wako umekamilika.',
  pleaseDeposit: 'Tafadhali weka pesa kufurahia huduma zetu!',
  resetPassword: 'Badili Nenosiri',
  enterYourPhoneNumber: 'Weka namba yako ya simu kubadili nenosiri: ',
  resetPhoneNumberPlaceholder: 'Weka namba ya simu ukianza na 255',
  smsHasBeenSent: 'Ujumbe mfupi wa SMS umetumwa kwenye namba uliyoweka',
  resetCodePlaceholder: 'Ingiza namba uliyopokea',
  resend: 'TUMA TENA',
  send: 'TUMA',
  leagues: 'Ligi',
  clearAll: 'ONDOA ZOTE',
  applyChanges: 'KUBALI MABADILIKO',
  cancel: 'GHAIRI',
  noGames: 'HAKUNA MICHEZO',
  anSmsHasBeenSent: 'Ujumbe mfupi wa SMS umetumwa kwenye namba uliyoweka',
  enterNewPassword: 'Weka nenosiri jipya:',
  confirmNewPassword: 'Rudia nenosiri jipya:',
  done: 'IMEKAMILIKA',
  passwordWasResetSuccessfully: 'Nenosiri Limefanikiwa Kubadilishwa!',
  generalTerms: 'Vigezo na Masharti Kiujumla',
  responsibleGambling: 'Uwajibikaji Kimchezo',
  help: 'Msaada',
  statistics: 'Takwimu',
  licences: 'Leseni',
  myBets: 'MIKEKA YANGU',
  phoneErrorMsg: 'Lazima ziwe herufi 9 kamili',
  phoneErrorMsgWithCode: 'Lazima ziwe herufi 12 kamili',
  passwordErrorMsg: 'Lazima ziwe angalau herufi 5',
  nameErrorMsg: 'Herufi tu(usiweke alama na/au namba)',
  chooseDateOfBirth: 'Chagua tarehe ya kuzaliwa',
  mustStartWithCode: 'Namba ya simu lazima ianze na 255',
  invalidPhoneNumberError: 'Namba ya simu sio sahihi',
  invalidPhoneNumberFormatError: 'Mpangilio wa namba ya simu sio sahihi',
  logout: 'TOKA',
  mainMenu: 'Main Menu',
  mainBalance: 'Salio Kuu:',
  TZS: 'TZS',
  withdrawableMoney: 'Kiasi Kinachoweza Kutoka:',
  totalBonusMoney: 'Jumla ya Pesa ya Bonasi:',
  contactUs: 'Wasiliana Nasi',
  loyaltyPoints: 'Loyalty Points',
  loyaltyPointsWithValue: 'Loyalty Points: {0}',
  legalAndCompliance: 'Sheria & Utekelezaji',
  settings: 'Settings',
  messages: 'Ujumbe',
  promotions: 'Promosheni',
  myBetsMainMenu: 'Mikeka Yangu',
  howToVideos: 'Video za Jinsi-Ya',
  balanceManagement: 'Usimamizi wa Salio',
  viewLoyaltyPoints: 'Angalia Loyalty Points',
  Language: 'Lugha',
  myProfile: 'Akaunti Yangu',
  personalDetails: 'Taarifa Binafsi',
  changePassword: 'Badili Nenosiri',
  selfExclusion: 'Jitoe',
  timeOut: 'Pumzika',
  depositLimits: 'Vikomo Kuweka Pesa',
  saveChanges: 'HIFADHI MABADILIKO',
  save: 'HIFADHI',
  YES: 'NDIO',
  NO: 'HAPANA',
  OK: 'SAWA',
  confirmPasswordText: 'Nenosiri limefanikiwa kubadilishwa',
  DONE: 'IMEKAMILIKA',
  currentPassword: 'Nenosiri la Sasa',
  newPassword: 'Nennosiri Jipya',
  changePasswordError: 'Nenosiri jipya na nenosiri ulilorudia havifanani',
  week: 'Wiki',
  depositLimitText: 'Vikomo vya kuweka pesa vimewekwa',
  ID: 'ID',
  Mobile255xxx: 'Simu 255xxx',
  email: 'Barua Pepe',
  passportID: 'Pasipoti/Kitambulisho',
  birthdate: 'Tarehe ya Kuzaliwa',
  gender: 'Jinsia',
  country: 'Nchi',
  city: 'Mji',
  eligibleForBonus: 'Stahiki kupata bonasi:',
  selfExclusionText:
    'Hichi kipengele kinakuruhusu kujitoa kutoka kwenye akaunti yako ya PigaBet kwa muda fulani. Ukijitoa hautaweza kutumia akaunti yako, wala kushiriki michezo yoyote kwa muda utakaochagua (kuanzia miezi 6 mpaka miaka 5). Lakini, kutumia kipengele hiki hakitakunyima uwezo wa kufanya miamala ya kutoa hela kutoka kwenye akaunti yako ya PigaBet.',
  CONFIRMATION: 'UTHIBITISHO',
  selfExclusionTextModal: 'Unathibitisha nia yako ya kujitoa?',
  selfExclusionPeriodSet: 'Muda wa Kujitoa Umewekwa',
  sorryToSeeYouGoDeeYouIn: 'Tunasikitika kuona unaondoka, karibu tena baada ya',
  timeOutText:
    'Kujitoa kwa muda kunakuruhusu kupumzika kwa kipindi utakachochagua. Endapo utajipumzisha, hutaweza kucheza, kuweka wala kutoa hela kutoka kwenye akaunti yako',
  timeOutModalText: 'Unathibitisha nia yako ya kupumzika?',
  timeOutPeriodSet: 'Muda wa Kupumzika Umewekwa',
  depositLimitsText:
    'Vikomo vya kuweka pesa vinakuruhusu kuweka kikomo cha hela unayoweza kuweka kwenye akaunti yako. Kiwango kinaweza kuchaguliwa kwa muda wa siku, wiki au mwezi. Kikomo cha pesa kitakuwezesha kuzuia kiasi cha hela kinachoingia kwenye akaunti yako kabla ya kuanza kubashiri.',
  months: 'miezi',
  timeout: 'Pumzika',
  years: 'miaka',
  hours: 'masaa',
  days: 'siku',
  oneWeek: 'Wiki moja',
  male: 'Mwanaume',
  family: 'Familia',
  chooseGender: 'Chagua Jinsia',
  chooseCountry: 'Chagua Nchi',
  myBetsTitle: 'Mikeka Yangu',
  betSlip: 'MKEKA',
  openBets: 'INAYOENDKELEA',
  settledBets: 'ILIYOKAMILIKA',
  remove: 'Ondoa',
  savedBetSlips: 'MIKEKA ILIYOHIFADHIWA',
  multiple: 'Multiple',
  system: 'System',
  single: 'Single',
  chain: 'Chain',
  multipleUpper: 'MULTIPLE',
  systemUpper: 'SYSTEM',
  singleUpper: 'SINGLE',
  chainUpper: 'CHAIN',
  removeAll: 'Ondoa Zote',
  odds: 'Odi',
  possibleWin: 'Jumla ya ushindi:',
  tax: 'Kodi',
  finalPayout: 'Ushindi baada ya makato',
  finalPayoutUpper: 'USHINDI BAADA YA MAKATO',
  acceptOddChanges: 'Kubali Mabadiliko ya Odi',
  bonusInfo: 'Taarifa ya Bonasi',
  saveBetSlip: 'HIFADHI MKEKA',
  placeBet: 'WEKA MKEKA',
  max: 'MWISHO',
  enterStake: 'Weka kiasi',
  emptyBetSlip: 'Mkeka wako uko tupu',
  loggedOutWarning: 'kuweka mkeka tafadhali, ',
  placeBetWarning: 'Kuweka mkeka tafadhali, weka kiasi',
  or: ' au ',
  betPlacedCongrats: 'Hongera! Mkeka wako umefanikiwa kuwekwa',
  placeAnotherBet: 'Weka mkeka mwingine?',
  cashOutSuccess: 'Ushindi umekamilika kutoka!',
  hasBeenAddedToYourWallet: ' imeongezwa kwenye salio lako.',
  noBetsToShow: 'Hamna masoko ya kuonesha',
  liveUpper: 'MUBASHARA',
  cashOut: 'TOA USHINDI{0}',
  confirmCashOut: 'KUBALI KUTOA USHINDI{0}',
  totalOdds: 'Jumla ya odi',
  stake: 'Kiasi',
  totalBonus: 'Jumla ya bonasi',
  bonus: 'Bonasi',
  viewSelections: 'ONA MACHAGUO:',
  hideSelections: 'FICHA MACHAGUO:',
  betNow: 'WEKA MKEKA',
  acceptChanges: 'KUBALI MABADILIKO NA WEKA MKEKA',
  signInOrRegister: 'Kuweka mkeka, Ingia au Jisajili',
  accumulatorBonus: 'Bonasi ya Machaguo',
  winner: 'WINNER',
  handicap: 'HANDICAP',
  totals: 'TOTALS',
  emptyBoosted: 'Hamna odi zilizoimarishwa kwa sasa',
  emptyMultiple: 'Hamna mikeka ya siku kwa sasa',
  won: 'Ulioshinda',
  lost: 'Uliokosa',
  wonAmount: 'Kiasi ulichoshinda',
  cashedOut: 'Ushindi uliotoa mapema',
  today: 'Leo',
  todayUpper: 'LEO',
  tomorrow: 'Kesho',
  tomorrowUpper: 'KESHO',
  yesterday: 'Jana',
  thisWeek: 'Wiki Hii',
  lastWeek: 'Wiki Iliyopita',
  thisMonth: 'Mwezi Huu',
  lastMonth: 'Mwezi Uliopita',
  wonUpper: 'USHINDI - {0}',
  wonStateUpper: 'SHINDA',
  lostUpper: 'KOSA',
  cashedOutUpper: 'USHINDI ULIOTOLEWA MAPEMA',
  back: 'NYUMA',
  position: 'Nafasi:',
  points: 'Pointi:',
  emptyEvents: 'Hakuna matukio kwa sasa',
  emptyFavouriteMarkets:
    'Hauna masoko uyapendayo. Kuongeza masoko uyapendayo gusa alama ya nyota pembeni ya masoko kwenye orodha.',
  all: 'Yote',
  confirmLower: 'Kubali',
  cancelLower: 'Ghairi',
  resetLower: 'Reset',
  notResultedUpper: 'HAINA MATOKEO',
  returnedUpper: 'IMERUDISHWA',
  returned: 'Imerudiswa',
  deposit: 'Weka Pesa',
  depositUpper: 'WEKA PESA',
  withdraw: 'Toa Pesa',
  withdrawUpper: 'TOA PESA',
  transactionHistory: 'Historia ya Miamala',
  withdrawStatus: 'Hali ya Miamala Iliyotolewa',
  currentBalance: 'Salio la Sasa',
  enterAmountToDeposit: 'Ingiza kiasi unachoweka ({0})',
  pleaseSelectAPaymentMethod: 'Tafadhali chagua njia ya malipo:',
  enterAmountForExchange:
    'Weka kiasi cha pointi unazotaka kubadilisha kuwa hela kisha gusa Wasilisha',
  pointsYouWantToExchangePlaceholder: 'Weka pointi unazotaka kubadili...',
  exchangeShopUpper: 'EXCHANGE SHOP',
  exchangeShop: 'Exchange Shop',
  submitUpper: 'WASILISHA',
  purchaseOrderCompletedTitle: 'Umefanikiwa Kuagiza!',
  purchaseOrderCompletedDescription:
    'Tutawasiliana na wewe kwa tarifa zaidi. Ahsante.',
  withdrawCompletedTitle: '{0} {1} imefanikiwa kutolewa!',
  depositCompletedTitle: '{0} {1} imefanikiwa kuwekwa!',
  loyaltyPointsExchangeCompletedTitle: '{0} {1} imefanikiwa kubadilishwa!',
  selectWithdrawMethod: 'Tafadhali chagua njia ya malipo:',
  amount: 'Kiasi',
  enterAmountToWithdraw: 'Ingiza kiasi unachotoa',
  minMaxLimits: 'swa {{currency}} {{minValue}}, swa {{currency}} {{maxValue}}',
  totalStake: 'Jumla ya kiasi',
  showMore: 'Ona zaidi',
  showLess: 'Ona kidogo',
  emptyMessages: 'Hauna ujumbe wowote',
  clearUpper: 'FUTA',
  callToActionText: '{0} {1} x {2} = USHINDI BAADA YA MAKATO: {0} {3}',
  count: 'Hesabu',
  minOdds: 'Odi ya chini',
  minStake: 'Kiasi cha chini',
  playBillNumber: 'Namba ya Biashara',
  onYourPhoneDial: 'Kwenye simu yako, piga',
  chooseOption: 'Chagua namba',
  selectOption: 'Chagua namba',
  enterBusinessNumber: 'Ingiza namba ya biashara',
  businessNumber: 'Namba ya biashara',
  amountYouWantToDeposit: 'Ingiza kiasi unachoweka',
  enterYour: 'Ingiza',
  pinUpper: 'PIN',
  press: 'Bonyeza',
  toConfirm: '1 kukubali',
  okWithCommas: '"SAWA"',
  payBills: 'Lipia Bili',
  payByMpesa: 'Lipa kwa M-pesa',
  enterMerchantNumber: 'Ingiza namba ya biashara',
  makePayments: 'Fanya Malipo',
  enterThe: 'Ingiza',
  enterAny: 'Ingiza',
  referenceNumber: 'namba ya kumbukumbu (namba ya simu)',
  enter: 'Ingiza',
  minimum: 'Kiasi cha chini',
  maximum: 'Kiasi cha juu',
  mobileNumber: 'Namba ya Simu:',
  yourOperatorIsNotSupported:
    'Mtandao wako hautambuliki, tafadhali chagua njia nyingine',
  reversed: 'Imerudishwa',
  paid: 'Imelipwa',
  pending: 'Haijafika',
  rejected: 'Imekataliwa',
  dateAndId: 'Tarehe/ID',
  time: 'Muda',
  id: 'ID',
  method: 'Njia',
  status: 'Hali',
  noDataToShow: 'Hakuna taarifa za kuonesha',
  cannotBeCombined: 'Matukio yenye alama hayawezi kuweka pamoja',
  filter: 'Chuja',
  transactionType: 'Aina ya Muamala',
  category: 'Kipengele',
  finalBalance: 'Salio Kuu',
  applyUpper: 'APPLY',
  type: 'Aina',
  main: 'Kikuu',
  casino: 'Kasino',
  seeWithdrawStatus: 'ANGALIA HALI YA MIAMALA ILIYOTOLEWA',
  promoCode: 'Promo Code',
  bonuses: 'Bonasi',
  bonusesHistory: 'Historia ya Bonasi',
  canceled: 'Imehairishwa',
  new: 'Mpya',
  call: 'Piga',
  whatsApp: 'WhatsApp',
  instagram: 'Instagram',
  facebook: 'Facebook',
  twitter: 'Twitter',
  youtube: 'Youtube',
  confirmFingerprint: 'Hakikisha Alama ya Kidole',
  epl: 'EPL',
  nbcPremier: 'NBC Premier League',
  laLiga: 'La Liga',
  ligue1: 'Ligue 1',
  serieA: 'Serie A',
  bundesliga: 'Bundesliga',
  minimumStakeError: 'Kiasi cha chini cha ubashiri hakijafikiwa (1 {0})',
  maximumStakeError: 'Kiasi cha juu cha ubashiri ni ({0} {1})',
  betSelectionChanged: 'Kuna mabadiliko ya odi',
  liveCasino: 'Kasino Mubashara',
  slots: 'Sloti',
  poker: 'Poka',
  skillGames: 'Michezo ya Ujuzi',
  blast: 'Blast',
  noCasinoGames: 'Hakuna Michezo',
  gameInfo: 'Maelezo ya Mchezo',
  playNow: 'CHEZA SASA',
  demo: 'JARIBIO',
  signInToAbleContinue: 'Ili kuweza kuendelea, tafadhali Ingia',
  jackpotModalTitle: 'Jackpot ya Kasino ya PigaBet',
  totalWinner: 'Jumla ya Washindi',
  largestWinner: 'Mshindi Mkubwa',
  lastWinner: 'Mshindi wa Karibuni',
  other: 'wengine',
  noVideos: 'Hakuna video',
  notStarted: 'Bado Haijaanza',
  tryLater: 'Tafadhali Jaribu Tena Baadae',
  eventHasBeenDeleted: 'Tukio limefutwa',
  eventHasBeenBlocked: 'Tukio limezuiwa',
  noInternet: 'Oops, Hamna intaneti',
  makeSureWifiIsOn:
    'Hakikisha kifaa chako wifi au data za simu zimewashwa na kisha ujaribu tena.',
  verificationCode: 'Namba ya uthibitisho',
  getCode: 'PATA NAMBA',
  smsConfirmation: 'SMS YA UTHIBITISHO',
  theCodeWillBeSent: 'Namba itatumwa kwa {0} namba ya simu ya mtumiaji',
  codeWasSent: 'Namba ya uthibitisho imetumwa kikamilifu.',
  PendingWithdrawalRequests: 'Tayari una ombi la muamala wa kutoa pesa',
  biometricSensorAlertTitle:
    'Je, ungependa kutumia "Utambulisho wa Sura/Alama ya Kidole" kuingia',
  biometricSensorAlertDescription:
    'Kwa "Utambulisho wa Sura/Alama ya Kidole" unaweza kutumia kwa urahisi na kwa usalama zaidi akaunti yako ya PigaBet',
  biometricSensorAlertCancelText: 'Tengeneza baadae',
  biometricSensorAlertConfirmText:
    'Tumia "Utambulisho wa Sura/Alama ya Kidole"',
  copy: 'Nakili',
  copied: 'Imenakiliwa',
  playerId: 'ID ya Mchezaji: ',
  OVER: 'JUU YA',
  UNDER: 'CHINI YA',
  lockedOdds: 'Odi zimefungwa',
  pigabetCasinoJackpot: 'KASINO JACKPOT YA PIGABET',
  suspended: 'swa swa swa',
  featuredGames: 'swa swa swa',
};
