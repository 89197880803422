import React, {memo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import './index.scss';

import {getLanguage} from '../../../Redux/AppSlice/AppSelectors';

import {APP_LANGUAGES} from '../../../Constants/Languages';
import {CMS_URL, SITE_ID, SOCKET_RESPONSES} from '../../../Constants/Socket';

import {AppModalBackButton} from '../../UI';

const Details = ({activeBonusId, setActiveBonusId}) => {
  const language = useSelector(getLanguage);

  const [bonusInfo, setBonusInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${CMS_URL}/api/public/v1/${
        language === APP_LANGUAGES.CHN ? 'chi' : language
      }/partners/${SITE_ID}/promotions/${activeBonusId}?platform=0`,
    )
      .then(response => response.json())
      .then(data => {
        if (data?.text === SOCKET_RESPONSES?.OK) {
          setBonusInfo(data?.data);
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [activeBonusId, language]);

  return (
    <div
      className={`bonusDetailsContainer wrap row gap-10 justify-center ${
        isLoading ? 'loading' : ''
      }`}>
      <AppModalBackButton onBackClick={() => setActiveBonusId('all')} />
      <div className="my-lg">
        <img
          width={300}
          alt={bonusInfo?.title}
          src={`${CMS_URL}${bonusInfo?.src}`}
        />
      </div>
      <div
        className="px-xxl column"
        dangerouslySetInnerHTML={{__html: bonusInfo?.content}}
      />
    </div>
  );
};

export default memo(Details);
