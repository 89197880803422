import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FreeMode, Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import './index.scss';

import {setMainMenuModal} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {getMainMenuModal} from '../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize} from '../../../Hooks';

import {
  M_PESA,
  HALO_PESA,
  TIGO_PESA,
  AIRTEL_NEW,
} from '../../../Constants/PaymentServices';
import {PROFILE} from '../../../Constants/MainMenu';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {BALANCE_MANAGEMENT_TAB_ITEMS} from '../../../Constants/Menu';

import Deposit from './Deposit';
import Withdraw from './Withdraw';
import SendToFriend from './SendToFriend';

import {AppButton} from '../../UI';

import {ReactComponent as CopyIcon} from '../../../Assets/Icons/MainMenu/copy.svg';
import {he} from 'date-fns/locale';

let copyTimeout = null;

const BalanceManagement = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const user = useSelector(getUser);
  const currency = useSelector(getCurrency);
  const mainMenuModal = useSelector(getMainMenuModal);

  const [isCopied, setIsCopied] = useState(false);
  const [showBalance, setShowBalance] = useState(true);

  const userOperator = useMemo(() => {
    switch (user?.username?.slice?.(3, 5)) {
      case '62':
      case '61':
        return HALO_PESA;
      case '68':
      case '69':
      case '78':
        return AIRTEL_NEW;
      case '74':
      case '75':
      case '76':
        return M_PESA;
      case '71':
      case '67':
      case '65':
      case '77':
        return TIGO_PESA;
      default:
        break;
    }
  }, [user?.username]);

  const copyPressHandler = useCallback(async () => {
    clearTimeout(copyTimeout);
    await navigator.clipboard.writeText(user?.unique_id);
    setIsCopied(true);
    copyTimeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [user?.unique_id]);

  return (
    <div className="flex column items-center balanceManagementContainer full-width">
      <div className="flex row items-center full-width justify-center">
        <div
          className="col-xs-12 flex column justify-between gap-10"
          // style={{minHeight: 510}}
        >
          <div className="column full-height flex-1">
            <div className="column">
              {/*<Swiper*/}
              {/*  freeMode*/}
              {/*  keyboard*/}
              {/*  spaceBetween={10}*/}
              {/*  modules={[Keyboard, FreeMode]}*/}
              {/*  className="balanceCardsSwiper full-width"*/}
              {/*  slidesPerView={width < BREAKPOINT_XS ? 'auto' : 2}>*/}
              {/*  {BALANCE_MANAGEMENT_CARDS.map(item => (*/}
              {/*    <SwiperSlide*/}
              {/*      key={item?.id}*/}
              {/*      style={width < BREAKPOINT_XS ? {width: '70%'} : {}}>*/}
              {/*      <div*/}
              {/*        className={`column ${*/}
              {/*          item?.id === 1 ? 'greenBackground' : 'yellowBackground'*/}
              {/*        } py-md px-lg cardContainer`}>*/}
              {/*        <span*/}
              {/*          className={`${*/}
              {/*            item?.id === 1 ? 'whiteText' : 'blackText'*/}
              {/*          } bold-500 text-caption`}>*/}
              {/*          {t(item?.title)}*/}
              {/*        </span>*/}
              {/*        <span*/}
              {/*          className={`${*/}
              {/*            item?.id === 1 ? 'whiteText' : 'blackText'*/}
              {/*          } bold-700`}>*/}
              {/*          {bigNumbersPrettier(*/}
              {/*            item?.id === 1*/}
              {/*              ? showBalance*/}
              {/*                ? fixedNumberWithoutRound(user?.balance)*/}
              {/*                : '******'*/}
              {/*              : fixedNumberWithoutRound(user?.bonus_balance),*/}
              {/*          )}{' '}*/}
              {/*          {(showBalance || item?.id === 2) && currency}*/}
              {/*        </span>*/}
              {/*        {item?.id === 1 && (*/}
              {/*          <div*/}
              {/*            className="row pa-sm rounded-borders lightenGreenBackground self-end"*/}
              {/*            onClick={() =>*/}
              {/*              setShowBalance(prevState => !prevState)*/}
              {/*            }>*/}
              {/*            <img*/}
              {/*              width={17}*/}
              {/*              height={17}*/}
              {/*              src={require(*/}
              {/*                `../../../Assets/Icons/Globals/${*/}
              {/*                  showBalance*/}
              {/*                    ? 'closedEyeWhite'*/}
              {/*                    : 'openedEyeWhite'*/}
              {/*                }.svg`,*/}
              {/*              )}*/}
              {/*              alt={*/}
              {/*                showBalance ? 'closedEyeWhite' : 'openedEyeWhite'*/}
              {/*              }*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        )}*/}
              {/*        <img*/}
              {/*          width={60}*/}
              {/*          height={60}*/}
              {/*          alt={item?.icon}*/}
              {/*          className="itemIcon"*/}
              {/*          src={require(*/}
              {/*            `../../../Assets/Icons/Globals/${item?.icon}.svg`,*/}
              {/*          )}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </SwiperSlide>*/}
              {/*  ))}*/}
              {/*</Swiper>*/}
              {/*<div className="rowCenterBetween lightGrayBackground px-xl py-xs">*/}
              {/*  <div className="row items-center gap-10 py-sm">*/}
              {/*    <div className="whiteBackground flex items-center justify-center nameContainer">*/}
              {/*      <span className="blackText font-15 bold-600">{`${user?.first_name*/}
              {/*        ?.charAt(0)*/}
              {/*        ?.toUpperCase()}${user?.last_name*/}
              {/*        ?.charAt(0)*/}
              {/*        ?.toUpperCase()}`}</span>*/}
              {/*    </div>*/}
              {/*    <div className="column">*/}
              {/*      <span className="blackText font-15 bold-600">{`${user?.first_name} ${user?.last_name}`}</span>*/}
              {/*      <div className="row items-center gap-10">*/}
              {/*        <span className="blackText font-12">{user?.id}</span>*/}
              {/*        <div*/}
              {/*          onClick={copyPressHandler}*/}
              {/*          className="row items-center gap-5 bold-500">*/}
              {/*          <CopyIcon*/}
              {/*            width={11}*/}
              {/*            height={11}*/}
              {/*            icon="copy"*/}
              {/*            fill="black"*/}
              {/*          />*/}
              {/*          {isCopied && (*/}
              {/*            <span className="grayText text-caption-small resetLineHeight">*/}
              {/*              {t('copied')}*/}
              {/*            </span>*/}
              {/*          )}*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div*/}
              {/*    className="row gap-5 items-center"*/}
              {/*    onClick={*/}
              {/*      !user?.is_verified*/}
              {/*        ? () =>*/}
              {/*            dispatch(*/}
              {/*              setMainMenuModal({*/}
              {/*                tab: PROFILE,*/}
              {/*                isVisible: true,*/}
              {/*                forceToOpenProfileIndex: 5,*/}
              {/*              }),*/}
              {/*            )*/}
              {/*        : () => {}*/}
              {/*    }>*/}
              {/*    <img*/}
              {/*      width={15}*/}
              {/*      height={15}*/}
              {/*      alt={user?.is_verified ? 'verified' : 'lost'}*/}
              {/*      src={require(*/}
              {/*        `../../../Assets/Icons/Globals/${*/}
              {/*          user?.is_verified ? 'verified' : 'lost'*/}
              {/*        }.svg`,*/}
              {/*      )}*/}
              {/*    />*/}
              {/*    <span className="blackText font-12 ">*/}
              {/*      {t(user?.is_verified ? 'verified' : 'unVerified')}*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div className="full-height column flex-1">
              <div className="row full-width px-md justify-between gap-10">
                <Swiper
                  keyboard
                  freeMode
                  mousewheel
                  modules={[Keyboard, FreeMode]}
                  className="tabsSwiper full-width"
                  spaceBetween={width < BREAKPOINT_XS ? 5 : 8}
                  slidesPerView={width < BREAKPOINT_XS ? 'auto' : 5}>
                  {BALANCE_MANAGEMENT_TAB_ITEMS.map(
                    ({title, tabIndex, disabled = false}) => (
                      <SwiperSlide key={tabIndex}>
                        <AppButton
                          type={17}
                          width={148}
                          height={30}
                          fontSize={12}
                          title={t(title)}
                          disabled={disabled}
                          titleClassName="bold-600"
                          className={
                            'flex items-center justify-center'
                            // width < BREAKPOINT_XS
                            //   ? 'px-lg py-md'
                            //   : 'flex items-center justify-center'
                          }
                          active={
                            tabIndex ===
                            mainMenuModal?.balanceManagementTabIndex
                          }
                          onClick={() => {
                            if (tabIndex !== 2) {
                              dispatch(
                                setMainMenuModal({
                                  balanceManagementTabIndex: tabIndex,
                                }),
                              );
                            }
                          }}
                        />
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </div>
              <div className="px-md flex-1 column">
                {mainMenuModal?.balanceManagementTabIndex === 0 ? (
                  <Deposit userOperator={userOperator} />
                ) : mainMenuModal?.balanceManagementTabIndex === 3 ? (
                  <SendToFriend />
                ) : (
                  <Withdraw userOperator={userOperator} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BalanceManagement);
