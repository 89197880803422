import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const PopularCompetitionSkeletons = ({count = 10}) => (
  <Skeleton
    height={32}
    width={180}
    count={count}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--darkenGray)"
    highlightColor="var(--lightGray)"
    containerClassName="row gap-5 pb-sm"
  />
);

export default memo(PopularCompetitionSkeletons);
