import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {setAuthModal} from '../../../Redux/AppSlice';
import {setUserSubId} from '../../../Redux/UserSlice';
import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import './index.scss';

import {AppButton, AppCheckbox, AppInput, AppLanguageChooser} from '../../UI';

import {
  LOGIN_USER,
  SOCKET_RESPONSES,
  GET_USER_SIGN_IN,
} from '../../../Constants/Socket';
import {
  PHONE_NUMBER_PREFIX,
  PHONE_NUMBER_PLACEHOLDER,
} from '../../../Constants/Globals';

import {useAppFunctions, useSocket} from '../../../Hooks';

import logo from '../../../Assets/Icons/Globals/PigabetLogo.svg';
import LasVegasCasino from '../../../Assets/Layout/LasVegasLogo.png';
import leGrandeCasino from '../../../Assets/Layout/leGrandeCasino.png';
import palmBeachCasino from '../../../Assets/Layout/palmBeachCasino.png';

const SignIn = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {loginCb} = useAppFunctions();
  const {login, getUser} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const inputRef = useRef(null);
  const userDataRef = useRef({});

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [signInErrorMsg, setSignInErrorMsg] = useState(null);
  const [isSignInLoading, setIsSignInLoading] = useState(false);

  const usernameErrorMsg = useMemo(
    () => (phone && phone.length !== 9 ? 'phoneErrorMsg' : ''),
    [phone],
  );

  const passwordErrorMsg = useMemo(
    () => (password && password.length < 4 ? 'passwordErrorMsg' : ''),
    [password],
  );

  const isSignInDisabled = useMemo(
    () =>
      !!(
        !phone.trim() ||
        !password.trim() ||
        usernameErrorMsg ||
        passwordErrorMsg ||
        signInErrorMsg
      ),
    [password, passwordErrorMsg, signInErrorMsg, phone, usernameErrorMsg],
  );

  const signInHandler = useCallback(() => {
    setIsSignInLoading(true);
    login({
      password,
      rid: LOGIN_USER,
      encrypted_token: rememberMe,
      username: `${PHONE_NUMBER_PREFIX}${phone}`,
    });
  }, [login, password, phone, rememberMe]);

  const enterClickHandler = useCallback(
    e => {
      if (e?.keyCode === 13 && !isSignInDisabled) {
        signInHandler();
      }
    },
    [isSignInDisabled, signInHandler],
  );

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case LOGIN_USER:
          if (data?.code === 0) {
            userDataRef.current = {
              user_id: data?.data?.user_id,
              ...(data?.data?.jwe_token
                ? {jwe_token: data?.data?.jwe_token}
                : {}),
              auth_token: data?.data?.auth_token,
            };

            getUser(GET_USER_SIGN_IN);
          }
          // invalid credentials error case
          if (
            data?.data?.status === SOCKET_RESPONSES.INVALID_CREDENTIALS ||
            data.data.status === SOCKET_RESPONSES.CLIENT_EXCLUDED ||
            data?.code === SOCKET_RESPONSES.NEED_RECAPTCHA ||
            data?.data?.status === SOCKET_RESPONSES.CLIENT_LOCKED
          ) {
            setIsSignInLoading(false);
            setSignInErrorMsg(data?.data?.details?.Message);
            inputRef.current?.blur();
          }
          break;
        case GET_USER_SIGN_IN:
          if (data?.code === 0) {
            dispatch(setUserSubId(data?.data?.subid));
            await loginCb({
              user_id: userDataRef.current?.user_id,
              ...(userDataRef.current?.jwe_token
                ? {jwe_token: userDataRef.current?.jwe_token}
                : {}),
              auth_token: userDataRef.current?.auth_token,
              user: Object.values(data?.data?.data?.profile || {})?.[0],
            });
          }
          setIsSignInLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, getUser, loginCb],
  );

  useEffect(() => {
    wssSocket?.addEventListener?.('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener?.('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    document.addEventListener('keydown', enterClickHandler);

    return () => document.removeEventListener('keydown', enterClickHandler);
  }, [enterClickHandler]);

  return (
    <div className="signInWrapper columnCenter gap-5 full-width">
      <img src={logo} alt="logo" height={40} />
      <div className="columnCenter gap-3">
        <span className="blueText text-body2 bold-600">{t('signIn')}</span>
      </div>
      <div className="full-width column">
        <div className="flex row gap-5">
          <div className="codeContainer column items-center justify-center px-xl">
            <span className="blueText font-10 bold-500">{t('phoneCode')}</span>
            <span className="blueText text-subtitle2 bold-700">
              {PHONE_NUMBER_PREFIX}
            </span>
          </div>
          <AppInput
            maxLength={9}
            inputType="number"
            onChange={setPhone}
            error={t(usernameErrorMsg)}
            setErrorMsg={setSignInErrorMsg}
            placeholder={PHONE_NUMBER_PLACEHOLDER}
          />
        </div>
        <div className="passwordContainer">
          <AppInput
            password
            inputRef={inputRef}
            onChange={setPassword}
            setErrorMsg={setSignInErrorMsg}
            placeholder={t('passwordPlaceholder')}
            error={t(signInErrorMsg || passwordErrorMsg)}
          />
        </div>
        <div className="row items-center gap-10 justify-between">
          <AppCheckbox
            label="rememberMe"
            className="py-sm"
            value={rememberMe}
            onChange={() => setRememberMe(prevState => !prevState)}
          />
          <span className="font-10 bold-500 blueText py-md">
            {t('forgotPassword')}{' '}
          </span>
        </div>
        <div className="signInContainer columnCenter gap-10">
          <AppButton
            type={3}
            title={t('signIn')}
            onClick={signInHandler}
            loading={isSignInLoading}
            disabled={isSignInDisabled}
          />
          <AppButton
            type={1}
            title={t('register')}
            onClick={() => dispatch(setAuthModal({tabIndex: 1}))}
          />
          <span className="font-10 bold-500 blueText py-md">
            {t('needHelp')}
          </span>
        </div>
        <div className="columnCenter">
          <AppLanguageChooser />
          <div className="rowCenterBetween affiliatesImageContainer gap-20 pt-xl">
            <img height={35} src={palmBeachCasino} alt="palmBeachCasino" />
            <img height={30} src={LasVegasCasino} alt="LasVegasCasino" />
            <img height={35} src={leGrandeCasino} alt="palmBeachCasino" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SignIn);
