import {createSelector} from '@reduxjs/toolkit';

const getPartnerState = state => state?.partner;

export const getPartner = createSelector(
  getPartnerState,
  state => state?.partner,
);

export const getPartnerSubId = createSelector(
  getPartnerState,
  state => state?.partnerSubId,
);

export const getCurrency = createSelector(
  getPartnerState,
  state => state?.partner?.currency,
);

export const getCashOutPercentage = createSelector(
  getPartnerState,
  state => state?.partner?.cashout_percetage,
);

export const getTaxPercentage = createSelector(
  getPartnerState,
  state => state?.partner?.tax_percent,
);

export const getMaxOddForMultipleBet = createSelector(
  getPartnerState,
  state => state?.partner?.max_odd_for_multiple_bet,
);

export const getPriceDecimals = createSelector(
  getPartnerState,
  state => state?.partner?.price_decimals,
);
