import React, {memo} from 'react';
import {useScreenSize} from '../../../../Hooks';

import './index.scss';

import {BREAKPOINT_XS} from '../../../../Constants/Globals';

const Circles = ({count = 67, type = 1}) => {
  const {width} = useScreenSize();

  return (
    <div className="full-width row whiteBackground">
      {Array.apply(
        null,
        Array(width < BREAKPOINT_XS ? Math.round(width / 6) : count),
      ).map((_, index) => (
        <div key={index} className={`circles circles_${type}`} />
      ))}
    </div>
  );
};

export default memo(Circles);
