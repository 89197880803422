import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getSid, getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../../Hooks';

import {bigNumbersPrettier} from '../../../Utils/BigNumbersPrettier';

import {
  SITE_ID,
  GET_PARTNER_BIG_WINS,
  GET_PARTNER_LAST_WINS,
} from '../../../Constants/Socket';
import {
  BREAKPOINT_XS,
  WINNERS_HEADER_DATA,
  TOP_WINNERS_HEADER_TITLE,
  LAST_WINNERS_HEADER_TITLE,
} from '../../../Constants/Globals';

import {AppButton} from '../../UI';
import TopWinnersRowSkeleton from '../../UI/Skeletons/TopWinnersRowSkeleton';

import {ReactComponent as CurrencyIcon} from '../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as AnonymIcon} from '../../../Assets/Icons/Globals/anonym.svg';
import {ReactComponent as TopWinnersIcon} from '../../../Assets/Icons/Globals/topWinners.svg';
import {ReactComponent as LastWinnersIcon} from '../../../Assets/Icons/Globals/lastWinners.svg';

const TopWinners = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const {getTopWinners, getLastWinners} = useSocket();

  const sid = useSelector(getSid);
  const wssSocket = useSelector(getWssSocket);

  const [images, setImages] = useState([]);
  const [topWinners, setTopWinners] = useState([]);
  const [lastWinners, setLastWinners] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedHeaderTitle, setSelectedHeaderTitle] = useState(
    TOP_WINNERS_HEADER_TITLE,
  );

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);
    switch (data?.rid) {
      case GET_PARTNER_LAST_WINS:
        setLastWinners(data?.data?.details);
        const lastWinsIds = [
          ...new Set(data?.data?.details?.map(item => item?.game_id)),
        ];
        setIsDataLoading(false);
        axios
          .get(
            `https://cmsbetconstruct.com/casino/getGames?partner_id=${SITE_ID}
          &lang=eng&is_mobile=1&country=AM&use_webp=1
          &external_id=${lastWinsIds?.join(',')}&only_images=1`,
          )
          ?.then(res => {
            setImages(res?.data?.games);
          })
          ?.catch();
        break;
      case GET_PARTNER_BIG_WINS:
        setTopWinners(data?.data?.details);
        const bigWinsIds = [
          ...new Set(data?.data?.details?.map(item => item?.game_id)),
        ];
        axios
          .get(
            `https://cmsbetconstruct.com/casino/getGames?partner_id=702
          &lang=eng&is_mobile=1&country=AM&use_webp=1
          &external_id=${bigWinsIds?.join(',')}&only_images=1`,
          )
          ?.then(res => {
            setImages(res?.data?.games);
          })
          ?.catch();
        setIsDataLoading(false);
        break;
      default:
        break;
    }
  }, []);

  const WINNERS_DATA = useMemo(
    () =>
      selectedHeaderTitle === TOP_WINNERS_HEADER_TITLE
        ? Array.isArray(topWinners)
          ? topWinners
          : []
        : selectedHeaderTitle === LAST_WINNERS_HEADER_TITLE
        ? Array.isArray(lastWinners)
          ? lastWinners
          : []
        : [],
    [lastWinners, selectedHeaderTitle, topWinners],
  );

  useEffect(() => {
    setIsDataLoading(true);
    if (selectedHeaderTitle === TOP_WINNERS_HEADER_TITLE) {
      getTopWinners();
    } else {
      getLastWinners();
    }
  }, [getLastWinners, getTopWinners, selectedHeaderTitle, sid]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="topWinnersContainer column py-sm">
      <div className="row full-width buttonsContainer pa-xxs">
        {WINNERS_HEADER_DATA.map(({id, title, name, icon}, index) => (
          <AppButton
            type={2}
            key={id}
            title={
              <div className="flex items-center gap-10">
                {name === 'topWinners' ? (
                  <TopWinnersIcon
                    width={25}
                    height={20}
                    fill={title === selectedHeaderTitle ? '#022751' : 'white'}
                  />
                ) : (
                  <LastWinnersIcon
                    width={25}
                    height={20}
                    fill={title === selectedHeaderTitle ? '#022751' : 'white'}
                  />
                )}
                <span className="bold-500 font-15">{t(name)}</span>
              </div>
            }
            loading={isDataLoading}
            // titleClassName="font-12 bold-600"
            active={title === selectedHeaderTitle}
            onClick={() => setSelectedHeaderTitle(title)}
            className={`px-sm py-md items-center justify-center flex flex-1 tabButtons ${
              index === 0 && 'firstButtonRadius'
            } ${index === 1 && 'secondButtonRadius'} ${
              title !== selectedHeaderTitle && 'notSelectedBorder'
            }`}
          />
        ))}
      </div>
      <div
        style={{maxHeight: 600, border: '1px solid var(--appYellow)'}}
        className={`whiteText bold-600 pa-${
          width > BREAKPOINT_XS ? 'xl' : 'md'
        } scroll-auto-y mt-md  rounded-borders scroll-3 scroll gap-8`}>
        {isDataLoading ? (
          <TopWinnersRowSkeleton />
        ) : (
          <div className="column rounded-borders pa-xs gap-15">
            <div className="row items-center whiteText bold-600 font-9 pb-sm">
              <span className="flex-1 ml-xxxl">{t('playerID')}</span>
              <div className="flex-1 row items-center" style={{minWidth: 220}}>
                <span className="flex-1 flex">{t('game')}</span>
                <span className="flex-1 flex textCenter items-center justify-start ml-xxxl">
                  {t('prize')}
                </span>
              </div>
            </div>
            {!!WINNERS_DATA?.length ? (
              <>
                {/*<div className="row items-center bold-800  headerTitlesContainer pa-md">*/}
                {/*  <div className="col-xs-4 flex">*/}
                {/*    <span>{t('game')}</span>*/}
                {/*  </div>*/}
                {/*  <div className="col-xs-4  flex">*/}
                {/*    <span>{t('time')}</span>*/}
                {/*  </div>*/}
                {/*  <div className="col-xs-4  flex">*/}
                {/*    <span className="textWithDotes">{t('winAmount')}</span>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*{index === 0 && (*/}
                {/*)}*/}
                {(WINNERS_DATA || [])?.map?.((item, index) => {
                  const imageUrl = images?.find(
                    imageItem =>
                      +imageItem?.extearnal_game_id === +item?.game_id,
                  )?.icon_1;

                  return (
                    <div
                      key={item?.time}
                      className="overflow-hidden row justify-between items-center rounded-borders px-sm topWinnersRowItem col-gutter-sm  blueText"
                      style={{backgroundColor: '#133863', height: 50}}>
                      <div className="row items-center flex-1 justify-start overflow-hidden">
                        <div className="flex items-center gap-10">
                          <span
                            className={`font-${
                              width < BREAKPOINT_XS ? '10' : '15'
                            } bold-600 whiteText `}>
                            {index + 1}th
                          </span>
                          <AnonymIcon
                            width={width < BREAKPOINT_XS ? 18 : 40}
                            height={width < BREAKPOINT_XS ? 18 : 40}
                          />
                        </div>
                        <div
                          className={`column overflow-hidden ${
                            !!imageUrl ? 'ml-md' : 'ml-xxs'
                          }`}
                          style={{height: 'inherit'}}>
                          <span
                            className={`font-${
                              width < BREAKPOINT_XS ? '12' : '15'
                            } bold-600 whiteText ellipsis`}>
                            {item?.player_user_name}
                          </span>
                          {/*{width < BREAKPOINT_XS && (*/}
                          {/*<span className="gameName font-10 blueText">*/}
                          {/*  {item?.game_name}*/}
                          {/*</span>*/}
                          {/*)}*/}
                        </div>
                      </div>
                      <div
                        className="flex-1 row items-center justify-between"
                        style={{minWidth: 220}}>
                        <div className={`row items-center flex-1`}>
                          {/*{width > BREAKPOINT_XS && (*/}
                          <div className="row items-center gap-10">
                            {!!imageUrl ? (
                              <img
                                src={imageUrl}
                                alt={item?.extearnal_game_id}
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: 5,
                                  objectFit: 'cover',
                                }}
                              />
                            ) : (
                              <div style={{width: 30}} />
                            )}
                            <span
                              className={`gameName font-${
                                width < BREAKPOINT_XS ? '10' : '15'
                              } bold-600 blueText whiteText`}>
                              {item?.game_name}
                            </span>
                          </div>
                          {/*)}*/}
                        </div>
                        <div className="row items-center gap-10 pl-xl flex-1 overflow-hidden">
                          <CurrencyIcon width={25} height={13} />

                          <span
                            className={`font-${
                              width > BREAKPOINT_XS ? '18' : '12'
                            } bold-${
                              width > BREAKPOINT_XS ? '700' : '600'
                            } whiteText ellipsis`}>
                            {bigNumbersPrettier(item?.win_amount)}
                          </span>
                        </div>
                      </div>
                      {/*<div className="col-xs-4 flex">*/}
                      {/*  <span className="textWithDotes">{item?.game_name}</span>*/}
                      {/*</div>*/}
                      {/*<div className="col-xs-4  flex">*/}
                      {/*  <span className="textWithDotes">*/}
                      {/*    {formatUnixDate(moment?.(item?.time)?.unix?.())}*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                      {/*<div className="col-xs-4  flex">*/}
                      {/*  <span className="greenText mr-sm">*/}
                      {/*    {item?.currency_id}*/}
                      {/*  </span>*/}
                      {/*  <span className="textWithDotes">*/}
                      {/*    {bigNumbersPrettier(item?.win_amount)}*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="darkBlueBackground rowCenter noDataContainer">
                <span className="bold-500 text-body2 whiteText text-center">
                  {t('noDataAvailable')}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TopWinners);
