import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FreeMode, Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import './index.scss';

import {getAuthModal} from '../../../Redux/AppSlice/AppSelectors';

import {PRIVACY_POLICY_HEADER_ITEMS} from '../../../Constants/Menu';

import {AppButton} from '../index';

const PrivacyPolicy = ({tabIndex = null}) => {
  const {t} = useTranslation();

  const authModal = useSelector(getAuthModal);

  const [pageIndex, setPageIndex] = useState(
    tabIndex || authModal?.privacyTabIndex || 0,
  );
  const [content, setContent] = useState(
    PRIVACY_POLICY_HEADER_ITEMS?.[pageIndex]?.html,
  );

  const onClick = useCallback((page, html) => {
    setContent(html);
    setPageIndex(page);
  }, []);

  return (
    <div className="flex column appPrivacyPolicyContainer full-width">
      <div className="flex row pb-md">
        <Swiper
          freeMode
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard, FreeMode]}
          className="privacyPolicySwiper">
          {PRIVACY_POLICY_HEADER_ITEMS?.map(({title, page, html}) => (
            <SwiperSlide key={title}>
              <AppButton
                small
                type={2}
                width={200}
                fontSize={11}
                title={t(title)}
                active={pageIndex === page}
                onClick={() => onClick(page, html)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        dangerouslySetInnerHTML={{__html: content}}
        className="flex column scroll-y scroll-1 pr-md blueText fit"
      />
    </div>
  );
};

export default memo(PrivacyPolicy);
