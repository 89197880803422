import {memo} from 'react';

import './index.scss';

const Button = ({
  type,
  title,
  onClick,
  className,
  width = {},
  height = {},
  fontSize = 13,
  small = false,
  active = false,
  loading = false,
  titleClassName = null,
  disabled = false,
  toUpperCase = false,
  disabledWithoutLayer = false,
}) => (
  <button
    style={{width, height}}
    onClick={disabled || disabledWithoutLayer ? () => {} : onClick}
    className={`${
      !!className ? className : 'px-lg py-md'
    } button cursor-pointer ${
      disabled && !disabledWithoutLayer ? 'button_disabled' : ''
    } ${active ? `active_background_${type}` : `background_${type}`} ${
      loading ? 'loading' : ''
    } ${small ? 'smallSize' : ''}`}>
    {typeof title === 'string' ? (
      <span
        className={`${titleClassName ? titleClassName : 'buttonTitle'}`}
        style={{fontSize}}>
        {toUpperCase ? title?.toUpperCase() : title}
      </span>
    ) : (
      title
    )}
  </button>
);
export default memo(Button);
