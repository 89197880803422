import React, {memo, useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {useSocket, useStorage} from '../../../../Hooks';

import {
  setMainMenuModal,
  setMessagesModal,
  setMyBetsModal,
  setShowLiveChat,
  setShowMobileBetSlip,
} from '../../../../Redux/AppSlice';
import {getUser} from '../../../../Redux/UserSlice/UserSelectors';
import {getLanguage} from '../../../../Redux/AppSlice/AppSelectors';

import {
  MY_BETS,
  MESSAGES,
  MAIN_MENU_STACKS,
  BALANCE_MANAGEMENT,
  PROMOTIONS,
  MAIN_MENU_STACKS_MOBILE,
  TRANSACTION_HISTORY,
  LEGAL_COMPLIANCE,
  LIVE_CHAT,
} from '../../../../Constants/MainMenu';

import '../../index.scss';

import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';
import {ReactComponent as ArrowDownIcon} from '../../../../Assets/Icons/Globals/arrowDown.svg';

import {AppLanguageChooser} from '../../../UI';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

const MainMenuTab = () => {
  const {t} = useTranslation();
  const {logout} = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {getLoginDataFromStorage} = useStorage();
  const [getParam, setGetParam] = useSearchParams();

  const user = useSelector(getUser);
  const language = useSelector(getLanguage);

  const copyTimeoutRef = useRef(null);

  const [isCopied, setIsCopied] = useState(false);

  const copyIdHandler = useCallback(async () => {
    setIsCopied(true);
    await navigator.clipboard.writeText(user?.id);
    clearTimeout(copyTimeoutRef.current);
    copyTimeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [user?.id]);

  const itemClick = useCallback(
    item => {
      dispatch(setMainMenuModal({isVisible: false, isDesktop: true}));
      switch (item?.tab) {
        case MY_BETS:
          dispatch(setMyBetsModal({isVisible: true}));
          break;
        case MESSAGES:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 3}));
          break;
        case BALANCE_MANAGEMENT:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 0}));
          break;
        case TRANSACTION_HISTORY:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 1}));
          break;
        case PROMOTIONS:
          getParam.set('showPromotions', 'all');
          setGetParam(getParam);
          break;
        case LEGAL_COMPLIANCE:
          navigate({
            pathname: '/privacy',
            search: `?${createSearchParams({
              tab: 'privacyPolicy',
            })}`,
          });
          break;
        case LIVE_CHAT:
          dispatch(setShowLiveChat(true));
          break;
        default:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 2}));
          break;
      }
    },
    [dispatch, getParam, navigate, setGetParam],
  );

  const onLogoutClick = useCallback(async () => {
    const loginData = await getLoginDataFromStorage();
    logout({jwe_token: loginData?.jwe_token});
    dispatch(setMainMenuModal({isVisible: false}));
  }, [dispatch, getLoginDataFromStorage, logout]);

  return (
    <div className="full-width mainMenuContainer">
      <div className="flex justify-between newLightenBlueBackground menuItem px-md paddingVertical-8 mb-sm rounded-borders">
        <div>
          <span className="whiteText font-12 bold-600">{t(`playerId`)}</span>
          <span className="ml-sm whiteText font-12 bold-600">{user?.id}</span>
        </div>
        <div
          onClick={copyIdHandler}
          className="flex rowCenter copyBetButton whiteBackground gap-3 cursor-pointer">
          <CopyIcon
            width={7}
            height={8}
            fill="var(--appBlue)"
            className="cursor-pointer"
          />
          <span className="blueText font-11 text-center">
            {t(isCopied ? 'copied' : 'copy')}
          </span>
        </div>
      </div>
      <div className="flex column full-width gap-5">
        {MAIN_MENU_STACKS_MOBILE?.map(item => (
          <div
            key={item?.title}
            onClick={() => itemClick(item)}
            className="flex items-center justify-between newLightenBlueBackground mainMenuListItem px-md paddingVertical-8 cursor-pointer">
            <span className="whiteText font-12 bold-600">
              {t(`${item?.title}`)}
            </span>
            <img alt={item?.title} width={14} height={14} src={item?.icon} />
          </div>
        ))}
        <AppLanguageChooser
          ButtonComponent={
            <div className="flex items-center justify-between newLightenBlueBackground mainMenuListItem px-md paddingVertical-8 cursor-pointer">
              <span className="whiteText font-12 bold-600">
                {t(`changeLanguage`)}
              </span>
              <div
                className="row items-center whiteBackground gap-3 pa-xxs"
                style={{borderRadius: 2}}>
                <img
                  width={16}
                  height={10}
                  alt="choosedLanguage"
                  src={require(
                    `../../../../Assets/Icons/Flags/${language || 'eng'}.svg`,
                  )}
                />
                <ArrowDownIcon
                  width={7}
                  height={7}
                  fill="var(--newLightBlue)"
                />
              </div>
            </div>
          }
        />
        <div
          onClick={onLogoutClick}
          className="flex items-center justify-center mainMenuListItem pa-md mt-xxxl cursor-pointer logOutButton">
          <span className="whiteText font-15 bold-600">{t(`logout`)}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(MainMenuTab);
