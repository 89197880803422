import moment from 'moment';

import i18next from 'i18next';

export const checkIsToday = unix_timestamp => {
  const today = moment().startOf('day');
  const date = moment.unix(unix_timestamp);
  return date.isSame(today, 'd');
};

export const getGameStartTime = unix_timestamp => {
  const today = moment().startOf('day');
  const tomorrow = moment().add(1, 'day').startOf('day');

  const date = moment.unix(unix_timestamp);

  return date.isSame(today, 'd')
    ? `${i18next.t('todayUpper')} - ${date.format('HH:mm')}`
    : date.isSame(tomorrow, 'day')
    ? `${i18next.t('tomorrowUpper')} - ${date.format('HH:mm')}`
    : date.format('DD/MM/YY - HH:mm');
};

export const formatScoreStartTime = unix_timestamp => {
  const today = moment().startOf('day');
  const tomorrow = moment().add(1, 'day').startOf('day');

  const date = moment.unix(unix_timestamp);

  return date.format('DD-MM-YY HH:mm');
};

export const convertToDate = date => moment(date).format('YYYY/MM/DD');

export const convertToTime = date => moment(date).format('HH:mm');

export const convertToTimestamp = strDate => {
  const datum = Date.parse(strDate);
  return datum / 1000;
};

export const formatScoreTimestamp = unixTimestamp => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const monthsOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Convert Unix timestamp (in seconds) to milliseconds and then to Date object
  const date = new Date(unixTimestamp * 1000);
  const now = new Date();

  const day = date.getDate();
  const month = monthsOfYear[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const dayOfWeek = daysOfWeek[date.getDay()];

  const time = `${hours % 12 || 12}:${minutes}${hours >= 12 ? 'pm' : 'am'}`;

  const isToday =
    now.getDate() === day &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear();
  const isTomorrow =
    now.getDate() + 1 === day &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear();

  if (isToday) {
    return `Today ${time}`;
  } else if (isTomorrow) {
    return `Tomorrow ${time}`;
  } else {
    return `${day} ${month} ${time}`;
  }
};
