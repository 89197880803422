import React, {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import './index.scss';

import {
  getRecaptcha,
  getMainMenuModal,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {PRIVACY_POLICY_HEADER_ITEMS} from '../../../Constants/Menu';

import {
  AppIntro,
  AppBetSlip,
  AppRecaptcha,
  AppSuccessModal,
  AppButton,
} from '../../../Components/UI';
import MyBets from '../../../Components/MyBets';
import Header from '../../../Components/Header';
import AuthStack from '../../../Components/Auth';
import MainMenu from '../../../Components/MainMenu';
import NewFooter from '../../../Components/NewFooter';
import NewMainMenu from '../../../Components/NewMainMenu';
import MobileHeader from '../../../Components/Header/MobileHeader';
import MobileBottomTab from '../../../Components/Home/MobileBottomTab';
import BetSlipSuccessModal from '../../../Components/BetSlip/Components/SuccessModal';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useTranslation} from 'react-i18next';

const Privacy = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const shareBetId = useMemo(() => getParam.get('shareBetId'), [getParam]);

  const recaptcha = useSelector(getRecaptcha);
  const mainMenuModal = useSelector(getMainMenuModal);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);

  const onClick = useCallback(
    (title, html) => {
      getParam.set('tab', title);
      setGetParam(getParam);
    },
    [getParam, setGetParam],
  );

  return (
    <div className="privacyPageContainer darkBlueBackground fit">
      {width > BREAKPOINT_XS ? (
        <Header showTabs={false} showSearch={false} />
      ) : (
        <MobileHeader showFilters={false} showSearch={false} />
      )}
      <div
        style={{boxShadow: '0px 3px 3px 0px #00000040'}}
        className="blueBackground row items-center justify-center py-xxl gap-10">
        <Swiper
          freeMode
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard, FreeMode]}
          className="privacyPolicyPageSwiper">
          {PRIVACY_POLICY_HEADER_ITEMS?.map(({title, html}) => (
            <SwiperSlide key={title}>
              <AppButton
                type={6}
                height={40}
                fontSize={15}
                title={t(title)}
                width="fit-content"
                className="py-md px-xl"
                titleClassName="bold-600"
                onClick={() => onClick(title, html)}
                active={getParam.get('tab') === title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className="mt-xs blueBackground"
        style={{padding: width < BREAKPOINT_XS ? '10px' : '70px 50px'}}>
        <div
          style={{border: '1px solid white'}}
          dangerouslySetInnerHTML={{
            __html: PRIVACY_POLICY_HEADER_ITEMS?.find(
              item => item?.title === getParam.get('tab'),
            )?.html,
          }}
          className="flex column whiteText fit rounded-borders px-xxl py-md font-10 bold-500"
        />
      </div>
      <NewFooter />
      {width < BREAKPOINT_XS && <MobileBottomTab />}
      <AuthStack />
      <div
        className={`appBetSlipWrapper_mobile flex justify-center fit blueBackground rounded-top--borders column appBetSlipWrapper ${
          showMobileBetSlip ? 'slide-up' : 'slide-down'
        }`}
        style={{
          zIndex: 16,
          background: '#00000070',
        }}>
        <div
          style={{
            marginTop: 45,
            width: '97dvw',
            height: '100%',
            borderRadius: 12,
          }}>
          <AppBetSlip />
        </div>
      </div>
      <MyBets />
      <AppSuccessModal />
      {recaptcha?.isVisible && <AppRecaptcha />}
      <AppIntro />
      {!!shareBetId && (
        <BetSlipSuccessModal
          isSharedBet
          betId={shareBetId}
          isOpen={!!shareBetId}
          setIsOpen={() => {
            getParam.delete('shareBetId');
            setGetParam(getParam);
          }}
        />
      )}
      {width < BREAKPOINT_XS && !mainMenuModal?.isDesktop ? (
        <NewMainMenu />
      ) : (
        <MainMenu />
      )}
    </div>
  );
};

export default Privacy;
