import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  headerTabIndex: 0,
  openBetsCount: null,
  bonusInfoData: null,
  isBetSlipDataLoading: false,
};

export const bettingSlice = createSlice({
  name: 'betting',
  initialState,
  reducers: {
    setIsBetSlipDataLoading: (state, {payload}) => {
      state.isBetSlipDataLoading = payload;
    },
    setHeaderTabIndex: (state, {payload}) => {
      state.headerTabIndex = payload;
    },
    setOpenBetsCount: (state, {payload}) => {
      state.openBetsCount = payload;
    },
    setBonusInfoData: (state, {payload}) => {
      state.bonusInfoData = payload;
    },
  },
});

export const {
  setOpenBetsCount,
  setBonusInfoData,
  setHeaderTabIndex,
  setIsBetSlipDataLoading,
} = bettingSlice.actions;

export default bettingSlice.reducer;
