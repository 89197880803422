import {useEffect, useCallback} from 'react';

const useOutSideClickHandler = (innerRef, handler) => {
  const handleClickOutside = useCallback(
    event => {
      if (innerRef?.current && !innerRef?.current?.contains(event?.target)) {
        handler();
      }
    },
    [handler, innerRef],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchend', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [handleClickOutside, innerRef]);
};

export default useOutSideClickHandler;
