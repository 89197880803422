import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const MessageItemSkeleton = () => (
  <Skeleton
    count={5}
    height={60}
    width="100%"
    duration={0.7}
    className="my-sm"
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(MessageItemSkeleton);
