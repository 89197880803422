import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';
import {setAuthModal, setSuccessModal} from '../../../Redux/AppSlice';

import {useSocket} from '../../../Hooks';

import {
  SOCKET_RESPONSES,
  RESET_PASSWORD_VIA_SMS,
} from '../../../Constants/Socket';
import {
  PHONE_NUMBER_PREFIX,
  PHONE_NUMBER_PLACEHOLDER,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../Constants/Globals';

import {AppButton, AppInput, AppModalBackButton} from '../../UI';

const ForgotPassword = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {resetPasswordViaSms} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [phone, setPhone] = useState('');
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [incorrectPhoneError, setIncorrectPhoneError] = useState(null);

  const phoneInputRef = useRef(null);

  const phoneErrorMsg = useMemo(
    () =>
      phone && phone?.trim?.()?.length !== 9
        ? t('phoneErrorMsgWithCode')
        : false,
    [phone, t],
  );

  const isResetDisabled = useMemo(
    () => !!(!phone || phoneErrorMsg || incorrectPhoneError),
    [incorrectPhoneError, phone, phoneErrorMsg],
  );

  const resetPressHandler = useCallback(() => {
    setIsResetLoading(true);
    resetPasswordViaSms(`${PHONE_NUMBER_PREFIX}${phone}`);
  }, [phone, resetPasswordViaSms]);

  const backPressHandler = useCallback(() => {
    dispatch(setAuthModal({tabIndex: 0}));
  }, [dispatch]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case RESET_PASSWORD_VIA_SMS:
          if (data?.data?.result === 0) {
            dispatch(
              setAuthModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('passwordWasResetSuccessfully'),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          } else {
            phoneInputRef.current?.blur?.();
            switch (data?.data?.result) {
              case SOCKET_RESPONSES.CLIENT_NOT_FOUND:
                setIncorrectPhoneError(t('clientNotFound'));
                break;
              case SOCKET_RESPONSES.INVALID_PHONE_NUMBER_FORMAT:
                setIncorrectPhoneError(t('invalidPhoneNumberFormatError'));
                break;
              default:
                setIncorrectPhoneError(
                  typeof data?.data?.details === 'string'
                    ? data?.data?.details
                    : t('somethingWentWrong'),
                );
                break;
            }
          }
          setIsResetLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="forgotPasswordContainer pa-lg column full-width">
      <AppModalBackButton onBackClick={backPressHandler} />
      <span className="blueText text-body1 bold-600 text-center pb-xl">
        {t('resetPasswordTitle')?.toUpperCase?.()}
      </span>
      <div className="gap-15 flex column">
        <span className="blueText text-body2">{t('enterYourPhoneNumber')}</span>
        <div className="flex row gap-5">
          <div className="codeContainer column items-center justify-center px-xl">
            <span className="blueText font-10 bold-500">{t('phoneCode')}</span>
            <span className="blueText text-subtitle2 bold-700">
              {PHONE_NUMBER_PREFIX}
            </span>
          </div>
          <AppInput
            inputType="number"
            onChange={setPhone}
            errorNumberOfLines={3}
            setErrorMsg={setIncorrectPhoneError}
            placeholder={PHONE_NUMBER_PLACEHOLDER}
            error={phoneErrorMsg || incorrectPhoneError}
          />
        </div>
      </div>
      <div className="mt-auto">
        <AppButton
          type={3}
          title={t('reset')}
          loading={isResetLoading}
          disabled={isResetDisabled}
          onClick={resetPressHandler}
        />
      </div>
    </div>
  );
};

export default memo(ForgotPassword);
