export const PROVIDER_ICONS = {
  all: require('../Assets/Images/Casino/all.png'),
  ASG: require('../Assets/Images/Casino/ASG.png'),
  ATC: require('../Assets/Images/Casino/ATC.png'),
  BMG: require('../Assets/Images/Casino/BMG.png'),
  BTS: require('../Assets/Images/Casino/BTS.png'),
  EZG: require('../Assets/Images/Casino/EZG.png'),
  VGS: require('../Assets/Images/Casino/VGS.png'),
  CTG: require('../Assets/Images/Casino/CTG.png'),
  EGT: require('../Assets/Images/Casino/EGT.png'),
  EVL: require('../Assets/Images/Casino/EVL.png'),
  GNI: require('../Assets/Images/Casino/GNI.png'),
  HBN: require('../Assets/Images/Casino/HBN.png'),
  HGF: require('../Assets/Images/Casino/HGF.png'),
  HKS: require('../Assets/Images/Casino/HKS.png'),
  NCY: require('../Assets/Images/Casino/NCY.png'),
  NOV: require('../Assets/Images/Casino/NOV.png'),
  NTG: require('../Assets/Images/Casino/NTG.png'),
  ONP: require('../Assets/Images/Casino/ONP.png'),
  OTN: require('../Assets/Images/Casino/OTN.png'),
  PCG: require('../Assets/Images/Casino/PCG.png'),
  PPG: require('../Assets/Images/Casino/PPG.png'),
  PRG: require('../Assets/Images/Casino/PRG.png'),
  PSN: require('../Assets/Images/Casino/PSN.png'),
  PTG: require('../Assets/Images/Casino/PTG.png'),
  QSD: require('../Assets/Images/Casino/QSD.png'),
  RLG: require('../Assets/Images/Casino/RLG.png'),
  RLX: require('../Assets/Images/Casino/RLX.png'),
  RPL: require('../Assets/Images/Casino/RPL.png'),
  SPD: require('../Assets/Images/Casino/SPD.png'),
  SPM: require('../Assets/Images/Casino/SPM.png'),
  SYN: require('../Assets/Images/Casino/SYN.png'),
  TKK: require('../Assets/Images/Casino/TKK.png'),
  TPG: require('../Assets/Images/Casino/TPG.png'),
  WZN: require('../Assets/Images/Casino/WZN.png'),
  SPB: require('../Assets/Images/Casino/SPB.png'),
  RTE: require('../Assets/Images/Casino/RTE.png'),
  NCE: require('../Assets/Images/Casino/NCE.png'),
  NEE: require('../Assets/Images/Casino/NEE.png'),
  BGE: require('../Assets/Images/Casino/BGE.png'),
  BSB: require('../Assets/Images/Casino/BSB.png'),
};
