import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useScreenSize} from '../../Hooks';

import {AppModal, AppModalBackButton} from '../UI';

import {setMainMenuModal} from '../../Redux/AppSlice';
import {getUser} from '../../Redux/UserSlice/UserSelectors';
import {getMainMenuModal} from '../../Redux/AppSlice/AppSelectors';

import {
  DEPOSIT,
  PROFILE,
  SETTINGS,
  WITHDRAW,
  TIME_OUT,
  MAIN_MENU,
  PROMOTIONS,
  MY_PROFILE,
  VERIFY_ACCOUNT,
  DEPOSIT_LIMITS,
  SEND_TO_FRIEND,
  SELF_EXCLUSION,
  CHANGE_PASSWORD,
  PERSONAL_DETAILS,
  BALANCE_MANAGEMENT,
  TRANSACTION_HISTORY,
} from '../../Constants/MainMenu';
import {BREAKPOINT_XS} from '../../Constants/Globals';

import './index.scss';

import Profile from '../MainMenu/Profile';
import SettingsTab from './Tabs/SettingsTab';
import MainMenuTab from './Tabs/MainMenuTab';
import MyProfileTabs from './Tabs/MyProfileTabs';
import PromotionsTab from './Tabs/PromotionsTab';
import BalanceManagement from '../MainMenu/BalanceManagement';
import BalanceManagementTab from './Tabs/BalanceManagementTab';
import TransactionHistory from '../MainMenu/TransactionHistory';

const NewMainMenu = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const user = useSelector(getUser);

  const mainMenuModal = useSelector(getMainMenuModal);

  const renderHeader = useMemo(
    () =>
      mainMenuModal?.forceToOpenProfileIndex === 0
        ? PERSONAL_DETAILS
        : mainMenuModal?.forceToOpenProfileIndex === 1
        ? CHANGE_PASSWORD
        : mainMenuModal?.forceToOpenProfileIndex === 2
        ? SELF_EXCLUSION
        : mainMenuModal?.forceToOpenProfileIndex === 3
        ? TIME_OUT
        : mainMenuModal?.forceToOpenProfileIndex === 4
        ? DEPOSIT_LIMITS
        : mainMenuModal?.forceToOpenProfileIndex === 5
        ? VERIFY_ACCOUNT
        : mainMenuModal?.tab === BALANCE_MANAGEMENT ||
          mainMenuModal?.tab === WITHDRAW ||
          mainMenuModal?.tab === DEPOSIT ||
          mainMenuModal?.tab === SEND_TO_FRIEND
        ? BALANCE_MANAGEMENT
        : mainMenuModal?.tab === SETTINGS
        ? SETTINGS
        : mainMenuModal?.tab === MY_PROFILE || mainMenuModal?.tab === PROFILE
        ? MY_PROFILE
        : mainMenuModal?.tab === PROMOTIONS
        ? PROMOTIONS
        : MAIN_MENU,
    [mainMenuModal?.forceToOpenProfileIndex, mainMenuModal?.tab],
  );

  const renderTab = useMemo(
    () => (
      <div className="full-width " style={{minHeight: 270}}>
        {mainMenuModal?.tab === MAIN_MENU ? (
          <MainMenuTab />
        ) : mainMenuModal?.tab === BALANCE_MANAGEMENT ? (
          <BalanceManagementTab />
        ) : mainMenuModal?.tab === WITHDRAW ||
          mainMenuModal?.tab === DEPOSIT ||
          mainMenuModal?.tab === SEND_TO_FRIEND ? (
          <BalanceManagement />
        ) : mainMenuModal?.tab === TRANSACTION_HISTORY ? (
          <TransactionHistory />
        ) : mainMenuModal?.tab === SETTINGS ? (
          <SettingsTab />
        ) : mainMenuModal?.tab === PROMOTIONS ? (
          <PromotionsTab />
        ) : mainMenuModal?.tab === MY_PROFILE ? (
          <MyProfileTabs />
        ) : mainMenuModal?.tab === PROFILE ? (
          <Profile />
        ) : null}
      </div>
    ),
    [mainMenuModal?.tab],
  );

  const backClick = useCallback(() => {
    dispatch(
      setMainMenuModal({
        tab:
          mainMenuModal?.tab === WITHDRAW ||
          mainMenuModal?.tab === DEPOSIT ||
          mainMenuModal?.tab === SEND_TO_FRIEND
            ? BALANCE_MANAGEMENT
            : mainMenuModal?.tab === PROFILE
            ? MY_PROFILE
            : mainMenuModal?.tab === MY_PROFILE
            ? SETTINGS
            : MAIN_MENU,
        forceToOpenProfileIndex: null,
      }),
    );
  }, [dispatch, mainMenuModal?.tab]);

  return (
    <AppModal
      border
      closeIconType={2}
      contentClassName={`newLightBlueBackground px-sm pt-md pb-xs`}
      removeContentPaddings={
        true
        // mainMenuModal?.tab === WITHDRAW ||
        // mainMenuModal?.tab === DEPOSIT ||
        // mainMenuModal?.tab === MAIN_MENU ||
        // mainMenuModal?.tab === SEND_TO_FRIEND
      }
      header={
        <div className="flex column items-center full-width">
          {mainMenuModal?.tab !== MAIN_MENU &&
            !(
              mainMenuModal?.tab === WITHDRAW ||
              mainMenuModal?.tab === DEPOSIT ||
              mainMenuModal?.tab === SEND_TO_FRIEND
            ) && <AppModalBackButton onBackClick={backClick} />}
          <span className="py-md font-15 blueText bold-600">
            {t(renderHeader)?.toUpperCase()}
          </span>
        </div>
      }
      isOpen={mainMenuModal?.isVisible}
      maxWidth={width < BREAKPOINT_XS ? '600px' : undefined}
      onRequestClose={() => {
        dispatch(setMainMenuModal({isVisible: false, isDesktop: false}));
        setTimeout(
          () =>
            dispatch(
              setMainMenuModal({
                tab: MAIN_MENU,
                balanceManagementTabIndex: 0,
                forceToOpenProfileIndex: null,
              }),
            ),
          200,
        );
      }}>
      {/*{renderTab}*/}
      <MainMenuTab />
    </AppModal>
  );
};

export default memo(NewMainMenu);
