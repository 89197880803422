import {
  getTodayUnix,
  getLastWeekUnix,
  getThisWeekUnix,
  getThisMonthUnix,
  getLastMonthUnix,
  getYesterdayUnix,
} from '../Utils/GetDatePeriods';

export const WON_STATE = 3;
export const LOST_STATE = 1;
export const ALL_STATE = null;
export const RETURNED_STATE = 2;
export const CASHED_OUT_STATE = 5;

export const BET_HISTORY_FILTERS_BY_STATE = [
  {
    id: 1,
    title: 'allBets',
    value: ALL_STATE,
  },
  {
    id: 2,
    title: 'won',
    value: WON_STATE,
  },
  {
    id: 3,
    title: 'lost',
    value: LOST_STATE,
  },
  {
    id: 4,
    title: 'cashedOut',
    value: CASHED_OUT_STATE,
  },
  {
    id: 5,
    title: 'returned',
    value: RETURNED_STATE,
  },
];

export const TODAY_STATE = 1;
export const YESTERDAY_STATE = 2;
export const THIS_WEEK_STATE = 3;
export const LAST_WEEK_STATE = 4;
export const THIS_MONTH_STATE = 5;
export const LAST_MONTH_STATE = 6;

export const BET_HISTORY_FILTERS_BY_DATE = [
  {
    id: 1,
    title: 'today',
    value: getTodayUnix(),
  },
  {
    id: 2,
    title: 'yesterday',
    value: getYesterdayUnix(),
  },
  {
    id: 3,
    title: 'thisWeek',
    value: getThisWeekUnix(),
  },
  {
    id: 4,
    title: 'lastWeek',
    value: getLastWeekUnix(),
  },
  {
    id: 5,
    title: 'thisMonth',
    value: getThisMonthUnix(),
  },
  {
    id: 6,
    title: 'lastMonth',
    value: getLastMonthUnix(),
  },
];
