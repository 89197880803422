import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const HomeSportSkeletons = ({isMobile}) => (
  <div className="flex row gap-5">
    {Array.apply(null, Array(5))?.map((_, index) => (
      <Skeleton
        count={1}
        key={index}
        width={100}
        duration={0.7}
        className="mb-xs"
        borderRadius="5px"
        height={isMobile ? 60 : 35}
        baseColor="rgb(219, 221, 227)"
        highlightColor="rgb(225, 227, 231)"
      />
    ))}
  </div>
);

export default memo(HomeSportSkeletons);
