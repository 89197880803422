import React, {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const BetItemInfoSkeleton = () => (
  <Skeleton
    count={1}
    width={120}
    height={35}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--darkenGray)"
    highlightColor="var(--semiLightGray)"
  />
);

export default memo(BetItemInfoSkeleton);
