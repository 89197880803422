import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../Redux/AppSlice';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {
  CASINO_METHODS,
  PAYMENT_SERVICES,
  LE_GRANDE_CASINO_ID,
  PALM_BEACH_CASINO_ID,
  CASINO_MAX_WITHDRAW_AMOUNT,
  CASINO_MIN_WITHDRAW_AMOUNT,
  OPERATOR_MIN_WITHDRAW_AMOUNT,
  OPERATOR_MAX_WITHDRAW_AMOUNT,
  PIGABET_SHOP,
  BANK,
  LAS_VEGAS_CASINO_TITLE,
} from '../../../../Constants/PaymentServices';
import {WITHDRAW} from '../../../../Constants/Socket';
import {
  BREAKPOINT_XS,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../../Constants/Globals';

import {AppButton, AppInput} from '../../../UI';

import logo from '../../../../Assets/Layout/HeaderLogo.png';

const Withdraw = ({userOperator}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {withdraw} = useSocket();
  const {width} = useScreenSize();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const inputRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [activeOperator, setActiveOperator] = useState(
    PAYMENT_SERVICES.find(item => item?.title === userOperator) ||
      PAYMENT_SERVICES?.[0],
  );

  const withdrawClickHandler = useCallback(() => {
    setIsWithdrawLoading(true);
    withdraw({
      amount: withdrawAmount,
      service: activeOperator?.id,
      ...(activeOperator?.office_id
        ? {
            office_id: activeOperator?.office_id,
          }
        : {}),
    });
  }, [activeOperator?.id, activeOperator?.office_id, withdraw, withdrawAmount]);

  const isCasinoOperator = useMemo(
    () =>
      activeOperator?.id === PALM_BEACH_CASINO_ID ||
      activeOperator?.id === LE_GRANDE_CASINO_ID,
    [activeOperator?.id],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case WITHDRAW:
          if (
            data?.data?.result === 0 ||
            data?.data?.result === '0' ||
            data?.data?.status?.trim() === 'finish_status'
          ) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('withdrawCompletedTitle', {
                    currency,
                    amountValue: bigNumbersPrettier(withdrawAmount),
                  }),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          } else {
            inputRef?.current.blur();

            if (
              data?.data?.status?.trim() === 'error' &&
              typeof data?.data?.message === 'string'
            ) {
              setErrorMsg(data?.data?.message);
            } else if (typeof data?.data?.details === 'string') {
              setErrorMsg(data?.data?.details);
            }
          }
          setIsWithdrawLoading(false);
          break;
        default:
          break;
      }
    },
    [currency, dispatch, t, withdrawAmount],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="menu-balance-withdrawContainer full-height column justify-between flex-1">
      <div className="column pt-lg">
        <span className="whiteText font-12 bold-500">
          {t('selectWithdrawMethod')}
        </span>
        <div className="column gap-15 pb-xxxl pt-sm">
          <div className="row gap-10">
            {/*<div className="flex items-center justify-center rounded-borders pa-sm paymentsHeaderContainer flex-shrink">*/}
            {/*  <span className="text-center grayText bold-500">MOBILE MONEY</span>*/}
            {/*</div>*/}
            <Swiper
              freeMode
              keyboard
              mousewheel
              spaceBetween={5}
              modules={[Keyboard, FreeMode]}
              className="paymentsSwiper full-width"
              slidesPerView={width < BREAKPOINT_XS ? 'auto' : 5}
              initialSlide={
                PAYMENT_SERVICES?.findIndex?.(
                  item => item?.title === userOperator,
                ) || 0
              }>
              {PAYMENT_SERVICES?.map(
                item =>
                  item?.title !== BANK && (
                    <SwiperSlide key={item?.id}>
                      <img
                        src={item?.logo}
                        alt={item?.title}
                        onClick={() => setActiveOperator(item)}
                        className={`rounded-borders block paymentSwiperImage cursor-pointer ${
                          activeOperator?.title !== item?.title
                            ? 'not-selected'
                            : ''
                        }`}
                      />
                    </SwiperSlide>
                  ),
              )}
            </Swiper>
          </div>
          <div className="column gap-5">
            <span className="whiteText font-12 bold-500">
              {`${t('selectCasinoToWithdrawFrom')} :`}
            </span>
            {/*<div className="flex items-center justify-center rounded-borders pa-sm paymentsHeaderContainer flex-shrink">*/}
            {/*  <span className="text-center grayText bold-500">CASH DESK</span>*/}
            {/*</div>*/}
            <Swiper
              freeMode
              keyboard
              mousewheel
              modules={[Keyboard, FreeMode]}
              className="casinoPaymentsSwiper full-width"
              spaceBetween={width < BREAKPOINT_XS ? 5 : 10}
              slidesPerView={width < BREAKPOINT_XS ? 'auto' : 3}>
              {CASINO_METHODS.map(item => (
                <SwiperSlide key={item?.id}>
                  <div
                    className={`flex-center flex rounded-borders casinoPaymentSwiperImageContainer ${
                      activeOperator?.title !== item?.title
                        ? 'not-selected'
                        : ''
                    }`}
                    style={{
                      width: 140,
                      height: 50,
                      background: item?.background,
                    }}>
                    <img
                      src={item?.logo}
                      alt={item?.title}
                      onClick={() => setActiveOperator(item)}
                      style={{
                        height:
                          item?.title === LAS_VEGAS_CASINO_TITLE ? 36 : 90,
                        objectFit: 'contain',
                      }}
                      className={`casinoPaymentSwiperImage cursor-pointer`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="gap-5 column">
            <span className="whiteText font-12 bold-500">
              {`${t('bettingShops')} :`}
            </span>
            <div
              className={`items-center flex rounded-borders ${
                activeOperator?.title !== PIGABET_SHOP?.title
                  ? 'not-selected'
                  : ''
              }`}
              style={{
                background: PIGABET_SHOP?.background,
              }}>
              <img
                src={PIGABET_SHOP.logo}
                alt={PIGABET_SHOP.title}
                onClick={() => setActiveOperator(PIGABET_SHOP)}
                style={{
                  objectFit: 'contain',
                }}
                className={`casinoPaymentSwiperImage cursor-pointer `}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="column gap-4 pb-md">
        <span className="font-12 whiteText bold-500">{t('amount')}</span>
        <div className="row gap-10">
          <div className="flex-1">
            <AppInput
              type={9}
              height={35}
              error={errorMsg}
              inputRef={inputRef}
              errorNumberOfLines={2}
              setErrorMsg={setErrorMsg}
              inputValue={withdrawAmount}
              onChange={setWithdrawAmount}
              placeholder={t('enterAmountToWithdraw')}
            />
          </div>
          <div>
            <AppButton
              type={3}
              height={35}
              fontSize={15}
              titleClassName="bold-600"
              loading={isWithdrawLoading}
              onClick={withdrawClickHandler}
              className="flex items-center justify-center paddingVertical-9 px-xxl"
              title={t('withdraw')?.toUpperCase?.()}
              disabled={
                !+withdrawAmount ||
                +withdrawAmount <
                  (isCasinoOperator
                    ? CASINO_MIN_WITHDRAW_AMOUNT
                    : OPERATOR_MIN_WITHDRAW_AMOUNT)
              }
            />
          </div>
        </div>
        <span className="font-10 bold-400" style={{color: '#FFFFFF59'}}>
          {t('minMaxLimits', {
            currency,
            minValue: bigNumbersPrettier(
              isCasinoOperator
                ? CASINO_MIN_WITHDRAW_AMOUNT
                : OPERATOR_MIN_WITHDRAW_AMOUNT,
            ),
            maxValue: bigNumbersPrettier(
              isCasinoOperator
                ? CASINO_MAX_WITHDRAW_AMOUNT
                : OPERATOR_MAX_WITHDRAW_AMOUNT,
            ),
          })}
        </span>
      </div>
    </div>
  );
};

export default memo(Withdraw);
