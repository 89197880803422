import React, {memo, useCallback, useState} from 'react';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {useSocket} from '../../../Hooks';

import {formatUnixDate} from '../../../Utils/DateFormatter';

import {ReactComponent as DeleteIcon} from '../../../Assets/Icons/Globals/delete.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downTriangle.svg';
import trashIcon from '../../../Assets/Icons/Globals/trash.svg';

const MessageItem = ({item, index, sentMessage = false}) => {
  const {readInbox, deleteInbox} = useSocket();

  const [isExpanded, setExpanded] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const onClick = useCallback(() => {
    setExpanded(prevExpanded => !prevExpanded);
    if (!item?.checked && !sentMessage) {
      readInbox({inboxId: item?.id, index});
    }
  }, [index, item?.checked, item?.id, readInbox, sentMessage]);

  const onDeleteInbox = useCallback(
    e => {
      e.stopPropagation();
      deleteInbox({inboxId: item?.id, type: sentMessage ? 'sent' : 'inbox'});
    },
    [deleteInbox, item?.id, sentMessage],
  );

  const TriangleIcon = isExpanded ? TopTriangle : DownTriangle;

  return (
    <div className="messageItemContainer rounded-borders">
      <div
        {...getToggleProps({
          onClick,
        })}>
        <div
          style={{height: 45, background: isExpanded ? 'white' : '#1b355d'}}
          className={`row px-md py-lg items-center justify-between ${
            item?.checked ? 'checked ' : 'unChecked '
          } ${
            isExpanded
              ? 'blueText rounded-top-borders'
              : 'whiteText rounded-borders'
          }`}>
          <div className="row items-center gap-40">
            <span className=" font-12 bold-600">{item?.subject}</span>
            <span className=" font-12 bold-500">
              {formatUnixDate(item?.date)}
            </span>
          </div>
          <div className="gap-5 row items-center">
            {item?.is_deletable && (
              <div
                style={{
                  width: 25,
                  height: 25,
                }}
                onClick={e => onDeleteInbox(e)}
                className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer">
                <img width={11} height={13} alt="delete" src={trashIcon} />
              </div>

              //     <DeleteIcon
              //     width={15}
              //   height={15}
              //   fill="var(--appRed)"
              //   onClick={e => onDeleteInbox(e)}
              // />
            )}
            {/*<TriangleIcon width={8} height={8} fill="var(--white)" />*/}
          </div>
        </div>
      </div>
      <div
        {...getCollapseProps()}
        className={`flex column checked ${
          isExpanded ? 'blueText' : 'whiteText'
        }`}>
        <div
          className={`px-md pt-md pb-xl rounded-bottom-borders ${
            isExpanded ? 'whiteBackground' : 'whiteText'
          }`}>
          <span className=" bold-500 font-12 text-pre text-wrap">
            {item?.body}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(MessageItem);
