import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  user: {},
  userSubId: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, {payload}) => {
      state.user = payload ? {...(state?.user || {}), ...payload} : payload;
    },
    setUserSubId: (state, {payload}) => {
      state.userSubId = payload;
    },
  },
});

export const {setUser, setUserSubId} = userSlice.actions;

export default userSlice.reducer;
