import {useCallback, useEffect, useRef} from 'react';
import i18next from 'i18next';
import {useDispatch, useSelector} from 'react-redux';

import {
  setSid,
  setLanguage,
  setRecaptcha,
  setWssSocket,
  setIsLoggedIn,
} from '../Redux/AppSlice';
import store from '../Redux/store';
import {setOpenBetsCount} from '../Redux/BettingSlice';
import {setUser, setUserSubId} from '../Redux/UserSlice';
import {getWssSocket} from '../Redux/AppSlice/AppSelectors';
import {setPartner, setPartnerSubId} from '../Redux/PartnerSlice';

import {
  SITE_ID,
  GET_USER,
  LOGOUT_USER,
  WSS_ENDPOINT,
  RESTORE_LOGIN,
  LOGIN_ENCRYPTED,
  REQUEST_SESSION,
  SOCKET_RESPONSES,
  GET_PARTNER_CONFIG,
  SUBSCRIBE_TO_EVENTS,
  GET_OPENED_BETS_COUNT,
  CHANGE_SESSION_LANGUAGE,
} from '../Constants/Socket';
import {APP_LANGUAGES} from '../Constants/Languages';

import {
  useSocket,
  useStorage,
  useAppFunctions,
  useGetNetworkStatus,
} from './index';

let reconnectionTimeout = null;

const useConnectSocket = () => {
  const {
    logout,
    getUser,
    restoreLogin,
    requestSession,
    loginEncrypted,
    getPartnerConfig,
    getOpenedBetsCount,
  } = useSocket();
  const dispatch = useDispatch();
  const {subscribeToEvent} = useSocket();
  const {logoutCb, loginCb} = useAppFunctions();
  const {networkStatus} = useGetNetworkStatus();
  const {getLoginDataFromStorage, getBetSlipDataFromStorage} = useStorage();

  const wssSocket = useSelector(getWssSocket);

  const userDataRef = useRef({});
  const removeUserDataFromStoreRef = useRef(true);

  const onOpenCb = useCallback(() => {
    if (
      typeof i18next.language === 'string' &&
      (i18next.language === APP_LANGUAGES.SWA ||
        i18next.language === APP_LANGUAGES.ENG ||
        i18next.language === APP_LANGUAGES.CHN)
    ) {
      dispatch(setLanguage(i18next.language));
      requestSession(i18next.language);
    } else {
      dispatch(setLanguage(APP_LANGUAGES.ENG));
      requestSession(APP_LANGUAGES.ENG);
    }
  }, [dispatch, requestSession]);

  const onCloseCb = useCallback(() => {
    clearTimeout(reconnectionTimeout);
    reconnectionTimeout = setTimeout(() => {
      console.log('socket closed');
      dispatch(setWssSocket(new WebSocket(WSS_ENDPOINT)));
    }, 1000);
  }, [dispatch]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event.data);

      if (data?.code === SOCKET_RESPONSES.NEED_RECAPTCHA) {
        dispatch(
          setRecaptcha({
            isVisible: true,
            action: data?.rid,
            site_key: data?.data,
          }),
        );
        return;
      }

      const updatedUserData = Object.values(
        data?.data?.[store?.getState()?.user?.userSubId]?.profile || {},
      )?.[0];

      const updatedPartnerData = Object.values(
        data?.data?.[store?.getState()?.partner?.partnerSubId]?.partner || {},
      )?.[0];

      if (updatedUserData) {
        if (updatedUserData?.logout) {
          removeUserDataFromStoreRef.current = false;
          const loginData = await getLoginDataFromStorage();
          logout({jwe_token: loginData?.jwe_token});
        } else {
          getOpenedBetsCount();
          dispatch(setUser(updatedUserData));
        }
      }

      if (updatedPartnerData) {
        dispatch(setPartner(updatedPartnerData));
      }

      switch (data?.rid) {
        case REQUEST_SESSION:
          dispatch(setSid(data?.data?.sid));
          getPartnerConfig();
          const loginData = await getLoginDataFromStorage();
          if (!!loginData?.jwe_token && !!loginData?.auth_token) {
            loginEncrypted({
              jwe_token: loginData?.jwe_token,
              auth_token: loginData?.auth_token,
            });
          } else if (!!loginData?.auth_token) {
            restoreLogin({
              user_id: loginData?.user_id,
              auth_token: loginData?.auth_token,
            });
          } else {
            dispatch(setIsLoggedIn(false));
          }

          const betSlipData = getBetSlipDataFromStorage();
          if (betSlipData?.length) {
            betSlipData.forEach(game => {
              const gameId = game?.id;
              const marketId = +Object.keys(game?.market || {})?.[0];
              const eventId = +Object.keys(
                game?.market?.[marketId]?.event || {},
              )?.[0];
              subscribeToEvent({
                gameId,
                eventId,
                marketId,
                rid: `${SUBSCRIBE_TO_EVENTS}_${marketId}_${eventId}`,
              });
            });
          }
          break;
        case RESTORE_LOGIN:
        case LOGIN_ENCRYPTED:
          if (!!data?.data?.auth_token) {
            getUser();
            userDataRef.current = {
              user_id: data?.data?.user_id,
              ...(data?.data?.jwe_token
                ? {jwe_token: data?.data?.jwe_token}
                : {}),
              auth_token: data?.data?.auth_token,
            };
          } else {
            dispatch(setIsLoggedIn(false));
          }
          break;
        case GET_USER:
          if (data?.code === 0) {
            dispatch(setUserSubId(data?.data?.subid));
            await loginCb({
              user_id: userDataRef.current?.user_id,
              ...(userDataRef.current?.jwe_token
                ? {jwe_token: userDataRef.current?.jwe_token}
                : {}),
              auth_token: userDataRef.current?.auth_token,
              user: Object.values(data?.data?.data?.profile || {})?.[0],
            });
          }
          break;
        case GET_PARTNER_CONFIG:
          dispatch(setPartnerSubId(data?.data?.subid));
          dispatch(setPartner(data?.data?.data?.partner?.[SITE_ID] || {}));
          break;
        case LOGOUT_USER:
          await logoutCb(removeUserDataFromStoreRef.current);
          break;
        case GET_OPENED_BETS_COUNT:
          dispatch(setOpenBetsCount(data?.data?.bets?.count));
          break;
        case CHANGE_SESSION_LANGUAGE:
          onCloseCb();
          break;
        default:
          break;
      }
    },
    [
      logout,
      getUser,
      loginCb,
      dispatch,
      logoutCb,
      onCloseCb,
      restoreLogin,
      loginEncrypted,
      getPartnerConfig,
      subscribeToEvent,
      getOpenedBetsCount,
      getLoginDataFromStorage,
      getBetSlipDataFromStorage,
    ],
  );

  useEffect(() => {
    if (!!networkStatus) {
      dispatch(setWssSocket(new WebSocket(WSS_ENDPOINT)));
    }
  }, [networkStatus]);

  useEffect(() => {
    if (wssSocket) {
      wssSocket?.addEventListener?.('open', onOpenCb);
      wssSocket?.addEventListener?.('message', onMessageCb);
      wssSocket?.addEventListener?.('close', onCloseCb);
    }

    return () => {
      wssSocket?.removeEventListener?.('open', onOpenCb);
      wssSocket?.removeEventListener?.('message', onMessageCb);
      wssSocket?.removeEventListener?.('close', onCloseCb);
    };
  }, [wssSocket]);
};

export default useConnectSocket;
