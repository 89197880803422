import React, {memo, useCallback, useEffect, useMemo} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useDispatch, useSelector} from 'react-redux';

import {
  getLanguage,
  getRecaptcha,
  getWssSocket,
} from '../../../Redux/AppSlice/AppSelectors';
import {setRecaptcha, setShowIntro} from '../../../Redux/AppSlice';

import {useSocket} from '../../../Hooks';

import {
  GET,
  VALIDATE_SESSION,
  VALIDATE_RECAPTCHA,
} from '../../../Constants/Socket';
import {APP_LANGUAGES} from '../../../Constants/Languages';

import './index.scss';

const Recaptcha = () => {
  const dispatch = useDispatch();
  const {validateRecaptcha} = useSocket();

  const recaptcha = useSelector(getRecaptcha);
  const language = useSelector(getLanguage);
  const wssSocket = useSelector(getWssSocket);

  const languageCode = useMemo(
    () =>
      language === APP_LANGUAGES.CHN
        ? 'zh-CN'
        : language === APP_LANGUAGES.SWA
        ? 'sw'
        : 'en',
    [language],
  );

  const onChange = useCallback(
    g_recaptcha_response => {
      validateRecaptcha({
        action:
          recaptcha?.action === GET ? VALIDATE_SESSION : recaptcha?.action,
        g_recaptcha_response,
      });
    },
    [recaptcha?.action, validateRecaptcha],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case VALIDATE_RECAPTCHA:
          if (data?.code === 0 && data?.data?.result) {
            dispatch(setRecaptcha({isVisible: false}));
            dispatch(setShowIntro({isVisible: false}));
          }
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);
    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="appRecaptchaWrapper flex justify-center items-center">
      <ReCAPTCHA
        hl={languageCode}
        onChange={onChange}
        sitekey={recaptcha?.site_key}
      />
    </div>
  );
};

export default memo(Recaptcha);
