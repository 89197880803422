import React, {memo} from 'react';

import {ReactComponent as TwitterIcon} from '../../../../Assets/Icons/Socials/x.svg';
import {ReactComponent as FacebookIcon} from '../../../../Assets/Icons/Socials/facebook.svg';
import {ReactComponent as TelegramIcon} from '../../../../Assets/Icons/Socials/telegram.svg';
import {ReactComponent as WhatsappIcon} from '../../../../Assets/Icons/Socials/whatsapp.svg';

const BetSocialShare = ({betId}) => (
  <div className="flex justify-between items-center gap-10 py-md">
    <a
      target="_blank"
      rel="noreferrer"
      className="flex"
      href={`whatsapp://send?text=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
      <FacebookIcon />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      className="flex"
      href={`https://twitter.com/intent/tweet?url=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
      <TwitterIcon />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      className="flex"
      href={`https://t.me/share/url?url=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
      <TelegramIcon />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      className="flex"
      href={`whatsapp://send?text=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
      <WhatsappIcon />
    </a>
  </div>
);

export default memo(BetSocialShare);
