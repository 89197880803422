import React, {memo, useCallback} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useSearchParams} from 'react-router-dom';
import {FreeMode, Keyboard} from 'swiper/modules';

import {AppButton} from '../../UI';

import {getNextSevenDaysUnixTimestamps} from '../../../Utils/GetNextSevenDaysFilters';

import './index.scss';

const LiveCalendarFilters = () => {
  const [getParam, setGetParam] = useSearchParams();

  const getDayParam = getParam.get('day');

  const onClickItem = useCallback(
    item => {
      getParam.delete('region');
      getParam.set('day', `${item?.startOfDay}_${item?.endOfDay}`);
      setGetParam(getParam);
    },
    [getParam, setGetParam],
  );

  return (
    <div className="liveCalendarFilters row full-width mb-sm">
      <Swiper
        freeMode
        keyboard
        mousewheel
        spaceBetween={5}
        slidesPerView="auto"
        modules={[Keyboard, FreeMode]}
        className="liveCalendarFiltersSwiper full-width">
        {(getNextSevenDaysUnixTimestamps() || [])?.map((item, index) => (
          <SwiperSlide key={index}>
            <AppButton
              type={15}
              onClick={() => onClickItem(item)}
              className="py-xs px-sm"
              active={
                +item?.startOfDay === +getDayParam?.split?.('_')?.[0] &&
                +item?.endOfDay === +getDayParam?.split?.('_')?.[1]
              }
              title={
                <div className="column px-lg">
                  <span className="text-caption-small bold-600 blueText">
                    {item?.dayName}
                  </span>
                  <span className="text-caption-small bold-600 blueText">
                    {item?.day}/{item?.monthNumber}
                  </span>
                </div>
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(LiveCalendarFilters);
