import {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getLanguage} from '../../../Redux/AppSlice/AppSelectors';

import './index.scss';

import useAppFunctions from '../../../Hooks/UseAppFunctions';

import {availableLanguages} from '../../../Constants/Languages';

import {AppButton, AppModal} from '../index';

import {ReactComponent as TriangleTop} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downTriangle.svg';

const LanguageChooser = ({type = 1, ButtonComponent = null}) => {
  const {t} = useTranslation();
  const {changeLanguage} = useAppFunctions();

  const language = useSelector(getLanguage);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const saveClickHandler = useCallback(() => {
    changeLanguage(selectedLanguage);
    setIsOpen(false);
  }, [changeLanguage, selectedLanguage]);

  const ArrowIcon = isOpen ? TriangleTop : DownTriangle;

  return (
    <div className="appLanguageChooserContainer">
      {ButtonComponent ? (
        <div onClick={() => setIsOpen(true)}>{ButtonComponent}</div>
      ) : (
        <div
          style={{
            backgroundColor: type === 3 && isOpen ? 'var(--appYellow)' : '',
          }}
          onClick={() =>
            type === 3 ? setIsOpen(prev => !prev) : setIsOpen(true)
          }
          className={`languageContainer ${
            type === 3 && isOpen ? 'rounded-top-borders' : 'rounded-borders'
          } background_${type} rowCenter px-md gap-3 cursor-pointer`}>
          <img
            className="flag"
            alt="choosedLanguage"
            src={require(
              `../../../Assets/Icons/Flags/${language || 'eng'}.svg`,
            )}
          />
          <div className="flex-shrink">
            <ArrowIcon
              width={11}
              height={8}
              fill={`${
                type === 3 && isOpen ? 'var(--appBlue)' : 'var(--darkenGray)'
              }`}
            />
          </div>
        </div>
      )}
      {type !== 3 && (
        <AppModal
          isOpen={isOpen}
          maxWidth="400px"
          onRequestClose={() => setIsOpen(false)}
          onAfterOpen={() => setSelectedLanguage(language)}
          header={
            <div className="py-md">
              <span className="text-body2 blueText bold-600">
                {t('chooseLanguage')}
              </span>
            </div>
          }>
          <div className="column items-center full-width gap-5 languageItemContainer px-xxxl">
            <div className="column gap-10 full-width">
              {availableLanguages?.map(item => (
                <AppButton
                  type={2}
                  key={item?.status}
                  active={item?.status === selectedLanguage}
                  onClick={() => setSelectedLanguage(item?.status)}
                  title={
                    <div className="flex items-center gap-10 justify-center">
                      <img width={30} alt={item?.status} src={item?.icon} />
                      <span className="text-body2 bold-500">
                        {t(item?.title)}
                      </span>
                    </div>
                  }
                />
              ))}
            </div>
            <div className="mt-auto full-width">
              <AppButton
                type={3}
                title={t('save')}
                onClick={saveClickHandler}
              />
            </div>
          </div>
        </AppModal>
      )}
      {type === 3 && isOpen && (
        <div
          className="column absolute gap-4 pa-sm"
          style={{
            right: 40,
            zIndex: 1,
            background: 'rgb(2,34,86)',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            border: '1px solid var(--appYellow)',
          }}>
          {availableLanguages?.map(item => (
            <div
              key={item?.status}
              onClick={() => {
                changeLanguage(item?.status);
                setIsOpen(false);
              }}
              className="row items-center gap-10 px-md py-md cursor-pointer rounded-borders"
              style={{
                width: 110,
                height: 30,
                background:
                  item?.status === language
                    ? 'var(--appYellow)'
                    : ' rgb(19, 50, 99)',
              }}>
              <img width={20} alt={item?.status} src={item?.icon} />
              <span
                className={`font-10 bold-600 ${
                  item?.status === language ? 'blueText' : 'whiteText'
                }`}>
                {t(item?.title)}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(LanguageChooser);
