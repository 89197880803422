import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import './index.scss';

import {useOutSideClickHandler, useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import {AppButton} from '../../UI';
import HomeSportSkeletons from '../../UI/Skeletons/HomeSportSkeletons';
import PopularCompetitionSkeletons from '../../UI/Skeletons/PopularCompetitionSkeletons';

import {getIsContentScrolled} from '../../../Redux/AppSlice/AppSelectors';
import {getIsSportDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {filterCasinoSubCategories} from '../../../Utils/FilterCasinoSubCategories';

import {
  SLOTS_FIXED_SUB_CATEGORIES,
  LIVE_CASINO_FIXED_SUB_CATEGORIES,
} from '../../../Constants/CasinoSubCategoriesArray';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {PROVIDER_ICONS} from '../../../Constants/ProviderIcons';
import {PROVIDERS_ALL_GAMES_NAME} from '../../../Constants/CasinoMenuFilter';

import allProviders from '../../../Assets/Images/Casino/all.png';
import {setShowCasinoSearchModal} from '../../../Redux/CasinoSlice';

import lastPlayedImage from '../../../Assets/Images/Casino/lastPlayed.png';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';
import mobileLastPlayedImage from '../../../Assets/Images/Casino/mobileLastPlayed.svg';
import {ReactComponent as DownProviders} from '../../../Assets/Icons/Casino/downProviders.svg';
import {show} from 'react-modal/lib/helpers/ariaAppHider';

const HomeProviders = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getSlotsSubCategories, getProviders, getLiveCasinoSubCategories} =
    useCasino();

  const subCategoryIdParam = useMemo(
    () => getParam.get('subCategoryId'),
    [getParam],
  );
  const providerParam = useMemo(() => getParam.get('provider'), [getParam]);

  const isContentScrolled = useSelector(getIsContentScrolled);
  const isSportDataLoading = useSelector(getIsSportDataLoading);

  const providersContentRef = useRef(null);
  useOutSideClickHandler(providersContentRef, () => {
    setShowProviders(false);
  });
  const [providers, setProviders] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showProviders, setShowProviders] = useState(false);
  const [providersLoading, setProvidersLoading] = useState(false);
  const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);

  useEffect(() => {
    setProvidersLoading(true);
    if (params?.category === 'slots') {
      getProviders({categoryId: subCategoryIdParam})
        ?.then(res => {
          setProviders(res?.data?.providers);
        })
        ?.finally(() => {
          setTimeout(() => {
            setProvidersLoading(false);
          }, 300);
        });
    } else {
      getLiveCasinoSubCategories({extra_category_id: subCategoryIdParam})
        ?.then(res => {
          setProviders(res?.data?.providers);
        })
        ?.finally(() => {
          setTimeout(() => {
            setProvidersLoading(false);
          }, 300);
        });
    }
    if (!providerParam) {
      getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
      setGetParam(getParam);
    }
  }, [subCategoryIdParam]);

  useEffect(() => {
    setProvidersLoading(true);
    setSubCategoriesLoading(true);
    if (params?.category === 'slots') {
      getSlotsSubCategories()
        ?.then(res => {
          const filteredSubCategories = filterCasinoSubCategories(
            res?.data?.categories,
            SLOTS_FIXED_SUB_CATEGORIES,
          );
          setSubCategories(filteredSubCategories);
        })
        ?.finally(() => {
          setTimeout(() => {
            setSubCategoriesLoading(false);
          }, 300);
        });
      getProviders()
        ?.then(res => {
          setProviders(res?.data?.providers);
        })
        ?.finally(() => {
          setTimeout(() => {
            setProvidersLoading(false);
          }, 300);
        });
    } else {
      getLiveCasinoSubCategories()
        ?.then(res => {
          const filteredSubCategories = filterCasinoSubCategories(
            res?.data?.categories,
            LIVE_CASINO_FIXED_SUB_CATEGORIES,
          );
          setSubCategories(filteredSubCategories);

          setProviders(res?.data?.providers);
        })
        ?.finally(() => {
          setTimeout(() => {
            setSubCategoriesLoading(false);
            setProvidersLoading(false);
          }, 300);
        });
    }
    if (!providerParam) {
      getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
      setGetParam(getParam);
    }
  }, [params?.category]);

  const renderProviders = useMemo(
    () => (
      <>
        {width < BREAKPOINT_XS ? null : (
          // <SwiperSlide key="lastPlayed">
          //   <AppButton
          //     type={6}
          //     width={110}
          //     active={location?.pathname?.includes('lastPlayed')}
          //     height={width < BREAKPOINT_XS && !isContentScrolled ? 23 : 'auto'}
          //     title={
          //       <div
          //         onClick={() =>
          //           navigate({
          //             pathname: `/casino/${params?.category}/lastPlayed`,
          //           })
          //         }
          //         className="columnCenter gap-5 lastPlayedContainer">
          //         {!isContentScrolled && (
          //           <img
          //             height={18}
          //             width={18}
          //             src={lastPlayedImage}
          //             alt="lastPlayedIcon"
          //           />
          //         )}
          //         <span className="bold-600 font-12">{t('lastPlayed')}</span>
          //       </div>
          //     }
          //   />
          // </SwiperSlide>
          <SwiperSlide key="lastPlayed">
            <AppButton
              type={6}
              height={36}
              width="fit-content"
              active={location?.pathname?.includes('lastPlayed')}
              title={
                <div
                  onClick={() =>
                    navigate({
                      pathname: `/casino/lastPlayed/${params?.category}`,
                    })
                  }
                  className="row items-center justify-center gap-5 lastPlayedContainer">
                  {!isContentScrolled && (
                    <img
                      width={20}
                      src={lastPlayedImage}
                      alt="lastPlayedIcon"
                    />
                  )}
                  <span className="bold-600 font-12">{t('lastPlayed')}</span>
                </div>
              }
            />
          </SwiperSlide>
        )}
        <SwiperSlide key="allProviders">
          <AppButton
            type={6}
            height={width < BREAKPOINT_XS && !isContentScrolled ? 23 : 'auto'}
            title={
              width < BREAKPOINT_XS ? (
                <div className="columnCenter gap-5">
                  {!isContentScrolled && !(width < BREAKPOINT_XS) && (
                    <img width={25} src={allProviders} alt="allProvidersIcon" />
                  )}
                  <span className="bold-600 font-12">{t('all')}</span>
                </div>
              ) : (
                t('allProviders')
              )
            }
            className="flex items-center gap-5"
            active={providerParam === PROVIDERS_ALL_GAMES_NAME}
            onClick={() => {
              getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
              setGetParam(getParam);
            }}
          />
        </SwiperSlide>
        {providers?.map(providerItem => (
          <SwiperSlide key={providerItem?.name}>
            <AppButton
              type={6}
              height={width < BREAKPOINT_XS && !isContentScrolled ? 23 : 'auto'}
              className="flex items-center gap-5"
              active={providerItem?.name === providerParam}
              onClick={() => {
                getParam.set('provider', providerItem?.name);
                setGetParam(getParam);
              }}
              title={
                isContentScrolled ? (
                  <span className="bold-600 font-12">
                    {providerItem?.title}
                  </span>
                ) : (
                  <img
                    alt={providerItem?.name}
                    src={PROVIDER_ICONS?.[providerItem?.name]}
                    style={{
                      width: 80,
                      objectFit: 'contain',
                      height: width < BREAKPOINT_XS ? 60 : 20,
                    }}
                  />
                )
              }
            />
          </SwiperSlide>
        ))}
      </>
    ),
    [
      t,
      width,
      navigate,
      getParam,
      providers,
      setGetParam,
      providerParam,
      params?.category,
      isContentScrolled,
      location?.pathname,
    ],
  );

  const renderSubCategories = useMemo(
    () => (
      <>
        {subCategories?.map(subCategoryItem => (
          <SwiperSlide key={subCategoryItem?.name}>
            <div
              key={subCategoryItem?.id}
              className={`px-md rowCenter bold-600 gap-5 cursor-pointer ${
                subCategoryItem?.id === subCategoryIdParam
                  ? 'blueText'
                  : 'whiteText'
              }`}
              style={{
                borderRadius: 15,
                backgroundColor:
                  subCategoryItem?.id === subCategoryIdParam
                    ? 'var(--appYellow)'
                    : '#003069',
                height: 30,
              }}
              onClick={() => {
                getParam.delete('provider');
                getParam.set('subCategoryId', subCategoryItem?.id);
                setGetParam(getParam);
              }}>
              <img
                width={12}
                alt={subCategoryItem?.name}
                src={require(
                  `../../../Assets/Icons/Casino/${
                    subCategoryItem?.id === subCategoryIdParam ? 'selected' : ''
                  }${
                    subCategoryItem?.name.charAt(0).toUpperCase() +
                    subCategoryItem?.name.slice(1)
                  }.svg`,
                )}
              />
              <span className="font-9 text-uppercase bold-600">
                {subCategoryItem?.title}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </>
    ),
    [getParam, setGetParam, subCategories, subCategoryIdParam],
  );

  return (
    <div className="subCategoriesContainer gap-10 flex column">
      {width < BREAKPOINT_XS ? (
        <div className="flex rowCenter gap-7">
          <div
            ref={providersContentRef}
            style={{borderRadius: !showProviders && 25}}
            className={`${
              showProviders
                ? 'yellowBackground rounded-top-borders'
                : 'whiteBackground'
            } rowCenter mobileProvidersContainer gap-5 justify-between px-md relative`}
            onClick={() => setShowProviders(prevState => !prevState)}>
            <span className="font-9 bold-600 blueText">{t('Providers')}</span>
            <DownProviders
              width={19}
              height={20}
              style={{transform: showProviders ? 'rotate(180deg)' : ''}}
            />
            {!!showProviders && (
              <div
                style={{
                  top: 30,
                  left: 0,
                  right: 0,
                  zIndex: 999,
                  minWidth: 280,
                  position: 'absolute',
                  background: '#003069',
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  border: '1px solid var(--appYellow)',
                }}>
                <div
                  className="gap-5 flex column scroll-3 scroll-auto-y"
                  style={{
                    margin: 5,
                    minHeight: 150,
                    maxHeight: 350,
                    borderRadius: 5,
                  }}>
                  {providers?.map(providerItem => (
                    <div
                      key={providerItem?.name}
                      className="px-md py-xs cursor-pointer mr-xs"
                      style={{
                        backgroundColor:
                          providerItem?.name === providerParam
                            ? 'var(--appYellow)'
                            : '#174277',
                        borderRadius: 5,
                      }}
                      onClick={e => {
                        e?.preventDefault?.();
                        e?.stopPropagation?.();
                        getParam.set('provider', providerItem?.name);
                        setGetParam(getParam);
                        setShowProviders(false);
                      }}>
                      <span
                        className={`bold-600 font-12 ${
                          providerItem?.name === providerParam
                            ? 'blueText'
                            : 'whiteText'
                        }`}>
                        {providerItem?.title}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <AppButton
            small
            type={5}
            width={65}
            height={30}
            fontSize={9}
            title={t('reset')}
            className="reset rowCenter"
            disabled={
              (!providerParam || providerParam === PROVIDERS_ALL_GAMES_NAME) &&
              !subCategoryIdParam
            }
            onClick={() => {
              getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
              getParam.delete('subCategoryId');
              setGetParam(getParam);
            }}
          />
          <div
            className="rowCenter whiteBackground mobileLastPlayed justify-between px-md"
            onClick={() =>
              navigate({
                pathname: `/casino/lastPlayed/${params?.category}`,
              })
            }>
            <img src={mobileLastPlayedImage} alt="lastPlayedIcon" />
            <span className="bold-600 font-9 text-uppercase">
              {t('lastPlayed')}
            </span>
          </div>
          <div
            className="whiteBackground rowCenter mobileSearchContainer"
            onClick={() => dispatch(setShowCasinoSearchModal(true))}>
            <SearchIcon width={15} height={15} fill="var(--appBlue)" />
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-10 mb-xs wrap">
          <div className="flex items-center gap-10">
            <div className="relative cursor-pointer" ref={providersContentRef}>
              <div
                ref={providersContentRef}
                style={{borderRadius: !showProviders && 25}}
                className={`${
                  showProviders
                    ? 'yellowBackground rounded-top-borders'
                    : 'whiteBackground'
                } rowCenter mobileProvidersContainer gap-10 justify-between px-md relative`}
                onClick={() => setShowProviders(prevState => !prevState)}>
                <span className="font-12 bold-600 blueText">
                  {t('Providers')}
                </span>
                <DownProviders
                  width={19}
                  height={20}
                  style={{transform: showProviders ? 'rotate(180deg)' : ''}}
                />
              </div>
              {showProviders && (
                <div
                  className="absolute pa-md row gap-10 wrap"
                  style={{
                    top: 34,
                    zIndex: 10,
                    width: 927,
                    height: 380,
                    background: '#003069',
                    borderTopRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    border: '1px solid var(--appYellow)',
                  }}>
                  {providers?.map(providerItem => (
                    <div
                      key={providerItem?.name}
                      className="px-md py-xs cursor-pointer mr-xs "
                      style={{
                        height: 35,
                        backgroundColor:
                          providerItem?.name === providerParam
                            ? 'var(--appYellow)'
                            : 'white',
                        borderRadius: 5,
                      }}
                      onClick={e => {
                        e?.preventDefault?.();
                        e?.stopPropagation?.();
                        getParam.set('provider', providerItem?.name);
                        setGetParam(getParam);
                        setShowProviders(false);
                      }}>
                      <img
                        width={150}
                        height={30}
                        alt={providerItem?.name}
                        style={{objectFit: 'contain'}}
                        src={PROVIDER_ICONS?.[providerItem?.name]}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              style={{width: 170, height: 30, borderRadius: 15}}
              onClick={() => dispatch(setShowCasinoSearchModal(true))}
              className="flex rowCenter whiteBackground gap-10 cursor-pointer">
              <SearchIcon width={15} height={15} fill="var(--appBlue)" />
              <span className="blueText bold-600">Search any slot</span>
            </div>
            <AppButton
              small
              type={5}
              width={70}
              height={30}
              className="reset"
              disabled={
                (!providerParam ||
                  providerParam === PROVIDERS_ALL_GAMES_NAME) &&
                !subCategoryIdParam
              }
              title={t('reset')}
              onClick={() => {
                getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
                getParam.delete('subCategoryId');
                setGetParam(getParam);
              }}
            />
          </div>
          {subCategoriesLoading ? (
            <PopularCompetitionSkeletons count={3} />
          ) : (
            width > BREAKPOINT_XS && (
              <div
                className="flex gap-5"
                style={{
                  padding: 1,
                  borderRadius: 25,
                  border: '1px solid var(--appYellow)',
                }}>
                {subCategories?.map(subCategoryItem => (
                  <div
                    key={subCategoryItem?.id}
                    className={`px-md rowCenter bold-600 gap-5 cursor-pointer ${
                      subCategoryItem?.id === subCategoryIdParam
                        ? 'blueText'
                        : 'whiteText'
                    }`}
                    style={{
                      borderRadius: 15,
                      backgroundColor:
                        subCategoryItem?.id === subCategoryIdParam
                          ? 'var(--appYellow)'
                          : '#003069',
                      height: 30,
                    }}
                    onClick={() => {
                      getParam.delete('provider');
                      getParam.set('subCategoryId', subCategoryItem?.id);
                      setGetParam(getParam);
                    }}>
                    <img
                      width={12}
                      alt={subCategoryItem?.name}
                      src={require(
                        `../../../Assets/Icons/Casino/${
                          subCategoryItem?.id === subCategoryIdParam
                            ? 'selected'
                            : ''
                        }${
                          subCategoryItem?.name.charAt(0).toUpperCase() +
                          subCategoryItem?.name.slice(1)
                        }.svg`,
                      )}
                    />
                    <span className="bold-600 font-12 text-uppercase">
                      {subCategoryItem?.title}
                    </span>
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      )}
      {width < BREAKPOINT_XS && (
        <Swiper
          freeMode
          spaceBetween={5}
          slidesPerView="auto"
          keyboard={width > BREAKPOINT_XS}
          mousewheel={width > BREAKPOINT_XS}
          navigation={width > BREAKPOINT_XS}
          slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
          slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
          modules={[
            ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
            FreeMode,
          ]}
          className={`flex items-center homeProvidersSwiper z-index-2 ${
            width < BREAKPOINT_XS ? 'mobile blueBackground' : 'desktop'
          }`}
          style={{
            margin: 0,
            padding: 3,
            width: '100%',
            maxWidth: 788,
            borderRadius: 25,
            border: '1px solid var(--appYellow)',
          }}>
          {renderSubCategories}
        </Swiper>
      )}
      {isSportDataLoading ? (
        <HomeSportSkeletons isMobile={width < BREAKPOINT_XS} />
      ) : providersLoading ? (
        <></>
      ) : providers?.length > 0 ? (
        <Swiper
          freeMode
          spaceBetween={5}
          slidesPerView="auto"
          keyboard={width > BREAKPOINT_XS}
          mousewheel={width > BREAKPOINT_XS}
          navigation={width > BREAKPOINT_XS}
          slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
          slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
          modules={[
            ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
            FreeMode,
          ]}
          className={`flex items-center homeProvidersSwiper full-width z-index-2 ${
            width < BREAKPOINT_XS ? 'mobile blueBackground mb-xl' : 'desktop'
          }`}>
          {renderProviders}
        </Swiper>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HomeProviders;
