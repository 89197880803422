import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  partner: {},
  partnerSubId: null,
};

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setPartner: (state, {payload}) => {
      state.partner = payload
        ? {...(state?.partner || {}), ...payload}
        : payload;
    },

    setPartnerSubId: (state, {payload}) => {
      state.partnerSubId = payload;
    },
  },
});

export const {setPartner, setPartnerSubId} = partnerSlice.actions;

export default partnerSlice.reducer;
