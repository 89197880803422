import {memo, useMemo} from 'react';

import Events from './Events';

import {ReactComponent as BlockedGame} from '../../../Assets/Icons/Globals/blockedGame.svg';

const Markets = ({currentMarkets, displayKey, isDisabled, gameItem}) => {
  const marketsArray = useMemo(
    () =>
      Object.values(currentMarkets || {})?.find?.(
        item => item?.display_key === displayKey,
      ),
    [currentMarkets, displayKey],
  );

  const EventsArray = useMemo(
    () => Object.values(marketsArray?.event || {}),
    [marketsArray?.event],
  );

  return (
    <Events
      gameItem={gameItem}
      displayKey={displayKey}
      marketsArray={marketsArray}
      isSuspended={EventsArray?.length < 0 || !!isDisabled}
    />
  );
};

export default memo(Markets);
