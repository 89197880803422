import React, {memo, useMemo} from 'react';
import {
  GAME_VISUALIZATION_TOKEN,
  SUPPORTED_SPORTS_FOR_VISUALIZATION,
} from '../../../Constants/Globals';
import {useSearchParams} from 'react-router-dom';

import {SITE_ID} from '../../../Constants/Socket';

const LiveTransaction = () => {
  const [getParam] = useSearchParams();

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  return (
    SUPPORTED_SPORTS_FOR_VISUALIZATION?.includes(sportParam) && (
      <iframe
        className="full-width"
        style={{maxWidth: 470, aspectRatio: 1.8}}
        src={`https://visualization.ml.bcua.io/${sportGameIdParam}?lang=eng&score=true
              &team_names=true&timer=false&token=${GAME_VISUALIZATION_TOKEN}&partner=${SITE_ID}`}
      />
    )
  );
};

export default memo(LiveTransaction);
