import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {setMainMenuModal} from '../../../../Redux/AppSlice';

import {ReactComponent as ProfileIcon} from '../../../../Assets/Icons/MainMenu/profile.svg';

import {MY_PROFILE} from '../../../../Constants/MainMenu';

import '../../index.scss';

const SettingsTab = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="flex column full-width gap-5">
      <div
        onClick={() => dispatch(setMainMenuModal({tab: MY_PROFILE}))}
        className="flex items-center justify-between lightGrayBackground mainMenuListItem px-xxl py-md cursor-pointer">
        <span className="blueText bold-500">{t(`myProfile`)}</span>
        <ProfileIcon width={18} height={18} fill="var(--appBlue)" />
      </div>
    </div>
  );
};

export default memo(SettingsTab);
