import React, {memo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {AppButton} from '../../UI';

import {useScreenSize} from '../../../Hooks';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';

import {FUN, REAL} from '../../../Constants/Socket';
import {BREAKPOINT_MD} from '../../../Constants/Globals';

const LobbyGameItem = ({item, clickHandler}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const [playDemoContainerStyle, setPlayDemoContainerStyle] = useState({
    display: 'none',
  });

  return (
    <div
      className="buttonContainer cursor-pointer relative"
      style={{
        width: `${(width - 10 - 4 * 5) / 4}px`,
      }}
      onMouseEnter={e => {
        setPlayDemoContainerStyle({display: 'flex'});
      }}
      onMouseLeave={e => {
        setPlayDemoContainerStyle({display: 'none'});
      }}>
      <img alt={item?.id} className="img" src={item?.icon_3} />
      <div
        style={{
          left: 0,
          right: 0,
          bottom: 10,
          position: 'absolute',
          ...playDemoContainerStyle,
        }}
        className={`flex row items-center px-md gap-5 ${
          width < BREAKPOINT_MD && 'wrap mb-xxs'
        }`}>
        {item?.types?.realMode && (
          <AppButton
            small
            type={3}
            title={t('play')}
            titleClassName="text-capitalize bold-600"
            className={`${!user?.auth_token && 'disabledGame'} cursor-pointer`}
            onClick={() => {
              clickHandler({game: item, mode: REAL});
              dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
            }}
          />
        )}
        {item?.types?.funMode && (
          <AppButton
            small
            type={1}
            fontSize={15}
            title={t('demo')}
            titleClassName="bold-600"
            className="cursor-pointer"
            onClick={() => clickHandler({game: item, mode: FUN})}
          />
        )}
      </div>
    </div>
  );
};

export default memo(LobbyGameItem);
