import {memo, useMemo} from 'react';
import Skeleton from 'react-loading-skeleton';

const CarouselSkeletons = () => {
  const height = useMemo(
    () =>
      document.querySelector('.homeCarouselContainer')?.clientWidth / 3.92 - 50,
    [],
  );

  return (
    <Skeleton
      count={1}
      width="80%"
      duration={0.7}
      height={height}
      className="my-sm"
      borderRadius="5px"
      style={{minHeight: 150}}
      baseColor="var(--semiLightGray)"
      highlightColor="var(--darkenGray)"
      containerClassName="justify-center flex"
    />
  );
};

export default memo(CarouselSkeletons);
