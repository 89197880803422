import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {setMyBetsModal} from '../../Redux/AppSlice';
import {getMyBetsModal} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import TabsHeader from './TabsHeader';
import OpenBets from '../BetSlip/Tabs/OpenBets';
import SettledBets from '../BetSlip/Tabs/SettledBets';

import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppModal} from '../UI';

import myBetsIcon from '../../Assets/Icons/MainMenu/myBets.svg';

const MyBets = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const myBetsModal = useSelector(getMyBetsModal);

  const [pageIndex, setPageIndex] = useState(0);

  const onRequestClose = useCallback(() => {
    dispatch(setMyBetsModal({isVisible: false}));
  }, [dispatch]);

  const Header = useMemo(
    () => (
      <div className="flex items-center pa-lg gap-5">
        <img src={myBetsIcon} alt="myBetsIcon" width={20} />
        <span className="text-subtitle2 blueText bold-600">
          {t('myBets')?.toUpperCase?.()}
        </span>
      </div>
    ),
    [t],
  );

  return (
    <AppModal
      header={Header}
      onRequestClose={onRequestClose}
      isOpen={myBetsModal?.isVisible}
      maxWidth={width < BREAKPOINT_XS ? '95vw' : undefined}>
      <div className="flex column fit px-md" style={{minHeight: '70vh'}}>
        <TabsHeader pageIndex={pageIndex} setPageIndex={setPageIndex} />

        {pageIndex === 0 ? (
          <OpenBets rowType />
        ) : pageIndex === 2 ? (
          <SettledBets rowType />
        ) : null}
      </div>
    </AppModal>
  );
};

export default memo(MyBets);
