import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {MARKETS_FIXED_COUNTS} from '../../../Constants/MatchFilters';

import EventItem from './EventItem';

import {ReactComponent as MatchStatsIcon} from '../../../Assets/Icons/Globals/matchStats.svg';
import {ReactComponent as MoreMarketsIcon} from '../../../Assets/Icons/Globals/moreMarkets.svg';

const Events = ({marketsArray, displayKey, gameItem, isSuspended}) => {
  const {width} = useScreenSize();

  const betSlipCount = useSelector(getBetSlipCount);

  const eventsArray = useMemo(
    () =>
      Array.apply(
        null,
        Array(marketsArray?.col_count || MARKETS_FIXED_COUNTS?.[displayKey]),
      ),
    [displayKey, marketsArray?.col_count],
  );

  return eventsArray?.map((item, index) => {
    const eventItem = Object.values(marketsArray?.event || {})?.find(
      item => item?.order === index,
    );

    return (
      <div
        className="flex"
        key={!!eventItem ? eventItem?.id : gameItem?.id + index}>
        <div className="flex">
          <div className="columnCenter relative">
            <EventItem
              showBase
              showBoosted
              key={betSlipCount}
              isFirst={index === 0}
              gameId={gameItem?.id}
              base={eventItem?.base}
              eventId={eventItem?.id}
              displayKey={displayKey}
              price={eventItem?.price}
              isSuspended={isSuspended}
              marketId={marketsArray?.id}
              lastPrice={eventItem?.lastPrice}
              isLast={index === eventsArray?.length - 1}
            />
          </div>
        </div>
        {width < BREAKPOINT_XS && index === eventsArray?.length - 1 && (
          <div className="column gap-5 justify-center pl-sm">
            <MoreMarketsIcon width={11} />
            <MatchStatsIcon width={11} />
          </div>
        )}
      </div>
    );
  });
};
// DO NOT PUT IN MEMO
export default Events;
