import React, {memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useSocket} from '../../../../Hooks';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {AppCheckbox} from '../../../UI';
import CompetitionSkeletons from '../../../UI/Skeletons/CompetitionSkeletons';

import {GET_COMPETITIONS} from '../../../../Constants/Socket';

import './index.scss';

const Competitions = ({
  sportAlias,
  regionAlias,
  gameFilters,
  selectedCompetitionsArray,
  setSelectedCompetitionsArray,
}) => {
  const {getCompetitions} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [competitions, setCompetitions] = useState([]);
  const [isCompetitionsLoading, setIsCompetitionsLoading] = useState(false);

  const handleChange = useCallback(
    item => {
      const newArray = [...selectedCompetitionsArray];
      if (!selectedCompetitionsArray.includes(item?.id)) {
        newArray.push(item?.id);
      } else {
        newArray.splice(selectedCompetitionsArray.indexOf(item?.id), 1);
      }
      setSelectedCompetitionsArray(newArray);
    },
    [selectedCompetitionsArray, setSelectedCompetitionsArray],
  );

  const onMessageCb = useCallback(async event => {
    const data = JSON.parse(event.data);

    switch (data?.rid) {
      case GET_COMPETITIONS:
        setCompetitions(Object.values(data?.data?.data?.competition || {}));
        setIsCompetitionsLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    setIsCompetitionsLoading(true);
    getCompetitions({
      regionAlias: regionAlias,
      gameFilters: gameFilters,
      activeSportAlias: sportAlias,
    });
  }, [regionAlias]);

  return (
    <div className="competitionsContainer px-md py-md">
      {isCompetitionsLoading ? (
        <CompetitionSkeletons />
      ) : (
        competitions?.map(item => (
          <div
            className="pr-sm"
            key={item?.id}
            onClick={e => e.stopPropagation()}>
            <AppCheckbox
              onChange={e => handleChange(item)}
              value={selectedCompetitionsArray?.includes(item?.id)}
              label={
                <div className="flex justify-between row items-center full-width">
                  <span className="textWithDotes blueText font-15 pl-sm name">
                    {item?.name}
                  </span>
                  <span className="textWithDotes blueText font-11 count">
                    {item?.game}
                  </span>
                </div>
              }
            />
            <div className="line" />
          </div>
        ))
      )}
    </div>
  );
};

export default memo(Competitions);
