import React, {memo, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getTaxPercentage} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';

import CalculationRow from '../CalculationRow';

import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';

let copyTimeout = null;

const CashOutCalculations = ({
  tax,
  stake,
  betId,
  bonus,
  payout,
  outcome,
  totalOdds,
  finalPayout,
}) => {
  const {t} = useTranslation();

  const taxPercentage = useSelector(getTaxPercentage);

  const [isCopied, setIsCopied] = useState(false);

  const copyIdHandler = useCallback(
    async e => {
      e.stopPropagation();
      clearTimeout(copyTimeout);
      await navigator.clipboard.writeText(betId);
      setIsCopied(true);
      copyTimeout = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    },
    [betId],
  );

  return (
    <div className="column gap-10 px-md cashOutCalculationsContainer pt-sm">
      <div className="column gap-5">
        <div className="row justify-between items-center">
          <span className="blueText bold-700 font-12">{t('betId')}</span>
          <div className="gap-5 row justify-between bold-700 font-12">
            <div className="row items-center gap-10">
              {isCopied ? (
                <span className="blueText bold-700 font-12">{t('copied')}</span>
              ) : (
                <CopyIcon
                  width={16}
                  height={16}
                  fill="var(--appBlue)"
                  onClick={copyIdHandler}
                />
              )}
              <span className="blueText bold-700 font-12">{betId}</span>
            </div>
          </div>
        </div>
        <CalculationRow
          hideCurrency
          amount={totalOdds}
          title={t('totalOdds')}
          titleClassName={
            outcome ? 'blueText bold-700 font-12' : 'blueText bold-700 font-12'
          }
          amountClassName={
            outcome ? 'blueText bold-700 font-12' : 'blueText bold-700 font-12'
          }
        />
        <CalculationRow
          amount={stake}
          title={t('stake')}
          titleClassName={
            outcome ? 'blueText bold-700 font-12' : 'blueText bold-700 font-12'
          }
          amountClassName={
            outcome ? 'blueText bold-700 font-12' : 'blueText bold-700 font-12'
          }
        />
        {!!tax && (
          <CalculationRow
            title={
              <span className="blueText bold-700 font-12">
                <span className="">{t('tax')}</span>
                <span> ({taxPercentage}%)</span>
              </span>
            }
            amount={tax}
            amountClassName={
              outcome
                ? 'blueText bold-700 font-12'
                : 'blueText bold-700 font-12'
            }
          />
        )}
        {!!bonus && (
          <CalculationRow
            amount={bonus}
            amountClassName="greenText"
            title={t('accumulatorBonus')}
          />
        )}
        {!!finalPayout && !outcome && (
          <CalculationRow
            title={t('finalPayout')}
            amountClassName="greenText"
            amount={fixedNumberWithoutRound(finalPayout)}
          />
        )}
        {!!payout && outcome !== 1 && (
          <CalculationRow
            amount={payout}
            amountClassName="greenText"
            title={
              <span className="greenText bold-700 font-12 text-caption">
                {outcome === 5
                  ? t('cashedOut')
                  : outcome === 3
                  ? t('wonAmount')
                  : t('returned')}
              </span>
            }
          />
        )}
      </div>
    </div>
  );
};

export default memo(CashOutCalculations);
