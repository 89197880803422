import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useAppFunctions, useSocket} from '../../Hooks';

import {setAuthModal} from '../../Redux/AppSlice';
import {setUserSubId} from '../../Redux/UserSlice';
import {getWssSocket} from '../../Redux/AppSlice/AppSelectors';

import {
  LOGIN_USER,
  SOCKET_RESPONSES,
  GET_USER_SIGN_IN,
} from '../../Constants/Socket';
import {
  PHONE_NUMBER_PREFIX,
  PHONE_NUMBER_PLACEHOLDER,
} from '../../Constants/Globals';

import {AppButton, AppCheckbox, AppInput} from '../UI';

const LoggedOutContainer = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {loginCb} = useAppFunctions();
  const {login, getUser} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const inputRef = useRef(null);
  const userDataRef = useRef({});

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [signInErrorMsg, setSignInErrorMsg] = useState(null);
  const [isSignInLoading, setIsSignInLoading] = useState(false);

  const usernameErrorMsg = useMemo(
    () => (phone && phone.length !== 9 ? 'phoneErrorMsg' : ''),
    [phone],
  );

  const passwordErrorMsg = useMemo(
    () => (password && password.length < 4 ? 'passwordErrorMsg' : ''),
    [password],
  );

  const isSignInDisabled = useMemo(
    () =>
      !!(
        !phone.trim() ||
        !password.trim() ||
        usernameErrorMsg ||
        passwordErrorMsg ||
        signInErrorMsg
      ),
    [password, passwordErrorMsg, signInErrorMsg, phone, usernameErrorMsg],
  );

  const registerHandler = useCallback(
    () => dispatch(setAuthModal({tabIndex: 1, isVisible: true})),
    [dispatch],
  );

  const signInHandler = useCallback(() => {
    setIsSignInLoading(true);
    login({
      password,
      rid: LOGIN_USER,
      encrypted_token: rememberMe,
      username: `${PHONE_NUMBER_PREFIX}${phone}`,
    });
  }, [login, password, phone, rememberMe]);

  const enterClickHandler = useCallback(
    e => {
      if (e?.keyCode === 13 && !isSignInDisabled) {
        signInHandler();
      }
    },
    [isSignInDisabled, signInHandler],
  );

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case LOGIN_USER:
          if (data?.code === 0) {
            userDataRef.current = {
              user_id: data?.data?.user_id,
              ...(data?.data?.jwe_token
                ? {jwe_token: data?.data?.jwe_token}
                : {}),
              auth_token: data?.data?.auth_token,
            };

            getUser(GET_USER_SIGN_IN);
          }
          // invalid credentials error case
          if (
            data?.data?.status === SOCKET_RESPONSES.INVALID_CREDENTIALS ||
            data.data.status === SOCKET_RESPONSES.CLIENT_EXCLUDED ||
            data?.code === SOCKET_RESPONSES.NEED_RECAPTCHA ||
            data?.data?.status === SOCKET_RESPONSES.CLIENT_LOCKED
          ) {
            setIsSignInLoading(false);
            setSignInErrorMsg(data?.data?.details?.Message);
            inputRef.current?.blur();
          }
          break;
        case GET_USER_SIGN_IN:
          if (data?.code === 0) {
            dispatch(setUserSubId(data?.data?.subid));
            await loginCb({
              user_id: userDataRef.current?.user_id,
              ...(userDataRef.current?.jwe_token
                ? {jwe_token: userDataRef.current?.jwe_token}
                : {}),
              auth_token: userDataRef.current?.auth_token,
              user: Object.values(data?.data?.data?.profile || {})?.[0],
            });
          }
          setIsSignInLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, getUser, loginCb],
  );

  useEffect(() => {
    wssSocket?.addEventListener?.('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener?.('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    document.addEventListener('keydown', enterClickHandler);

    return () => document.removeEventListener('keydown', enterClickHandler);
  }, [enterClickHandler]);

  return (
    <div className="row items-center gap-10">
      <div className="row items-center gap-10">
        <div
          className="column items-center yellowBackground rounded-borders justify-center"
          style={{height: 30, width: 48}}>
          <span className="bold-600 font-6 blueText text-uppercase resetLineHeight mt-xs">
            {t('code')}:
          </span>
          <span
            className="blueText bold-600 font-15"
            style={{lineHeight: 1.25}}>
            {PHONE_NUMBER_PREFIX}
          </span>
        </div>
        <div className="row items-center gap-10">
          <div style={{width: 110}} className="relative">
            <AppInput
              type={5}
              errorToTop
              height={30}
              maxLength={9}
              inputType="number"
              onChange={setPhone}
              error={t(usernameErrorMsg)}
              setErrorMsg={setSignInErrorMsg}
              placeholder={PHONE_NUMBER_PLACEHOLDER}
            />
            <AppCheckbox
              type={2}
              value={rememberMe}
              className="absolute"
              label={
                <span
                  className="whiteText font-8 bold-700 relative"
                  style={{top: 1}}>
                  {t('rememberMe')}
                </span>
              }
              onChange={() => setRememberMe(prevState => !prevState)}
            />
          </div>
          <div style={{width: 110}} className="relative">
            <AppInput
              type={5}
              password
              errorToTop
              height={30}
              inputRef={inputRef}
              onChange={setPassword}
              setErrorMsg={setSignInErrorMsg}
              placeholder={t('passwordPlaceholder')}
              eyeIconStyle={{width: 8, top: 12, right: 11}}
              error={t(signInErrorMsg || passwordErrorMsg)}
            />
            <span
              style={{right: 0}}
              className="whiteText font-8 bold-700 absolute cursor-pointer"
              onClick={() =>
                dispatch(setAuthModal({tabIndex: 2, isVisible: true}))
              }>
              {t('ResetPassword')}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{height: 45}}
        className="flex items-center justify-center gap-10">
        <AppButton
          type={3}
          width={88}
          height={30}
          fontSize={12}
          onClick={signInHandler}
          loading={isSignInLoading}
          title={t('signIn')}
          titleClassName="bold-600"
          disabled={isSignInDisabled}
          className="row items-center justify-center"
        />
        <AppButton
          type={1}
          height={30}
          width={100}
          fontSize={12}
          onClick={registerHandler}
          titleClassName="bold-600"
          title={t('register')}
          className="row items-center justify-center"
        />
      </div>
    </div>
  );
};

export default memo(LoggedOutContainer);
