import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import './index.scss';
import {useTranslation} from 'react-i18next';
import {DateDropdown} from 'react-date-dropdown';
import {useDispatch, useSelector} from 'react-redux';

import {setAuthModal} from '../../../../../Redux/AppSlice';
import {setUserSubId} from '../../../../../Redux/UserSlice';
import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../../Redux/PartnerSlice/PartnerSelectors';

import {useAppFunctions, useSocket} from '../../../../../Hooks';

import {nameValidator} from '../../../../../Utils/Validator';

import {
  LOGIN_USER,
  REGISTER_USER,
  SOCKET_RESPONSES,
  GET_USER_REGISTERED,
  VALIDATE_REGISTER_RECAPTCHA,
} from '../../../../../Constants/Socket';
import {PHONE_NUMBER_PREFIX} from '../../../../../Constants/Globals';

import {
  AppInput,
  AppButton,
  AppCheckbox,
  AppModalBackButton,
} from '../../../../UI';

import logo from '../../../../../Assets/Icons/Globals/PigabetLogo.svg';

const DetailedInfo = ({
  phone,
  password,
  lastName,
  firstName,
  dateOfBirth,
  setLastName,
  setErrorMsg,
  setFirstName,
  affiliateCode,
  setCurrentTab,
  setDateOfBirth,
  agreementValue,
  setAffiliateCode,
  setAgreementValue,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {loginCb} = useAppFunctions();
  const {register, validateRegisterRecaptcha, login, getUser} = useSocket();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const userDataRef = useRef({});

  const [isRegisterLoading, setIsRegisterLoading] = useState(false);

  const firstNameErrorMsg = useMemo(
    () =>
      firstName?.trim?.() && !nameValidator(firstName?.trim?.())
        ? t('nameErrorMsg')
        : '',
    [firstName, t],
  );

  const lastNameErrorMsg = useMemo(
    () =>
      lastName?.trim?.() && !nameValidator(lastName?.trim?.())
        ? t('nameErrorMsg')
        : '',
    [lastName, t],
  );

  const isRegisterDisabled = useMemo(() => {
    return !!(
      !firstName?.trim?.() ||
      !lastName?.trim?.() ||
      !dateOfBirth ||
      !agreementValue
    );
  }, [agreementValue, dateOfBirth, firstName, lastName]);

  const registerHandler = useCallback(() => {
    setIsRegisterLoading(true);
    validateRegisterRecaptcha();
  }, [validateRegisterRecaptcha]);

  const goToTerms = useCallback(() => {
    dispatch(setAuthModal({isCloseDisabled: true, privacyTabIndex: 0}));
    setCurrentTab(3);
  }, [dispatch, setCurrentTab]);

  const goToPrivacy = useCallback(() => {
    dispatch(setAuthModal({isCloseDisabled: true, privacyTabIndex: 1}));
    setCurrentTab(3);
  }, [dispatch, setCurrentTab]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case VALIDATE_REGISTER_RECAPTCHA:
          if (data?.code === 0 && data?.data?.result) {
            register({
              password,
              currency_name: currency,
              birth_date: dateOfBirth,
              last_name: lastName?.trim?.(),
              first_name: firstName?.trim?.(),
              promo_code: affiliateCode?.trim?.(),
              phone: `${PHONE_NUMBER_PREFIX}${phone?.trim?.()}`,
              username: `${PHONE_NUMBER_PREFIX}${phone?.trim?.()}`,
            });
          } else {
            setIsRegisterLoading(false);
          }
          break;
        case REGISTER_USER:
          if (data?.code === 0 && data?.data?.result === SOCKET_RESPONSES.OK) {
            login({
              username: `${PHONE_NUMBER_PREFIX}${phone?.trim?.()}`,
              password: password?.trim?.(),
              encrypted_token: true,
            });
          } else {
            //duplicated username error case
            if (data?.data?.result === SOCKET_RESPONSES.DUPLICATE_LOGIN) {
              setErrorMsg(data?.data?.details?.Message);
              setCurrentTab(0);
            }
            setIsRegisterLoading(false);
          }
          break;
        case LOGIN_USER:
          if (data?.code === 0) {
            userDataRef.current = {
              user_id: data?.data?.user_id,
              ...(data?.data?.jwe_token
                ? {jwe_token: data?.data?.jwe_token}
                : {}),
              auth_token: data?.data?.auth_token,
            };
            getUser(GET_USER_REGISTERED);
          } else {
            setIsRegisterLoading(false);
          }
          break;
        case GET_USER_REGISTERED:
          if (data?.code === 0) {
            dispatch(setUserSubId(data?.data?.subid));
            await loginCb({
              closeModal: false,
              user_id: userDataRef.current?.user_id,
              auth_token: userDataRef.current?.auth_token,
              ...(userDataRef.current?.jwe_token
                ? {jwe_token: userDataRef.current?.jwe_token}
                : {}),
              user: Object.values(data?.data?.data?.profile || {})?.[0],
            });
            setCurrentTab(2);
          }
          setIsRegisterLoading(false);
          break;
        default:
          break;
      }
    },
    [
      phone,
      login,
      loginCb,
      getUser,
      password,
      register,
      dispatch,
      currency,
      lastName,
      firstName,
      dateOfBirth,
      setErrorMsg,
      affiliateCode,
      setCurrentTab,
    ],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="detailedInfoWrapper container columnCenter gap-5 py-lg full-width">
      <AppModalBackButton onBackClick={() => setCurrentTab(0)} />
      <img src={logo} alt="logo" className="logo" />
      <div className="rowCenter stepsContainer">
        <div className="stepBlock_active"></div>
        <div className="stepBlock_active"></div>
      </div>
      <div className="innerBlock column">
        <div className="inputContainer">
          <AppInput
            inputValue={firstName}
            onChange={setFirstName}
            error={firstNameErrorMsg}
            placeholder={t('firstName')}
          />
        </div>

        <AppInput
          inputValue={lastName}
          onChange={setLastName}
          error={lastNameErrorMsg}
          placeholder={t('lastName')}
        />
        <DateDropdown
          defaultDate={dateOfBirth}
          selectClass="selectClass"
          optionClass="optionClass"
          containerClass="containerClass"
          yearEnd={new Date().getFullYear() - 18}
          onDateChange={e => {
            setDateOfBirth(e);
          }}
          selectPlaceholder={{
            day: t('day'),
            year: t('year'),
            month: t('month'),
          }}
        />
        <AppInput
          inputValue={affiliateCode}
          onChange={setAffiliateCode}
          placeholder={t('affiliateCode')}
        />
        <div className="flex column">
          <AppCheckbox
            label="agreement"
            value={agreementValue}
            onChange={() => setAgreementValue(prevState => !prevState)}
          />
          <span className="flex column blueText text-caption mx-xl">
            {t('agreementText')}
            <span>
              <a
                href="#"
                onClick={goToPrivacy}
                className="blueText text-caption">
                {' '}
                {t('privacyPolicy')}
              </a>{' '}
              {t('andThe')}{' '}
              <a href="#" className="blueText text-caption" onClick={goToTerms}>
                {' '}
                {t('generalTerms')}
              </a>
            </span>
          </span>
        </div>
      </div>

      <div className="mt-xxl full-width">
        <AppButton
          type={3}
          title={t('register')}
          onClick={registerHandler}
          loading={isRegisterLoading}
          disabled={isRegisterDisabled}
        />
      </div>
    </div>
  );
};

export default memo(DetailedInfo);
