import {fixedNumberWithoutRound} from './GetOddValue';
import {bigNumbersPrettier} from './BigNumbersPrettier';

export const calculateSystemBet = ({
  oddsArray,
  stakeAmount,
  taxPercentage,
  systemBetCount,
}) => {
  const possibleWinArr = [];
  const finalPayoutArr = [];
  const combinations = getCombinations(oddsArray?.length, systemBetCount);

  for (let i = 0; i < combinations?.length; i++) {
    const subOddsArr = combinations?.[i].map(index => oddsArray?.[index]);
    const possibleWin = subOddsArr?.reduce(
      (acc, curr) => acc * curr,
      stakeAmount,
    );

    const tax = (possibleWin - stakeAmount) * (taxPercentage / 100);
    const finalPayout = possibleWin - tax;

    possibleWinArr?.push(possibleWin);
    finalPayoutArr?.push(finalPayout);
  }

  const possibleWin = possibleWinArr.reduce((acc, curr) => acc + curr, 0);

  const tax =
    (possibleWin - stakeAmount * combinations?.length) * (taxPercentage / 100);

  const finalPayout = finalPayoutArr.reduce((acc, curr) => acc + curr, 0);

  return {
    tax: bigNumbersPrettier(fixedNumberWithoutRound(tax)),
    possibleWin: bigNumbersPrettier(fixedNumberWithoutRound(possibleWin)),
    finalPayout: bigNumbersPrettier(fixedNumberWithoutRound(finalPayout)),
  };
};

export const getCombinations = (n, k) => {
  const result = [];
  function helper(start, combination) {
    if (combination?.length === k) {
      result?.push(combination);
      return;
    }

    for (let i = start; i < n; i++) {
      helper(i + 1, combination?.concat(i));
    }
  }

  helper(0, []);

  return result;
};
