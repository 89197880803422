import React, {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const AppSelectSkeleton = ({type = 1}) => (
  <Skeleton
    count={1}
    width="100%"
    duration={0.7}
    borderRadius="5px"
    containerClassName="flex"
    height={type === 1 ? 50 : type === 2 ? 35 : 30}
    baseColor={
      type === 1 || type === 2 ? 'var(--lightBlue)' : 'var(--lightPurple)'
    }
    highlightColor={
      type === 1 || type === 2 ? 'var(--appBlue)' : 'var(--lightGray)'
    }
  />
);

export default memo(AppSelectSkeleton);
