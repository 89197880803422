import React, {memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useScreenSize, useSocket} from '../../../../Hooks';

import RegionItem from './RegionItem';

import {
  GET_All_REGIONS,
  GET_USER_DATA_PERSONALIZATION,
  SET_USER_DATA_PERSONALIZATION,
} from '../../../../Constants/Socket';

import {
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';

import RegionSkeletons from '../../../UI/Skeletons/RegionSkeletons';
import {AppButton, AppInput} from '../../../UI';
import {useTranslation} from 'react-i18next';

const Sports = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const {getFavoriteCompetition} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const [regions, setRegions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);
  const [favouriteCompetitionsObject, setFavouriteCompetitionsObject] =
    useState({});

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);

    switch (data?.rid) {
      case GET_All_REGIONS:
        setSelectedIndex(0);
        setRegions(
          Object.values(data?.data?.data?.region || {})?.sort(
            (a, b) => a?.order - b?.order,
          ),
        );
        setIsRegionsLoading(false);
        break;
      case SET_USER_DATA_PERSONALIZATION:
        console.log('SET_USER_DATA_PERSONALIZATION', data);
        break;
      case GET_USER_DATA_PERSONALIZATION:
        setFavouriteCompetitionsObject(
          data?.data?.details?.data?.sportFavoriteCompetition?.configs,
        );
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    if (!!isLoggedIn) {
      getFavoriteCompetition();
    }
  }, [isLoggedIn]);

  return (
    width > 1240 && (
      <div className={``} style={{width: 255, backgroundColor: '#194478'}}>
        {isRegionsLoading ? (
          <RegionSkeletons />
        ) : (
          <div className="flex column">
            <div className="row items-center px-md pt-sm gap-10">
              <AppInput
                height={40}
                type={4}
                search
                placeholder={t('searchAnyLeague')}
              />
              <AppButton
                type={16}
                width={80}
                height={40}
                // onClick={onCancel}
                className={`resetButton`}
                title={<span className="bold-600">{t('resetLower')}</span>}
              />
            </div>
            {['_', ...regions]?.map((regionItem, index) => {
              return (
                <RegionItem
                  key={index}
                  index={index}
                  regionItem={regionItem}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  isExpanded={index === selectedIndex}
                  favouriteCompetitionsObject={favouriteCompetitionsObject}
                  setFavouriteCompetitionsObject={
                    setFavouriteCompetitionsObject
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    )
  );
};

export default memo(Sports);
