import {NUMBERS_FORMAT_WITH_COMMA_REGEX} from '../Constants/Globals';

export const bigNumbersPrettier = number => {
  const parts = number?.toString?.()?.split?.('.');
  if (parts && parts[0]) {
    parts[0] = parts?.[0]?.replace?.(NUMBERS_FORMAT_WITH_COMMA_REGEX, ',');
  }
  return parts?.join?.('.');
};

export const phoneNumberPrettier = number => {
  const parts = number?.toString?.()?.split?.('.');
  if (parts && parts[0]) {
    parts[0] = parts?.[0]?.replace?.(NUMBERS_FORMAT_WITH_COMMA_REGEX, ' ');
  }
  return parts?.join?.('.');
};
