import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.scss';

const CompetitionSkeletons = () => (
  <div className="textCenter">
    <Skeleton
      count={4}
      height={30}
      width="100%"
      duration={0.7}
      className="top-5"
      borderRadius="5px"
      baseColor="var(--semiLightGray)"
      highlightColor="var(--semiDarkGray)"
    />
  </div>
);

export default memo(CompetitionSkeletons);
