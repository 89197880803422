import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../../../../Hooks';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {setSuccessModal} from '../../../../Redux/AppSlice';
import {getSuccessModal} from '../../../../Redux/AppSlice/AppSelectors';

import {AppButton, AppModal} from '../../index';

import {BREAKPOINT_XXS} from '../../../../Constants/Globals';

import success from '../../../../Assets/Icons/Globals/success.svg';

const SuccessModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const successModal = useSelector(getSuccessModal);

  const clickHandler = useCallback(() => {
    dispatch(
      setSuccessModal({
        isVisible: false,
        successTitle: '',
        successDescription: '',
      }),
    );
  }, [dispatch]);

  const Header = useMemo(
    () => <span className="py-md text-subtitle2 whiteText">{t('done')}</span>,
    [t],
  );

  return (
    <AppModal
      headerType={2}
      header={Header}
      onRequestClose={clickHandler}
      isOpen={successModal?.isVisible}
      maxWidth={`${width < BREAKPOINT_XXS ? '100%' : '400px'}`}>
      <div className="flex column items-center justify-between successContainer full-width mx-auto">
        <div className="flex justify-center successIcon">
          <img src={success} alt="success" width={120} />
        </div>
        <div className="flex column items-center gap-15 titlesWrapper">
          <div className="flex column items-center">
            <span className="greenText text-subtitle2 textCenter bold-600">
              {successModal?.successTitle}
            </span>
            <span className="greenText text-subtitle2 textCenter bold-600">
              {successModal?.successDescription}
            </span>
          </div>
          <AppButton type={3} title={t('ok')} onClick={clickHandler} />
        </div>
      </div>
    </AppModal>
  );
};

export default memo(SuccessModal);
