import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize, useStorage} from '../../../Hooks';

import {
  FILTERS_LIVE_CALENDAR,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

import Games from '../../../Components/Home/Games';
import FeaturedGames from '../../../Components/Home/FeaturedGames';
import FixedMarketsFilters from '../../../Components/Home/FixedMarketsFilters';
import LiveCalendarFilters from '../../../Components/Home/LiveCalendarFilters';

const Home = () => {
  const params = useParams();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();

  const {setGroupedByCompetition, getGroupedByCompetition} = useStorage();
  const [isGrouped, setIsGrouped] = useState(getGroupedByCompetition() || true);

  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);
  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  useEffect(() => {
    setGroupedByCompetition(isGrouped);
  }, [isGrouped, setGroupedByCompetition]);

  return (
    <div
      className={`homePageContainer ${width > BREAKPOINT_XS ? 'pa-md' : ''}`}>
      {/*{params?.filter === FILTERS_UPCOMING_MATCHES_NAME && <FeaturedGames />}*/}
      <div className="sportsWrapper">
        <div className={`flex column ${width < BREAKPOINT_XS && 'reverse'}`}>
          {/*{width > BREAKPOINT_XS && <ActionBar />}*/}
        </div>
        <div
          className={`${
            width > BREAKPOINT_XS
              ? 'mt-sm pa-md rounded-top-borders py-sm'
              : 'py-sm'
          }`}>
          <div className={`${width > BREAKPOINT_XS ? 'mx-sm' : ''}`}>
            <div>
              {!sportGameIdParam &&
                gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME && (
                  <LiveCalendarFilters />
                )}
              {/*{params?.filter === FILTERS_LIVE_CALENDAR ? (*/}
              {/*) : params?.filter === FILTERS_UPCOMING_MATCHES_NAME ||*/}
              {/*  params?.filter === FILTERS_LIVE_NAME ? (*/}
              {/*  <FixedCompetitions />*/}
              {/*) : (*/}
              {/*  <PopularCompetitions />*/}
              {/*)}*/}
              {width < BREAKPOINT_XS && (
                <FixedMarketsFilters
                  isGrouped={
                    location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`)
                      ? false
                      : isGrouped
                  }
                  setIsGrouped={setIsGrouped}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex column gamesContainer scroll-3 rounded-bottom-borders pb-md">
        <Games
          isGrouped={
            location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`)
              ? false
              : isGrouped
          }
          setIsGrouped={setIsGrouped}
        />
      </div>
    </div>
  );
};

export default Home;
