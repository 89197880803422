import React, {memo} from 'react';

import './index.scss';

import {ReactComponent as WarningIcon} from '../../../Assets/Icons/Globals/warning.svg';

const Error = ({error, iconColor = 'var(--appRed)'}) =>
  !!error && (
    <div className="flex items-center row appErrorContainer full-width justify-center pa-sm gap-5">
      <WarningIcon width={20} height="100%" fill={iconColor} />
      {typeof error === 'string' ? (
        <span className="redText text-caption bold-500">{error}</span>
      ) : (
        error
      )}
    </div>
  );

export default memo(Error);
