import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {ReactComponent as PromotionsIcon} from '../../../../Assets/Icons/MainMenu/promotion.svg';

import '../../index.scss';

const PromotionsTab = () => {
  const {t} = useTranslation();
  const [getParam, setGetParam] = useSearchParams();

  return (
    <div className="flex column full-width gap-5">
      <div
        onClick={() => {
          getParam.set('showPromotions', 'all');
          setGetParam(getParam);
        }}
        className="flex items-center justify-between lightGrayBackground mainMenuListItem px-xxl py-md cursor-pointer">
        <span className="blueText bold-500">{`${t('promotions')}/${t(
          'bonuses',
        )}`}</span>
        <PromotionsIcon width={18} height={18} />
      </div>
    </div>
  );
};

export default memo(PromotionsTab);
