export const TRANSACTION_FILTER = 'transaction';
export const TEST_2 = 'test2';
export const TEST_3 = 'test3';
export const TEST_4 = 'test4';
export const TEST_5 = 'test5';

export const SINGLE_GAME_STATICS_FILTERS = [
  {icon: 'Transaction', value: TRANSACTION_FILTER},
  {icon: 'test2', value: TEST_2},
  {icon: 'test3', value: TEST_3},
  {icon: 'test4', value: TEST_4},
  {icon: 'test5', value: TEST_5},
];
