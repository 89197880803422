import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {Virtuoso} from 'react-virtuoso';

import './index.scss';

import GameItem from './GameItem';
import {AppButton} from '../../UI';
import Regions from '../Sports/Regions';
import SelectedGame from '../SelectedGame';
import AccordionItem from './AccordionItem';
import RegionsModal from '../ActionBar/RegionsModal';
import GameSkeletons from '../../UI/Skeletons/GameSkeletons';
import CompetitionsWrapper from '../Sports/Competitions/CompetitionsWrapper';

import {useScreenSize} from '../../../Hooks';

import {
  getSportsArray,
  getIsGameDataLoading,
  getIsPopularCompetitionsLoading,
} from '../../../Redux/SportSlice/SportSelectors';
import {
  getWssSocket,
  getShowBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {setShowMobileRegionsModal} from '../../../Redux/AppSlice';
import {setSubIds, setIsGameDataLoading} from '../../../Redux/SportSlice';

import {
  MATCH_FILTERS,
  FOOTBALL_ALIAS,
  MARKETS_WINNER,
  MARKETS_TOTALS,
  MARKETS_HANDICAP,
  MARKETS_ODD_EVEN,
  LIMIT_OF_ALL_GAMES,
  FILTERS_TODAY_NAME,
  FILTERS_LIVE_CALENDAR,
  MARKETS_DOUBLE_CHANCE,
  FILTERS_BOOSTED_ODDS_NAME,
  MARKETS_BOTH_TEAM_TO_SCORE,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../../../Constants/MatchFilters';
import {
  GET_GAMES,
  GET_BOOSTED_GAMES,
  GET_INITIAL_GAMES,
} from '../../../Constants/Socket';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';
import {Flags} from '../../../Constants/Flags';

import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';
import {
  FIXED_MARKETS_FILTERS,
  FIXED_FOOTBALL_MARKETS_FILTERS,
} from '../../../Constants/FixedMarketFiltersArray';

const Games = ({isGrouped, setIsGrouped}) => {
  const params = useParams();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const sports = useSelector(getSportsArray);
  const wssSocket = useSelector(getWssSocket);
  const showBetSlip = useSelector(getShowBetSlip);
  const isGameDataLoading = useSelector(getIsGameDataLoading);
  const isPopularCompetitionsLoading = useSelector(
    getIsPopularCompetitionsLoading,
  );

  const [games, setGames] = useState([]);
  const [isExpanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(
    document?.querySelector?.('.sportsWrapper')?.offsetHeight,
  );
  const [selectedRegion, setSelectedRegion] = useState({});

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const getMarketParam = getParam.get('market');
  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const searchValue = useMemo(() => getParam.get('search'), [getParam]);
  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const activeSportCount = useMemo(
    () => sports?.find(item => item?.alias === params?.sport)?.game,
    [params?.sport, sports],
  );

  const isFootball = useMemo(
    () => params?.sport === FOOTBALL_ALIAS,
    [params?.sport],
  );

  const GAMES_DATA = useMemo(
    () =>
      games
        // ?.filter(
        //   item =>
        //     item?.team1_name
        //       ?.toLocaleLowerCase()
        //       ?.includes(
        //         ((!!params?.region ? !!searchValue || '' : '') || '')
        //           ?.toLocaleLowerCase()
        //           ?.trim(),
        //       ) ||
        //     item?.team2_name
        //       ?.toLocaleLowerCase()
        //       ?.includes(
        //         ((!!params?.region ? !!searchValue || '' : '') || '')
        //           ?.toLocaleLowerCase()
        //           ?.trim(),
        //       ),
        // )
        ?.sort((a, b) =>
          params?.filter === FILTERS_TODAY_NAME
            ? a?.competition_order - b?.competition_order
            : a?.start_ts - b?.start_ts,
        ),
    [games, params?.filter],
  );

  const isResetDisabled = useMemo(() => {
    return (
      !!searchValue ||
      !!params?.competition ||
      (getMarketParam !== 'matchResult' && width < BREAKPOINT_XS)
    );
  }, [getMarketParam, params?.competition, searchValue, width]);

  const groupedData = useMemo(
    () =>
      Object.values(
        GAMES_DATA?.reduce((acc, item) => {
          const key = item?.competition_id;

          if (!acc?.[key]) {
            acc[key] = [];
          }

          acc?.[key]?.push?.(item);

          return acc;
        }, {}) || {},
      )?.sort((a, b) => a?.[0]?.competition_order - b?.[0]?.competition_order),
    [GAMES_DATA],
  );

  const showShowMoreButton = useMemo(
    () =>
      width < BREAKPOINT_XS &&
      params?.filter !== FILTERS_POPULAR_MATCHES_NAME &&
      params?.filter !== FILTERS_TODAY_NAME &&
      params?.filter !== FILTERS_LIVE_CALENDAR &&
      GAMES_DATA?.length >= LIMIT_OF_ALL_GAMES &&
      +activeSportCount >= LIMIT_OF_ALL_GAMES &&
      !params?.region &&
      !isGameDataLoading,
    [
      width,
      params?.filter,
      params?.region,
      activeSportCount,
      isGameDataLoading,
      GAMES_DATA?.length,
    ],
  );

  const onShowMore = useCallback(() => {
    if (width < BREAKPOINT_XS) {
      dispatch(setShowMobileRegionsModal(true));
    } else {
      setExpanded(prevState => {
        if (!!prevState) {
          setSelectedRegion({});
        }
        return !prevState;
      });
    }
  }, [dispatch, width]);

  const onCheckInUpcomingMatches = useCallback(() => {
    if (params?.filter === FILTERS_UPCOMING_MATCHES_NAME) {
      setGames([]);
      return;
    }
    if (params?.region && params?.competition) {
      navigate(
        `/sports/${FILTERS_UPCOMING_MATCHES_NAME}/${params?.sport}/${params?.region}/${params?.competition}`,
      );
    } else {
      navigate(`/sports/${FILTERS_UPCOMING_MATCHES_NAME}/${params?.sport}`);
    }
  }, [
    navigate,
    params?.sport,
    params?.filter,
    params?.region,
    params?.competition,
  ]);

  const onReset = useCallback(() => {
    if (
      !searchValue &&
      !params?.region &&
      !params?.competition &&
      getMarketParam
    ) {
      getParam.delete('market');
      setGetParam(getParam);
      return;
    }
    navigate(
      `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${params?.sport}`,
    );
    // if (!params?.region && !params?.competition && !!searchValue) {
    //   getInitialGames({
    //     activeSportAlias: params?.sport,
    //     gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
    //   });
    // }
  }, [
    navigate,
    getParam,
    setGetParam,
    searchValue,
    params?.sport,
    params?.filter,
    getMarketParam,
    params?.region,
    params?.competition,
  ]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event.data);
      if (
        data?.rid?.includes(GET_INITIAL_GAMES) ||
        data?.rid === GET_BOOSTED_GAMES
      ) {
        dispatch(setSubIds({games: data?.data?.subid}));
        const sportObject = Object.values(data?.data?.data?.sport)?.find(
          item => item?.alias === sportParam,
        );
        const regionObject = sportObject?.region;

        const gamesArray = [];
        for (const region in regionObject) {
          for (const competition in regionObject[region]?.competition) {
            for (const currentGame in regionObject[region]?.competition?.[
              competition
            ]?.game) {
              gamesArray.push({
                ...regionObject[region]?.competition?.[competition]?.game?.[
                  currentGame
                ],
                competition_name:
                  regionObject[region]?.competition?.[competition]?.name,
                competition_id:
                  regionObject[region]?.competition?.[competition]?.id,
                competition_order:
                  regionObject[region]?.competition?.[competition]
                    ?.favorite_order ??
                  regionObject[region]?.competition?.[competition]?.order,
              });
            }
          }
        }
        setGames(gamesArray || []);
        setTimeout(() => {
          dispatch(setIsGameDataLoading(false));
        }, 400);
      } else if (data?.rid === GET_GAMES) {
        dispatch(setSubIds({games: data?.data?.subid}));
        let gamesData = [];

        const regionObject =
          Object.values(data?.data?.data?.sport || {})?.[0]?.region || {};

        for (const region in regionObject) {
          for (const competition in regionObject[region]?.competition) {
            for (const currentGame in regionObject[region]?.competition?.[
              competition
            ]?.game) {
              gamesData.push({
                ...regionObject[region]?.competition?.[competition]?.game?.[
                  currentGame
                ],
                competition_name:
                  regionObject[region]?.competition?.[competition]?.name,
                competition_id:
                  regionObject[region]?.competition?.[competition]?.id,
                competition_order:
                  regionObject[region]?.competition?.[competition]
                    ?.favorite_order ??
                  regionObject[region]?.competition?.[competition]?.order,
              });
            }
          }
        }

        setGames(gamesData || []);
        setTimeout(() => {
          dispatch(setIsGameDataLoading(false));
        }, 400);
      } else if (data?.rid === GET_BOOSTED_GAMES) {
        let gamesData = [];
        const sportObject = Object.values(data?.data?.data?.sport)?.find(
          item => item?.alias === params?.sport,
        );
        const competitionsObject = Object.values(sportObject?.region || {})?.[0]
          ?.competition;

        for (const competition in competitionsObject) {
          gamesData.push(
            ...Object.values(competitionsObject?.[competition]?.game || {}),
          );
        }
        setGames(gamesData || []);
        setTimeout(() => {
          dispatch(setIsGameDataLoading(false));
        }, 400);
      }
    },
    [dispatch, sportParam, params?.sport],
  );

  useEffect(() => {
    if (!showShowMoreButton) {
      setExpanded(false);
      setSelectedRegion({});
    }
  }, [showShowMoreButton]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useLayoutEffect(() => {
    if (!isGameDataLoading && !isPopularCompetitionsLoading) {
      setHeight(document?.querySelector?.('.sportsWrapper')?.offsetHeight);
    }
  }, [width, isGameDataLoading, isPopularCompetitionsLoading]);

  const getResultName = useCallback(
    displayKey => {
      let colCount = null;
      for (let i = 0; i < GAMES_DATA?.length; i++) {
        if (!!colCount) {
          break;
        }

        const market = Object.values(GAMES_DATA[i]?.market || {})?.find(
          marketItem => marketItem?.display_key === displayKey,
        );

        if (!!market?.col_count) {
          colCount = market?.col_count;
        }
      }
      // TODO it's written without map because the names Pano wants to change every time
      return (
        <div
          className="justify-end flex items-center full-height rounded-borders"
          style={{
            marginRight: width > BREAKPOINT_XS ? 0 : 15,
            backgroundColor: '#003069',
          }}>
          {displayKey === MARKETS_WINNER ? (
            colCount === 2 ? (
              <span
                className="whiteText bold-600 text-caption text-center text-uppercase"
                style={{width: 125}}>
                {t('matchResult')}
              </span>
            ) : (
              <span
                className="whiteText bold-600 text-caption text-center text-uppercase"
                style={{width: 190}}>
                {t('matchResult')}
              </span>
            )
          ) : displayKey === MARKETS_BOTH_TEAM_TO_SCORE ? (
            <div className="flex column items-center overflow-hidden px-md">
              <span
                className="whiteText bold-600 text-caption text-center ellipsis full-width text-uppercase"
                style={{maxWidth: 125}}>
                {t('bothTeamToScoreTitle')}
              </span>
            </div>
          ) : displayKey === MARKETS_DOUBLE_CHANCE ? (
            <span
              className="whiteText bold-600 text-caption text-center text-uppercase"
              style={{width: 190}}>
              {t('doubleChanceFilter')}
            </span>
          ) : displayKey === MARKETS_HANDICAP ? (
            <span
              className="whiteText bold-600 text-caption text-center text-uppercase"
              style={{width: 125}}>
              {t('handicap')}
            </span>
          ) : displayKey === MARKETS_TOTALS ? (
            <span
              className="whiteText bold-600 text-caption text-center text-uppercase"
              style={{width: 125}}>
              {t('total')}
            </span>
          ) : MARKETS_ODD_EVEN ? (
            <span
              className="whiteText bold-600 text-caption text-center text-uppercase"
              style={{width: 125}}>
              {t('oddEven')}
            </span>
          ) : (
            <></>
          )}
        </div>
      );
    },
    [GAMES_DATA, t, width],
  );

  const ArrowIcon = isExpanded ? TopTriangle : DownTriangle;

  return isGameDataLoading ? (
    <GameSkeletons />
  ) : GAMES_DATA?.length > 0 ? (
    <div className={`${sportGameIdParam && 'row'}`}>
      <div
        className={`gamesDataContainer ${
          ((!!sportGameIdParam && width <= BREAKPOINT_MD) ||
            (showBetSlip && !!sportGameIdParam && width <= BREAKPOINT_LG)) &&
          'hidden'
        }`}
        style={{flex: 1}}>
        <div
          className={`flex row px-md pb-md ${
            width > BREAKPOINT_XS ? '' : 'lightGrayBackground'
          }`}
          style={
            width < BREAKPOINT_XS
              ? {borderBottom: '2px solid var(--appBlue)'}
              : {}
          }>
          <div
            style={{flexGrow: 1}}
            className={`row items-center flex-shrink gap-20 ${
              width < BREAKPOINT_XS ? 'py-xs' : ''
            }`}>
            {width < BREAKPOINT_XS &&
              params?.filter !== FILTERS_BOOSTED_ODDS_NAME &&
              !location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) && (
                <div>
                  <AppButton
                    type={11}
                    className="leaguesButton px-sm py-xs"
                    onClick={() => {
                      dispatch(setShowMobileRegionsModal(true));
                    }}
                    title={
                      <div className="flex items-center justify-between gap-5">
                        <div className="flex items-center gap-5">
                          {Flags?.[params?.region] && (
                            <img
                              alt="flag"
                              width={20}
                              src={Flags?.[params?.region]}
                            />
                          )}
                          <span className="text-caption blackText">
                            {t('league')}
                          </span>
                        </div>
                        <ArrowIcon
                          width={8}
                          height={8}
                          fill="gray"
                          alt="arrowIcon"
                          className="flex-shrink"
                        />
                      </div>
                    }
                  />
                  <RegionsModal />
                </div>
              )}
            {/*<div>*/}
            {/*  <AppButton*/}
            {/*    type={5}*/}
            {/*    onClick={onReset}*/}
            {/*    disabledWithoutLayer={!isResetDisabled}*/}
            {/*    className={`resetButton py-sm px-xl ${*/}
            {/*      !isResetDisabled ? 'disabledReset' : ''*/}
            {/*    } ${width < BREAKPOINT_XS ? '' : 'desktop'}`}*/}
            {/*    title={*/}
            {/*      <span className="text-caption bold-500">{t('reset')}</span>*/}
            {/*    }*/}
            {/*  />*/}
            {/*</div>*/}
            {width > BREAKPOINT_XS &&
              !location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) && (
                <div
                  className="row items-center rounded-borders cursor-pointer"
                  style={{border: '1px solid var(--appYellow)'}}>
                  <div
                    style={{borderTopLeftRadius: 3, borderBottomLeftRadius: 3}}
                    onClick={() => setIsGrouped(true)}
                    // style={{borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}
                    className={`px-xl py-sm ${
                      isGrouped ? 'yellowBackground blueText' : 'whiteText'
                    }`}>
                    <span>{t('competitions')}</span>
                  </div>

                  <div
                    style={{
                      borderTopRightRadius: 3,
                      borderBottomRightRadius: 3,
                    }}
                    className={`px-xl py-sm ${
                      !isGrouped ? 'yellowBackground blueText' : 'whiteText'
                    }`}
                    onClick={() => setIsGrouped(false)}>
                    <span>{t('time')}</span>
                  </div>
                </div>
              )}
          </div>
          <div className="row gap-30">
            <div className={`sm-hide ${!!getMarketParam && 'hide'}`}>
              {getResultName(MARKETS_WINNER)}
            </div>
            {isFootball && (
              <div
                className={`sm-hide ${
                  (!!getMarketParam ||
                    (!!sportGameIdParam && width >= BREAKPOINT_MD) ||
                    (!!showBetSlip &&
                      width >= BREAKPOINT_XS &&
                      width < BREAKPOINT_MD)) &&
                  'hide'
                }`}>
                {getResultName(MARKETS_DOUBLE_CHANCE)}
              </div>
            )}
            {isFootball && (
              <div
                className={`sm-hide ${
                  (!!getMarketParam ||
                    (!!sportGameIdParam && width >= BREAKPOINT_MD) ||
                    (!!showBetSlip &&
                      width >= BREAKPOINT_XS &&
                      width < BREAKPOINT_LG)) &&
                  'hide'
                }`}>
                {getResultName(MARKETS_BOTH_TEAM_TO_SCORE)}
              </div>
            )}
            <div
              className={`sm-hide ${
                (!!getMarketParam ||
                  (!!sportGameIdParam && width >= BREAKPOINT_MD) ||
                  (!!showBetSlip &&
                    !!isFootball &&
                    width >= BREAKPOINT_XS &&
                    width < BREAKPOINT_LG)) &&
                'hide'
              }`}>
              {getResultName(MARKETS_HANDICAP)}
            </div>
            <div
              className={` md-hide sm-hide ${
                (!!getMarketParam ||
                  (!!sportGameIdParam && width > BREAKPOINT_MD) ||
                  (!!showBetSlip &&
                    width >= BREAKPOINT_XS &&
                    width < BREAKPOINT_LG)) &&
                'hide'
              }`}>
              {getResultName(MARKETS_TOTALS)}
            </div>
            <div className={`${!getMarketParam && 'hide'}`}>
              {getResultName(
                (params?.sport === FOOTBALL_ALIAS
                  ? FIXED_FOOTBALL_MARKETS_FILTERS
                  : FIXED_MARKETS_FILTERS
                )?.find(item => item?.title === getMarketParam)?.displayKey,
              )}
            </div>
          </div>
        </div>
        <div
          className={`flex column gap-5 px-md ${
            !!sportGameIdParam && width < BREAKPOINT_MD ? 'hidden' : ''
          }`}>
          <div className={`${isGrouped ? 'block' : 'hidden'}`}>
            {groupedData?.length && (
              <Virtuoso
                useWindowScroll
                data={groupedData}
                itemContent={(index, item) => (
                  <div className={`${index !== 0 ? 'pt-xxxl' : ''}`}>
                    <AccordionItem
                      defaultExpanded
                      count={item?.length}
                      GAMES_DATA={GAMES_DATA}
                      key={item?.[0]?.competition_id}
                      selectedGameId={sportGameIdParam}
                      region_alias={item?.[0]?.region_alias}
                      title={`${item?.[0]?.region_alias} - ${item?.[0]?.competition_name}`}>
                      {item?.map((gameItem, index) => (
                        <GameItem
                          isGrouped
                          index={index}
                          key={gameItem?.id}
                          gameItem={gameItem}
                          setGames={setGames}
                          gameState={gameItem?.info?.current_game_state}
                          current_game_time={gameItem?.info?.current_game_time}
                          current_game_last_set={
                            gameItem?.stats?.[
                              `score_${gameItem?.info?.current_game_state}`
                            ]
                          }
                        />
                      ))}
                    </AccordionItem>
                  </div>
                )}
              />
            )}
          </div>
          <div className={`${isGrouped ? 'hidden' : 'block'}`}>
            {GAMES_DATA?.length && (
              <Virtuoso
                useWindowScroll
                data={GAMES_DATA}
                itemContent={(index, gameItem) => (
                  <GameItem
                    index={index}
                    key={gameItem?.id}
                    gameItem={gameItem}
                    setGames={setGames}
                    gameState={gameItem?.info?.current_game_state}
                    current_game_time={gameItem?.info?.current_game_time}
                    current_game_last_set={
                      gameItem?.stats?.[
                        `score_${gameItem?.info?.current_game_state}`
                      ]
                    }
                  />
                )}
              />
            )}
          </div>
          <div className="relative">
            {showShowMoreButton && (
              <div
                className="rowCenter mt-lg"
                {...getToggleProps({
                  onClick: onShowMore,
                })}>
                <p className="textCenter cursor-pointer text-body1 bold-500">
                  {t('showMore')}
                </p>
              </div>
            )}
            <div className="flex mb-lg justify-center absolute-center z-max showMoreContainer">
              <div className="regionsWrapper scroll-1" {...getCollapseProps()}>
                <Regions
                  sportAlias={params?.sport}
                  selectedRegion={selectedRegion}
                  setSelectedRegion={setSelectedRegion}
                />
              </div>
              {!!isExpanded && selectedRegion?.id && (
                <div className="competitionsWrapper relative scroll-1 whiteBackground">
                  <CompetitionsWrapper
                    showMore
                    setExpanded={setExpanded}
                    selectedRegion={selectedRegion}
                    sportItem={{alias: params?.sport}}
                    setSelectedRegion={setSelectedRegion}
                    gameFilters={MATCH_FILTERS?.[params?.filter]?.status}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!sportGameIdParam && (
        <div
          className="scroll-auto-y scroll-1"
          style={{
            flex: 1,
            padding: '0px 5px',
            maxHeight: width > BREAKPOINT_LG ? 1500 : '100%',
          }}>
          <SelectedGame gameId={sportGameIdParam} />
        </div>
      )}
    </div>
  ) : params?.filter === FILTERS_UPCOMING_MATCHES_NAME ||
    params?.filter === FILTERS_LIVE_CALENDAR ? (
    <span className="textCenter font-24 py-xl">{t('noGames')}</span>
  ) : (
    <div className="flex justify-center my-xl">
      <AppButton
        type={2}
        width="30%"
        fontSize={20}
        onClick={onCheckInUpcomingMatches}
        title={t('checkInUpcomingMatches')}
        className="onCheckInUpcomingMatchesButton"
      />
    </div>
  );
};

export default memo(Games);
