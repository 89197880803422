import React from 'react';
import {RouterProvider} from 'react-router-dom';
import Modal from 'react-modal';

import './i18n';
import 'react-loading-skeleton/dist/skeleton.css';

import {useConnectSocket} from './Hooks';

import {router} from './Routing/router';
Modal.setAppElement('#root');

const App = () => {
  useConnectSocket();

  return <RouterProvider router={router} />;
};

export default App;
