import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import Competitions from './index';
import {AppButton} from '../../../UI';

import {
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  MATCH_FILTERS,
} from '../../../../Constants/MatchFilters';

import {getCompetitionsArray} from '../../../../Utils/GetSportId';

const CompetitionsWrapper = ({
  sportItem,
  setExpanded,
  gameFilters,
  selectedRegion,
  showMore = false,
  setSelectedRegion,
}) => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const competitionsRef = useRef(null);

  const [selectedCompetitionsArray, setSelectedCompetitionsArray] = useState(
    [],
  );

  const isDisabled = useMemo(
    () =>
      selectedCompetitionsArray?.length === 0 ||
      (params?.competition?.length > 0 &&
        selectedCompetitionsArray?.sort().join(',') ===
          getCompetitionsArray(params?.competition).sort().join(',')),
    [params?.competition, selectedCompetitionsArray],
  );

  const setInitialValues = useCallback(() => {
    if (selectedRegion?.alias === params?.region) {
      setSelectedCompetitionsArray(getCompetitionsArray(params?.competition));
    } else {
      setSelectedCompetitionsArray([]);
    }
  }, [params?.competition, params?.region, selectedRegion?.alias]);

  const applyChangesHandler = useCallback(() => {
    navigate(
      `/sports/${
        MATCH_FILTERS?.[
          params?.filter !== FILTERS_BOOSTED_ODDS_NAME
            ? params?.filter
            : FILTERS_UPCOMING_MATCHES_NAME
        ]?.name
      }/${sportItem?.alias}/${
        selectedRegion?.alias
      }/${selectedCompetitionsArray?.join('_')}`,
    );
    setExpanded(false);
    setSelectedRegion({});
  }, [
    navigate,
    setExpanded,
    params?.filter,
    sportItem?.alias,
    setSelectedRegion,
    selectedRegion?.alias,
    selectedCompetitionsArray,
  ]);

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);

  return (
    <div
      ref={competitionsRef}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="absolute competitionsBlock"
      style={
        showMore
          ? {}
          : {
              top:
                document.querySelector('.regionItemContainer.selected')
                  ?.offsetTop -
                  document.querySelector('.regionItemContainer.selected')
                    ?.parentElement?.parentElement?.parentElement?.scrollTop -
                  document.querySelector('.sportsContainer')?.scrollTop || 0,
            }
      }>
      <div className="competitionsWrapper">
        <Competitions
          gameFilters={gameFilters}
          sportAlias={sportItem?.alias}
          regionAlias={selectedRegion?.alias}
          selectedCompetitionsArray={selectedCompetitionsArray}
          setSelectedCompetitionsArray={setSelectedCompetitionsArray}
        />
      </div>
      <div className="ma-md column gap-5">
        <AppButton
          small
          type={3}
          fontSize={11}
          disabled={isDisabled}
          title={t('applyChanges')}
          onClick={applyChangesHandler}
        />
        <AppButton
          small
          type={5}
          fontSize={11}
          disabled={isDisabled}
          title={t('clearAll')}
          onClick={setInitialValues}
        />
      </div>
    </div>
  );
};

export default memo(CompetitionsWrapper);
