import React, {memo} from 'react';
import {useDispatch} from 'react-redux';

import './index.scss';

import {setShowLiveChat} from '../../Redux/AppSlice';

import {LIVE_CHAT_URI} from '../../Constants/Globals';

const LiveChat = ({show}) => {
  const dispatch = useDispatch();

  return (
    <div className={`mobileLiveChatContainer ${show ? 'flex' : 'hidden'}`}>
      <div
        className="overlay"
        onClick={() => dispatch(setShowLiveChat(false))}
      />
      <iframe width="100%" src={LIVE_CHAT_URI} />
    </div>
  );
};

export default memo(LiveChat);
