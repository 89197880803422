import {memo} from 'react';
import {t} from 'i18next';

import './index.scss';

const Checkbox = ({label, value, onChange, className, type = 1}) => (
  <label
    className={`checkbox_container row items-center gap-5 ${
      className ? className : ''
    }`}>
    <input
      type="checkbox"
      onChange={onChange}
      checked={value || ''}
      className={`checkbox checkbox_${type}`}
    />
    {typeof label === 'string' ? (
      <span className="blueText text-caption bold-500">{t(label)}</span>
    ) : (
      label
    )}
  </label>
);

export default memo(Checkbox);
