import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {useScreenSize} from '../../../Hooks';

import {ReactComponent as Back} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as Minimize} from '../../../Assets/Icons/Globals/minimize.svg';

import DepositButton from '../../Header/DepositButton';

import './index.scss';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

const GameActionBarHeader = ({setIsFullScreen}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const {width} = useScreenSize();
  const navigate = useNavigate();
  const [getParam, setGetParam] = useSearchParams();

  const onBackHandler = useCallback(() => {
    setIsFullScreen(false);
    getParam.delete('casinoGameId');
    setGetParam(getParam);
    if (location?.pathname?.includes('/casino/poker')) {
      navigate('/casino/lobby');
    }
  }, [getParam, location?.pathname, navigate, setGetParam, setIsFullScreen]);

  return (
    <div className="casinoGameActionBarContainer flex row justify-between px-lg py-md items-center darkBlueBackground">
      <div
        onClick={onBackHandler}
        className={`flex rowCenter gap-5 cursor-pointer blueBackground px-md py-sm rounded-borders`}>
        <Back width={10} height={10} fill="var(--white)" className="backIcon" />
        <span className="whiteText text-caption-small bold-700">
          {t('back')}
        </span>
      </div>

      <DepositButton />
      <div className="flex row items-center">
        <div
          onClick={() => {
            if (width < BREAKPOINT_XS) {
              document
                .querySelector('body')
                .classList.remove('overflow-hidden');
              document
                .querySelector('html')
                .classList.remove('overflow-hidden');
            }
            setIsFullScreen(false);
          }}
          className="flex row items-center cursor-pointer gap-5 blueBackground pa-sm rounded-borders">
          <Minimize
            width={18}
            height={18}
            fill="var(--white)"
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};
export default memo(GameActionBarHeader);
