import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {
  Outlet,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {LiveChatWidget} from '@livechat/widget-react';
import {useTranslation} from 'react-i18next';
import './Layout.scss';

import {
  setSubIds,
  setSports,
  setBoostedGameIds,
  setIsSportDataLoading,
  setActiveFilterParams,
} from '../Redux/SportSlice';
import store from '../Redux/store';
import {
  getSid,
  getWssSocket,
  getRecaptcha,
  getAuthModal,
  getIsLoggedIn,
  getShowBetSlip,
  getShowSearchModal,
  getShowMobileBetSlip,
  getIsContentScrolled,
  getMainMenuModal,
} from '../Redux/AppSlice/AppSelectors';
import {setProviders} from '../Redux/CasinoSlice';
import {setIsContentScrolled, setShowBetSlip} from '../Redux/AppSlice';
import {getSubIds, getSportsArray} from '../Redux/SportSlice/SportSelectors';
import {getShowCasinoSearchModal} from '../Redux/CasinoSlice/CasinoSelectors';

import {getNextSevenDaysUnixTimestamps} from '../Utils/GetNextSevenDaysFilters';

import {useScreenSize} from '../Hooks';
import useSocket from '../Hooks/UseSocket';
import useCasino from '../Hooks/UseCasino';

import {
  LICENSE_ID,
  GET_ALL_SPORTS,
  GET_BOOSTED_SPORTS,
  GET_BOOSTED_SELECTIONS,
} from '../Constants/Socket';
import {
  MATCH_FILTERS,
  FILTERS_TODAY_NAME,
  FILTERS_LIVE_CALENDAR,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../Constants/Globals';
import {
  CATEGORY_BOOSTED_RTP_GAMES_ID,
  CATEGORY_SKILL_GAMES_ID,
} from '../Constants/CasinoMenuFilter';
import {FixedCasinoProviders} from '../Constants/FixedCasinoProviders';

import {
  AppIntro,
  AppHeader,
  AppBetSlip,
  AppCarousel,
  AppRecaptcha,
  AppSuccessModal,
  AppCallToAction,
  AppNewCallToAction,
} from '../Components/UI';
import Footer from '../Components/Footer';
import MyBets from '../Components/MyBets';
import AuthStack from '../Components/Auth';
import Messages from '../Components/Messages';
import MainMenu from '../Components/MainMenu';
import NewFooter from '../Components/NewFooter';
import Promotions from '../Components/Promotions';
import NewMainMenu from '../Components/NewMainMenu';
import HomeSports from '../Components/Home/HomeSports';
import TopWinners from '../Components/Footer/TopWinners';
import Sports from '../Components/Home/Sports/SportsList';
import SportFilters from '../Components/Home/SportFilters';
import MobileHeader from '../Components/Header/MobileHeader';
import MobileBottomTab from '../Components/Home/MobileBottomTab';
import SearchModal from '../Components/Home/ActionBar/SearchModal';
import CommunityChatWidget from '../Components/CommunityChatWidget';
import BetSlipSuccessModal from '../Components/BetSlip/Components/SuccessModal';
import CasinoSearchModal from '../Components/Casino/CasinoActionBar/CasinoSearchModal';

import coloredClose from '../Assets/Icons/Globals/coloredClose.svg';
import {ReactComponent as BetSlipIcon} from '../Assets/Icons/Globals/betslip.svg';
import {ReactComponent as CloseIcon} from '../Assets/Icons/Globals/closeTransparent.svg';

const Layout = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getCasinoProviders} = useCasino();
  const {
    getGames,
    getSports,
    getBoostedGames,
    getInitialGames,
    getBoostedSports,
    getBoostedSelections,
  } = useSocket();

  const sid = useSelector(getSid);
  const subIds = useSelector(getSubIds);
  const wssSocket = useSelector(getWssSocket);
  const recaptcha = useSelector(getRecaptcha);
  const authModal = useSelector(getAuthModal);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const sportsData = useSelector(getSportsArray);
  const showBetSlip = useSelector(getShowBetSlip);
  const isScrolled = useSelector(getIsContentScrolled);
  const mainMenuModal = useSelector(getMainMenuModal);
  const showSearchModal = useSelector(getShowSearchModal);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);
  const showCasinoSearchModal = useSelector(getShowCasinoSearchModal);

  const contentWrapperRef = useRef(null);
  const previousGetParam = useRef('');

  const dayParam = useMemo(() => getParam.get('day'), [getParam]);
  const casinoGameIdParam = useMemo(
    () => getParam.get('casinoGameId'),
    [getParam],
  );
  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );
  const getMarketParam = useMemo(() => getParam.get('market'), [getParam]);
  const shareBetIdParam = useMemo(() => getParam.get('shareBetId'), [getParam]);
  // const casinoCategoryParam = useMemo(
  //   () => getParam.get('category'),
  //   [getParam],
  // );

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  // const requestGames = useCallback(
  //   showLoader => {
  //     if (params?.region && params?.competition) {
  //       getGames({
  //         showLoader,
  //         regionAlias: params?.region,
  //         activeSportAlias: params?.sport,
  //         gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
  //         competitionId: params?.competition?.split('_')?.map(item => +item),
  //       });
  //     } else {
  //       const searchQuery = store?.getState?.()?.sport?.searchQuery;
  //       getInitialGames({
  //         showLoader,
  //         search: searchQuery,
  //         activeSportAlias: params?.sport,
  //         gameFilters:
  //           params?.filter === FILTERS_LIVE_CALENDAR
  //             ? {
  //                 game: {
  //                   is_live: false,
  //                   start_ts: {
  //                     '@gte':
  //                       +getDayParam?.split('_')?.[0] ||
  //                       getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
  //                     '@lt':
  //                       +getDayParam?.split('_')?.[1] ||
  //                       getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
  //                   },
  //                 },
  //               }
  //             : MATCH_FILTERS?.[params?.filter]?.status,
  //         withoutLimit:
  //           params?.filter === FILTERS_TODAY_NAME ||
  //           params?.filter === FILTERS_LIVE_CALENDAR,
  //       });
  //     }
  //   },
  //   [
  //     getGames,
  //     getDayParam,
  //     params?.sport,
  //     params?.filter,
  //     params?.region,
  //     getInitialGames,
  //     params?.competition,
  //   ],
  // );

  const onMessageCb = useCallback(
    event => {
      // const data = JSON.parse(event?.data);
      // const gameCount = data?.data?.[subIds?.sports]?.sport;
      //
      // if (data?.rid === GET_ALL_SPORTS || data?.rid === GET_BOOSTED_SPORTS) {
      //   dispatch(setSubIds({sports: data?.data?.subid}));
      //
      //   const sportsArray = Object.values(data?.data?.data?.sport || {})?.sort(
      //     (a, b) => a?.order - b?.order,
      //   );
      //
      //   const initialSport = sportsArray?.[0];
      //   if (params?.region && params?.competition) {
      //     navigate({
      //       pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
      //         params?.sport
      //       }/${params?.region}/${params?.competition}`,
      //       ...(!!selectedGameId
      //         ? {
      //             search: createSearchParams({
      //               game: selectedGameId,
      //             }).toString(),
      //           }
      //         : {}),
      //     });
      //   } else if (params?.sport) {
      //     navigate({
      //       pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
      //         params?.sport
      //       }`,
      //       ...(!!selectedGameId
      //         ? {
      //             search: createSearchParams({
      //               game: selectedGameId,
      //             }).toString(),
      //           }
      //         : !!shareBetId
      //         ? {
      //             search: createSearchParams({
      //               shareBetId: shareBetId,
      //             }).toString(),
      //           }
      //         : {}),
      //     });
      //   } else if (!!params?.filter) {
      //     navigate({
      //       pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
      //         initialSport?.alias
      //       }`,
      //       ...(!!selectedGameId
      //         ? {
      //             search: createSearchParams({
      //               game: selectedGameId,
      //             }).toString(),
      //           }
      //         : {}),
      //     });
      //   }
      //
      //   dispatch(setSports(sportsArray));
      //   setTimeout(() => {
      //     dispatch(setIsSportDataLoading(false));
      //   }, 100);
      // } else if (data?.rid === GET_BOOSTED_SELECTIONS) {
      //   const gameIds = Object.keys(data?.data?.details || {})?.map(
      //     item => +item,
      //   );
      //
      //   dispatch(setBoostedGameIds(gameIds));
      //
      //   getBoostedSports({
      //     gameIdsArray: gameIds,
      //   });
      //
      //   getBoostedGames({
      //     gameIdsArray: gameIds,
      //     activeSportAlias: params?.sport,
      //   });
      // } else if (Object.keys(gameCount || {})?.length > 0) {
      //   const newSports = [...sportsData];
      //
      //   Object.entries(data?.data?.[subIds?.sports]?.sport || {})?.map(el => {
      //     const elementElementIndex = sportsData?.findIndex(
      //       item => +item?.id === +el?.[0],
      //     );
      //     newSports[elementElementIndex] = {
      //       ...newSports[elementElementIndex],
      //       ...el?.[1],
      //     };
      //     if (
      //       el?.[1]?.game > sportsData?.[elementElementIndex]?.game &&
      //       newSports[elementElementIndex]?.alias === params?.sport
      //     ) {
      //       requestGames(false);
      //     }
      //   });
      //
      //   dispatch(setSports(newSports));
      // }
    },
    [
      dispatch,
      navigate,
      sportsData,
      shareBetIdParam,
      // requestGames,
      params?.sport,
      params?.region,
      params?.filter,
      casinoGameIdParam,
      subIds?.sports,
      getBoostedGames,
      getBoostedSports,
      params?.competition,
    ],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  // useEffect(() => {
  //   if (!showSearchModal) {
  //     getParam.delete('search');
  //     setGetParam(getParam);
  //   }
  // }, [showSearchModal]);

  // useEffect(() => {
  //   if (
  //     params?.filter === FILTERS_LIVE_CALENDAR &&
  //     previousGetParam.current !== getDayParam
  //   ) {
  //     getParam.set(
  //       'day',
  //       `${
  //         getDayParam?.split('_')?.[0] ||
  //         getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay
  //       }_${
  //         getDayParam?.split('_')?.[1] ||
  //         getNextSevenDaysUnixTimestamps()?.[0]?.endOfDay
  //       }`,
  //     );
  //     setGetParam(getParam);
  //     previousGetParam.current = `${
  //       getDayParam?.split('_')?.[0] ||
  //       getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay
  //     }_${
  //       getDayParam?.split('_')?.[1] ||
  //       getNextSevenDaysUnixTimestamps()?.[0]?.endOfDay
  //     }`;
  //     requestGames(true);
  //   }
  // }, [getDayParam, params?.filter]);

  // useEffect(() => {
  //   if (width < BREAKPOINT_XS && !getMarketParam) {
  //     getParam.set('market', 'matchResult');
  //     setGetParam(getParam);
  //   }
  // }, [width, getMarketParam]);

  // useEffect(() => {
  //   if (!!sid) {
  //     const activeFilterParams = store.getState()?.sport?.activeFilterParams;
  //
  //     if (params?.category === 'liveCasino' || params?.tab === 'liveCasino') {
  //       dispatch(setProviders(FixedCasinoProviders));
  //     } else if (
  //       params?.category === 'slots' ||
  //       params?.category === 'skill' ||
  //       params?.category === 'boostedRTP' ||
  //       typeof params?.tab !== 'undefined'
  //     ) {
  //       dispatch(setIsSportDataLoading(true));
  //       getCasinoProviders({
  //         categoryId:
  //           params?.category === 'skill'
  //             ? CATEGORY_SKILL_GAMES_ID
  //             : params?.category === 'boostedRTP'
  //             ? CATEGORY_BOOSTED_RTP_GAMES_ID
  //             : null,
  //       })
  //         ?.then(res =>
  //           dispatch(
  //             setProviders(
  //               res?.data?.providers?.filter(item => item?.name !== 'EVB'),
  //             ),
  //           ),
  //         )
  //         ?.finally(() =>
  //           setTimeout(() => dispatch(setIsSportDataLoading(false)), 100),
  //         );
  //     } else if (params?.filter || params?.filter !== activeFilterParams) {
  //       dispatch(setActiveFilterParams(MATCH_FILTERS?.[params?.filter]?.name));
  //       if (params?.filter === FILTERS_BOOSTED_ODDS_NAME) {
  //         getBoostedSelections();
  //       } else {
  //         getSports({
  //           gameFilters:
  //             params?.filter === FILTERS_LIVE_CALENDAR
  //               ? {
  //                   game: {
  //                     start_ts: {
  //                       '@gte':
  //                         +getDayParam?.split('_')?.[0] ||
  //                         getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
  //                       '@lt':
  //                         +getDayParam?.split('_')?.[1] ||
  //                         getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
  //                     },
  //                   },
  //                 }
  //               : MATCH_FILTERS?.[params?.filter]?.status,
  //         });
  //       }
  //     }
  //     if (params?.sport) {
  //       if (params?.filter === FILTERS_BOOSTED_ODDS_NAME) {
  //         const boostedGameIds = store.getState()?.sport?.boostedGameIds;
  //         if (boostedGameIds?.length > 0) {
  //           getBoostedGames({
  //             gameIdsArray: boostedGameIds,
  //             activeSportAlias: params?.sport,
  //           });
  //         }
  //       } else {
  //         if (
  //           params?.filter !== FILTERS_BOOSTED_ODDS_NAME &&
  //           params?.filter !== FILTERS_LIVE_CALENDAR
  //         ) {
  //           requestGames();
  //         }
  //       }
  //     }
  //   }
  // }, [
  //   sid,
  //   params?.tab,
  //   params?.sport,
  //   params?.filter,
  //   params?.region,
  //   params?.category,
  //   params?.competition,
  // ]);

  // useEffect(() => {
  //   if (
  //     location.pathname === '/' ||
  //     location.pathname === '/sports' ||
  //     location.pathname === '/sports/'
  //   ) {
  //     navigate(`/sports/${FILTERS_TODAY_NAME}`);
  //   }
  // }, []);

  useEffect(() => {
    if (!!gameFilterParam && !!sportParam && !!sid) {
      getInitialGames({
        activeSportAlias: sportParam,
        gameFilters: MATCH_FILTERS?.[gameFilterParam]?.status,
      });
    }
  }, [gameFilterParam, sportParam, sid, sportGameIdParam]);

  useEffect(() => {
    if (!!regionParam && !!sid) {
      const splitArray = regionParam?.split?.('-');
      const competitions =
        splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
      const flattenedArray = (competitions || [])
        ?.flat?.(Infinity)
        ?.map?.(item => +item);
      getGames({
        activeSportAlias: sportParam,
        competitionId: flattenedArray,
        gameFilters: MATCH_FILTERS?.[gameFilterParam]?.status,
      });
    }
  }, [regionParam, sid]);

  useEffect(() => {
    if (!!dayParam && !!sid) {
      getInitialGames({
        activeSportAlias: sportParam,
        gameFilters: {
          game: {
            is_live: false,
            start_ts: {
              '@gte':
                +dayParam?.split('_')?.[0] ||
                getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
              '@lt':
                +dayParam?.split('_')?.[1] ||
                getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
            },
          },
        },
      });
    }
  }, [dayParam, sid]);

  useEffect(() => {
    if (!!sid && location.pathname?.includes('/sports')) {
      getSports({rid: GET_ALL_SPORTS});
    }
  }, [sid]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/sports`);
    }
  }, []);

  useEffect(() => {
    if (
      showMobileBetSlip ||
      (!!casinoGameIdParam && location?.pathname?.includes('casino'))
    ) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [location?.pathname, casinoGameIdParam, showMobileBetSlip]);

  return (
    <div
      className={`appLayout ${
        width < BREAKPOINT_XS
          ? isLoggedIn
            ? 'loggedInPadding'
            : 'notLoggedInPadding'
          : ''
      } `}>
      {width < BREAKPOINT_XS ? (
        <MobileHeader
          showFilters={
            !casinoGameIdParam || (!!casinoGameIdParam && authModal?.isVisible)
          }
        />
      ) : (
        <AppHeader />
      )}
      {gameFilterParam !== FILTERS_UPCOMING_MATCHES_NAME && <AppCarousel />}
      {!sportGameIdParam && !location?.pathname?.includes('casino') && (
        <div className="blueBackground">
          <HomeSports />
          <SportFilters isMobile />
        </div>
      )}
      <div className="contentContainerWrapper row full-width">
        {location?.pathname !== '/casino/poker' &&
          !location?.pathname?.includes('casino') && <Sports />}
        <div
          ref={contentWrapperRef}
          onScroll={e => {
            if (width < BREAKPOINT_XS) {
              dispatch(setIsContentScrolled(e?.target?.scrollTop > 50));
            }
          }}
          className={`contentWrapper flex-1 ${
            width < BREAKPOINT_XS
              ? ''
              : location?.pathname?.includes('casino')
              ? 'mx-md'
              : 'mx-sm'
          } ${
            location?.pathname?.includes('casino') ? 'px-md' : ''
          } rounded-top-borders ${
            location?.pathname?.includes('casino') &&
            !!showMobileBetSlip &&
            'hidden'
          }`}>
          <div className="content full-height">
            {location.pathname !== '/jackpot' &&
              location?.pathname !== '/casino/poker' && (
                <>
                  {/*{location?.pathname?.includes('casino') && <AppCarousel />}*/}
                  {/*{width > BREAKPOINT_XS && <AppJackpot />}*/}
                </>
              )}
            <Outlet />
            {location?.pathname?.includes('casino') &&
              !location?.pathname?.includes('casino/poker') && <TopWinners />}
            {/*<AppCallToAction />*/}
            {/*<AppNewCallToAction />*/}
          </div>
        </div>
        {/*{(width > BREAKPOINT_XS || showMobileBetSlip) && (*/}
        {/*  <div*/}
        {/*    style={*/}
        {/*      width < BREAKPOINT_XS*/}
        {/*        ? {position: 'absolute', top: 0, width: '100%', zIndex: 16}*/}
        {/*        : {}*/}
        {/*    }*/}
        {/*    className={`appBetSlipWrapper_desktop blueBackground rounded-top--borders column ${*/}
        {/*      showMobileBetSlip ? 'slide-up' : 'slide-down'*/}
        {/*    } appBetSlipWrapper`}>*/}
        {/*    <div className="row items-center  justify-between yellowBackground pa-sm">*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          width: 40,*/}
        {/*          height: 40,*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <span className="font-20 blueText bold-600">{t('myBets')}</span>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          width: 40,*/}
        {/*          height: 40,*/}
        {/*        }}*/}
        {/*        className="whiteBackground items-center justify-center flex rounded-borders">*/}
        {/*        <img*/}
        {/*          alt="close"*/}
        {/*          width={10}*/}
        {/*          height={10}*/}
        {/*          src={coloredClose}*/}
        {/*          className="cursor-pointer"*/}
        {/*          onClick={() => dispatch(setShowBetSlip(false))}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={`full-height betSlipDesktopWrapper rounded-borders`}>*/}
        {/*      <AppBetSlip />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {width > BREAKPOINT_XS ? (
          <div
            style={{...(width < BREAKPOINT_XS ? {display: 'none'} : {})}}
            className={`${
              width < BREAKPOINT_XS
                ? 'appBetSlipWrapper_mobile flex items-center justify-center full-width'
                : 'appBetSlipWrapper_desktop blueBackground rounded-top--borders'
            } ${
              width >= BREAKPOINT_XS ? (!!showBetSlip ? 'column' : 'hide') : ''
            } ${
              showMobileBetSlip && width < BREAKPOINT_XS
                ? 'slide-up'
                : 'slide-down'
            } appBetSlipWrapper`}>
            {width > BREAKPOINT_XS && (
              <div
                className="row items-center  justify-between yellowBackground pa-sm"
                style={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}>
                <div
                  style={{
                    width: 26,
                    height: 26,
                  }}
                />
                <span className="font-15 blueText bold-600">{t('myBets')}</span>
                <div
                  style={{
                    width: 26,
                    height: 26,
                  }}
                  className="whiteBackground items-center justify-center flex rounded-borders">
                  <img
                    alt="close"
                    width={10}
                    height={10}
                    src={coloredClose}
                    className="cursor-pointer"
                    onClick={() => dispatch(setShowBetSlip(false))}
                  />
                </div>
              </div>
            )}
            <div
              className={`${
                width > BREAKPOINT_XS
                  ? 'full-height betSlipDesktopWrapper'
                  : 'full-width flex justify-center'
              } rounded-borders`}>
              <AppBetSlip />
            </div>
          </div>
        ) : (
          <div
            className={`appBetSlipWrapper_mobile flex justify-center fit blueBackground rounded-top--borders column appBetSlipWrapper ${
              showMobileBetSlip ? 'slide-up' : 'slide-down'
            }`}
            style={{
              zIndex: 16,
              background: '#00000070',
            }}>
            <div
              style={{
                marginTop: 45,
                width: '97dvw',
                height: '100%',
                borderRadius: 12,
              }}>
              <AppBetSlip />
            </div>
          </div>
        )}
      </div>
      {!!getParam?.get('showPromotions') && <Promotions />}
      <AuthStack />
      {width < BREAKPOINT_XS && !mainMenuModal?.isDesktop ? (
        <NewMainMenu />
      ) : (
        <MainMenu />
      )}
      {width < BREAKPOINT_XS && <MobileBottomTab />}
      {/*<Messages />*/}
      <MyBets />
      <AppSuccessModal />
      {showSearchModal && <SearchModal />}
      {showCasinoSearchModal && <CasinoSearchModal />}
      {recaptcha?.isVisible && <AppRecaptcha />}
      <AppIntro />
      {width > BREAKPOINT_XS && (
        <LiveChatWidget
          license={LICENSE_ID?.toString?.()}
          visibility={width > BREAKPOINT_XS ? 'minimized' : 'hidden'}
        />
      )}
      {!!shareBetIdParam && (
        <BetSlipSuccessModal
          isSharedBet
          betId={shareBetIdParam}
          isOpen={!!shareBetIdParam}
          setIsOpen={() => {
            getParam.delete('shareBetId');
            setGetParam(getParam);
          }}
        />
      )}
      {/*<CommunityChatWidget*/}
      {/*  show={width > BREAKPOINT_XS && isLoggedIn !== null}*/}
      {/*/>*/}
      {location.pathname !== '/jackpot' && !sportGameIdParam && <NewFooter />}
    </div>
  );
};

export default Layout;
