import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import './index.scss';

import {useScreenSize} from '../../Hooks';

import {getIsLoggedIn} from '../../Redux/AppSlice/AppSelectors';

import {
  IOS_APP_URL,
  BREAKPOINT_XS,
  ANDROID_APP_URL,
} from '../../Constants/Globals';
import {PAYMENT_SERVICES, SOCIALS} from '../../Constants/PaymentServices';

import logo from '../../Assets/Layout/HeaderLogo.png';
import responsibly from '../../Assets/Icons/Globals/18+.svg';
import IosMarket from '../../Assets/Images/AppMarkets/Ios.png';
import LasVegasCasino from '../../Assets/Layout/LasVegasLogo.png';
import cashIcon from '../../Assets/Images/PaymentServices/cash.png';
import InvestmentLogo from '../../Assets/Layout/investmentLogo.png';
import AndroidMarket from '../../Assets/Images/AppMarkets/Android.png';
import LeGrandeCasino from '../../Assets/Layout/leGrandeCasinoWhite.png';
import PalmBeachCasino from '../../Assets/Layout/palmBeachCasinoWhite.png';

import CollapseItem from './CollapseItem';

const NewFooter = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {width} = useScreenSize();

  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <div
      key={width < BREAKPOINT_XS}
      className={`newFooterContainer pt-xxxl pb-xl darkenBlueBackground column items-center justify-center mx-auto ${
        isLoggedIn ? 'loggedInPadding' : 'loggedOutPadding'
      }`}
      style={{maxWidth: 1094}}>
      <div className="column items-center justify-center gap-18 pb-xxxl">
        <span className="yellowText bold-700 font-14">
          {t('valuedPartners')}
        </span>
        <div className="row items-center justify-center gap-15">
          <img
            width={58}
            height={57}
            src={PalmBeachCasino}
            alt="PalmBeachCasino"
          />
          <img
            width={63}
            height={24}
            src={LasVegasCasino}
            alt="LasVegasCasino"
          />
          <img
            width={41}
            height={51}
            src={LeGrandeCasino}
            alt="LeGrandeCasino"
          />
        </div>
      </div>
      <div
        className={`row items-start ${
          width < BREAKPOINT_XS ? 'gap-2' : 'gap-20'
        } pb-xl bottomBorder full-width wrap `}>
        <CollapseItem
          title="aboutUs"
          alwaysOpen={width > BREAKPOINT_XS}
          content={
            <div
              className="bold-500 font-10 whiteText column gap-10 rounded-borders pa-sm"
              style={{background: 'rgb(0, 48, 105)'}}>
              <span className="yellowText">{t('duniaInvestment')}</span>
              <div>
                <span>{t('aboutUsText')}</span>
              </div>
              <div className="column">
                <span className="yellowText">
                  {t('TIN')} <span className="whiteText">115-646-583</span>
                </span>
                <span className="yellowText">
                  {t('companyRegNumber')}{' '}
                  <span className="whiteText">86149</span>
                </span>
                <span className="yellowText">
                  {t('offices')}
                  <span className="whiteText">
                    10th Floor Harbour View Towers Samora Avenue
                  </span>
                </span>
                <span className="yellowText">
                  {t('sportsBettingLicense')}
                  <span className="whiteText">SBI000000021</span>
                </span>
                <span className="yellowText">
                  {t('internetCasinoLicense')}
                  <span className="whiteText">OLC000000003</span>
                </span>
              </div>
            </div>
          }
        />
        <CollapseItem
          alwaysOpen={width > BREAKPOINT_XS}
          defaultExpanded={width > BREAKPOINT_XS}
          title="applications"
          content={
            <div className="gap-8 column pa-sm">
              <img
                height={52}
                width="100%"
                src={IosMarket}
                alt="IosMarket"
                style={{objectFit: 'contain'}}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(IOS_APP_URL, '_blank');
                }}
              />
              <img
                height={52}
                width="100%"
                src={AndroidMarket}
                alt="AndroidMarket"
                style={{objectFit: 'contain'}}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(ANDROID_APP_URL, '_blank');
                }}
              />
            </div>
          }
        />
        <CollapseItem
          defaultExpanded={width > BREAKPOINT_XS}
          alwaysOpen={width > BREAKPOINT_XS}
          title="generalLink"
          content={
            <div className="column gap-3 whiteText">
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {t('blog')}
              </span>
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {t('howToVideos')}
              </span>
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {t('news')}
              </span>
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {t('promotions')}
              </span>
            </div>
          }
        />
        <CollapseItem
          defaultExpanded={width > BREAKPOINT_XS}
          alwaysOpen={width > BREAKPOINT_XS}
          title="legalAndCompliance"
          content={
            <div className="column gap-3 whiteText">
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate({
                    pathname: '/privacy',
                    search: `?${createSearchParams({
                      tab: 'termsConditions',
                    })}`,
                  });
                }}>
                {t('generalTerms')}
              </span>
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate({
                    pathname: '/privacy',
                    search: `?${createSearchParams({
                      tab: 'privacyPolicy',
                    })}`,
                  });
                }}>
                {t('privacyPolicy')}
              </span>
              <span
                style={{background: 'rgb(0, 48, 105)'}}
                className="font-12 px-md py-sm rounded-borders"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate({
                    pathname: '/privacy',
                    search: `?${createSearchParams({
                      tab: 'responsibleGaming',
                    })}`,
                  });
                }}>
                {t('responsibleGambling')}
              </span>
            </div>
          }
        />
      </div>
      <div className="row items-center gap-40 py-xl full-width bottomBorder justify-center">
        <img width={88} height={52} src={InvestmentLogo} alt="InvestmentLogo" />
        <span className="font-10 whiteText" style={{width: 185}}>
          {t('companyRegulated')}
        </span>
      </div>
      <div
        className={`row items-center justify-${
          width < BREAKPOINT_XS ? 'center' : 'between'
        } full-width py-xl bottomBorder wrap gap-20`}>
        <div className="row items-center gap-20">
          {width > BREAKPOINT_XS && (
            <span className="bold-600 font-15 whiteText">
              {t('paymentMethods')}
            </span>
          )}
          <div className="justify-center flex row items-center gap-10 wrap">
            {PAYMENT_SERVICES.map(item => (
              <img
                width={80}
                height={37}
                key={item?.id}
                alt={item?.title}
                src={item?.logo || cashIcon}
              />
            ))}
          </div>
        </div>
        <div className="row items-center gap-20">
          {width > BREAKPOINT_XS && (
            <span className="bold-600 font-15 whiteText">
              {t('followUsOn')}
            </span>
          )}
          <div className="justify-center flex row items-center gap-5 wrap">
            {SOCIALS.map(item => (
              <img
                width={37}
                height={37}
                key={item?.id}
                alt={item?.id}
                src={require(`../../Assets/Icons/Socials/${item?.logo}.svg`)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="pt-xl gap-20 column items-center">
        <div className="row items-center gap-5">
          <span className="font-14 bold-700 yellowText">
            {t('playResponsibly')}
          </span>
          <img alt="18+" width={11} height={11} src={responsibly} />
        </div>
        <span className="whiteText font-10">
          {t('copyRight', {year: new Date().getFullYear()})}
        </span>
        <img alt="logo" width={148} height={74} src={logo} />
      </div>
    </div>
  );
};

export default memo(NewFooter);
