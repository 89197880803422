import {createSelector} from '@reduxjs/toolkit';

const getBettingState = state => state?.betting;

export const getIsBetSlipDataLoading = createSelector(
  getBettingState,
  state => state?.isBetSlipDataLoading,
);

export const getHeaderTabIndex = createSelector(
  getBettingState,
  state => state?.headerTabIndex,
);

export const getOpenBetsCount = createSelector(
  getBettingState,
  state => state?.openBetsCount,
);

export const getBonusInfoData = createSelector(
  getBettingState,
  state => state?.bonusInfoData,
);
