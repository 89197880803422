import React, {memo, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../../Redux/AppSlice';

import {useScreenSize, useSocket} from '../../../../../Hooks';

import {
  TIME_OUT,
  BREAKPOINT_XS,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../../../Constants/Globals';
import {SET_CLIENT_SELF_EXCLUSION} from '../../../../../Constants/Socket';

import {AppButton, AppRadioButton} from '../../../../UI';

const TimeOut = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {updateSelfExclusion} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [selectedLimit, setSetSelectedLimit] = useState({
    limit: TIME_OUT?.[0]?.limit,
    index: 0,
  });

  const saveClickHandler = useCallback(() => {
    setIsSaveLoading(true);
    updateSelfExclusion({limits: selectedLimit?.limit, exc_type: 1});
  }, [selectedLimit?.limit, updateSelfExclusion]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case SET_CLIENT_SELF_EXCLUSION:
          if (data?.code === 0 && data?.data?.result === 0) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('timeOutPeriodSet'),
                  successDescription: `${t('sorryToSeeYouGoDeeYouIn')} ${
                    TIME_OUT?.[selectedLimit?.index]?.title
                  }!`,
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          }
          setIsSaveLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, selectedLimit?.index, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <>
      <div
        className={`flex column px-md items-center justify-between timeOutContainer ${
          !(width < BREAKPOINT_XS) && 'py-xl '
        }`}>
        <div className="flex row items-center full-width justify-center">
          <div className="col-xs-12 col-sm-10 col-md-8 flex column content justify-between gap-10">
            <div className="flex justify-between column gap-10 col-sm-12 col-md-4">
              {!(width < BREAKPOINT_XS) && (
                <span className="whiteText font-15 bold-700 textCenter py-md">
                  {t('timeOutReminder')}
                </span>
              )}
              <span className="whiteText bold-500 font-12 textCenter">
                {t('timeOutText')}
              </span>
              <div className="row gap-10 wrap mx-auto items-center justify-center">
                {TIME_OUT.map(({limit, title, value}, index) => (
                  <div style={{width: 130}} key={value}>
                    <AppRadioButton
                      type={2}
                      value={value}
                      text={t(title)}
                      name="exclusionTime"
                      defaultChecked={index === 0}
                      isActive={selectedLimit?.limit === limit}
                      onChange={() => setSetSelectedLimit({limit, index})}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <AppButton
          type={3}
          loading={isSaveLoading}
          onClick={saveClickHandler}
          title={t('confirmLower').toUpperCase()}
        />
      </div>
    </>
  );
};

export default memo(TimeOut);
