import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Countdown, {zeroPad} from 'react-countdown';
import {useDispatch, useSelector} from 'react-redux';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';

import {
  getWssSocket,
  getShowBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {setActiveFilterParams} from '../../../Redux/SportSlice';
import {getSubIds} from '../../../Redux/SportSlice/SportSelectors';

import Markets from './Markets';

import {useScreenSize} from '../../../Hooks';

import {getSportId} from '../../../Utils/GetSportId';
import {getDateColor} from '../../../Utils/DateFormatter';
import {formatGameStates} from '../../../Utils/EventNamesFormatter';
import {checkIsToday, getGameStartTime} from '../../../Utils/TimeConvertors';

import {
  FOOTBALL_ALIAS,
  MARKETS_HANDICAP,
  FILTERS_LIVE_CALENDAR,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../../../Constants/MatchFilters';
import {
  MARKETS_TOTALS,
  MARKETS_WINNER,
  MARKETS_DOUBLE_CHANCE,
  MARKETS_BOTH_TEAM_TO_SCORE,
} from '../../../Constants/MatchFilters';
import {Flags} from '../../../Constants/Flags';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';

import {ReactComponent as TimerIcon} from '../../../Assets/Icons/Globals/timer.svg';

import './index.scss';

import {AppBlinkingLiveIcon, AppLiveIndicator} from '../../UI';
import {
  FIXED_MARKETS_FILTERS,
  FIXED_FOOTBALL_MARKETS_FILTERS,
} from '../../../Constants/FixedMarketFiltersArray';

const GameItem = ({
  setGames,
  gameItem,
  gameState,
  current_game_time,
  isGrouped = false,
  current_game_last_set,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const subIds = useSelector(getSubIds);
  const wssSocket = useSelector(getWssSocket);
  const showBetSlip = useSelector(getShowBetSlip);

  const [isBlocked, setIsBlocked] = useState(false);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(
    current_game_last_set,
  );
  const [isGameLive, setIsGameLive] = useState(!!gameItem?.is_live);
  const [gameStartTime, setGameStartTime] = useState(null);
  const [currentGameState, setCurrentGameState] = useState(gameState);
  const [team1score, setTeam1score] = useState(gameItem?.info?.score1);
  const [team2score, setTeam2score] = useState(gameItem?.info?.score2);
  const [currentMarkets, setCurrentMarkets] = useState(null);
  const [currentGameTime, setCurrentGameTime] = useState(current_game_time);
  const [currentMarketsCount, setCurrentMarketsCount] = useState(null);
  const [team1RedCards, setTeam1RedCards] = useState(
    gameItem?.stats?.red_card?.team1_value,
  );
  const [team2RedCards, setTeam2RedCards] = useState(
    gameItem?.stats?.red_card?.team2_value,
  );
  const getDayParam = getParam.get('day');
  const sportParam = getParam.get('sport');
  const getMarketParam = getParam.get('market');

  const isFootball = useMemo(() => sportParam === FOOTBALL_ALIAS, [sportParam]);
  const sportId = useMemo(() => getSportId(sportParam), [sportParam]);

  const selectedGameId = useMemo(() => getParam.get('sportGameId'), [getParam]);

  // TODO do not delete need to handle
  const isSpecialBet = useMemo(
    () => gameItem?.show_type === 'OUTRIGHT',
    [gameItem?.show_type],
  );

  const isDisabled = useMemo(
    () => !currentMarketsCount || isBlocked,
    [currentMarketsCount, isBlocked],
  );

  const startTime = useMemo(
    () => getGameStartTime(gameStartTime),
    [gameStartTime],
  );
  const isToday = useMemo(() => checkIsToday(gameStartTime), [gameStartTime]);

  const minutesBeforeGame = useMemo(
    () => Math.round((new Date(gameStartTime * 1000) - new Date()) / 1000),
    [gameStartTime],
  );

  const navigateToGame = useCallback(() => {
    if (!isDisabled) {
      getParam.set('sportGameId', gameItem?.id);
      setGetParam(getParam);
      // navigate({
      //   pathname: location?.pathname,
      //   search: createSearchParams({
      //     game: gameItem?.id,
      //     ...(getMarketParam ? {market: getMarketParam} : {}),
      //     ...(getDayParam ? {day: getDayParam} : {}),
      //   }).toString(),
      // });
    }
  }, [isDisabled, getParam, gameItem?.id, setGetParam]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event.data);
      if (data?.data?.[subIds?.games]) {
        const regionObject =
          data?.data?.[subIds?.games]?.sport?.[sportId]?.region;

        for (const region in regionObject) {
          const competitionObject = regionObject[region]?.competition;
          for (const competition in competitionObject) {
            const gameObject =
              competitionObject[competition]?.game?.[gameItem?.id];

            if (competitionObject[competition]?.game?.[gameItem?.id] === null) {
              setGames(prev => {
                if (prev?.length === 1) {
                  dispatch(setActiveFilterParams(''));
                  navigate(`/sports/${FILTERS_UPCOMING_MATCHES_NAME}`);
                } else {
                  return prev?.filter(
                    currentItem => currentItem?.id !== gameItem?.id,
                  );
                }
              });
              return;
            }

            if (gameObject?.market) {
              // HANDLE MARKET CHANGES
              const prevMarkets = {...currentMarkets};

              for (const market in gameObject?.market) {
                if (gameObject?.market?.[market] === null) {
                  // HANDLE MARKET DELETING CASE
                  delete prevMarkets[market];
                } else if (
                  typeof prevMarkets?.[market] === 'undefined' ||
                  Object.keys(prevMarkets?.[market])?.length === 0
                ) {
                  // HANDLE MARKET ADDING CASE
                  prevMarkets[market] = gameObject?.market?.[market];
                } else if (prevMarkets?.[market]) {
                  // HANDLE MARKET PRICE CHANGING
                  const changedEventObject =
                    gameObject?.market?.[market]?.event;

                  const prevEvents = {...prevMarkets[market].event};

                  for (const changedEvent in changedEventObject) {
                    if (changedEventObject[changedEvent] === null) {
                      // HANDLE EVENT DELETING CASE
                      delete prevEvents[changedEvent];
                    } else if (
                      typeof prevEvents[changedEvent] === 'undefined' ||
                      Object.keys(prevEvents[changedEvent])?.length === 0
                    ) {
                      // HANDLE EVENT ADDING CASE
                      prevEvents[changedEvent] =
                        gameObject?.market?.[market].event[changedEvent];
                    } else if (prevEvents?.[changedEvent]) {
                      // HANDLE EVENT PRICE CHANGING
                      const lastPrice =
                        currentMarkets[market]?.event?.[changedEvent]?.price;
                      prevEvents[changedEvent] = {
                        ...(prevEvents?.[changedEvent] || {}),
                        ...(changedEventObject?.[changedEvent] || {}),
                        ...(+lastPrice !==
                        +changedEventObject?.[changedEvent]?.price
                          ? {
                              lastPrice:
                                currentMarkets[market]?.event?.[changedEvent]
                                  ?.price,
                            }
                          : {}),
                      };
                    }
                  }
                  prevMarkets[market].event = prevEvents;
                }
              }
              setCurrentMarkets(prevMarkets);
            }

            // HANDLE MARKET COUNT CHANGES
            if (typeof gameObject?.markets_count !== 'undefined') {
              setCurrentMarketsCount(gameObject?.markets_count);
            }

            // HANDLE BLOCK CHANGES
            if (typeof gameObject?.is_blocked !== 'undefined') {
              setIsBlocked(!!gameObject?.is_blocked);
            }
            if (typeof gameObject?.info?.current_game_state !== 'undefined') {
              setCurrentGameState(gameObject?.info?.current_game_state);
            }
            if (typeof gameObject?.is_live !== 'undefined') {
              setIsGameLive(!!gameObject?.is_live);
            }
            if (
              typeof gameObject?.info?.score1 !== 'undefined' &&
              !!gameObject?.info?.score1
            ) {
              setTeam1score(gameObject?.info?.score1);
            }
            if (typeof gameObject?.info?.current_game_time !== 'undefined') {
              setCurrentGameTime(gameObject?.info?.current_game_time);
            }
            if (
              typeof gameObject?.info?.score2 !== 'undefined' &&
              !!gameObject?.info?.score2
            ) {
              setTeam2score(gameObject?.info?.score2);
            }
            if (
              typeof gameObject?.stats?.red_card?.team1_value !== 'undefined' &&
              !!gameObject?.stats?.red_card?.team1_value
            ) {
              setTeam1RedCards(gameObject?.stats?.red_card?.team1_value);
            }
            if (
              typeof gameObject?.stats?.red_card?.team2_value !== 'undefined' &&
              !!gameObject?.stats?.red_card?.team2_value
            ) {
              setTeam2RedCards(gameObject?.stats?.red_card?.team2_value);
            }
            if (
              typeof gameObject?.stats?.[`score_${currentGameState}`] !==
              'undefined'
            ) {
              setCurrentGameLastSet(prevState => ({
                ...prevState,
                ...gameObject?.stats?.[`score_${currentGameState}`],
              }));
            }
          }
        }
      }
    },
    [
      sportId,
      setGames,
      dispatch,
      navigate,
      gameItem?.id,
      subIds?.games,
      currentMarkets,
      currentGameState,
    ],
  );

  useEffect(() => {
    setCurrentMarkets(gameItem?.market || {});
    setCurrentMarketsCount(gameItem?.markets_count || 0);
    setIsBlocked(!!gameItem?.is_blocked);
    setGameStartTime(gameItem?.start_ts);
  }, [
    gameItem?.market,
    gameItem?.start_ts,
    gameItem?.is_blocked,
    gameItem?.markets_count,
  ]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      onClick={navigateToGame}
      className={`gameItemContainer cursor-pointer ${
        width < BREAKPOINT_XS ? 'mobileBorderSeparator pb-sm' : ' desktop'
      } ${
        +selectedGameId === +gameItem?.id
          ? 'yellowBackground'
          : 'whiteBackground'
      }`}>
      <div className="row items-center pa-xl">
        <div className="row gameItemWrapper justify-between">
          <div className="statsContainer">
            <div className="flex column">
              <div className="flex items-center">
                <div>
                  {location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) ? (
                    <div className="flex items-center">
                      <TimerIcon fill="var(--gray)" width={15} height={15} />
                      <span className="text-caption-small ml-xs">
                        {startTime?.split?.('-')?.[1]}
                      </span>
                    </div>
                  ) : isToday &&
                    !gameItem?.is_live &&
                    !!minutesBeforeGame &&
                    +minutesBeforeGame < 3600 ? (
                    <div className="flex items-center gap-5 grayText">
                      <TimerIcon fill="var(--gray)" width={15} height={15} />
                      <span className="text-caption-small">
                        {/*<span>{t('startingIn')}</span>*/}
                        <Countdown
                          date={Date.now() + +minutesBeforeGame * 1000}
                          renderer={({hours, minutes, seconds, completed}) => (
                            <span className="blueText bold-700">
                              {zeroPad(minutes)}{' '}
                            </span>
                          )}
                        />
                        <span className="blueText bold-700">{t('M')}</span>
                        {/*<span style={getDateColor(startTime)} className="bold-700">*/}
                        {/*  {startTime?.split('-')?.[1]}*/}
                        {/*</span>*/}
                      </span>
                    </div>
                  ) : isGameLive ? (
                    <div className="row items-center text-caption-small gap-5">
                      <AppBlinkingLiveIcon />
                      <AppLiveIndicator />
                      {gameItem?.info?.set_count !== 1 &&
                        !currentGameTime &&
                        typeof currentGameLastSet?.team1_value !==
                          'undefined' && (
                          <span className="orangeText bold-600">
                            {currentGameLastSet?.team1_value}-
                            {currentGameLastSet?.team2_value}
                          </span>
                        )}
                      {currentGameTime && currentGameTime !== 'undefined' && (
                        <span className="blueText bold-600">
                          {currentGameTime?.trim?.()}`
                        </span>
                      )}
                      {currentGameState &&
                        typeof currentGameState !== 'undefined' && (
                          <span className="grayText">
                            {t(
                              formatGameStates({
                                gameState: currentGameState,
                                sportAlias: gameItem?.sport_alias,
                              }),
                            )}
                          </span>
                        )}
                    </div>
                  ) : (
                    <span
                      className="text-caption-small"
                      style={getDateColor(startTime)}>
                      {startTime}
                    </span>
                  )}
                </div>
                {location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) && (
                  <div className="rowCenter ml-xs">
                    {Flags?.[gameItem?.region_alias] && (
                      <img
                        width={16}
                        alt={gameItem?.region_alias}
                        src={Flags?.[gameItem?.region_alias]}
                      />
                    )}
                    <span
                      className="text-caption-small bold-500 textWithDotes ml-xs grayText"
                      style={{
                        ...{
                          maxWidth:
                            width - 20 - 190 - 52 - 40 > 50
                              ? width - 20 - 190 - 52 - 40
                              : 50,
                        },
                      }}>
                      {gameItem?.competition_name}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex column overflow-hidden">
                <div className="row items-center gap-5">
                  <img
                    width={15}
                    height={15}
                    alt={gameItem?.team1_id}
                    src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                      gameItem?.team1_id / 2000,
                    )}/${gameItem?.team1_id}.png`}
                  />
                  <span
                    style={{maxWidth: 0.32 * width}}
                    className={`font-13 blueText textWithDotes teamNames bold-${
                      +team1score > +team2score ? '700' : '500'
                    }`}>
                    {gameItem?.team1_name}
                  </span>
                  {!!team1RedCards && (
                    <div
                      style={{width: 10, height: 13, borderRadius: 3}}
                      className="redBackground flex items-center justify-center">
                      {!!team1RedCards && +team1RedCards > 1 && (
                        <span className="font-9 whiteText bold-700">
                          {team1RedCards}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {!!gameItem?.team2_name && (
                  <div className="row items-center gap-5">
                    <img
                      width={15}
                      height={15}
                      alt={gameItem?.team2_id}
                      src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                        gameItem?.team2_id / 2000,
                      )}/${gameItem?.team2_id}.png`}
                    />
                    <span
                      style={{maxWidth: 0.32 * width}}
                      className={`font-13 textWithDotes blueText teamNames bold-${
                        +team2score > +team1score ? '700' : '500'
                      }`}>
                      {gameItem?.team2_name}
                    </span>
                    {!!team2RedCards && (
                      <div
                        style={{width: 10, height: 13, borderRadius: 3}}
                        className="redBackground flex items-center justify-center">
                        {!!team2RedCards && +team2RedCards > 1 && (
                          <span className="font-9 whiteText bold-700">
                            {team2RedCards}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {width > BREAKPOINT_XS &&
                !isGrouped &&
                !location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) && (
                  <div className="flex items-center gap-5">
                    {Flags?.[gameItem?.region_alias] && (
                      <img
                        alt="flag"
                        className="flag"
                        src={Flags?.[gameItem?.region_alias]}
                      />
                    )}
                    <span className="text-caption bold-500">
                      {gameItem?.competition_name}
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className="flex items-end">
            <div
              className={`flex column items-center pr-${
                width < BREAKPOINT_XS ? 'sm' : 'xl'
              } gap-5`}>
              {!!team1score &&
                typeof team1score !== 'undefined' &&
                isGameLive && (
                  <span
                    className="orangeText text-caption bold-700 "
                    style={{lineHeight: 1}}>
                    {team1score}{' '}
                  </span>
                )}
              {!!team2score &&
                typeof team2score !== 'undefined' &&
                isGameLive && (
                  <span
                    className="orangeText text-caption bold-700 "
                    style={{lineHeight: 1}}>
                    {team2score}{' '}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="row gap-30 items-end pt-xl ">
          <div className={`flex gap-5 sm-hide ${!!getMarketParam && 'hide'}`}>
            <Markets
              gameItem={gameItem}
              isDisabled={isDisabled}
              displayKey={MARKETS_WINNER}
              currentMarkets={currentMarkets}
            />
          </div>
          {isFootball && (
            <div
              className={`flex gap-5 sm-hide ${
                (!!getMarketParam ||
                  (!!selectedGameId && width >= BREAKPOINT_MD) ||
                  (!!showBetSlip &&
                    width >= BREAKPOINT_XS &&
                    width < BREAKPOINT_MD)) &&
                'hide'
              }`}>
              <Markets
                gameItem={gameItem}
                isDisabled={isDisabled}
                currentMarkets={currentMarkets}
                displayKey={MARKETS_DOUBLE_CHANCE}
              />
            </div>
          )}
          {isFootball && (
            <div
              className={`flex gap-5 sm-hide ${
                (!!getMarketParam ||
                  (!!selectedGameId && width >= BREAKPOINT_MD) ||
                  (!!showBetSlip &&
                    width >= BREAKPOINT_XS &&
                    width < BREAKPOINT_LG)) &&
                'hide'
              }`}>
              <Markets
                gameItem={gameItem}
                isDisabled={isDisabled}
                currentMarkets={currentMarkets}
                displayKey={MARKETS_BOTH_TEAM_TO_SCORE}
              />
            </div>
          )}
          <div
            className={`flex gap-5 sm-hide ${
              (!!getMarketParam ||
                (!!selectedGameId && width >= BREAKPOINT_MD) ||
                (!!showBetSlip &&
                  !!isFootball &&
                  width >= BREAKPOINT_XS &&
                  width < BREAKPOINT_LG)) &&
              'hide'
            }`}>
            <Markets
              gameItem={gameItem}
              isDisabled={isDisabled}
              displayKey={MARKETS_HANDICAP}
              currentMarkets={currentMarkets}
            />
          </div>
          <div
            className={`flex gap-5 md-hide sm-hide ${
              (!!getMarketParam ||
                (!!selectedGameId && width > BREAKPOINT_MD) ||
                (!!showBetSlip &&
                  width >= BREAKPOINT_XS &&
                  width < BREAKPOINT_LG)) &&
              'hide'
            }`}>
            <Markets
              gameItem={gameItem}
              isDisabled={isDisabled}
              displayKey={MARKETS_TOTALS}
              currentMarkets={currentMarkets}
            />
          </div>
          <div className={`flex gap-5 ${!getMarketParam && 'hide'}`}>
            <Markets
              gameItem={gameItem}
              isDisabled={isDisabled}
              currentMarkets={currentMarkets}
              displayKey={
                (isFootball
                  ? FIXED_FOOTBALL_MARKETS_FILTERS
                  : FIXED_MARKETS_FILTERS
                )?.find(item => item?.title === getMarketParam)?.displayKey
              }
            />
          </div>
        </div>
      </div>
      {width > BREAKPOINT_XS && !+selectedGameId && (
        <div className="marketsCountContainer px-xl">
          {/*  <div className="flex justify-end relative marketsCountWrapper">*/}
          {/*<span className="blueText bold-700 text-caption-small">*/}
          {/*  {!!currentMarketsCount && '+'}*/}
          {/*  {currentMarketsCount}*/}
          {/*</span>*/}
          {/*  </div>*/}
          <div className="full-width relative line"></div>
        </div>
      )}
    </div>
  );
};

export default memo(GameItem);
