import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import StartTime from '../StartTime';

const BasketballGlobalScore = ({game}) => {
  const {t} = useTranslation();

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;
    gameHalf = gameHalf?.replace?.(/set1/g, '1ST');
    gameHalf = gameHalf?.replace?.(/set2/g, '2ND');
    gameHalf = gameHalf?.replace?.(/set3/g, '3RD');
    gameHalf = gameHalf?.replace?.(/set4/g, '4TH');

    return gameHalf;
  }, [game?.info?.current_game_state]);

  return (
    <div
      className={`flex column items-center justify-${
        !!game?.is_live || game?.info?.current_game_state === 'finished'
          ? 'center'
          : 'between'
      }`}>
      {game?.info?.current_game_state === 'finished' ? (
        <div></div>
      ) : (
        <StartTime game={game} />
      )}
      <div className="flex row items-center gap-5">
        {!!game?.is_live && (
          <span
            className={`bold-500 font-30 ${
              game?.info?.current_game_state === 'finished'
                ? 'whiteText'
                : 'orangeText'
            }`}>
            {game?.info?.score1}
          </span>
        )}
        <div style={{width: 10, height: 2}} className="whiteBackground">
          -
        </div>
        {!!game?.is_live && (
          <span className="bold-500 font-30 orangeText">
            {game?.info?.score2}
          </span>
        )}
      </div>
      {game?.info?.current_game_state === 'finished' ? (
        <span className="font-12 bold-600 whiteText" style={{marginTop: 10}}>
          {t('finished')}
        </span>
      ) : (
        !!game?.is_live &&
        game?.info?.current_game_state && (
          <div className="flex gap-5">
            <span
              className={`bold-600 font-14 ${
                game?.info?.current_game_state === 'finished' ||
                game?.info?.current_game_state === 'Half End'
                  ? 'whiteText'
                  : 'orangeText'
              }`}>
              {halfValue}
            </span>
            {game?.info?.current_game_time &&
              game?.info?.current_game_state !== 'Half End' && (
                <span className="whiteText bold-600 font-14">
                  {t('quarter')}{' '}
                  <span className="bold-600 font-14 orangeText">
                    {game?.info?.current_game_time?.split?.(':')?.[0]}'
                  </span>
                </span>
              )}
          </div>
        )
      )}
      {!game?.is_live && <div></div>}
    </div>
  );
};

export default BasketballGlobalScore;
