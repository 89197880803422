import React, {useMemo} from 'react';

import EventListItem from './EventListItem';

const EventsList = ({marketItem, team1Name, team2Name}) => {
  const eventsArray = useMemo(
    () =>
      Object.values(marketItem?.event || {})?.sort(
        (a, b) => a?.order - b?.order,
      ) || [],
    [marketItem?.event],
  );

  return Array.apply(null, Array(marketItem?.col_count))?.map((_, index) => {
    const eventItem = eventsArray?.find(
      eventItem => +eventItem?.order === index,
    );

    return (
      <EventListItem
        key={eventItem?.id}
        eventItem={eventItem}
        team1Name={team1Name}
        team2Name={team2Name}
        marketItem={marketItem}
      />
    );
  });
};
// DO NOT PUT IN MEMO
export default EventsList;
