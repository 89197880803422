import React, {memo, useCallback, useState} from 'react';

import './index.scss';

import openedEye from '../../../Assets/Icons/Globals/openedEye.svg';
import checkedIcon from '../../../Assets/Icons/Globals/success.svg';
import closedEye from '../../../Assets/Icons/Globals/closedEye.svg';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';

const Input = ({
  error,
  inputRef,
  inputValue,
  placeholder,
  type = 1,
  onFocusCb = () => {},
  leftIcon = null,
  onChange = () => {},
  height = 45,
  eyeIconStyle,
  errorTextClass = null,
  className = null,
  setErrorMsg = () => {},
  search = false,
  checked = false,
  disabled = false,
  inputType = 'text',
  contentEditable = true,
  password = false,
  errorToTop = false,
  errorNumberOfLines = null,
  autoComplete = 'new-password',
  maxLength = null,
  ...props
}) => {
  const [showText, setShowText] = useState(password);
  const [isBlurred, setIsBlurred] = useState(true);

  const onChangeText = useCallback(() => {
    if (error && setErrorMsg && typeof setErrorMsg === 'function') {
      setErrorMsg(null);
    }
  }, [error, setErrorMsg]);

  return (
    <div className="flex column full-width gap-3 inputContainerWrapper overflow-hidden">
      {error && isBlurred && errorToTop && (
        <span
          style={{top: -15}}
          className={`redText bold-700 font-7 absolute ${
            errorNumberOfLines
              ? `ellipsis-${errorNumberOfLines}-lines`
              : 'ellipsis'
          }`}>
          {error}
        </span>
      )}
      <div
        className={`inputContainer flex ${
          type === 7 ? 'rounded-borders lightGrayBackground' : ''
        }`}>
        {leftIcon && leftIcon}
        <input
          {...props}
          ref={inputRef}
          value={inputValue}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          contentEditable={contentEditable}
          type={showText ? 'password' : inputType}
          style={{height, paddingRight: password ? 40 : 0}}
          className={`input background_${type} ${
            !isBlurred ? `focused_${type}` : ''
          } ${!!search ? 'leftSpaceWithSearch' : ''} ${
            className ? className : ''
          }`}
          onBlur={() => setIsBlurred(true)}
          onFocus={() => {
            setIsBlurred(false);
            onFocusCb();
          }}
          onChange={e => {
            if (!!maxLength) {
              e.target.value = e.target.value.slice(0, maxLength);
            }

            onChange(e?.target?.value);
            onChangeText(e?.target?.value);
          }}
          onWheel={() => inputRef?.current?.blur?.()}
        />
        {password && (
          <img
            alt="eye"
            className="eyeIcon cursor-pointer"
            src={showText ? openedEye : closedEye}
            style={eyeIconStyle ? eyeIconStyle : {top: height / 2 - 9}}
            onClick={() => setShowText(prevState => !prevState)}
          />
        )}
        {search && (
          <div className="searchIconContainer flex items-center justify-center">
            <SearchIcon width={11} height={11} fill="var(--appBlue)" />
          </div>
        )}
        {checked && (
          <img alt="checked" src={checkedIcon} className="checkedIcon" />
        )}
      </div>
      {error && isBlurred && !errorToTop && (
        <span
          className={`redText ${
            errorTextClass ? errorTextClass : 'text-caption'
          } ${
            errorNumberOfLines
              ? `ellipsis-${errorNumberOfLines}-lines`
              : 'ellipsis'
          }`}>
          {error}
        </span>
      )}
    </div>
  );
};

export default memo(Input);
