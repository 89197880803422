import React, {memo, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {setAuthModal} from '../../../Redux/AppSlice';

import MainInfo from './Tabs/MainInfo';
import Congrats from './Tabs/Congrats';
import DetailedInfo from './Tabs/DetailedInfo';

import {AppModalBackButton, AppPrivacyPolicy} from '../../UI';

const Register = () => {
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  // 1 Step
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  // 2 Step
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [affiliateCode, setAffiliateCode] = useState('');
  const [agreementValue, setAgreementValue] = useState(false);

  const onBackClick = useCallback(() => {
    dispatch(setAuthModal({isCloseDisabled: false, privacyTabIndex: null}));
    setCurrentTab(1);
  }, [dispatch]);

  return currentTab === 0 ? (
    <MainInfo
      phone={phone}
      password={password}
      setPhone={setPhone}
      errorMsg={errorMsg}
      setPassword={setPassword}
      setErrorMsg={setErrorMsg}
      setCurrentTab={setCurrentTab}
    />
  ) : currentTab === 1 ? (
    <div className="mx-auto">
      <DetailedInfo
        phone={phone}
        password={password}
        lastName={lastName}
        firstName={firstName}
        dateOfBirth={dateOfBirth}
        setErrorMsg={setErrorMsg}
        setLastName={setLastName}
        setFirstName={setFirstName}
        setCurrentTab={setCurrentTab}
        affiliateCode={affiliateCode}
        setDateOfBirth={setDateOfBirth}
        agreementValue={agreementValue}
        setAffiliateCode={setAffiliateCode}
        setAgreementValue={setAgreementValue}
      />
    </div>
  ) : currentTab === 2 ? (
    <div className="mx-auto">
      <Congrats />
    </div>
  ) : currentTab === 3 ? (
    <div className="py-lg full-width">
      <AppModalBackButton onBackClick={onBackClick} />
      <AppPrivacyPolicy />
    </div>
  ) : null;
};

export default memo(Register);
