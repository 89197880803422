export const availableLanguages = [
  {
    status: 'eng',
    title: 'english',
    icon: require('../Assets/Images/Flags/UnitedKingdom.png'),
  },
  {
    status: 'swa',
    title: 'swahili',
    icon: require('../Assets/Images/Flags/UnitedRepublicofTanzania.png'),
  },
  {
    status: 'chn',
    title: 'chinese',
    icon: require('../Assets/Images/Flags/China.png'),
  },
];

export const APP_LANGUAGES = {
  SWA: 'swa',
  ENG: 'eng',
  CHN: 'chn',
};
