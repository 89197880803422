import React, {memo} from 'react';

import './index.scss';

const Counter = ({active, value, type = 1}) => (
  <div
    className={`flex items-center justify-center rounded-borders counterContainer counterContainer_${type} counterContainer_${type}_${
      active ? 'active' : ''
    }`}>
    <span
      className={`text-caption-extra-small bold-600 title title_${type} title_${type}_${
        active ? 'active' : ''
      }`}>
      {value}
    </span>
  </div>
);

export default memo(Counter);
