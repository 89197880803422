export const FixedCompetitionsFilters = {
  //Football
  Soccer: [
    {
      id: 1,
      regionId: 2570001,
      competitionId: 538,
      regionAlias: 'England',
      title: 'Premier League',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/epl.png'),
    },
    {
      id: 2,
      regionId: 2260001,
      title: 'nbcPremier',
      competitionId: 11193,
      regionAlias: 'United Republic of Tanzania',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/nbcpremier.png'),
    },
    {
      id: 3,
      title: 'laLiga',
      regionId: 2150001,
      competitionId: 545,
      regionAlias: 'Spain',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/laliga.png'),
    },
    {
      id: 4,
      title: 'ligue1',
      regionId: 830001,
      competitionId: 548,
      regionAlias: 'France',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/ligue1.png'),
    },
    {
      id: 5,
      title: 'serieA',
      regionId: 1170001,
      competitionId: 543,
      regionAlias: 'Italy',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/serieA.png'),
    },
    {
      id: 6,
      regionId: 900001,
      competitionId: 541,
      title: 'bundesliga',
      regionAlias: 'Germany',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/bundesliga.png'),
    },
  ],
  //Basketball
  Basketball: [
    {
      id: 1,
      title: 'NBA',
      regionId: 50003,
      competitionId: 756,
      regionAlias: 'North America',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/NBA.png'),
    },
    {
      id: 2,
      title: 'NCAA',
      regionId: 50003,
      competitionId: 757,
      regionAlias: 'North America',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/NCAA.png'),
    },
    {
      id: 3,
      regionId: 20003,
      competitionId: 692,
      title: 'EuroBasket',
      regionAlias: 'Europe',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/EuroBasket.png'),
    },
    {
      id: 4,
      regionId: 20003,
      competitionId: 679,
      title: 'ABA League',
      regionAlias: 'Europe',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/ABALeague.png'),
    },
    {
      id: 5,
      regionId: 2410013,
      competitionId: 812,
      title: 'BBL Trophy',
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/BBLChampionship.png'),
    },
    {
      id: 6,
      regionId: 2410013,
      competitionId: 813,
      title: 'BBL Championship',
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/BBLChampionship.png'),
    },
    {
      id: 7,
      title: 'BBL Cup',
      regionId: 2410013,
      competitionId: 9373,
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Basketball/BBLChampionship.png'),
    },
  ],
  //Cricket
  Cricket: [
    {
      id: 1,
      regionId: 1100019,
      competitionId: 10851,
      regionAlias: 'India',
      title: 'Indian Premier League',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/IPL.png'),
    },
    {
      id: 2,
      regionId: 10019,
      competitionId: 7160,
      regionAlias: 'World',
      title: 'One Day international',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/OneDayInternational.png'),
    },
    {
      id: 3,
      regionId: 10019,
      title: 'Test Series',
      competitionId: 14505,
      regionAlias: 'World',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/TestSeries.png'),
    },
    {
      id: 4,
      regionId: 10019,
      regionAlias: 'World',
      title: 'T20 World Cup',
      competitionId: 18281640,
      logo: require('../Assets/Images/FixedCompetitions/Cricket/T20WorldCup.png'),
    },
    {
      id: 5,
      regionId: 10019,
      regionAlias: 'World',
      competitionId: 10389,
      title: 'ICC World Cup',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/ICCWorldCup.png'),
    },
    {
      id: 6,
      regionId: 21,
      competitionId: 14311,
      regionAlias: 'Australia',
      title: 'Twenty20 Big Bash',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/BigBashLeague.png'),
    },
    {
      id: 7,
      regionId: 2410019,
      title: 'The Hundred',
      competitionId: 18269450,
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/TheHundred.png'),
    },
    {
      id: 8,
      regionId: 2410019,
      title: 'T20 Blast',
      competitionId: 17085,
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/T20Blast.png'),
    },
  ],
  //Tennis
  Tennis: [
    {
      id: 1,
      regionId: 210004,
      competitionId: 1130,
      regionAlias: 'Australia',
      title: 'Australian Open-Men',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/AustralianOpen.png'),
    },
    {
      id: 2,
      regionId: 210004,
      competitionId: 1129,
      regionAlias: 'Australia',
      title: 'Australian Open-Women',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/AustralianOpen.png'),
    },
    {
      id: 3,
      regionId: 830004,
      competitionId: 4736,
      regionAlias: 'France',
      title: 'French Open-Men',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/FrenchOpen.png'),
    },
    {
      id: 4,
      regionId: 830004,
      competitionId: 4737,
      regionAlias: 'France',
      title: 'French Open-Women',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/FrenchOpen.png'),
    },
    {
      id: 5,
      regionId: 2420004,
      regionAlias: 'USA',
      competitionId: 4835,
      title: 'US Open-Men',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/USOpen.png'),
    },
    {
      id: 6,
      regionId: 2420004,
      regionAlias: 'USA',
      competitionId: 4836,
      title: 'US Open-Women',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/USOpen.png'),
    },
    {
      id: 7,
      regionId: 2410004,
      competitionId: 4871,
      title: 'Wimbledon-Men',
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/Wimbledon.png'),
    },
    {
      id: 8,
      title: 'Wimbledon-Women',
      regionId: 2410004,
      competitionId: 4621,
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/Wimbledon.png'),
    },
    {
      id: 9,
      regionId: 10004,
      title: 'Laver Cup',
      competitionId: 25115,
      regionAlias: 'World',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/LaverCup.png'),
    },
  ],
  //Rugby League
  RugbyLeague: [
    {
      id: 1,
      regionId: 2570036,
      competitionId: 10599,
      title: 'Super League',
      regionAlias: 'England',
      logo: require('../Assets/Images/FixedCompetitions/RugbyLeague/SuperLeagueLogo.png'),
    },
    {
      id: 2,
      regionId: 210036,
      competitionId: 9383,
      regionAlias: 'Australia',
      title: 'National Rugby league',
      logo: require('../Assets/Images/FixedCompetitions/RugbyLeague/NationalRugbyLeague.png'),
    },
    {
      id: 3,
      regionId: 210036,
      competitionId: 10736,
      regionAlias: 'Australia',
      title: 'State of Origin',
      logo: require('../Assets/Images/FixedCompetitions/RugbyLeague/StateOfOrigin.png'),
    },
  ],
  //Rugby Union
  RugbyUnion: [
    {
      id: 1,
      regionId: 10037,
      competitionId: 10439,
      regionAlias: 'World',
      title: 'Super Rugby',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/SuperRugby.png'),
    },
    {
      id: 2,
      regionId: 10037,
      title: 'World Cup',
      competitionId: 12035,
      regionAlias: 'World',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/RugbyWorldCup.png'),
    },
    {
      id: 3,
      regionId: 257,
      competitionId: 10451,
      regionAlias: 'England',
      title: 'Premiership Rugby',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/PremiershipRugby.png'),
    },
    {
      id: 4,
      title: 'Top 14',
      regionId: 830037,
      competitionId: 10438,
      regionAlias: 'France',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/Top14.png'),
    },
    {
      id: 5,
      regionId: 20037,
      competitionId: 9385,
      title: 'Six Nations',
      regionAlias: 'Europe',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/SixNationsEurope.png'),
    },
  ],
  //Table Tennis
  TableTennis: [
    {
      id: 1,
      regionId: 10041,
      title: 'Setka Cup',
      regionAlias: 'World',
      competitionId: 18281698,
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/SetkaCup.png'),
    },
    {
      id: 2,
      regionId: 10041,
      title: 'TT Cup',
      regionAlias: 'World',
      competitionId: 18281742,
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/TTCup.png'),
    },
    {
      id: 3,
      regionId: 1900041,
      title: 'Liga Pro',
      regionAlias: 'Russia',
      competitionId: 18275894,
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/RussiaLigaPro.png'),
    },
    {
      id: 4,
      regionId: 670041,
      title: 'Liga Pro',
      competitionId: 18275895,
      regionAlias: 'Czech Republic',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/CzechLigaPro.png'),
    },
    {
      id: 5,
      regionId: 10041,
      regionAlias: 'World',
      competitionId: 18285512,
      title: 'WTT Star Contender Bangkok',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/WTTStarContenderBangkok.png'),
    },
  ],
  //Formula 1
  Formula1: [
    {
      id: 1,
      regionId: 10025,
      competitionId: 14597,
      regionAlias: 'World',
      title: 'F1 World Championship',
      logo: require('../Assets/Images/FixedCompetitions/Formula1/F1.png'),
    },
  ],
  //Boxing
  Boxing: [
    {
      id: 1,
      regionId: 10017,
      competitionId: 14597,
      regionAlias: 'World',
      title: 'Professional Boxing',
      logo: require('../Assets/Images/FixedCompetitions/Boxing/ProfessionalBoxing.png'),
    },
    {
      id: 2,
      regionId: 10017,
      competitionId: 12774,
      regionAlias: 'World',
      title: 'Professional Boxing - Women',
      logo: require('../Assets/Images/FixedCompetitions/Boxing/ProfessionalBoxing.png'),
    },
    {
      id: 3,
      regionId: 10017,
      regionAlias: 'World',
      competitionId: 18277571,
      title: 'Bare Knuckle Fighting Championships',
      logo: require('../Assets/Images/FixedCompetitions/Boxing/BKFC.png'),
    },
  ],
  //MMA
  Mma: [
    {
      id: 1,
      regionId: 10044,
      competitionId: 16483,
      regionAlias: 'World',
      title: 'Cage Warriors',
      logo: require('../Assets/Images/FixedCompetitions/MMA/CageWarriors.png'),
    },
    {
      id: 2,
      title: 'UFC',
      regionId: 10044,
      competitionId: 4164,
      regionAlias: 'World',
      logo: require('../Assets/Images/FixedCompetitions/MMA/UFC.png'),
    },
    {
      id: 3,
      regionId: 10044,
      competitionId: 16031,
      regionAlias: 'World',
      title: 'One Championship',
      logo: require('../Assets/Images/FixedCompetitions/MMA/ONEChampionship.png'),
    },
    {
      id: 4,
      regionId: 1900044,
      regionAlias: 'Russia',
      competitionId: 18259945,
      title: 'Absolute Championship Akhmat',
      logo: require('../Assets/Images/FixedCompetitions/MMA/AbsoluteChampionshipAkhmat.png'),
    },
    {
      id: 5,
      regionId: 1840044,
      title: 'FAME MMA',
      competitionId: 32349,
      regionAlias: 'Poland',
      logo: require('../Assets/Images/FixedCompetitions/MMA/FAMEMMA.png'),
    },
  ],
};
