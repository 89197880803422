import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {ReactComponent as Back} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as Maximize} from '../../../Assets/Icons/Globals/maximize.svg';

import './index.scss';

const GameActionBarFooter = ({setIsFullScreen, gameTitle}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [getParam, setGetParam] = useSearchParams();

  const onBackHandler = useCallback(() => {
    setIsFullScreen(false);
    getParam.delete('casinoGameId');
    setGetParam(getParam);
    if (location?.pathname?.includes('/casino/poker')) {
      navigate('/casino/lobby');
    }
  }, [getParam, location?.pathname, navigate, setGetParam, setIsFullScreen]);

  return (
    <div className="casinoGameActionBarContainer flex row justify-between items-center pt-md">
      <div
        onClick={onBackHandler}
        className="flex rowCenter gap-5 cursor-pointer darkBlueBackground px-md py-sm rounded-borders">
        <Back width={10} height={10} fill="var(--white)" className="backIcon" />
        <span className="whiteText text-caption-small bold-700">
          {t('back')}
        </span>
      </div>
      <div>
        <span className="whiteText bold-700 text-caption">{gameTitle}</span>
      </div>
      <div className="flex row items-center">
        <div
          onClick={() => setIsFullScreen(true)}
          className="flex row items-center cursor-pointer gap-5 darkBlueBackground px-md py-sm rounded-borders">
          <span className="whiteText bold-700 text-caption-small">
            {t('fullScreen')}
          </span>
          <Maximize
            width={18}
            height={18}
            fill="var(--white)"
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};
export default memo(GameActionBarFooter);
