// import {
//   POKER_SCREEN,
//   CASINO_SCREEN,
//   SPORTS_SCREEN,
//   JACKPOT_SCREEN,
//   BET_SLIP_SCREEN,
//   LIVE_CHAT_SCREEN,
//   LAST_PLAYED_SCREEN,
//   CASINO_HOME_SCREEN,
//   TOP_WINNERS_SCREEN,
//   LIVE_CASINO_SCREEN,
// } from '../Navigation/ScreenNames';
//
import {
  FILTERS_LIVE_NAME,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_MULTIPLE_DAY_NAME,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_POPULAR_COMPETITIONS_NAME,
} from '../Constants/MatchFilters';

export const chn = {
  yes: 'Ch',
  // [CASINO_SCREEN]: 'Ch',
  // [SPORTS_SCREEN]: 'Ch',
  [FILTERS_LIVE_NAME]: 'Ch',
  // [CASINO_HOME_SCREEN]: 'Ch',
  // [BET_SLIP_SCREEN]: 'Ch',
  // [JACKPOT_SCREEN]: 'Ch Ch',
  // [POKER_SCREEN]: 'CH CH',
  // [LIVE_CHAT_SCREEN]: 'Ch Ch',
  // [LIVE_CASINO_SCREEN]: 'Ch Ch',
  // [LAST_PLAYED_SCREEN]: 'Ch Ch',
  // [TOP_WINNERS_SCREEN]: 'Ch Ch',
  [FILTERS_BOOSTED_ODDS_NAME]: 'Ch Ch',
  [FILTERS_POPULAR_MATCHES_NAME]: 'Ch Ch',
  [FILTERS_UPCOMING_MATCHES_NAME]: 'Ch Ch',
  [FILTERS_MULTIPLE_DAY_NAME]: 'Ch Ch Ch Ch',
  [FILTERS_POPULAR_COMPETITIONS_NAME]: 'Ch Ch',
  searchTeam: ' h chh',
  home: ' h chh',
  draw: ' h chh',
  away: ' h chh',
  reset: ' h chh',
  league: ' h chh',
  promos: ' h chh',
  signIn: ' h chh',
  register: ' h chh',
  chooseLanguage: ' h chh',
  ok: ' h chh',
  seeMore: ' h chh',
  forgotPassword: ' h chh',
  continue: ' h chh',
  depositNow: ' h chh',
  logIn: ' h chh',
  alreadyHaveAnAccount: ' h chh',
  signInWaiting: ' h chh',
  signInLower: ' h chh',
  rememberMe: ' h chh',
  newMember: ' h chh',
  affiliates: ' h chh',
  passwordPlaceholder: ' h chh',
  registerAnAccount: ' h chh',
  phoneCode: ' h chh',
  phoneNumberPlaceholder: ' h chh',
  firstName: ' h chh',
  lastName: ' h chh',
  day: ' h chh',
  month: ' h chh',
  year: ' h chh',
  affiliateCode: ' h chh',
  agreement: ' h chh',
  iConfirm: ' h chh',
  iAgree: ' h chh',
  andThe: ' h chh',
  privacyPolicy: ' h chh',
  termsConditions: ' h chh',
  congratulations: ' h chh',
  youAreRegistered: ' h chh',
  pleaseDeposit: ' h chh',
  resetPassword: ' h chh',
  enterYourPhoneNumber: ' h chh',
  resetPhoneNumberPlaceholder: ' h chh',
  smsHasBeenSent: ' h chh',
  resetCodePlaceholder: ' h chh',
  resend: ' h chh',
  send: ' h chh',
  leagues: ' h chh',
  applyChanges: ' h chh',
  cancel: ' h chh',
  noGames: ' h chh',
  anSmsHasBeenSent: ' h chh',
  enterNewPassword: 'Chchchchc h chh',
  confirmNewPassword: 'Chchchchc h chh',
  done: 'Chchchchc h chh',
  passwordWasResetSuccessfully: ' h chh',
  generalTerms: ' h chh',
  responsibleGaming: ' h chh',
  help: ' h chh',
  statistics: ' h chh',
  licences: ' h chh',
  myBets: ' h chh',
  phoneErrorMsg: ' h chh',
  passwordErrorMsg: 'Chchchchc h chh',
  nameErrorMsg: 'Chchchchc h chh',
  chooseDateOfBirth: 'Chchchchc h chh',
  clearAll: 'CH CH CH',
  logout: 'logout',
  mainMenu: 'CH CH',
  mainBalance: 'CH CH',
  TZS: 'CH',
  withdrawableMoney: 'CH',
  totalBonusMoney: 'CH',
  loyaltyPoints: 'CH',
  contactUs: 'CH',
  legalAndCompliance: 'CH',
  settings: 'CH',
  messages: 'CH',
  promotions: 'CH',
  myBetsMainMenu: 'CH',
  balanceManagement: 'CH',
  viewLoyaltyPoints: 'CH',
  Language: 'CH',
  myProfile: 'CH',
  personalDetails: 'CH',
  changePassword: 'CH',
  selfExclusion: 'CH',
  timeOut: 'CH',
  depositLimits: 'CH',
  saveChanges: 'CH',
  save: 'CH',
  YES: 'CH',
  NO: 'CH',
  OK: 'CH',
  confirmPasswordText: 'CH CHCHCH',
  DONE: 'CH',
  currentPassword: 'CH',
  newPassword: 'CH',
  changePasswordError: 'CH',
  week: 'Week',
  depositLimitText: 'CH',
  ID: 'ID',
  Mobile255xxx: 'CH',
  email: 'CH',
  passportID: 'CH/CH',
  birthdate: 'CH CH',
  gender: 'CH',
  country: 'CH',
  city: 'CH',
  eligibleForBonus: 'CH CH CH:',
  selfExclusionText: 'CH',
  CONFIRMATION: 'CH',
  selfExclusionTextModal: 'CH',
  timeOutText: 'CH ',
  timeOutModalText: 'CH',
  timeOutPeriodSet: 'CH',
  months: 'CH',
  years: 'CH',
  hours: 'CH',
  days: 'CH',
  male: 'CH',
  family: 'CH',
  chooseGender: 'CH',
  totalBonus: 'CH CH',
  odds: 'CH CH',
  viewSelections: 'CH CH CH',
  hideSelections: 'CH CH Ch',
  betNow: 'CH CH Ch',
  acceptChanges: 'CH CH Ch CH CH Ch',
  signInOrRegister: 'CH CH Ch CH CH Ch',
  possibleWin: 'CH CH Ch CH CH Ch',
  accumulatorBonus: 'CH CH Ch CH CH Ch',
  tax: 'CH CH Ch CH CH Ch',
  finalPayout: 'CH CH Ch CH CH Ch',
  winner: 'CH CH Ch',
  handicap: 'CH CH Ch',
  totals: 'CH CH Ch',
  emptyBoosted: 'CH CH Ch CH CH Ch',
  emptyMultiple: 'CH CH Ch CH CH Ch CH CH Ch CH CH Ch',
  back: 'CH CH',
  position: 'CH CH',
  points: 'CH CH',
  emptyEvents: 'CH CH CH CH CH CH CH CH',
  emptyFavouriteMarkets: 'CH CH CH CH CH CH CH CH',
  all: 'CH',
  OVER: 'CH',
  UNDER: 'CH',
  lockedOdds: 'CH CH',
  suspended: 'CH CH CH',
  featuredGames: 'CH CH CH',
};
