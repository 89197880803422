import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom';

import Layout from './Layout';

import {
  HomePage,
  PokerPage,
  CasinoPage,
  PrivacyPage,
  LastPlayedPage,
  SportsJackpotPage,
} from './Pages';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route element={<HomePage />} path="/sports" />
        <Route path="/casino/:category/" element={<CasinoPage />} />
        <Route path="/casino/lastPlayed/:tab?/" element={<LastPlayedPage />} />
        <Route path="/casino/poker" element={<PokerPage />} />
        <Route path="jackpot" element={<SportsJackpotPage />} />
      </Route>
      <Route path="privacy" element={<PrivacyPage />} />
    </>,
  ),
);
