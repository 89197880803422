import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {AppButton, AppModal} from '../../../UI';

import {ReactComponent as SuccessIcon} from '../../../../Assets/Icons/Globals/success.svg';

const CashOutSuccessModal = ({isOpen, setIsOpen, cashOutedAmountRef}) => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);

  const Header = useMemo(
    () => <span className="py-md text-subtitle2 whiteText">{t('done')}</span>,
    [t],
  );

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <AppModal
      headerType={2}
      isOpen={isOpen}
      header={Header}
      maxWidth="400px"
      onRequestClose={onRequestClose}>
      <div
        style={{width: 300, height: 400}}
        className="flex column items-center justify-between mx-auto py-xl">
        <SuccessIcon width={150} fill="var(--lightGreen)" />
        <div className="column full-width gap-10">
          <div className="column">
            <span className="greenText text-caption bold-500 textCenter">
              {t('cashOutSuccess')}
            </span>
            <span className="textCenter">
              <span className="greenText text-caption bold-700">
                {currency} {bigNumbersPrettier(cashOutedAmountRef.current)}
              </span>
              <span className="blueText text-caption bold-500 ">
                {t('hasBeenAddedToYourWallet')}
              </span>
            </span>
          </div>
          <AppButton type={3} title={t('ok')} onClick={onRequestClose} />
        </div>
      </div>
    </AppModal>
  );
};

export default memo(CashOutSuccessModal);
