import React, {memo, useCallback, useMemo, useState} from 'react';
import {DateRange} from 'react-date-range';
import moment from 'moment';
import {addDays} from 'date-fns';

import './index.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {formatDate} from '../../../Utils/DateFormatter';

import {ReactComponent as CalendarIcon} from '../../../Assets/Icons/Globals/calendar.svg';

import {AppButton} from '../index';

const DatePicker = ({
  toDate,
  fromDate,
  disabled,
  setToDate,
  setFromDate,
  showDatePicker,
  type = 1,
  setShowDatePicker,
}) => {
  const [selecting, setSelecting] = useState(false);
  const [focusedRange, setFocusedRange] = useState([0, 0]);

  const ranges = useMemo(
    () => ({
      key: 'data',
      color: 'var(--appBlue)',
      endDate: new Date(toDate * 1000),
      startDate: new Date(fromDate * 1000),
    }),
    [fromDate, toDate],
  );

  const minDateLimit = useMemo(() => addDays(fromDate * 1000, -30), [fromDate]);

  const maxDateLimit = useMemo(() => addDays(fromDate * 1000, 30), [fromDate]);

  const handleFocusChange = useCallback(newFocusedRange => {
    setFocusedRange(newFocusedRange);
    setSelecting(newFocusedRange[1] === 1);
  }, []);

  const onChange = useCallback(
    data => {
      setToDate(moment(data?.data?.endDate).endOf('day').unix());
      setFromDate(moment(data?.data?.startDate).startOf('day').unix());
    },
    [setFromDate, setToDate],
  );

  return (
    <div
      className={`flex column fit appDatePickerWrapper appDatePickerWrapper_${type}`}>
      <AppButton
        disabled={disabled}
        height={type === 3 ? 35 : 'auto'}
        type={type === 2 ? 9 : type === 3 ? 18 : 11}
        onClick={() => setShowDatePicker(prev => !prev)}
        className={`${
          type === 2
            ? 'pl-xl py-xxs pr-xxs'
            : type === 3
            ? 'flex items-center'
            : 'py-sm px-lg'
        }`}
        title={
          <div
            className={`flex row items-center gap-5 justify-between ${
              type === 3 ? 'flex-1' : ''
            }`}>
            <span
              className={`bold-${type === 3 ? '400' : '600'} font-${
                type === 3 ? '10' : '12'
              }`}>{`${formatDate(fromDate * 1000)} - ${formatDate(
              toDate * 1000,
            )}`}</span>
            {type !== 3 ? (
              <div
                style={{width: 32, height: 32}}
                className="row items-center justify-center whiteBackground rounded-borders">
                <CalendarIcon width={14} height={15} fill="var(--appBlue)" />
              </div>
            ) : (
              <CalendarIcon width={15} height={15} fill="var(--white)" />
            )}
          </div>
        }
      />
      {showDatePicker && (
        <DateRange
          weekStartsOn={1}
          ranges={[ranges]}
          onChange={onChange}
          showDateDisplay={false}
          className="appDatePicker"
          focusedRange={focusedRange}
          showMonthAndYearPickers={false}
          onRangeFocusChange={handleFocusChange}
          minDate={selecting ? minDateLimit : undefined}
          maxDate={selecting ? maxDateLimit : new Date()}
        />
      )}
    </div>
  );
};

export default memo(DatePicker);
