import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import BetItemRow from './BetItemRow';
import {AppButton, AppModal} from '../../../UI';
import CalculationsBlock from '../CalculationsBlock';
import BetSlipSuccessModalSkeletons from '../../../UI/Skeletons/BetslipSuccesModalSkeletons';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {setShowMobileBetSlip} from '../../../../Redux/AppSlice';
import {setHeaderTabIndex} from '../../../../Redux/BettingSlice';
import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getMaxOddForMultipleBet} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {convertToTime, convertToDate} from '../../../../Utils/TimeConvertors';

import {CHECK_BET_STATUS} from '../../../../Constants/Socket';

import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';
import {ReactComponent as BetSlipIcon} from '../../../../Assets/Icons/Globals/betslip.svg';
import {ReactComponent as FacebookIcon} from '../../../../Assets/Icons/Globals/fb.svg';
import {ReactComponent as TelegramIcon} from '../../../../Assets/Icons/Globals/teleg.svg';
import {ReactComponent as WhatsappIcon} from '../../../../Assets/Icons/Globals/wp.svg';
import {ReactComponent as TwitterIcon} from '../../../../Assets/Icons/Globals/tv.svg';

import './index.scss';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';

const BetSlipSuccessModal = ({betId, isOpen, setIsOpen, isSharedBet}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();
  const {checkBetStatus, subscribeToEvent} = useSocket();

  const copyTimeoutRef = useRef(null);

  const [data, setData] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const wssSocket = useSelector(getWssSocket);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const Header = useMemo(
    () => (
      <div className="flex items-center justify-between full-width px-md">
        <BetSlipIcon width={15} height={15} fill="var(--appBlue)" />
        <span className="py-md text-subtitle2 whiteText">
          {t(isSharedBet ? 'sharedBet' : 'shareYourBet')}
        </span>
        <div></div>
      </div>
    ),
    [isSharedBet, t],
  );

  const allEventsData = useMemo(
    () => data?.SelectionList?.map(item => ({price: item?.Price})),
    [data?.SelectionList],
  );

  const oddsAmount = useMemo(() => {
    const calculatedOdds = allEventsData?.reduce(
      (acc, curr) => acc * curr.price,
      1,
    );

    return calculatedOdds > maxOddForMultipleBet
      ? maxOddForMultipleBet
      : +fixedNumberWithoutRound(calculatedOdds);
  }, [allEventsData, maxOddForMultipleBet]);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
    getParam.delete('shareBetId');
    setGetParam(getParam);
  }, [getParam, setGetParam, setIsOpen]);

  const copyIdHandler = useCallback(async () => {
    setIsCopied(true);
    await navigator.clipboard.writeText(betId);
    copyTimeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [betId]);

  const addToBetSlip = useCallback(() => {
    data?.SelectionList.forEach((game, index) => {
      subscribeToEvent({
        gameId: game?.MatchId,
        eventId: game?.SelectionId,
      });
      if (index === data?.SelectionList?.length - 1) {
        setTimeout(() => {
          onRequestClose();
          if (width < BREAKPOINT_XS) {
            dispatch(setShowMobileBetSlip(true));
          }
        }, 200);
      }
    });
  }, [data?.SelectionList, subscribeToEvent, onRequestClose, width, dispatch]);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);
    switch (data?.rid) {
      case CHECK_BET_STATUS:
        setData(data?.data?.details);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!!isOpen) {
      setIsLoading(true);
      checkBetStatus({bet_id: betId});
    } else {
      setIsLoading(false);
      setData({});
    }
  }, [isOpen, checkBetStatus]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <AppModal
      headerType={2}
      isOpen={isOpen}
      header={Header}
      isCloseDisabled
      maxWidth="400px"
      maxHeight={'90dvh'}
      removeContentPaddings
      onRequestClose={onRequestClose}>
      <div className="flex column items-center justify-between mx-auto full-width lightGrayBackground betSlipSuccessModalContainer">
        <div className="text-center">
          <span className="font-12 blueText bold-600">{t('sportsBet')}</span>
          <div className="font-12 blueText bold-600 flex items-center justify-center">
            <span>{t('betId')}</span>
            <span className="mx-sm">{betId}</span>
            {isCopied ? (
              <span className="grayText bold-600 text-caption">
                {t('copied')}
              </span>
            ) : (
              <CopyIcon
                width={11}
                height={11}
                fill="var(--appBlue)"
                onClick={copyIdHandler}
                className="cursor-pointer bold-700"
              />
            )}
          </div>
          <div className="grayText bold-500 text-caption flex gap-3 justify-center items-center">
            <span>{t('placedOn')}</span>
            <span>{convertToDate(data?.Created)}</span>
            <span>at</span>
            <span>{convertToTime(data?.Created)}</span>
          </div>
        </div>
        <div className="whiteBackground full-width py-sm px-md my-md scroll-auto-y listContainer">
          <div className="column my-sm full-width listWrapper">
            {isLoading ? (
              <BetSlipSuccessModalSkeletons count={5} />
            ) : (
              data?.SelectionList?.map((betItem, index) => (
                <BetItemRow betItem={betItem} index={index} />
              ))
            )}
          </div>
          <CalculationsBlock
            odds={oddsAmount}
            systemBetCount={2}
            betType={data?.TypeName}
            stakeAmount={data?.Amount}
            allEventsData={allEventsData}
          />
        </div>
        <div className="full-width lightGrayBackground">
          {isSharedBet ? (
            <div className="full-width px-md mb-md">
              <AppButton
                type={3}
                onClick={addToBetSlip}
                title={t('addToMyBetSlip')}
              />
            </div>
          ) : (
            <div className="px-md flex items-center">
              <div className="shareYourBetNow">
                <span className="font-12 blueText bold-700">
                  {t('shareYourBetNow')}
                </span>
              </div>
              <div className="flex justify-between items-center gap-5">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex shareIcon"
                  href={`whatsapp://send?text=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
                  <WhatsappIcon width={'calc(100%) - 5'} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex shareIcon"
                  href={`whatsapp://send?text=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
                  <FacebookIcon width={'calc(100%) - 5'} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex shareIcon"
                  href={`https://twitter.com/intent/tweet?url=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
                  <TwitterIcon width={'calc(100%) - 5'} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="flex shareIcon"
                  href={`https://t.me/share/url?url=pigabet.apsuite.ru/sports/today/Soccer?shareBetId=${betId}`}>
                  <TelegramIcon width={'calc(100%) - 5'} />
                </a>
              </div>
            </div>
          )}
        </div>
        {!isSharedBet && (
          <div
            className={`flex items-center gap-10 full-width pa-md ${
              width < 400 ? 'column' : 'row'
            }`}>
            <AppButton
              type={3}
              title={t('myBets')}
              onClick={() => {
                setIsOpen(false);
                dispatch(setHeaderTabIndex(1));
                dispatch(setShowMobileBetSlip(true));
              }}
            />
            <AppButton
              type={1}
              onClick={onRequestClose}
              title={t('seeMoreOptions')}
            />
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default memo(BetSlipSuccessModal);
