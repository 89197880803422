import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_MD, BREAKPOINT_XXS} from '../../../Constants/Globals';

const CasinoGamesSkeletons = ({containerRef}) => {
  const {width} = useScreenSize();

  return (
    <div className="flex wrap col-gutter-xs">
      {Array.apply(null, Array(15))?.map((_, eventIndex) => (
        <Skeleton
          count={1}
          duration={0.7}
          key={eventIndex}
          borderRadius="5px"
          baseColor="var(--lightGray)"
          highlightColor="var(--semiLightGray)"
          width={
            (containerRef?.current?.offsetWidth || width) /
              (width > BREAKPOINT_MD ? 10 : width < BREAKPOINT_XXS ? 4 : 7) -
            6
          }
          height={
            width > BREAKPOINT_MD ? 200 : width < BREAKPOINT_XXS ? 120 : 150
          }
        />
      ))}
    </div>
  );
};

export default memo(CasinoGamesSkeletons);
