import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const TransactionHistoryRowSkeleton = () => (
  <Skeleton
    count={4}
    height={67}
    duration={0.7}
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(TransactionHistoryRowSkeleton);
