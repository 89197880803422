import {createSelector} from '@reduxjs/toolkit';

const getAppState = state => state?.user;

export const getUser = createSelector(getAppState, state => state?.user);

export const getUserSubId = createSelector(
  getAppState,
  state => state?.userSubId,
);
