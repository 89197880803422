import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {AppButton, AppSelect} from '../../../../UI';

import {useScreenSize, useSocket} from '../../../../../Hooks';

import {formatDate} from '../../../../../Utils/DateFormatter';

import {GET_IMAGES, UPLOAD_IMAGE} from '../../../../../Constants/Socket';

import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import VerifyAccountImagesSkeleton from '../../../../UI/Skeletons/VerifyAccountImagesSkeleton';
import {ReactComponent as LostIcon} from '../../../../../Assets/Icons/Globals/lost.svg';
import {ReactComponent as PendingIcon} from '../../../../../Assets/Icons/Globals/pending.svg';
import {ReactComponent as VerifiedIcon} from '../../../../../Assets/Icons/Globals/verified.svg';
import {ReactComponent as FileUploadIcon} from '../../../../../Assets/Icons/Globals/fileUpload.svg';
import {setMainMenuModal, setSuccessModal} from '../../../../../Redux/AppSlice';

import {BREAKPOINT_XS} from '../../../../../Constants/Globals';

const VerifyAccount = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {width} = useScreenSize();
  const {getImages, uploadImage} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [idType, setIdType] = useState(3);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isImageDataLoading, setIsImageDataLoading] = useState(false);

  const modalWidth = useMemo(
    () => (width < 600 ? width - 30 : width < BREAKPOINT_XS ? 600 : 1000),
    [width],
  );

  const onUploadImage = useCallback(
    event => {
      setIsImageDataLoading(true);
      if (event?.target?.files?.[0]) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          uploadImage({
            image_type: idType,
            image_data: reader.result,
            name: event.target.files[0]?.name,
          });
        };
      }
    },
    [idType, uploadImage],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case GET_IMAGES:
          if (data?.data?.data?.length) {
            setUploadedImages(data?.data?.data);
          }
          setIsImageDataLoading(false);
          break;
        case UPLOAD_IMAGE:
          if (data?.data?.result === 0) {
            dispatch(
              setSuccessModal({
                isVisible: true,
                successTitle: t('yourFileWasSuccessfullyUploaded'),
                successDescription: t('yourSubmissionIsUnderReview'),
              }),
            );
            setTimeout(() => {
              setIsImageDataLoading(true);
              getImages();
            }, 300);
          }
          setIsImageDataLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, getImages, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    setIsImageDataLoading(true);
    getImages();
    dispatch(
      setMainMenuModal({
        forceToOpenProfileIndex: null,
      }),
    );
  }, []);

  return (
    <div className="verifyAccountContainer py-lg column px-md">
      <span className="bold-700 font-15 whiteText textCenter">
        {t('accountVerification')}
      </span>
      <div className="whiteText bold-500 font-12 column gap-10 textCenter pt-md px-xxxl">
        <span> {t('verifyAccountNote')}</span>
        <span>{t('verifyAccountNote2')}</span>
        <span>{t('verifyAccountNote3')}</span>
      </div>
      <div className="row items-center gap-10 pt-sm">
        <div className="flex-1">
          <AppSelect
            type={5}
            value={idType}
            setValue={setIdType}
            titleClassName="bold-600 font-12"
            mainContainerClassName="py-md px-lg"
            className="verifyAccountSelect my-md"
            itemClassName="bold-600 font-12 py-md px-lg"
            options={[
              {name: t('passportID'), value: 3},
              {name: t('other'), value: 7},
            ]}
          />
        </div>
        <div className="row items-center gap-10 flex-1">
          {width < BREAKPOINT_XS && (
            <div>
              <label htmlFor="user-camera" className="custom-file-upload">
                <div
                  style={{
                    flex: 1,
                    height: 35,
                    borderRadius: 5,
                    backgroundColor: 'var(--lightGreen)',
                  }}
                  className="rowCenter px-lg py-sm whiteText bold-600">
                  {t('openCamera')}
                </div>
                <input
                  type="file"
                  capture="user"
                  id="user-camera"
                  onChange={onUploadImage}
                  accept="image/png, image/jpeg"
                />
              </label>
            </div>
          )}
          <div className="flex-1">
            <label htmlFor="file-upload" className="custom-file-upload">
              <div
                style={{
                  height: 35,
                  borderRadius: 5,
                  backgroundColor: 'rgb(0, 48, 105)',
                }}
                className="row justify-between items-center px-lg py-md ">
                <span className="font-12 bold-600 whiteText">
                  {t('chooseFile')}
                </span>
                <FileUploadIcon width={12} height={11} />
              </div>
            </label>
            <input
              type="file"
              id="file-upload"
              onChange={onUploadImage}
              accept="image/png, image/jpeg"
            />
          </div>
          <div>
            <AppButton
              type={3}
              height={35}
              width={126}
              fontSize={15}
              titleClassName="bold-600"
              title={t('confirm').toUpperCase()}
              className="flex justify-center items-center"
            />
          </div>
        </div>
      </div>
      <div className="py-xl column flex-1">
        {isImageDataLoading ? (
          <VerifyAccountImagesSkeleton modalWidth={modalWidth} />
        ) : (
          <div className="column items-center whiteText font-12 bold-500 gap-4">
            <div className="flex full-width gap-10">
              <div
                className="bold-500 font-12 whiteText  flex flex-1"
                // style={{width: (modalWidth - 54) / 4}}
              >
                {t('name')}
              </div>
              <div
                className="bold-500 font-12 whiteText  flex flex-1"
                // style={{width: (modalWidth - 54) / 4}}
              >
                {t('type')}
              </div>
              <div
                className="bold-500 font-12 whiteText  flex flex-1"
                // style={{width: (modalWidth - 54) / 4}}
              >
                {t('date')}
              </div>
              <div
                className="bold-500 font-12 whiteText  flex flex-1"
                // style={{width: (modalWidth - 54) / 4}}
              >
                {t('status')}
              </div>
            </div>

            <div className="gap-10 column full-width">
              {uploadedImages?.map(item => (
                <div key={item?.CreatedDate} className=" row gap-10">
                  <div
                    className="flex items-center pa-md rounded-borders flex-1 overflow-hidden"
                    style={{
                      border: '1px solid var(--appYellow)',
                    }}>
                    <span
                      // style={{width: (modalWidth - 54) / 4}}
                      className="textWithDotes font-10 bold-400 ellipsis">
                      {item?.Name}
                    </span>
                  </div>
                  <div
                    className="flex items-center pa-md rounded-borders flex-1 overflow-hidden"
                    style={{
                      border: '1px solid var(--appYellow)',
                    }}>
                    <span
                      // style={{width: (modalWidth - 54) / 4}}
                      className="textWithDotes font-10 bold-400 ellipsis">
                      {item?.ImageType}
                    </span>
                  </div>
                  <div
                    className="flex items-center pa-md rounded-borders flex-1 overflow-hidden"
                    style={{
                      border: '1px solid var(--appYellow)',
                    }}>
                    <span
                      // style={{width: (modalWidth - 54) / 4}}
                      className="textWithDotes font-10 bold-400 ellipsis">
                      {formatDate(item?.CreatedDate)}
                    </span>
                  </div>
                  <div
                    className="flex items-center pa-md rounded-borders flex-1 gap-5 justify-between overflow-hidden"
                    style={{
                      border: '1px solid var(--appYellow)',
                    }}>
                    <span
                      // style={{width: (modalWidth - 54) / 4}}
                      className="textWithDotes font-10 bold-400 ellipsis">
                      {item?.StatusName}{' '}
                    </span>
                    {item?.StatusName === 'Verified' ? (
                      <VerifiedIcon width={10} height={10} />
                    ) : item?.StatusName === 'Rejected' ? (
                      <LostIcon width={10} height={10} />
                    ) : (
                      <PendingIcon width={10} height={10} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(VerifyAccount);
