import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import './index.scss';

import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../../Hooks';

import {getThisWeekUnix} from '../../../Utils/GetDatePeriods';

import {BREAKPOINT_SM, BREAKPOINT_XS} from '../../../Constants/Globals';
import {GET_TRANSACTION_HISTORY} from '../../../Constants/Socket';

import TransactionHistoryItem from './TransactionHistoryItem';
import TransactionHistoryFilters from './TransactionHistoryFilters';

import TransactionHistoryRowSkeleton from '../../UI/Skeletons/TransactionHistoryRowSkeleton';

const TransactionHistory = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const {getTransactionHistory} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [historyData, setHistoryData] = useState([]);
  const [toDate, setToDate] = useState(getThisWeekUnix()?.end_date);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [fromDate, setFromDate] = useState(getThisWeekUnix()?.start_date);

  const ListEmptyComponent = useMemo(
    () => (
      <div className="flex items-center justify-center fit">
        <span className="whiteText bold-600 text-body1">
          {t('emptyTransactions')}
        </span>
      </div>
    ),
    [t],
  );

  useEffect(() => {
    setIsDataLoading(true);
    getTransactionHistory({from_date: fromDate, to_date: toDate});
  }, []);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event.data);
    switch (data?.rid) {
      case GET_TRANSACTION_HISTORY:
        setHistoryData(data?.data?.details);
        setIsDataLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="column full-width transactionHistoryContainer gap-40">
      <TransactionHistoryFilters
        toDate={toDate}
        fromDate={fromDate}
        setToDate={setToDate}
        setFromDate={setFromDate}
        isDataLoading={isDataLoading}
        setIsDataLoading={setIsDataLoading}
      />

      <div className="column full-height mx-md scroll scroll-4 scroll-auto-x">
        <div
          className="full-height"
          style={{
            minWidth: 500,
            ...(width < BREAKPOINT_XS && !!historyData?.length
              ? {
                  borderRadius: 5,
                  marginBottom: 10,
                  padding: '10px 6px',
                  border: '1px solid var(--appYellow)',
                }
              : {}),
          }}>
          {!!historyData?.length && (
            <div className="row  py-md px-md py-sm full-width wrap whiteText font-12 bold-500">
              <div className=" col-xs-3">
                <span>{t('dateAndId')}</span>
              </div>
              {/*<div className=" col-xs-3">*/}
              {/*  <span>{t('date')}</span>*/}
              {/*</div>*/}
              <div className=" col-xs-4">
                <span>{t('type')}</span>
              </div>
              <div
                className={` col-xs-3 row justify-${
                  width > BREAKPOINT_SM ? 'start' : 'start'
                }`}>
                <span>{t('amount')}</span>
              </div>
              <div
                className={` col-xs-2 row justify-${
                  width > BREAKPOINT_SM ? 'start' : 'start'
                }`}>
                <span>{t('finalBalance')}</span>
              </div>
            </div>
          )}
          {isDataLoading ? (
            <TransactionHistoryRowSkeleton />
          ) : !!historyData?.length ? (
            historyData?.map((item, index) => (
              <TransactionHistoryItem
                item={item}
                key={item?.TransactionId}
                isColored={index % 2 === 0}
              />
            ))
          ) : (
            ListEmptyComponent
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TransactionHistory);
