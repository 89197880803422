import React, {memo, useEffect, useMemo} from 'react';

import './index.scss';

import {getCombinations} from '../../../../Utils/CombinationsGenerator';

import {MULTIPLE_BET} from '../../../../Constants/Betting';

import {AppSelect} from '../../../UI';

const SystemDropdown = ({
  setBetType,
  eventsCount,
  allEventsData,
  systemBetCount,
  setSystemBetCount,
}) => {
  const availableOptions = useMemo(() => {
    let arr = [];
    for (let i = 2; i < allEventsData?.length; i++) {
      arr.push({
        name: `${i}/${eventsCount} (${
          getCombinations(allEventsData?.length, i)?.length
        } opt.)`,
        value: i,
      });
    }
    return arr;
  }, [allEventsData, eventsCount]);

  const selectedValue = useMemo(
    () =>
      availableOptions?.find(item => item?.value === systemBetCount)?.value ||
      availableOptions?.[availableOptions?.length - 1]?.value,
    [availableOptions, systemBetCount],
  );

  useEffect(() => {
    if (availableOptions?.length === 0) {
      setBetType(MULTIPLE_BET);
    }
  }, [availableOptions?.length, setBetType]);

  return (
    <div className="systemDropdownContainer">
      <AppSelect
        type={3}
        value={selectedValue}
        options={availableOptions}
        itemClassName="py-sm px-md"
        setValue={setSystemBetCount}
        titleClassName="bold-600 font-13"
        mainContainerClassName="py-sm px-md"
      />
    </div>
  );
};

export default memo(SystemDropdown);
