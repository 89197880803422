import React, {memo, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

import {ReactComponent as ArrowUpIcon} from '../../../Assets/Icons/Globals/arrowUp.svg';
import {ReactComponent as ArrowDownIcon} from '../../../Assets/Icons/Globals/arrowDown.svg';

const CollapseItem = ({
  title,
  content,
  alwaysOpen = true,
  defaultExpanded = true,
}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const ArrowIcon = isExpanded ? ArrowUpIcon : ArrowDownIcon;

  return (
    <div
      style={{
        width: width < BREAKPOINT_XS ? '100%' : 256,
        ...(width < BREAKPOINT_XS
          ? {borderBottom: '1px solid #D9DEE410'}
          : isExpanded
          ? {border: '1px solid var(--appYellow)'}
          : {}),
      }}
      className={`blueBackground rounded-borders`}
      {...getToggleProps({
        onClick: () => {
          if (!alwaysOpen) {
            setIsExpanded(!isExpanded);
          }
        },
      })}>
      <div
        className={`row items-center justify-between px-md py-sm rounded-top-borders ${
          isExpanded ? 'yellowBackground' : 'darkBlueBackground'
        }`}>
        <span
          className={`bold-600 ${isExpanded ? 'blueText' : 'whiteText'} font-${
            width < BREAKPOINT_XS ? '12' : '20'
          }`}>
          {t(title)}
        </span>
        {!alwaysOpen && (
          <div
            style={{width: 24, height: 24, borderRadius: '50%'}}
            className={`${
              isExpanded ? 'darkBlueBackground' : 'whiteBackground'
            } row items-center justify-center`}>
            <ArrowIcon
              width={10}
              height={10}
              fill={isExpanded ? 'white' : 'var(--darkBlue)'}
            />
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        <div className="flex column ma-sm">{content}</div>
      </div>
    </div>
  );
};

export default memo(CollapseItem);
