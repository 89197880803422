export const BANK = 'Bank';
export const CASH = 'Cash';
export const M_PESA = 'FastHub';
export const EZY_PESA = 'EzyPesa';
export const AIRTEL_NEW = 'AirtelVoda';
export const TIGO_PESA = 'FastHubTigo';
export const HALO_PESA = 'FastHubHalotel';

export let PAYMENT_SERVICES = [
  {
    id: 13071,
    title: M_PESA,
    logo: require('../Assets/Images/PaymentServices/MPesa.png'),
  },
  {
    id: 13081,
    title: HALO_PESA,
    logo: require('../Assets/Images/PaymentServices/HaloPesa.png'),
  },
  {
    id: 13080,
    title: TIGO_PESA,
    logo: require('../Assets/Images/PaymentServices/TigoPesa.png'),
  },
  {
    id: 13825,
    title: AIRTEL_NEW,
    logo: require('../Assets/Images/PaymentServices/AirtelMoney.png'),
  },
  {
    id: 14921,
    title: BANK,
    logo: require('../Assets/Images/PaymentServices/bank.png'),
  },
];

export let SOCIALS = [
  {
    id: 1,
    logo: 'facebook',
  },
  {
    id: 2,
    logo: 'x',
  },
  {
    id: 4,
    logo: 'instagram',
  },

  {
    id: 3,
    logo: 'whatsapp',
  },
  {
    id: 5,
    logo: 'youtube',
  },
];

export const PIGABET_SHOP_ID = 104818921;
export const LE_GRANDE_CASINO_ID = 104816;
export const PALM_BEACH_CASINO_ID = 104817;
export const LAS_VEGAS_CASINO_ID = 1048189;
export const CASH_WITHDRAW_SERVICE_ID = 353;
export const CASINO_MIN_WITHDRAW_AMOUNT = 20000;
export const OPERATOR_MIN_WITHDRAW_AMOUNT = 1000;
export const LE_GRANDE_CASINO_TITLE = 'LE_GRANDE';
export const LAS_VEGAS_CASINO_TITLE = 'LAS_VEGAS';
export const PIGABET_SHOP_TITLE = 'PIGABET_SHOP';
export const PALM_BEACH_CASINO_TITLE = 'PALM_BEACH';
export const OPERATOR_MAX_WITHDRAW_AMOUNT = 5000000;
export const CASINO_MAX_WITHDRAW_AMOUNT = 100000000;

export let CASINO_METHODS = [
  {
    background: '#9BD5E0',
    id: LE_GRANDE_CASINO_ID,
    title: LE_GRANDE_CASINO_TITLE,
    office_id: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/LeGrandeCasino.png'),
  },
  {
    background: '#FBC589',
    id: PALM_BEACH_CASINO_ID,
    title: PALM_BEACH_CASINO_TITLE,
    office_id: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/PalmBeachCasino.png'),
  },
  {
    id: LAS_VEGAS_CASINO_ID,
    background: 'var(--appRed)',
    title: LAS_VEGAS_CASINO_TITLE,
    office_id: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/LasVegasCasino.png'),
  },
];

export const PIGABET_SHOP = {
  id: PIGABET_SHOP_ID,
  title: PIGABET_SHOP_TITLE,
  background: 'var(--appBlue)',
  office_id: CASH_WITHDRAW_SERVICE_ID,
  logo: require('../Assets/Images/PaymentServices/HarbourViewTowers.png'),
};
