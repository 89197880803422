import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {AppButton} from '../../UI';

import {setAuthModal} from '../../../Redux/AppSlice';

import {
  BREAKPOINT_MD,
  BREAKPOINT_XS,
  BREAKPOINT_XXS,
} from '../../../Constants/Globals';
import {FUN, REAL} from '../../../Constants/Socket';

const GameItem = ({gameItem, clickHandler, isInModal}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const dispatch = useDispatch();

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [playDemoContainerStyle, setPlayDemoContainerStyle] = useState({
    display: 'none',
  });

  const gameItemWidth = useMemo(
    () =>
      isInModal && width > BREAKPOINT_XS
        ? 'calc(100%  / 12)'
        : width > BREAKPOINT_MD
        ? 'calc(100%  / 9)'
        : width < BREAKPOINT_XXS
        ? 'calc(100%  / 4)'
        : 'calc(100%  / 7)',
    [isInModal, width],
  );

  const preloadImage = useCallback(url => {
    setIsImageLoading(true);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsImageLoading(false);
    };
    img.onerror = () => {
      setIsImageLoading(false);
    };
  }, []);

  useEffect(() => {
    preloadImage(gameItem?.icon_3);
  }, []);

  return (
    <div
      onMouseEnter={e => {
        setPlayDemoContainerStyle({display: 'flex'});
      }}
      onMouseLeave={e => {
        setPlayDemoContainerStyle({display: 'none'});
      }}
      className="casinoGameItemContainer cursor-pointer"
      style={{
        width: gameItemWidth,
      }}>
      {isImageLoading ? (
        <div className="rowCenter full-width full-height loaderContainer">
          <span className="loader"></span>
        </div>
      ) : (
        <div style={{position: 'relative'}}>
          <img
            alt={gameItem?.name}
            src={gameItem?.icon_3}
            className="block image full-width"
          />
          <div
            style={{
              left: 0,
              right: 0,
              bottom: 10,
              position: 'absolute',
              ...playDemoContainerStyle,
            }}
            className={`flex row items-center px-md gap-5 ${
              (width < BREAKPOINT_MD || isInModal) && 'wrap mb-xxs'
            }`}>
            {gameItem?.types?.realMode && (
              <AppButton
                small
                type={3}
                title={t('play')}
                className="cursor-pointer"
                titleClassName="text-capitalize bold-600"
                onClick={() => {
                  clickHandler({mode: REAL});
                  dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
                }}
              />
            )}
            {gameItem?.types?.funMode && (
              <AppButton
                small
                type={1}
                fontSize={15}
                title={t('demo')}
                titleClassName="bold-600"
                className="cursor-pointer"
                onClick={() => clickHandler({mode: FUN})}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(GameItem);
