export const BETSLIP_HEADER_TABS = [
  {title: 'betSlip', key: 1, tabIndex: 0},
  {title: 'openBets', key: 2, tabIndex: 1},
  {title: 'settledBets', key: 3, tabIndex: 2},
];

export const SINGLE_BET = 'Single';
export const SYSTEM_BET = 'System';
export const MULTIPLE_BET = 'Multiple';

export const MAX_SYSTEM_BET_COUNT = 16;

export const BET_TYPES = [
  {name: SINGLE_BET, value: SINGLE_BET},
  {name: MULTIPLE_BET, value: MULTIPLE_BET},
  {name: SYSTEM_BET, value: SYSTEM_BET},
];
