import React, {memo} from 'react';

import './index.scss';

const RadioButton = ({
  name,
  text,
  value,
  type = 1,
  onChange,
  defaultChecked,
  isActive = false,
}) => (
  <label
    className={`radio-label appRadioButton_${type} ${
      isActive ? 'checked' : ''
    } row items-center gap-10`}>
    <input
      hidden
      name={name}
      type="radio"
      value={value}
      onChange={onChange}
      className="radio-input"
      defaultChecked={defaultChecked}
    />
    <span className="custom-radio" />
    <span
      className={
        type === 1
          ? `blueText bold-600 text-subtitle2`
          : `blueText bold-500 font-12`
      }>
      {text}
    </span>
  </label>
);

export default memo(RadioButton);
