import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';

const CalculationRow = ({
  title,
  amount,
  hideCurrency = false,
  titleClassName = 'blueText bold-700 font-12',
  amountClassName = 'blueText bold-700 font-12',
}) => {
  const currency = useSelector(getCurrency);

  return (
    <div className="row justify-between items-center">
      {typeof title === 'string' ? (
        <span className={`${titleClassName} bold-700 font-12 `}>{title}</span>
      ) : (
        title
      )}
      <div
        style={{width: '50%'}}
        className={`${amountClassName} gap-5 row justify-between items-center`}>
        {hideCurrency ? <div /> : <CurrencyIcon width={26} height={14} />}
        <span className="ellipsis bold-700 font-12">
          {bigNumbersPrettier(amount)}
        </span>
      </div>
    </div>
  );
};

export default memo(CalculationRow);
