import {createSelector} from '@reduxjs/toolkit';

const getCasinoState = state => state?.casino;

export const getProviders = createSelector(
  getCasinoState,
  state => state?.providers,
);

export const getShowCasinoSearchModal = createSelector(
  getCasinoState,
  state => state?.showCasinoSearchModal,
);
