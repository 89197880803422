import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

import './index.scss';

const RegionSkeletons = () => (
  <Skeleton
    count={3}
    height={50}
    width="100%"
    duration={0.7}
    className="top-5"
    borderRadius="5px"
    baseColor="var(--semiLightGray)"
    highlightColor="var(--semiDarkenGray)"
  />
);

export default memo(RegionSkeletons);
