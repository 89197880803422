import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {setMainMenuModal} from '../../../../Redux/AppSlice';

import {
  DEPOSIT,
  WITHDRAW,
  BALANCE_MANAGEMENT_STACKS,
} from '../../../../Constants/MainMenu';

import '../../index.scss';

const BalanceManagementTab = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="full-width mainMenuContainer">
      <div className="flex column full-width gap-5">
        {BALANCE_MANAGEMENT_STACKS?.map(item => (
          <div
            style={{
              backgroundColor:
                item?.title === DEPOSIT
                  ? 'var(--aqua)'
                  : item?.title === WITHDRAW
                  ? 'var(--lightRed)'
                  : '',
            }}
            key={item?.title}
            onClick={() =>
              dispatch(
                setMainMenuModal({
                  tab: item?.tab,
                  balanceManagementTabIndex: item?.tabIndex,
                }),
              )
            }
            className="flex items-center justify-between lightGrayBackground mainMenuListItem px-xxl py-md cursor-pointer">
            <span className="blueText bold-500">{t(`${item?.title}`)}</span>
            <img alt={item?.title} width={18} height={18} src={item?.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(BalanceManagementTab);
