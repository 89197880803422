import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {useScreenSize, useSocket} from '../../../Hooks';

import {
  getWssSocket,
  getShowMobileRegionsModal,
} from '../../../Redux/AppSlice/AppSelectors';
import {setShowMobileRegionsModal} from '../../../Redux/AppSlice';

import {AppButton, AppModal} from '../../UI';
import RegionSkeletons from '../../UI/Skeletons/RegionSkeletons';
import CompetitionSkeletons from '../../UI/Skeletons/CompetitionSkeletons';

import {getCompetitionsArray} from '../../../Utils/GetSportId';

import {Flags} from '../../../Constants/Flags';
import {MATCH_FILTERS} from '../../../Constants/MatchFilters';
import {GET_All_REGIONS, GET_COMPETITIONS} from '../../../Constants/Socket';

import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';
import {ReactComponent as RightTriangle} from '../../../Assets/Icons/Globals/rightArrow.svg';

import './index.scss';

const RegionsModal = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const {getLeagues, getCompetitions} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const showMobileRegionsModal = useSelector(getShowMobileRegionsModal);

  const [regions, setRegions] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);
  const [isCompetitionsLoading, setIsCompetitionsLoading] = useState(false);
  const [selectedCompetitionsArray, setSelectedCompetitionsArray] = useState(
    [],
  );

  const isDisabled = useMemo(
    () =>
      selectedCompetitionsArray?.length === 0 ||
      (params?.competition?.length > 0 &&
        selectedCompetitionsArray?.sort().join(',') ===
          getCompetitionsArray(params?.competition).sort().join(',')),
    [params?.competition, selectedCompetitionsArray],
  );

  const onRegionClick = useCallback(
    regionItem => {
      setSelectedCompetitionsArray(
        params?.competition?.split?.('_')?.map?.(item => +item),
      );
      if (selectedRegion?.id === regionItem?.id) {
        setSelectedRegion(null);
      } else {
        setSelectedRegion(regionItem);
      }
      setIsCompetitionsLoading(true);
      getCompetitions({
        regionAlias: regionItem?.alias,
        activeSportAlias: params?.sport,
        gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
      });
    },
    [
      params?.sport,
      params?.filter,
      getCompetitions,
      params?.competition,
      selectedRegion?.id,
    ],
  );

  const handleChange = useCallback(
    item => {
      const newArray = [...(selectedCompetitionsArray || [])];
      if (!selectedCompetitionsArray?.includes?.(item?.id)) {
        newArray?.push?.(item?.id);
      } else {
        newArray?.splice(selectedCompetitionsArray?.indexOf?.(item?.id), 1);
      }
      setSelectedCompetitionsArray(newArray);
    },
    [selectedCompetitionsArray],
  );

  const applyChangesHandler = useCallback(() => {
    let competitions = [];
    if (
      selectedRegion?.alias === params?.region ||
      !params?.competition?.length
    ) {
      competitions = selectedCompetitionsArray;
    } else if (!!params?.competition?.length) {
      competitions = selectedCompetitionsArray?.filter(item => {
        if (
          !params?.competition
            ?.split?.('_')
            ?.map(item => +item)
            ?.includes(item)
        ) {
          return item;
        }
      });
    }
    navigate(
      `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${params?.sport}/${
        selectedRegion?.alias
      }/${competitions?.join('_')}`,
    );
    dispatch(setShowMobileRegionsModal(false));
  }, [params, navigate, selectedRegion, selectedCompetitionsArray]);

  const setInitialValues = useCallback(() => {
    if (selectedRegion?.alias === params?.region) {
      setSelectedCompetitionsArray(getCompetitionsArray(params?.competition));
    } else {
      setSelectedCompetitionsArray([]);
    }
  }, [params?.competition, params?.region, selectedRegion?.alias]);

  const onAfterOpen = useCallback(() => {
    setIsRegionsLoading(true);
    getLeagues({
      activeSportAlias: params?.sport,
      gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
    });
    setSelectedCompetitionsArray(getCompetitionsArray(params?.competition));
  }, [getLeagues, params?.competition, params?.filter, params?.sport]);

  const onMessageCb = useCallback(async event => {
    const data = JSON.parse(event.data);

    switch (data?.rid) {
      case GET_All_REGIONS:
        setRegions(
          Object.values(data?.data?.data?.region || {})?.sort(
            (a, b) => a?.order - b?.order,
          ),
        );
        setIsRegionsLoading(false);
        break;
      case GET_COMPETITIONS:
        setCompetitions(
          Object.values(data?.data?.data?.competition || {})?.sort(
            (a, b) => a?.order - b?.order,
          ),
        );
        setIsCompetitionsLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const sportIcon = useMemo(() => {
    let src = null;
    try {
      src = require(`../../../Assets/Icons/Sport/${params?.sport}.svg`);
    } catch (error) {}
    return src;
  }, [params?.sport]);

  return (
    <AppModal
      maxWidth="95%"
      headerType={3}
      header={
        <div className="justify-center items-center row gap-5 py-md">
          {!!sportIcon && (
            <img width={20} height={20} src={sportIcon} alt={params?.sport} />
          )}
          <span className="text-body2 bold-600 blueText">{t('leagues')}</span>
        </div>
      }
      onAfterOpen={onAfterOpen}
      isOpen={showMobileRegionsModal}
      onRequestClose={() => {
        setSelectedRegion({});
        dispatch(setShowMobileRegionsModal(false));
      }}>
      <div className="column full-width full-height">
        <div className="regionsModalContainer full-width">
          <div className="row regions">
            <div
              className={`flex column gap-5 ${
                !!selectedRegion?.id ? 'halfWidth' : 'full-width'
              } scroll-y overflow-hidden-x scroll-1 pb-sm px-sm`}>
              {isRegionsLoading ? (
                <RegionSkeletons />
              ) : (
                regions?.map(regionItem => {
                  const ArrowIcon =
                    selectedRegion?.id === regionItem?.id
                      ? RightTriangle
                      : DownTriangle;

                  return (
                    <div
                      key={regionItem?.id}
                      onClick={() => onRegionClick(regionItem)}
                      className={`full-width flex justify-${
                        !!selectedRegion?.id ? 'center' : 'between'
                      } items-center regionItemContainer rounded-borders pa-md gap-10 ${
                        selectedRegion?.id === regionItem?.id
                          ? 'blueBackground'
                          : 'lightGrayBackground'
                      }`}>
                      <div className="row items-center gap-10 overflow-hidden">
                        <img
                          alt="flag"
                          width={25}
                          height={15}
                          src={Flags?.[regionItem?.alias]}
                        />
                        {!selectedRegion?.id && (
                          <span className="blackText bold-500 title block ellipsis overflow-hidden">
                            {regionItem?.name}
                          </span>
                        )}
                      </div>
                      {!selectedRegion?.id && (
                        <ArrowIcon
                          fill="var(--appBlue)"
                          className="arrowIcon flex-shrink"
                        />
                      )}
                    </div>
                  );
                })
              )}
            </div>
            {!!selectedRegion?.id &&
              (isCompetitionsLoading ? (
                <div className="full-width px-sm">
                  <CompetitionSkeletons />
                </div>
              ) : (
                <div className="column gap-5 px-sm scroll-y overflow-hidden-x scroll-1 halfWidth competitionsContainer full-width px-sm">
                  {competitions?.map(competitionItem => (
                    <div
                      key={competitionItem?.id}
                      onClick={() => handleChange(competitionItem)}
                      className={`flex justify-between  rounded-borders items-center ${
                        selectedCompetitionsArray?.includes(competitionItem?.id)
                          ? 'yellowBackground'
                          : 'blueBackground'
                      } pa-md`}>
                      <span
                        style={{maxWidth: width * 0.5}}
                        className={`textWithDotes bold-500 px-sm text-caption ${
                          selectedCompetitionsArray?.includes(
                            competitionItem?.id,
                          )
                            ? 'blueText'
                            : 'whiteText'
                        }`}>
                        {competitionItem?.name}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
        <div className="gap-5 column pt-md">
          <AppButton
            small
            type={3}
            disabled={isDisabled}
            title={t('applyChanges')}
            onClick={applyChangesHandler}
          />
          <AppButton
            small
            type={5}
            title={t('reset')}
            disabled={isDisabled}
            onClick={setInitialValues}
          />
        </div>
      </div>
    </AppModal>
  );
};

export default memo(RegionsModal);
