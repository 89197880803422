import React, {memo} from 'react';

import './index.scss';

const Switcher = ({
  label,
  onToggle,
  isToggled,
  type = 1,
  labelClassName = '',
}) => {
  return (
    <div
      onClick={onToggle}
      className="flex row items-center gap-5 cursor-pointer justify-between full-width">
      <span className={`blueText text-body2 ${labelClassName}`}>{label}</span>
      <div className={`toggle-switch toggle-switch_${type}`}>
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className={`switch switch_${type}`} />
      </div>
    </div>
  );
};

export default memo(Switcher);
