import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import './index.scss';

import {
  getIsLoggedIn,
  getShowBetSlip,
  getBetSlipCount,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {
  getCurrency,
  getMaxOddForMultipleBet,
} from '../../../Redux/PartnerSlice/PartnerSelectors';
import {setShowBetSlip, setShowMobileBetSlip} from '../../../Redux/AppSlice';
import {getBonusInfoData} from '../../../Redux/BettingSlice/BettingSelectors';

import {useScreenSize, useStorage} from '../../../Hooks';

import {fixedNumberWithoutRound} from '../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../Utils/BigNumbersPrettier';

import {
  BREAKPOINT_XS,
  CALL_TO_ACTION_EXAMPLE_STAKE,
} from '../../../Constants/Globals';

import {AppButton} from '../index';

let showTimeout = null;

const NewCallToAction = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {width} = useScreenSize();
  const {getBetSlipDataFromStorage} = useStorage();

  const currency = useSelector(getCurrency);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const betSlipCount = useSelector(getBetSlipCount);
  const bonusInfoData = useSelector(getBonusInfoData);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const [show, setShow] = useState(true);

  const showCallToActionContainer = useMemo(
    () =>
      betSlipCount > 1 &&
      show &&
      showBetSlip &&
      !location?.pathname?.includes('/casino'),
    [betSlipCount, location?.pathname, show, showBetSlip],
  );

  const oddInfo = useMemo(() => {
    let oddsAmount = 1;
    let oddsArray = [];
    const data = getBetSlipDataFromStorage();
    for (let i = 0; i < data?.length; i++) {
      const game = data?.[i];
      const market = Object.values(game?.market || {})?.[0];
      const event = Object.values(market?.event || {})?.[0];
      oddsArray.push(event?.price);
      oddsAmount =
        oddsAmount * event?.price >= maxOddForMultipleBet
          ? maxOddForMultipleBet
          : oddsAmount * event?.price;
    }
    return {amount: oddsAmount, oddsArray};
  }, [getBetSlipDataFromStorage, maxOddForMultipleBet, betSlipCount]);

  const finalPayout = useMemo(
    () =>
      bigNumbersPrettier(
        fixedNumberWithoutRound(oddInfo?.amount * CALL_TO_ACTION_EXAMPLE_STAKE),
      ),
    [oddInfo?.amount],
  );

  const notValidOdds = useMemo(() => {
    let notValid = [];
    bonusInfoData?.find(item => {
      notValid = oddInfo?.oddsArray?.filter(
        odd => odd < item?.MinOdds && oddInfo?.amount !== maxOddForMultipleBet,
      );
    });
    return notValid?.reduce((acc, curr) => curr * acc, 1);
  }, [
    bonusInfoData,
    oddInfo?.amount,
    oddInfo?.oddsArray,
    maxOddForMultipleBet,
  ]);

  const accumulatorBonusRowData = useMemo(() => {
    const foundBonusData = bonusInfoData?.find(item => {
      const validOddsArray = oddInfo?.oddsArray?.filter(
        odd => odd >= item?.MinOdds,
      );
      return (
        validOddsArray?.length >= item?.MinimumSelections &&
        validOddsArray?.length <= item?.MaximumSelections &&
        +CALL_TO_ACTION_EXAMPLE_STAKE >=
          item?.MinBetStakes?.MinStakes?.find(
            stake => stake?.Currency === currency,
          )?.Amount
      );
    });
    if (foundBonusData) {
      const nextItemIndex =
        bonusInfoData.findIndex(
          item => item?.AmountPercent === foundBonusData?.AmountPercent,
        ) + 1;
      return {
        percent: foundBonusData?.AmountPercent,
        amount:
          (((CALL_TO_ACTION_EXAMPLE_STAKE * oddInfo?.amount) / notValidOdds -
            CALL_TO_ACTION_EXAMPLE_STAKE) *
            +foundBonusData?.AmountPercent) /
          100,
        nextBonusPercent: bonusInfoData?.[nextItemIndex]?.AmountPercent,
        nextBonusAmount:
          (((CALL_TO_ACTION_EXAMPLE_STAKE * oddInfo?.amount) / notValidOdds -
            CALL_TO_ACTION_EXAMPLE_STAKE) *
            +bonusInfoData?.[nextItemIndex]?.AmountPercent) /
          100,
      };
    }
  }, [
    currency,
    notValidOdds,
    bonusInfoData,
    oddInfo?.amount,
    oddInfo?.oddsArray,
  ]);

  const extraBonusInfoText = useMemo(
    () =>
      t('addOneMore', {
        percent: accumulatorBonusRowData?.nextBonusPercent || 0,
        currency,
        amount: bigNumbersPrettier(
          fixedNumberWithoutRound(
            accumulatorBonusRowData?.nextBonusAmount || 0,
          ),
        ),
      }),
    [
      t,
      currency,
      accumulatorBonusRowData?.nextBonusPercent,
      accumulatorBonusRowData?.nextBonusAmount,
    ],
  );

  const totalReturnText = useMemo(
    () =>
      bigNumbersPrettier(
        fixedNumberWithoutRound(
          oddInfo?.amount * CALL_TO_ACTION_EXAMPLE_STAKE +
            (accumulatorBonusRowData?.amount || 0),
        ),
      ),
    [accumulatorBonusRowData?.amount, oddInfo?.amount],
  );

  const bonusAmountText = useMemo(
    () =>
      bigNumbersPrettier(
        fixedNumberWithoutRound(accumulatorBonusRowData?.amount || 0),
      ),
    [accumulatorBonusRowData?.amount],
  );

  const callToActionPressHandler = useCallback(() => {
    if (width < BREAKPOINT_XS) {
      dispatch(setShowMobileBetSlip(true));
    } else {
      dispatch(setShowBetSlip(true));
    }
  }, [dispatch, width]);

  useEffect(() => {
    setShow(true);
    clearTimeout(showTimeout);
    showTimeout = setTimeout(() => {
      setShow(false);
    }, 5000);
  }, [betSlipCount]);

  const desktopVersionStyles = {
    left: document?.querySelector?.('.contentWrapper')?.offsetLeft,
    width: document?.querySelector?.('.contentWrapper')?.offsetWidth,
  };

  return (
    accumulatorBonusRowData?.nextBonusPercent && (
      <div
        style={{width: 'fit-content'}}
        className="extraBonusInfoContainer py-xxs px-md flex items-center rounded-borders orangeBackground">
        <span className="font-13 textWithDotes whiteText">
          {extraBonusInfoText}
        </span>
      </div>
      // <div
      //   className={`newAppCallToActionContainer bold-800 text-caption-small darkBlueBackground whiteText fixed-bottom flex justify-center items-center px-md py-md ${
      //     width < BREAKPOINT_XS
      //       ? `mobile ${isLoggedIn ? 'loggedIn' : 'loggedOut'}`
      //       : 'desktop'
      //   }`}
      //   style={width > BREAKPOINT_XS ? desktopVersionStyles : {}}>
      //   {/*<div className="full-width row gap-5">*/}
      //   {/*  <div className="column full-width overflow-hidden relative">*/}
      //   {/*    <div className="row items-center justify-between gap-5">*/}
      //   {/*      <span className="textWithDotes">*/}
      //   {/*        {currency} {bigNumbersPrettier(CALL_TO_ACTION_EXAMPLE_STAKE)} X{' '}*/}
      //   {/*        <span>{t('totalOdds')}</span>:{' '}*/}
      //   {/*        {bigNumbersPrettier(fixedNumberWithoutRound(oddInfo?.amount))}*/}
      //   {/*      </span>*/}
      //   {/*      <span className="flex-shrink">*/}
      //   {/*        {currency} {finalPayout}*/}
      //   {/*      </span>*/}
      //   {/*    </div>*/}
      //   {/*    {accumulatorBonusRowData?.percent && (*/}
      //   {/*      <div className="row items-center justify-between gap-5">*/}
      //   {/*        <span className="textWithDotes">*/}
      //   {/*          <span>{t('accumulatorBonus')}</span> (*/}
      //   {/*          {accumulatorBonusRowData?.percent}%)*/}
      //   {/*        </span>*/}
      //   {/*        <span className="flex-shrink">*/}
      //   {/*          {currency} {bonusAmountText}*/}
      //   {/*        </span>*/}
      //   {/*      </div>*/}
      //   {/*    )}*/}
      //   {/*    <div className="row items-center justify-between gap-5 yellowText">*/}
      //   {/*      <span className="textWithDotes">{t('totalPigabetReturn')}</span>*/}
      //   {/*      <span className="flex-shrink">*/}
      //   {/*        {currency} {totalReturnText}*/}
      //   {/*      </span>*/}
      //   {/*    </div>*/}
      //   {/*  </div>*/}
      //   {/*  {width < BREAKPOINT_XS && (*/}
      //   {/*    <div className="flex flex-shrink justify-center items-center">*/}
      //   {/*      <AppButton*/}
      //   {/*        type={3}*/}
      //   {/*        height={40}*/}
      //   {/*        fontSize={12}*/}
      //   {/*        title={t('betNow')}*/}
      //   {/*        onClick={callToActionPressHandler}*/}
      //   {/*        className="flex justify-center items-center pa-sm flex-shrink"*/}
      //   {/*      />*/}
      //   {/*    </div>*/}
      //   {/*  )}*/}
      //     {accumulatorBonusRowData?.nextBonusPercent && (
      //       <div className="extraBonusInfoContainer absolute flex items-center justify-center mx-md">
      //         <span className="bold-700 py-sm px-md textWithDotes textCenter extraBonusInfoText">
      //           {extraBonusInfoText}
      //         </span>
      //       </div>
      //     )}
      //   </div>
    )
  );
};

export default memo(NewCallToAction);
