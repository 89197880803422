import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {getOpenBetsCount} from '../../../Redux/BettingSlice/BettingSelectors';

import {MY_BETS_TAB_ITEMS} from '../../../Constants/Menu';

import Counter from '../../BetSlip/Components/Counter';

import {AppButton} from '../../UI';

const TabsHeader = ({pageIndex, setPageIndex}) => {
  const {t} = useTranslation();

  const openBetsCount = useSelector(getOpenBetsCount);

  return (
    <div className="flex row gap-5">
      {MY_BETS_TAB_ITEMS?.map(({title, index}) => (
        <AppButton
          small
          type={2}
          key={index}
          width={150}
          fontSize={11}
          active={pageIndex === index}
          onClick={() => setPageIndex(index)}
          title={
            index === 0 ? (
              <div className="row gap-5 items-center justify-center">
                <span className="font-11 bold-700">{t(title)}</span>
                {!!openBetsCount && (
                  <Counter
                    type={2}
                    value={openBetsCount}
                    active={pageIndex === index}
                  />
                )}
              </div>
            ) : (
              t(title)
            )
          }
        />
      ))}
    </div>
  );
};

export default memo(TabsHeader);
