import {swa} from './Swahili';
import {eng} from './English';
import {chn} from './Chinese';

export const resources = {
  eng: {
    translation: eng,
  },
  chn: {
    translation: chn,
  },
  swa: {
    translation: swa,
  },
};
