export const SLOTS_FIXED_SUB_CATEGORIES = [
  '860',
  '247',
  '406',
  '94',
  '63',
  '59',
  '239',
];
export const LIVE_CASINO_FIXED_SUB_CATEGORIES = ['4', '2', '1', '6', '3'];
