import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {setMainMenuModal} from '../../../../Redux/AppSlice';

import {PROFILE, PROFILE_STACKS} from '../../../../Constants/MainMenu';

import '../../index.scss';

const MyProfileTabs = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="full-width mainMenuContainer">
      <div className="flex column full-width gap-5">
        {PROFILE_STACKS?.map(item => (
          <div
            key={item?.title}
            onClick={() =>
              dispatch(
                setMainMenuModal({
                  tab: PROFILE,
                  forceToOpenProfileIndex: item?.tabIndex,
                }),
              )
            }
            className="flex items-center justify-between lightGrayBackground mainMenuListItem px-xxl py-md cursor-pointer">
            <span className="blueText bold-500">{t(`${item?.title}`)}</span>
            <img alt={item?.title} width={18} height={18} src={item?.icon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(MyProfileTabs);
