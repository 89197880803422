import moment from 'moment';

export const getTodayUnix = () => ({
  end_date: moment?.()?.endOf?.('day')?.unix?.(),
  start_date: moment?.()?.startOf?.('day')?.unix?.(),
});

export const getYesterdayUnix = () => ({
  end_date: moment?.()?.subtract?.(1, 'day')?.endOf?.('day')?.unix?.(),
  start_date: moment?.()?.subtract?.(1, 'day')?.startOf?.('day')?.unix?.(),
});

export const getThisWeekUnix = () => ({
  end_date: moment?.()?.endOf?.('day')?.unix?.(),
  start_date: moment?.()?.startOf?.('isoWeek')?.unix?.(),
});

export const getLastWeekUnix = () => ({
  end_date: moment?.()?.subtract?.(7, 'days')?.endOf?.('isoWeek')?.unix?.(),
  start_date: moment?.()?.subtract?.(7, 'days')?.startOf?.('isoWeek')?.unix?.(),
});

export const getThisMonthUnix = () => ({
  end_date: moment?.()?.endOf?.('day')?.unix?.(),
  start_date: moment?.()?.startOf?.('month')?.unix?.(),
});

export const getLastMonthUnix = () => ({
  end_date: moment?.()?.subtract?.(1, 'month')?.endOf?.('month')?.unix?.(),
  start_date: moment?.()?.subtract?.(1, 'month')?.startOf?.('month')?.unix?.(),
});
