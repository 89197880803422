import React, {memo} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import './FiltersList.scss';

import {AppButton} from '../../UI';

import {ReactComponent as Star} from '../../../Assets/Icons/Globals/star.svg';
import {FreeMode} from 'swiper/modules';

const FiltersList = ({GROUP_DATA, activeGroupId, setActiveGroupId}) => (
  <div className="filtersListContainer blueBackground z-index-1">
    <Swiper
      freeMode
      spaceBetween={3}
      modules={[FreeMode]}
      slidesPerView="auto"
      className="filtersListSwiper flex items-center mt-sm">
      {GROUP_DATA?.map(item => (
        <SwiperSlide key={item?.title}>
          <AppButton
            type={17}
            height={30}
            className="flex items-center gap-5 py-xs"
            active={item?.groupId === activeGroupId}
            onClick={() => setActiveGroupId(item?.groupId)}
            title={
              item?.icon ? (
                <div className="px-md items-center justify-center row">
                  <Star width={25} height={25} fill="var(--white)" />
                </div>
              ) : (
                <div className="flex row justify-center textCenter filterItemContainer gap-5 py-sm px-lg">
                  <span className="text-caption bold-600">
                    {item?.title?.toUpperCase()}
                  </span>
                  {/*<span className="text-caption bold-600">*/}
                  {/*  ({item?.data?.length})*/}
                  {/*</span>*/}
                </div>
              )
            }
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default memo(FiltersList);
