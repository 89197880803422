import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {
  READ_USER_MESSAGE,
  DELETE_USER_MESSAGE,
  USER_MESSAGES_INCOMING,
} from '../../../../Constants/Socket';

import MessageItem from '../../MessageItem';

import MessageItemSkeleton from '../../../UI/Skeletons/MessageItemSkeleton';

const Inbox = ({setNewInboxesCount}) => {
  const {t} = useTranslation();
  const {getInboxes} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [inboxes, setInboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const newInboxesCount = useMemo(
    () => inboxes?.filter(message => !message?.checked, [])?.length,
    [inboxes],
  );

  const ListEmptyComponent = useMemo(
    () => (
      <div className="flex items-center justify-center fit">
        <span className="whiteText bold-600 text-body1">
          {t('emptyMessages')}
        </span>
      </div>
    ),
    [t],
  );

  useEffect(() => {
    setNewInboxesCount(newInboxesCount);
  }, [newInboxesCount, setNewInboxesCount]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      if (data?.rid === USER_MESSAGES_INCOMING) {
        setInboxes(data?.data?.messages || []);
        setIsLoading(false);
      } else if (
        data?.rid?.includes(READ_USER_MESSAGE) &&
        data?.data?.result === 0
      ) {
        const index = +data?.rid.split('-')[1];
        const newInboxes = [...(inboxes || [])];
        newInboxes[index] = {
          ...newInboxes[index],
          checked: 1,
        };
        setInboxes(newInboxes);
      } else if (
        data?.rid?.includes(DELETE_USER_MESSAGE) &&
        data?.data?.result === 0 &&
        data?.rid.split('-')[2] === 'inbox'
      ) {
        const inboxId = +data?.rid.split('-')[1];
        const filteredInboxes = (inboxes || [])?.filter(
          currentItem => currentItem?.id !== inboxId,
        );

        setInboxes(filteredInboxes);
      }
    },
    [inboxes],
  );

  useEffect(() => {
    setIsLoading(true);
    getInboxes({
      rid: USER_MESSAGES_INCOMING,
      type: 0 /* for incoming messages */,
    });
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="inboxContainer gap-10 column fit  pt-xl px-md">
      {isLoading ? (
        <MessageItemSkeleton />
      ) : !!inboxes?.length ? (
        inboxes?.map((item, index) => (
          <MessageItem item={item} key={item?.id} index={index} />
        ))
      ) : !isLoading && !inboxes?.length ? (
        ListEmptyComponent
      ) : null}
    </div>
  );
};

export default memo(Inbox);
