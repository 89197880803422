import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  providers: [],
  showCasinoSearchModal: false,
};

export const casinoSlice = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    setProviders: (state, {payload}) => {
      state.providers = payload;
    },
    setShowCasinoSearchModal: (state, {payload}) => {
      state.showCasinoSearchModal = payload;
    },
  },
});

export const {setProviders, setShowCasinoSearchModal} = casinoSlice.actions;

export default casinoSlice.reducer;
