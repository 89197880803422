import {useCallback, useEffect, useState} from 'react';

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

const useGetNetworkStatus = () => {
  const [networkStatus, setStatus] = useState(getOnLineStatus());

  const setOnline = useCallback(() => setStatus(true), []);
  const setOffline = useCallback(() => setStatus(false), []);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return {networkStatus};
};

export default useGetNetworkStatus;
