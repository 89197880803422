export const getNextSevenDaysUnixTimestamps = () => {
  const timestamps = [];
  const currentDate = new Date();

  // Set the start of today
  currentDate.setHours(0, 0, 0, 0);

  // Abbreviated day names
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  for (let i = 0; i < 7; i++) {
    const startOfDay = new Date(currentDate);
    startOfDay.setDate(currentDate.getDate() + i);

    const endOfDay = new Date(startOfDay);
    endOfDay.setHours(23, 59, 59, 999);

    const dayName = dayNames[startOfDay.getDay()];
    const monthName = monthNames[startOfDay.getMonth()];
    const day = startOfDay.getDate().toString().padStart(2, '0'); // format day to 2 digits
    const monthNumber = (startOfDay.getMonth() + 1).toString().padStart(2, '0'); // format month number to 2 digits

    timestamps.push({
      day: day,
      dayName: dayName,
      monthName: monthName,
      monthNumber: monthNumber,
      endOfDay: Math.floor(endOfDay.getTime() / 1000),
      startOfDay: Math.floor(startOfDay.getTime() / 1000),
    });
  }

  return timestamps;
};
