import React, {memo} from 'react';
import Skeleton from 'react-loading-skeleton';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

import {useScreenSize} from '../../../Hooks';

const LobbySkeletons = () => {
  const {width} = useScreenSize();

  return (
    <div className="flex row gap-5">
      <Swiper
        freeMode
        keyboard
        mousewheel
        spaceBetween={5}
        slidesPerView="auto"
        modules={[Keyboard, FreeMode]}
        className="lobbyGamesContainerSwiper full-width">
        {Array.apply(null, Array(10))?.map((_, index) => (
          <SwiperSlide key={index}>
            <Skeleton
              count={1}
              duration={0.7}
              className="top-5"
              borderRadius="5px"
              width={width * 0.2}
              height={width * 0.2 + 50}
              baseColor="var(--semiLightGray)"
              highlightColor="var(--semiDarkenGray)"
              style={{maxWidth: 300, maxHeight: 448}}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(LobbySkeletons);
