import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const VerifyAccountImagesSkeleton = ({modalWidth}) => (
  <Skeleton
    count={3}
    height={37}
    duration={0.7}
    borderRadius="5px"
    className="full-width"
    width={modalWidth - 60}
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(VerifyAccountImagesSkeleton);
