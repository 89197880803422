import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import './PersonalDetails.scss';

import {getUser} from '../../../../../Redux/UserSlice/UserSelectors';
import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../../../../Hooks';

import {UPDATE_USER} from '../../../../../Constants/Socket';
import {
  BREAKPOINT_XS,
  COUNTRIES,
  GENDERS,
} from '../../../../../Constants/Globals';

import Preferences from './Preferences';

import {AppButton, AppError, AppInput, AppSelect} from '../../../../UI';

const PersonalDetails = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const {userUpdate} = useSocket();

  const user = useSelector(getUser);
  const wssSocket = useSelector(getWssSocket);

  const [subscribedToInternalMessages, setSubscribedToInternalMessages] =
    useState(user?.subscribe_to_internal_message);

  const [subscribedToPhoneCall, setSubscribedToPhoneCall] = useState(
    user?.subscribe_to_phone_call,
  );

  const [subscribedToPushNotification, setSubscribedToPushNotification] =
    useState(user?.subscribe_to_push_notification);

  const [subscribedToSms, setSubscribedToSms] = useState(
    user?.subscribe_to_sms,
  );

  const [subscribedToEmail, setSubscribedToEmail] = useState(
    user?.subscribe_to_email,
  );

  const [errorMsg, setErrorMsg] = useState('');
  const [passportNumber, setPassportNumber] = useState(
    user?.doc_number ? user?.doc_number : '',
  );
  const [country, setCountry] = useState(
    user?.country_code ? user?.country_code : t('chooseCountry'),
  );
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [city, setCity] = useState(user?.city ? user?.city : '');
  const [email, setEmail] = useState(user?.email ? user?.email : '');
  const [gender, setGender] = useState(user?.gender || t('chooseGender'));
  const [address, setAddress] = useState(user?.address ? user?.address : '');

  const isSaveDisabled = useMemo(
    () => !currentPassword?.trim() || gender === t('chooseGender'),
    [currentPassword, gender, t],
  );

  const saveHandler = useCallback(() => {
    setIsSaveLoading(true);
    userUpdate({
      city,
      email,
      gender,
      address,
      country_code: country,
      password: currentPassword,
      subscribe_to_sms: subscribedToSms,
      subscribe_to_phone_call: subscribedToPhoneCall,
      subscribe_to_internal_message: subscribedToInternalMessages,
      subscribe_to_push_notification: subscribedToPushNotification,
      subscribe_to_email: subscribedToEmail,
      ...(passportNumber?.trim() === user?.doc_number
        ? {}
        : {doc_number: passportNumber}),
    });
  }, [
    city,
    email,
    gender,
    address,
    country,
    userUpdate,
    passportNumber,
    currentPassword,
    subscribedToSms,
    user?.doc_number,
    subscribedToEmail,
    subscribedToPhoneCall,
    subscribedToInternalMessages,
    subscribedToPushNotification,
  ]);

  const onMessageCb = useCallback(async event => {
    const data = JSON.parse(event?.data);
    switch (data?.rid) {
      case UPDATE_USER:
        if (data?.code === 0) {
          setCurrentPassword('');
          if (!!data?.data?.result) {
            setErrorMsg(data?.data?.details);
          } else {
            setErrorMsg('');
          }
        }
        setIsSaveLoading(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    wssSocket?.addEventListener?.('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener?.('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`flex px-md column justify-end personalDetailsContainer ${
        width < BREAKPOINT_XS ? 'pb-lg' : 'py-lg'
      }`}>
      <div className="flex column content">
        <div className="pb-md">
          <span className="whiteText font-12 bold-500 bold-500">
            {t('eligibleForBonus')}
            <span
              className={`${user?.is_bonus_allowed ? 'greenText' : 'redText'}`}>
              {' '}
              {user?.is_bonus_allowed ? t('yes') : t('no')}
            </span>
          </span>
        </div>
        <div className="flex row wrap col-gutter-md">
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">{t('id')}:</span>
            <AppInput
              checked
              type={9}
              height={35}
              contentEditable={false}
              inputValue={user?.unique_id}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">
              {t('birthDate')}:
            </span>
            <AppInput
              checked
              type={9}
              height={35}
              contentEditable={false}
              inputValue={user?.birth_date}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">
              {t('passportNumber')}:
            </span>
            <AppInput
              type={9}
              height={35}
              inputValue={passportNumber}
              onChange={setPassportNumber}
              checked={!!user?.doc_number}
              contentEditable={!!user?.doc_number}
              placeholder={t('enterPassportNumber')}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">{t('address')}:</span>
            <AppInput
              type={9}
              height={35}
              inputValue={address}
              onChange={setAddress}
              checked={!!user?.address}
              contentEditable={!!user?.address}
              placeholder={t('enterHomeAddress')}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">
              * {t('firstName')}:
            </span>
            <AppInput
              checked
              type={9}
              height={35}
              contentEditable={false}
              inputValue={user?.first_name}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">* {t('gender')}:</span>
            <AppSelect
              type={4}
              value={gender}
              options={GENDERS}
              setValue={setGender}
              checked={!!user?.gender}
              disabled={!!user?.gender}
              titleClassName="font-10 bold-400"
              titleStyle={{color: '#021F4B59'}}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">{t('country')}:</span>
            <AppSelect
              type={4}
              value={country}
              options={COUNTRIES}
              setValue={setCountry}
              titleClassName="font-10 bold-400"
              titleStyle={{color: '#021F4B59'}}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">* {t('mobile')}:</span>
            <AppInput
              checked
              type={9}
              height={35}
              contentEditable={false}
              inputValue={user?.phone}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">
              * {t('lastName')}:
            </span>
            <AppInput
              checked
              type={9}
              height={35}
              contentEditable={false}
              inputValue={user?.last_name}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">{t('email')}:</span>
            <AppInput
              type={9}
              height={35}
              inputValue={email}
              onChange={setEmail}
              checked={!!user?.email}
              contentEditable={!!user?.email}
              placeholder={t('enterEmailAddress')}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">{t('city')}:</span>
            <AppInput
              type={9}
              height={35}
              inputValue={city}
              onChange={setCity}
              checked={!!user?.city}
              contentEditable={!!user?.city}
              placeholder={t('enterYourCity')}
            />
          </div>
          <div className="gap-5 flex column col-xs-12 col-sm-6 col-md-3">
            <span className="whiteText font-12 bold-500">
              * {t('currentPassword')}:
            </span>
            <AppInput
              password
              type={9}
              height={35}
              inputValue={currentPassword}
              onChange={setCurrentPassword}
              placeholder={t('enterYourPassword')}
            />
          </div>
          <Preferences
            subscribedToSms={subscribedToSms}
            subscribedToEmail={subscribedToEmail}
            setSubscribedToSms={setSubscribedToSms}
            setSubscribedToEmail={setSubscribedToEmail}
            subscribedToPhoneCall={subscribedToPhoneCall}
            setSubscribedToPhoneCall={setSubscribedToPhoneCall}
            subscribedToPushNotification={subscribedToPushNotification}
            subscribedToInternalMessages={subscribedToInternalMessages}
            setSubscribedToInternalMessages={setSubscribedToInternalMessages}
            setSubscribedToPushNotification={setSubscribedToPushNotification}
          />
        </div>
        <div className="pt-lg flex column items-center gap-5 mt-auto">
          <AppError error={errorMsg} />
          <AppButton
            type={3}
            onClick={saveHandler}
            title={t('save')}
            loading={isSaveLoading}
            disabled={isSaveDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(PersonalDetails);
