import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useDispatch, useSelector} from 'react-redux';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';
import {useSearchParams} from 'react-router-dom';

import './index.scss';

import {
  getIsGameDataLoading,
  getIsSportDataLoading,
} from '../../../Redux/SportSlice/SportSelectors';
import {
  setSports,
  setActiveFilterParams,
  setIsSportDataLoading,
} from '../../../Redux/SportSlice';
import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../../Hooks';

import {GET_ALL_SPORTS} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {SportIcons} from '../../../Constants/SportIcons';
import {FILTERS_TODAY_NAME} from '../../../Constants/MatchFilters';

import {AppButton} from '../../UI';
import HomeSportSkeletons from '../../UI/Skeletons/HomeSportSkeletons';

const HomeSports = () => {
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const {getSports} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isGameDataLoading = useSelector(getIsGameDataLoading);
  const isSportDataLoading = useSelector(getIsSportDataLoading);

  const [getParam, setGetParam] = useSearchParams();
  const [sportDataStatic, setSportDataStatic] = useState([]);

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const changeSport = useCallback(
    pressedItem => {
      getParam.delete('region');
      getParam.delete('day');
      getParam.set('sport', pressedItem?.alias);
      getParam.set('gameFilter', FILTERS_TODAY_NAME);
      setGetParam(getParam);
      window.scrollTo(0, 0);
    },
    [getParam, setGetParam],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      if (data?.rid === GET_ALL_SPORTS) {
        const sportsArray = Object.values(data?.data?.data?.sport || {})?.sort(
          (a, b) => a?.order - b?.order,
        );
        dispatch(setSports(sportsArray));
        setSportDataStatic(sportsArray);
        setTimeout(() => {
          dispatch(setIsSportDataLoading(false));
        }, 100);
        getParam.set('sport', sportParam || sportsArray?.[0]?.alias);
        getParam.set('gameFilter', gameFilterParam || FILTERS_TODAY_NAME);
        setGetParam(getParam);
      }
    },
    [dispatch, gameFilterParam, getParam, setGetParam, sportParam],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const renderSports = useMemo(
    () =>
      sportDataStatic?.map(sportItem => (
        <SwiperSlide
          key={sportItem?.id}
          className={`item ${width > BREAKPOINT_XS ? 'pt-md' : ''}`}>
          <AppButton
            type={7}
            onClick={() => changeSport(sportItem)}
            disabledWithoutLayer={isGameDataLoading}
            active={sportItem?.alias === sportParam}
            className="items-center column py-sm px-lg"
            title={
              <div className="items-center justify-center column gap-3">
                <img
                  alt="sportIcon"
                  src={SportIcons?.[sportItem?.alias]}
                  width={width > BREAKPOINT_XS ? 20 : 30}
                  height={width > BREAKPOINT_XS ? 20 : 30}
                />
                <span className="font-12 bold-600 blueText">
                  {sportItem?.name?.toUpperCase()}
                  {/*{width > BREAKPOINT_XS && `(${sportItem?.game})`}*/}
                </span>
                <span className="absolute-top-right font-10 blueText bold-600 mobileCounter">
                  {sportItem?.game}
                </span>
              </div>
            }
          />
        </SwiperSlide>
      )),
    [sportDataStatic, width, isGameDataLoading, sportParam, changeSport],
  );

  useEffect(() => {
    getSports({rid: GET_ALL_SPORTS});

    return () => dispatch(setActiveFilterParams(''));
  }, []);

  return isSportDataLoading ? (
    <HomeSportSkeletons isMobile />
  ) : sportDataStatic?.length > 0 ? (
    <Swiper
      freeMode
      slidesPerGroup={5}
      slidesPerView="auto"
      keyboard={width > BREAKPOINT_XS}
      mousewheel={width > BREAKPOINT_XS}
      navigation={width > BREAKPOINT_XS}
      spaceBetween={width > BREAKPOINT_XS ? 8 : 5}
      slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
      slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
      className="homeSportsSwiper flex items-center full-width"
      modules={[
        FreeMode,
        ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
      ]}>
      {renderSports}
    </Swiper>
  ) : (
    <></>
  );
};

export default memo(HomeSports);
