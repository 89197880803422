import React, {memo} from 'react';

import './index.scss';

const ModalHeader = ({children, type}) => (
  <div
    className={`appModalHeaderContainer full-width appModalHeaderContainer_${type} flex items-center justify-center`}>
    {children}
  </div>
);

export default memo(ModalHeader);
