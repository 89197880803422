import {createSelector} from '@reduxjs/toolkit';

const getAppState = state => state?.app;

export const getWssSocket = createSelector(
  getAppState,
  state => state?.wssSocket,
);

export const getIsLoggedIn = createSelector(
  getAppState,
  state => state?.isLoggedIn,
);

export const getLanguage = createSelector(
  getAppState,
  state => state?.language,
);

export const getSid = createSelector(getAppState, state => state?.sid);

export const getShowIntro = createSelector(
  getAppState,
  state => state?.showIntro,
);

export const getShowMobileRegionsModal = createSelector(
  getAppState,
  state => state?.showMobileRegionsModal,
);

export const getRecaptcha = createSelector(
  getAppState,
  state => state?.recaptcha,
);

export const getAuthModal = createSelector(
  getAppState,
  state => state?.authModal,
);

export const getMainMenuModal = createSelector(
  getAppState,
  state => state?.mainMenuModal,
);

export const getMessagesModal = createSelector(
  getAppState,
  state => state?.messagesModal,
);

export const getSuccessModal = createSelector(
  getAppState,
  state => state?.successModal,
);

export const getMyBetsModal = createSelector(
  getAppState,
  state => state?.myBetsModal,
);

export const getShowBetSlip = createSelector(
  getAppState,
  state => state?.showBetSlip,
);

export const getBetSlipCount = createSelector(
  getAppState,
  state => state?.betSlipCount,
);

export const getShowMobileBetSlip = createSelector(
  getAppState,
  state => state?.showMobileBetSlip,
);

export const getShowLiveChat = createSelector(
  getAppState,
  state => state?.showLiveChat,
);

export const getShowSearchModal = createSelector(
  getAppState,
  state => state?.showSearchModal,
);

export const getIsContentScrolled = createSelector(
  getAppState,
  state => state?.isContentScrolled,
);
