import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {getIsLoggedIn} from '../../../../Redux/AppSlice/AppSelectors';
import {setAuthModal, setMainMenuModal} from '../../../../Redux/AppSlice';

import {AppError} from '../../../UI';

const ErrorContainer = ({
  isBettingBlocked,
  showBalanceError,
  showCantCombineWarning,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <>
      {!isLoggedIn && (
        <AppError
          error={
            <div className="text-caption redText bold-500">
              <span>{t('toPlaceYourBet')}, </span>
              <span
                className="text-underline bold-600 blueText cursor-pointer"
                onClick={() =>
                  dispatch(setAuthModal({tabIndex: 0, isVisible: true}))
                }>
                {t('signIn')}
              </span>
              <span>{t('or')}</span>
              <span
                className="text-underline bold-600 blueText cursor-pointer"
                onClick={() =>
                  dispatch(setAuthModal({tabIndex: 1, isVisible: true}))
                }>
                {t('register')}
              </span>
            </div>
          }
        />
      )}
      {isBettingBlocked ? (
        <AppError error={t('thereAreDeletedEvents')} />
      ) : (
        showCantCombineWarning && <AppError error={t('cannotBeCombined')} />
      )}
      {showBalanceError && (
        <AppError
          error={
            <div className="text-caption redText bold-500">
              <span>{t('insufficientBalance')}, </span>
              <span
                className="text-underline bold-600 blueText cursor-pointer"
                onClick={() =>
                  dispatch(
                    setMainMenuModal({
                      tabIndex: 0,
                      isVisible: true,
                      balanceManagementTabIndex: 0,
                    }),
                  )
                }>
                {t('deposit')}
              </span>
            </div>
          }
        />
      )}
    </>
  );
};

export default memo(ErrorContainer);
