import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const SearchGamesSkeletons = ({count = 7}) => (
  <div className="flex column gap-5">
    {Array.apply(null, Array(count))?.map((_, index) => (
      <Skeleton
        count={1}
        key={index}
        height={30}
        duration={0.7}
        borderRadius="5px"
        baseColor="rgb(219, 221, 227)"
        highlightColor="rgb(225, 227, 231)"
      />
    ))}
  </div>
);

export default memo(SearchGamesSkeletons);
