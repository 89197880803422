export const Flags = {
  Europe: require('../Assets/Images/Flags/Europe.png'),
  World: require('../Assets/Images/Flags/World.png'),
  Albania: require('../Assets/Images/Flags/Albania.png'),
  Algeria: require('../Assets/Images/Flags/Algeria.png'),
  Andorra: require('../Assets/Images/Flags/Andorra.png'),
  Zanzibar: require('../Assets/Images/Flags/Zanzibar.png'),
  Angola: require('../Assets/Images/Flags/Angola.png'),
  'Brunei Darussalam': require('../Assets/Images/Flags/BruneiDarussalam.png'),
  AntiguaandBarbuda: require('../Assets/Images/Flags/AntiguaandBarbuda.png'),
  'Solomon Islands': require('../Assets/Images/Flags/SolomonIslands.png'),
  Argentina: require('../Assets/Images/Flags/Argentina.png'),
  Armenia: require('../Assets/Images/Flags/Armenia.png'),
  Aruba: require('../Assets/Images/Flags/Aruba.png'),
  Australia: require('../Assets/Images/Flags/Australia.png'),
  Venezuela: require('../Assets/Images/Flags/Venezuela.png'),
  Myanmar: require('../Assets/Images/Flags/Myanmar.png'),
  Namibia: require('../Assets/Images/Flags/Namibia.png'),
  defaultFlag: require('../Assets/Images/Flags/defaultFlag.png'),
  'North America': require('../Assets/Images/Flags/NorthAmerica.png'),
  Philippines: require('../Assets/Images/Flags/Philippines.png'),
  Austria: require('../Assets/Images/Flags/Austria.png'),
  Estonia: require('../Assets/Images/Flags/Estonia.png'),
  Iran: require('../Assets/Images/Flags/Iran.png'),
  Seychelles: require('../Assets/Images/Flags/Seychelles.png'),
  Azerbaijan: require('../Assets/Images/Flags/Azerbaijan.png'),
  Uzbekistan: require('../Assets/Images/Flags/Uzbekistan.png'),
  'North Macedonia': require('../Assets/Images/Flags/NorthMacedonia.png'),
  Bahrain: require('../Assets/Images/Flags/Bahrain.png'),
  Bangladesh: require('../Assets/Images/Flags/Bangladesh.png'),
  Belgium: require('../Assets/Images/Flags/Belgium.png'),
  Latvia: require('../Assets/Images/Flags/Latvia.png'),
  Lithuania: require('../Assets/Images/Flags/Lithuania.png'),
  Belarus: require('../Assets/Images/Flags/Belarus.png'),
  Kazakhstan: require('../Assets/Images/Flags/Kazakhstan.png'),
  BosniaHerzegovina: require('../Assets/Images/Flags/BosniaandHerzegovina.png'),
  Cambodia: require('../Assets/Images/Flags/Cambodia.png'),
  Afghanistan: require('../Assets/Images/Flags/Afghanistan.png'),
  Africa: require('../Assets/Images/Flags/Africa.png'),
  Bhutan: require('../Assets/Images/Flags/Bhutan.png'),
  'Saint Kitts and Nevis': require('../Assets/Images/Flags/SaintKittsandNevis.png'),
  Liberia: require('../Assets/Images/Flags/Liberia.png'),
  Botswana: require('../Assets/Images/Flags/Botswana.png'),
  'Antigua and Barbuda': require('../Assets/Images/Flags/AntiguaandBarbuda.png'),
  Oceania: require('../Assets/Images/Flags/Oceania.png'),
  Djibouti: require('../Assets/Images/Flags/Djibouti.png'),
  Brazil: require('../Assets/Images/Flags/Brazil.png'),
  Zimbabwe: require('../Assets/Images/Flags/Zimbabwe.png'),
  Bulgaria: require('../Assets/Images/Flags/Bulgaria.png'),
  'Burkina Faso': require('../Assets/Images/Flags/BurkinaFaso.png'),
  Burundi: require('../Assets/Images/Flags/Burundi.png'),
  Dominica: require('../Assets/Images/Flags/Dominica.png'),
  Maldives: require('../Assets/Images/Flags/Maldives.png'),
  Fiji: require('../Assets/Images/Flags/Fiji.png'),
  'United Kingdom': require('../Assets/Images/Flags/UnitedKingdom.png'),
  'Dominican Republic': require('../Assets/Images/Flags/DominicanRepublic.png'),
  'Great Britain': require('../Assets/Images/Flags/UnitedKingdom.png'),
  Cameroon: require('../Assets/Images/Flags/Cameroon.png'),
  Canada: require('../Assets/Images/Flags/Canada.png'),
  'Faroe Islands': require('../Assets/Images/Flags/FaroeIslands.png'),
  'SRL Matches': require('../Assets/Images/Flags/srlMatches.png'),
  'Chinese Taipei': require('../Assets/Images/Flags/ChineseTaipei.png'),
  'Cyber Matches': require('../Assets/Images/Flags/defaultFlag.png'),
  Singapore: require('../Assets/Images/Flags/Singapore.png'),
  Syria: require('../Assets/Images/Flags/SyrianArabRepublic.png'),
  Barbados: require('../Assets/Images/Flags/Barbados.png'),
  Chile: require('../Assets/Images/Flags/Chile.png'),
  'San Marino': require('../Assets/Images/Flags/SanMarino.png'),
  Moldova: require('../Assets/Images/Flags/RepublicofMoldova.png'),
  'West Indies': require('../Assets/Images/Flags/WestIndies.png'),
  Tajikistan: require('../Assets/Images/Flags/Tajikistan.png'),
  TrinidadTobago: require('../Assets/Images/Flags/TrinidadandTobago.png'),
  Panama: require('../Assets/Images/Flags/Panama.png'),
  Nepal: require('../Assets/Images/Flags/Nepal.png'),
  Macau: require('../Assets/Images/Flags/Macao.png'),
  China: require('../Assets/Images/Flags/China.png'),
  Taiwan: require('../Assets/Images/Flags/Taiwan.png'),
  Colombia: require('../Assets/Images/Flags/Colombia.png'),
  America: require('../Assets/Images/Flags/defaultFlag.png'),
  'Costa Rica': require('../Assets/Images/Flags/CostaRica.png'),
  Croatia: require('../Assets/Images/Flags/Croatia.png'),
  Cuba: require('../Assets/Images/Flags/Cuba.png'),
  Cyprus: require('../Assets/Images/Flags/Cyprus.png'),
  'Czech Republic': require('../Assets/Images/Flags/CzechRepublic.png'),
  Kyrgyzstan: require('../Assets/Images/Flags/Kyrgyzstan.png'),
  Denmark: require('../Assets/Images/Flags/Denmark.png'),
  Egypt: require('../Assets/Images/Flags/Egypt.png'),
  'El Salvador': require('../Assets/Images/Flags/ElSalvador.png'),
  cotedivoire: require("../Assets/Images/Flags/Coted'Ivoire.png"),
  Finland: require('../Assets/Images/Flags/Finland.png'),
  Ethiopia: require('../Assets/Images/Flags/Ethiopia.png'),
  France: require('../Assets/Images/Flags/France.png'),
  Georgia: require('../Assets/Images/Flags/Georgia.png'),
  Germany: require('../Assets/Images/Flags/Germany.png'),
  Ghana: require('../Assets/Images/Flags/Ghana.png'),
  Gambia: require('../Assets/Images/Flags/Gambia.png'),
  Gibraltar: require('../Assets/Images/Flags/Gibraltar.png'),
  Ecuador: require('../Assets/Images/Flags/Ecuador.png'),
  Greece: require('../Assets/Images/Flags/Greece.png'),
  Guatemala: require('../Assets/Images/Flags/Guatemala.png'),
  Guinea: require('../Assets/Images/Flags/Guinea.png'),
  Honduras: require('../Assets/Images/Flags/Honduras.png'),
  'Hong Kong': require('../Assets/Images/Flags/HongKong.png'),
  Kosovo: require('../Assets/Images/Flags/Kosovo.png'),
  Hungary: require('../Assets/Images/Flags/Hungary.png'),
  Iceland: require('../Assets/Images/Flags/Iceland.png'),
  India: require('../Assets/Images/Flags/India.png'),
  Indonesia: require('../Assets/Images/Flags/Indonesia.png'),
  Iraq: require('../Assets/Images/Flags/Iraq.png'),
  Ireland: require('../Assets/Images/Flags/Ireland.png'),
  Israel: require('../Assets/Images/Flags/Israel.png'),
  Italy: require('../Assets/Images/Flags/Italy.png'),
  Jamaica: require('../Assets/Images/Flags/Jamaica.png'),
  Japan: require('../Assets/Images/Flags/Japan.png'),
  Jordan: require('../Assets/Images/Flags/Jordan.png'),
  Kenya: require('../Assets/Images/Flags/Kenya.png'),
  Kuwait: require('../Assets/Images/Flags/Kuwait.png'),
  Lebanon: require('../Assets/Images/Flags/Lebanon.png'),
  Libya: require('../Assets/Images/Flags/Libya.png'),
  Luxembourg: require('../Assets/Images/Flags/Luxembourg.png'),
  NorthMacedonia: require('../Assets/Images/Flags/NorthMacedonia.png'),
  Malawi: require('../Assets/Images/Flags/Malawi.png'),
  Malaysia: require('../Assets/Images/Flags/Malaysia.png'),
  Mali: require('../Assets/Images/Flags/Mali.png'),
  Malta: require('../Assets/Images/Flags/Malta.png'),
  Mauritania: require('../Assets/Images/Flags/Mauritania.png'),
  Mexico: require('../Assets/Images/Flags/Mexico.png'),
  Mongolia: require('../Assets/Images/Flags/Mongolia.png'),
  Monaco: require('../Assets/Images/Flags/Monaco.png'),
  Montenegro: require('../Assets/Images/Flags/Montenegro.png'),
  Morocco: require('../Assets/Images/Flags/Morocco.png'),
  Mozambique: require('../Assets/Images/Flags/Mozambique.png'),
  Netherlands: require('../Assets/Images/Flags/Netherlands.png'),
  'New Zealand': require('../Assets/Images/Flags/NewZealand.png'),
  Nicaragua: require('../Assets/Images/Flags/Nicaragua.png'),
  Niger: require('../Assets/Images/Flags/Niger.png'),
  Nigeria: require('../Assets/Images/Flags/Nigeria.png'),
  Oman: require('../Assets/Images/Flags/Oman.png'),
  Poland: require('../Assets/Images/Flags/Poland.png'),
  Portugal: require('../Assets/Images/Flags/Portugal.png'),
  'Puerto Rico': require('../Assets/Images/Flags/PuertoRico.png'),
  Romania: require('../Assets/Images/Flags/Romania.png'),
  Rwanda: require('../Assets/Images/Flags/Rwanda.png'),
  SanMarino: require('../Assets/Images/Flags/SanMarino.png'),
  'Saudi Arabia': require('../Assets/Images/Flags/SaudiArabia.png'),
  Senegal: require('../Assets/Images/Flags/Senegal.png'),
  Serbia: require('../Assets/Images/Flags/Serbia.png'),
  Slovenia: require('../Assets/Images/Flags/Slovenia.png'),
  Slovakia: require('../Assets/Images/Flags/Slovakia.png'),
  SolomonIslands: require('../Assets/Images/Flags/SolomonIslands.png'),
  UAE: require('../Assets/Images/Flags/unitedArabEmirates.png'),
  Spain: require('../Assets/Images/Flags/Spain.png'),
  Sudan: require('../Assets/Images/Flags/Sudan.png'),
  Sweden: require('../Assets/Images/Flags/Sweden.png'),
  Switzerland: require('../Assets/Images/Flags/Switzerland.png'),
  'United Republic of Tanzania': require('../Assets/Images/Flags/UnitedRepublicofTanzania.png'),
  Thailand: require('../Assets/Images/Flags/Thailand.png'),
  Togo: require('../Assets/Images/Flags/Togo.png'),
  Tunisia: require('../Assets/Images/Flags/Tunisia.png'),
  Turkey: require('../Assets/Images/Flags/Turkey.png'),
  Turkmenistan: require('../Assets/Images/Flags/Turkmenistan.png'),
  Uganda: require('../Assets/Images/Flags/Uganda.png'),
  Ukraine: require('../Assets/Images/Flags/Ukraine.png'),
  Uruguay: require('../Assets/Images/Flags/Uruguay.png'),
  Vietnam: require('../Assets/Images/Flags/Vietnam.png'),
  Zambia: require('../Assets/Images/Flags/Zambia.png'),
  Palestine: require('../Assets/Images/Flags/Palestine.png'),
  England: require('../Assets/Images/Flags/England.png'),
  'Northern Ireland': require('../Assets/Images/Flags/NorthernIreland.png'),
  Peru: require('../Assets/Images/Flags/Peru.png'),
  Paraguay: require('../Assets/Images/Flags/Paraguay.png'),
  Norway: require('../Assets/Images/Flags/Norway.png'),
  Qatar: require('../Assets/Images/Flags/Qatar.png'),
  Scotland: require('../Assets/Images/Flags/Scotland.png'),
  Wales: require('../Assets/Images/Flags/Wales.png'),
  USA: require('../Assets/Images/Flags/USA.png'),
  'South Korea': require('../Assets/Images/Flags/southKorea.png'),
  Asia: require('../Assets/Images/Flags/Asia.png'),
  Russia: require('../Assets/Images/Flags/RussianFederation.png'),
  Bolivia: require('../Assets/Images/Flags/Bolivia.png'),
  'South Africa': require('../Assets/Images/Flags/SouthAfrica.png'),
  'South America': require('../Assets/Images/Flags/southAmerica.png'),
};
