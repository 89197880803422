import {memo} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {ReactComponent as Back} from '../../../../Assets/Icons/Globals/back.svg';

const ModalBackButton = ({onBackClick}) => {
  const {t} = useTranslation();

  return (
    <div
      onClick={onBackClick}
      className="modalBackButtonWrapper container rowCenter cursor-pointer">
      <Back width={18} height={18} className="backIcon" />
      <span className="blueText text-caption bold-700">{t('back')}</span>
    </div>
  );
};

export default memo(ModalBackButton);
