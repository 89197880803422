import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../../Redux/AppSlice';

import {useScreenSize, useSocket} from '../../../../../Hooks';

import {DEPOSIT, USER_LIMIT} from '../../../../../Constants/Socket';
import {
  APP_MODAL_CLOSE_TIMEOUT_MS,
  BREAKPOINT_XS,
} from '../../../../../Constants/Globals';

import {AppButton, AppInput} from '../../../../UI';
import AppInputSkeleton from '../../../../UI/Skeletons/AppInputSkeleton';

const DepositLimits = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {depositLimit, getDepositLimit} = useSocket();

  const wssSocket = useSelector(getWssSocket);

  const [day, setDay] = useState('');
  const [week, setWeek] = useState('');
  const [month, setMonth] = useState('');
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLimitsDataLoading, setIsLimitsDataLoading] = useState(false);

  useEffect(() => {
    setIsLimitsDataLoading(true);
    getDepositLimit();
  }, []);

  const isSaveDisabled = useMemo(
    () =>
      day?.trim?.()?.length < 1 ||
      week?.trim?.()?.length < 1 ||
      month?.trim?.()?.length < 1,
    [day, month, week],
  );

  const saveClickHandler = useCallback(() => {
    setIsSaveLoading(true);

    // period_type time period type (2: Day, 3: Week, 4: Month, 5: Year)
    depositLimit([
      {
        period: 1,
        period_type: 2,
        deposit_limit: day,
      },
      {
        period: 1,
        period_type: 3,
        deposit_limit: week,
      },
      {
        period: 1,
        period_type: 4,
        deposit_limit: month,
      },
    ]);
  }, [depositLimit, day, month, week]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);

      switch (data?.rid) {
        case DEPOSIT:
          if (data?.code === 0) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('depositLimitsSet'),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          }
          break;
        case USER_LIMIT:
          if (data?.code === 0) {
            setDay(data?.data?.details?.max_day_deposit?.toString());
            setWeek(data?.data?.details?.max_week_deposit?.toString());
            setMonth(data?.data?.details?.max_month_deposit?.toString());
          }
          setIsLimitsDataLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <>
      <div
        className={`flex column items-center depositLimitsContainer justify-between px-md ${
          !(width < BREAKPOINT_XS) && 'py-xl'
        }`}>
        <div className="flex row items-center full-width justify-center">
          <div className="col-xs-12 col-sm-10 col-md-8 flex column content justify-between gap-10 full-width">
            <div className="flex justify-between column gap-10 col-sm-12 col-md-4">
              {!(width < BREAKPOINT_XS) && (
                <>
                  <span className="whiteText font-15 bold-700 textCenter py-md">
                    {t('depositLimits')}
                  </span>
                </>
              )}
              <span className="whiteText bold-500 font-12 textCenter px-xxxl">
                {t('depositLimitsText')}
              </span>
              <div className="row gap-10 px-md full-width pt-md">
                <div className="flex column full-width gap-4">
                  <span className="font-12 whiteText bold-500">
                    {t('day')}:
                  </span>
                  {isLimitsDataLoading ? (
                    <AppInputSkeleton />
                  ) : (
                    <AppInput
                      type={9}
                      height={35}
                      inputValue={day}
                      onChange={setDay}
                      placeholder={t('enterDay')}
                    />
                  )}
                </div>
                <div className="flex column full-width gap-4">
                  <span className="font-12 whiteText bold-500">
                    {t('week')}:
                  </span>
                  {isLimitsDataLoading ? (
                    <AppInputSkeleton />
                  ) : (
                    <AppInput
                      type={9}
                      height={35}
                      inputValue={week}
                      onChange={setWeek}
                      placeholder={t('enterWeek')}
                    />
                  )}
                </div>
                <div className="flex column full-width gap-4">
                  <span className="font-12 whiteText bold-500">
                    {t('month')}:
                  </span>
                  {isLimitsDataLoading ? (
                    <AppInputSkeleton />
                  ) : (
                    <AppInput
                      type={9}
                      height={35}
                      inputValue={month}
                      onChange={setMonth}
                      placeholder={t('enterMonth')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppButton
          type={3}
          loading={isSaveLoading}
          disabled={isSaveDisabled}
          onClick={saveClickHandler}
          title={t('confirm').toUpperCase()}
        />
      </div>
    </>
  );
};

export default memo(DepositLimits);
