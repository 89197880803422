export const FIXED_FOOTBALL_MARKETS_FILTERS = [
  {title: 'matchResult', displayKey: 'WINNER'},
  {title: 'doubleChanceFilter', displayKey: 'DOUBLE CHANCE'},
  {title: 'asianHandicap', displayKey: 'HANDICAP'},
  {title: 'total', displayKey: 'TOTALS'},
  {title: 'bothTeamToScoreTitle', displayKey: 'BOTHTEAMTOSCORE'},
  {title: 'oddEven', displayKey: 'ODD/EVEN'},
];

export const FIXED_MARKETS_FILTERS = [
  {title: 'matchResult', displayKey: 'WINNER'},
  {title: 'total', displayKey: 'TOTALS'},
  {title: 'handicapTitle', displayKey: 'HANDICAP'},
];
