import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {FILTERS_LIVE_NAME} from '../../../Constants/MatchFilters';

const LiveIndicator = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  return (
    <div className="liveIndicator px-sm py-xxs rounded-borders row items-center justify-center">
      <span
        className={`${
          width < BREAKPOINT_XS ? 'font-10' : 'font-10'
        } whiteText`}>
        {t(FILTERS_LIVE_NAME)}
      </span>
    </div>
  );
};

export default memo(LiveIndicator);
