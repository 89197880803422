export const MAIN_CATEGORY = false;
export const CASINO_CATEGORY = true;

export const DEFAULT_TRANSACTION_TYPE = 99999999; // this can be changed to any value(DocumentTypeId) taken from docs

export const TRANSACTION_CATEGORIES = [
  {
    name: 'main',
    value: MAIN_CATEGORY,
  },
  {
    name: 'casino',
    value: CASINO_CATEGORY,
  },
];
