import React, {memo} from 'react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useParams, useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {AppButton} from '../../UI';

import {FOOTBALL_ALIAS} from '../../../Constants/MatchFilters';
import {
  FIXED_MARKETS_FILTERS,
  FIXED_FOOTBALL_MARKETS_FILTERS,
} from '../../../Constants/FixedMarketFiltersArray';
import {BREAKPOINT_LG} from '../../../Constants/Globals';

const FixedMarketsFilters = ({setIsGrouped, isGrouped}) => {
  const params = useParams();
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const getDayParam = getParam.get('day');
  const getMarketParam = getParam.get('market');
  const getSportGameIdParam = getParam.get('sportGameId');

  return (
    (!getSportGameIdParam || width > BREAKPOINT_LG) && (
      <div className="fixedMarketsFiltersContainer row full-width">
        <Swiper
          freeMode
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard, FreeMode]}
          className="fixedMarketsFiltersSwiper full-width">
          {(
            (params?.sport === FOOTBALL_ALIAS
              ? FIXED_FOOTBALL_MARKETS_FILTERS
              : FIXED_MARKETS_FILTERS) || []
          )?.map(item => (
            <SwiperSlide key={item?.title}>
              <AppButton
                type={15}
                className="py-xs px-sm"
                active={getMarketParam === item?.title}
                title={
                  <span className="text-caption-small blackText bold-500">
                    {t(item?.title)}
                  </span>
                }
                onClick={() => {
                  setGetParam({
                    ...(getSportGameIdParam ? {game: getSportGameIdParam} : {}),
                    ...(getDayParam ? {day: getDayParam} : {}),
                    market: item?.title,
                  });
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
};

export default memo(FixedMarketsFilters);
