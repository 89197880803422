import store from '../Redux/store';

export const fixedNumberWithoutRound = (number, toFixed) => {
  const decimals = toFixed || store.getState().partner.partner.price_decimals;

  const partsArray = number?.toString?.()?.split?.('.');

  return partsArray?.length > 1
    ? Number(`${partsArray?.[0]}.${partsArray?.[1]?.substring(0, decimals)}`)
    : number?.toFixed(2);
};

export const getPriceLabel = price => {
  const priceDecimals =
    +store?.getState?.()?.partner?.partner?.price_decimals || 3;

  return (
    ((+price)?.toString()?.length < 4
      ? Number.parseFloat(+price)?.toFixed(+priceDecimals - 1)
      : +price) || '-'
  );
};
