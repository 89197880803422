import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import './index.scss';

import logo from '../../../Assets/Layout/HeaderLogo.png';

const Intro = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return (
    <div className={`introContainer ${isLoggedIn !== null ? 'hideIntro' : ''}`}>
      <img alt="logo" src={logo} className="logo"></img>
    </div>
  );
};

export default memo(Intro);
