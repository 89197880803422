import {configureStore} from '@reduxjs/toolkit';

import {
  appSlice,
  userSlice,
  sportSlice,
  casinoSlice,
  partnerSlice,
  bettingSlice,
} from './index';

const store = configureStore({
  reducer: {
    app: appSlice,
    user: userSlice,
    sport: sportSlice,
    casino: casinoSlice,
    partner: partnerSlice,
    betting: bettingSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
