export const MAIN_MENU = 'mainMenu';
export const MY_BETS = 'myBetsMainMenu';
export const BALANCE_MANAGEMENT = 'balanceManagement';
export const PROMOTIONS = 'promotions';
export const MESSAGES = 'messages';
export const SETTINGS = 'settings';
export const MY_PROFILE = 'myProfile';
export const PROFILE = 'profile';
export const LEGAL_COMPLIANCE = 'legalAndCompliance';
export const RESPONSIBLE_GAMING = 'responsibleGaming';
export const CONTACT_US = 'contactUs';
export const LIVE_CHAT = 'liveChat';

export const HOW_TO_VIDEOS = 'howToVideos';
export const DEPOSIT = 'deposit';
export const WITHDRAW = 'withdraw';
export const SEND_TO_FRIEND = 'sendToFriend';

export const TRANSACTION_HISTORY = 'transactionHistory';
export const PERSONAL_DETAILS = 'personalDetails';
export const CHANGE_PASSWORD = 'changePassword';
export const SELF_EXCLUSION = 'selfExclusion';
export const TIME_OUT = 'timeOut';
export const DEPOSIT_LIMITS = 'depositLimits';
export const VERIFY_ACCOUNT = 'verifyAccount';

export const MAIN_MENU_STACKS = [
  {
    tab: BALANCE_MANAGEMENT,
    title: BALANCE_MANAGEMENT,
    icon: require('../Assets/Icons/MainMenu/balanceManagement.svg').default,
  },
  {
    tab: MY_BETS,
    title: MY_BETS,
    icon: require('../Assets/Icons/MainMenu/myBets.svg').default,
  },
  {
    tab: PROMOTIONS,
    title: PROMOTIONS,
    icon: require('../Assets/Icons/MainMenu/promotion.svg').default,
  },
  {
    tab: MESSAGES,
    title: MESSAGES,
    icon: require('../Assets/Icons/MainMenu/messages.svg').default,
  },
  {
    tab: SETTINGS,
    title: SETTINGS,
    icon: require('../Assets/Icons/MainMenu/settings.svg').default,
  },
  // {
  //   tab: LEGAL_COMPLIANCE,
  //   title: LEGAL_COMPLIANCE,
  //   icon: require('../Assets/Icons/MainMenuTab/legalAndComliance.svg').default,
  // },
  // {
  //   tab: RESPONSIBLE_GAMING,
  //   title: RESPONSIBLE_GAMING,
  //   icon: require('../Assets/Icons/MainMenuTab/legalAndComliance.svg').default,
  // },
  // {
  //   tab: CONTACT_US,
  //   title: CONTACT_US,
  //   icon: require('../Assets/Icons/MainMenuTab/contact.svg').default,
  // },
  // {
  //   tab: HOW_TO_VIDEOS,
  //   title: HOW_TO_VIDEOS,
  //   icon: require('../Assets/Icons/MainMenu/youtube.svg').default,
  // },
];

export const MAIN_MENU_STACKS_MOBILE = [
  {
    tab: BALANCE_MANAGEMENT,
    title: BALANCE_MANAGEMENT,
    icon: require('../Assets/Icons/MainMenu/balanceManagement.svg').default,
  },
  {
    tab: TRANSACTION_HISTORY,
    title: TRANSACTION_HISTORY,
    icon: require('../Assets/Icons/MainMenu/print.svg').default,
  },
  {
    tab: MY_PROFILE,
    title: MY_PROFILE,
    icon: require('../Assets/Icons/MainMenu/print.svg').default,
  },
  {
    tab: PROMOTIONS,
    title: PROMOTIONS,
    icon: require('../Assets/Icons/MainMenu/promotion.svg').default,
  },
  {
    tab: MESSAGES,
    title: MESSAGES,
    icon: require('../Assets/Icons/MainMenu/messages.svg').default,
  },
  {
    tab: SETTINGS,
    title: SETTINGS,
    icon: require('../Assets/Icons/MainMenu/settings.svg').default,
  },
  {
    tab: LEGAL_COMPLIANCE,
    title: LEGAL_COMPLIANCE,
    icon: require('../Assets/Icons/MainMenu/legalAndComliance.svg').default,
  },
  {
    tab: LIVE_CHAT,
    title: LIVE_CHAT,
    icon: require('../Assets/Icons/MainMenu/liveChat.svg').default,
  },
];

export const BALANCE_MANAGEMENT_STACKS = [
  {
    tabIndex: 0,
    tab: DEPOSIT,
    title: DEPOSIT,
    icon: require('../Assets/Icons/MainMenu/deposit.svg').default,
  },
  {
    tabIndex: 1,
    tab: WITHDRAW,
    title: WITHDRAW,
    icon: require('../Assets/Icons/MainMenu/redWithdraw.svg').default,
  },
  {
    tabIndex: 3,
    tab: SEND_TO_FRIEND,
    title: SEND_TO_FRIEND,
    icon: require('../Assets/Icons/MainMenu/withdraw.svg').default,
  },
  {
    tab: TRANSACTION_HISTORY,
    title: TRANSACTION_HISTORY,
    icon: require('../Assets/Icons/MainMenu/history.svg').default,
  },
];

export const PROFILE_STACKS = [
  {
    tabIndex: 0,
    tab: PERSONAL_DETAILS,
    title: PERSONAL_DETAILS,
    icon: require('../Assets/Icons/MainMenu/personaldetails.svg').default,
  },
  {
    tabIndex: 1,
    tab: CHANGE_PASSWORD,
    title: CHANGE_PASSWORD,
    icon: require('../Assets/Icons/MainMenu/changePassword.svg').default,
  },
  {
    tabIndex: 2,
    tab: SELF_EXCLUSION,
    title: SELF_EXCLUSION,
    icon: require('../Assets/Icons/MainMenu/selfExclusion.svg').default,
  },
  {
    tabIndex: 3,
    tab: TIME_OUT,
    title: TIME_OUT,
    icon: require('../Assets/Icons/MainMenu/timeOut.svg').default,
  },
  {
    tabIndex: 4,
    tab: DEPOSIT_LIMITS,
    title: DEPOSIT_LIMITS,
    icon: require('../Assets/Icons/MainMenu/depositeLimits.svg').default,
  },
  {
    tabIndex: 5,
    tab: VERIFY_ACCOUNT,
    title: VERIFY_ACCOUNT,
    icon: require('../Assets/Icons/MainMenu/profile.svg').default,
  },
];
