import React, {memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import BetHistoryContainer from '../BetHistoryContainer';

const OpenBetsContainer = ({
  type,
  stake,
  betId,
  index,
  bonus,
  events,
  rowType,
  cash_out,
  date_time,
  totalOdds,
  tax_amount,
  finalPayout,
  eventsCount,
  systemMinCount,
  cashOutSubIdRef,
  cashOutedAmountRef,
  setIsSuccessModalOpen,
  setIsBetHistoryDataLoading,
}) => {
  const wssSocket = useSelector(getWssSocket);

  const [cashOutAmount, setCashOutAmount] = useState(cash_out);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      const updatedData = data?.data?.[cashOutSubIdRef.current];
      const updatedBetCashOut = updatedData?.cashout?.[betId];
      if (updatedData && updatedBetCashOut) {
        setCashOutAmount(updatedBetCashOut?.amount);
      }
    },
    [betId, cashOutSubIdRef],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <BetHistoryContainer
      type={type}
      index={index}
      stake={stake}
      betId={betId}
      bonus={bonus}
      events={events}
      rowType={rowType}
      cash_out={cash_out}
      totalOdds={totalOdds}
      date_time={date_time}
      tax_amount={tax_amount}
      finalPayout={finalPayout}
      eventsCount={eventsCount}
      cashOutAmount={cashOutAmount}
      systemMinCount={systemMinCount}
      cashOutedAmountRef={cashOutedAmountRef}
      setIsSuccessModalOpen={setIsSuccessModalOpen}
      setIsBetHistoryDataLoading={setIsBetHistoryDataLoading}
    />
  );
};

export default memo(OpenBetsContainer);
