import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useSelector} from 'react-redux';

import './index.scss';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {AppSelect, AppSwitcher} from '../../../UI';

import {ReactComponent as StarIcon} from '../../../../Assets/Icons/Globals/starFilled.svg';

const FreeBet = ({
  freeBetData,
  enableFreeBet,
  setStakeAmount,
  selectedFreeBet,
  setEnableFreeBet,
  setSelectedFreeBet,
}) => {
  const {t} = useTranslation();
  const currency = useSelector(getCurrency);

  const data = useMemo(
    () =>
      freeBetData?.map(item => ({
        ...item,
        value: item?.id,
        name: `${item?.amount} ${currency} (${t('exp')} ${moment
          ?.unix(item?.expiration_date)
          ?.format('DD.MM.YY, HH:MM')})`,
      })),
    [currency, freeBetData, t],
  );

  return (
    <div className="column gap-10">
      <div
        style={{background: '#009A18'}}
        className="freeBetContainer pr-md py-lg pl-xl gap-5 column rounded-borders">
        <div className="row items-center gap-10 justify-between">
          <div className="row items-center gap-10">
            <StarIcon />
            <span className="bold-600 whiteText">{t('freeBet')}</span>
          </div>
          <AppSwitcher
            type={6}
            isToggled={enableFreeBet}
            onToggle={() => {
              setEnableFreeBet(prevState => !prevState);
            }}
          />
        </div>
        <span className="whiteText font-10">{t('freeBetCondition')}</span>
      </div>
      {enableFreeBet && (
        <AppSelect
          type={1}
          options={data}
          disabled={freeBetData?.length === 1}
          onItemClickCb={item => {
            setSelectedFreeBet(item);
            setStakeAmount(item?.amount?.toString());
          }}
          value={selectedFreeBet?.id}
          titleClassName="bold-600 font-13"
          itemClassName="py-md px-lg font-13"
        />
      )}
    </div>
  );
};

export default memo(FreeBet);
