import React, {memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

import './index.scss';

import {AppButton} from '../../UI';

import {useScreenSize} from '../../../Hooks';

import {getIsGameDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {
  MATCH_FILTERS,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_POPULAR_COMPETITIONS_NAME,
} from '../../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

const SportFilters = ({isMobile = false}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const clickHandler = useCallback(
    filterItem => {
      if (filterItem?.name === gameFilterParam) {
        return;
      }

      getParam.delete('region');
      if (filterItem?.name !== FILTERS_UPCOMING_MATCHES_NAME) {
        getParam.delete('day');
      }

      getParam.set('gameFilter', filterItem?.name);
      setGetParam(getParam);
    },
    [gameFilterParam, getParam, setGetParam],
  );

  return (
    <Swiper
      keyboard
      freeMode
      mousewheel
      slidesPerView="auto"
      modules={[Keyboard, FreeMode]}
      wrapperClass="wrapperBorders mx-auto"
      spaceBetween={width < BREAKPOINT_XS ? 5 : 0}
      className={`sportFiltersSwiper full-width ${
        width > BREAKPOINT_XS ? 'desktop' : 'mobile mt-sm'
      }`}>
      {Object.values(MATCH_FILTERS).map(
        matchItem =>
          !(matchItem?.name === FILTERS_POPULAR_COMPETITIONS_NAME) && (
            <SwiperSlide
              key={matchItem?.name}
              className={`${width > BREAKPOINT_XS ? 'pa-xxs' : ''}`}>
              <AppButton
                type={isMobile ? 7 : 9}
                onClick={() => clickHandler(matchItem)}
                disabledWithoutLayer={isGameDataLoading}
                className="paddingVertical-7 px-lg rounded-15"
                active={gameFilterParam === matchItem?.name}
                title={
                  <div className="flex row gap-8 items-center">
                    {matchItem?.icon && (
                      <img
                        width={15}
                        alt={matchItem?.icon}
                        height={matchItem?.icon === 'live' ? 10 : 17}
                        src={require(
                          `../../../Assets/Icons/SportFilters/${matchItem?.icon}.svg`,
                        )}
                      />
                    )}
                    <span className="bold-600 font-12">
                      {t(matchItem?.name)}
                    </span>
                  </div>
                }
              />
            </SwiperSlide>
          ),
      )}
    </Swiper>
  );
};

export default memo(SportFilters);
