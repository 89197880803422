import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const SelectedGameSkeleton = () => (
  <div className="flex column gap-5">
    <Skeleton
      count={1}
      height={80}
      duration={0.7}
      className="top-5"
      borderRadius="5px"
      baseColor="var(--lightBlue)"
      highlightColor="var(--appBlue)"
    />
    <div className="flex row gap-5 overflow-hidden-x">
      {Array.apply(null, Array(7))?.map((_, index) => (
        <Skeleton
          count={1}
          key={index}
          width={100}
          height={35}
          duration={0.7}
          borderRadius="5px"
          baseColor="var(--lightBlue)"
          highlightColor="var(--appBlue)"
        />
      ))}
    </div>
    <Skeleton
      count={30}
      height={30}
      width="100%"
      duration={0.7}
      borderRadius="5px"
      className="top-5"
      baseColor="var(--lightBlue)"
      highlightColor="var(--appBlue)"
    />
  </div>
);

export default memo(SelectedGameSkeleton);
