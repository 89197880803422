import {useCallback} from 'react';

const useStorage = () => {
  const setData = useCallback((key, value) => {
    try {
      if (typeof value === 'object' && value !== null) {
        localStorage.setItem(key, value);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (e) {
      // error set value
      console.log(e, 'error setData from Storage');
    }
  }, []);

  const getObjectData = useCallback(key => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      // error reading value
      console.log(e, 'error getData from Storage');
    }
  }, []);

  const getPrimitiveData = useCallback(key => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      // error reading value
      console.log(e, 'error getData from Storage');
    }
  }, []);

  const removeData = useCallback(key => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      // error removing value
      console.log(e, 'error removeData from Storage');
    }
  }, []);

  const setLoginDataToStorage = useCallback(
    async loginData => {
      try {
        await setData('login_data', JSON.stringify(loginData));
      } catch (e) {}
    },
    [setData],
  );

  const getLoginDataFromStorage = useCallback(async () => {
    try {
      const loginData = await getObjectData('login_data');
      return JSON.parse(loginData);
    } catch (e) {}
  }, [getObjectData]);

  const removeLoginDataFromStorage = useCallback(async () => {
    try {
      return await removeData('login_data');
    } catch (e) {}
  }, [removeData]);

  const setGroupedByCompetition = useCallback(
    isGrouped => {
      try {
        setData('group_by_competition', JSON.stringify(isGrouped));
      } catch (e) {}
    },
    [setData],
  );

  const getGroupedByCompetition = useCallback(() => {
    try {
      const betSlipData = getObjectData('group_by_competition');
      return JSON.parse(betSlipData);
    } catch (e) {}
  }, [getObjectData]);

  const setBetSlipDataToStorage = useCallback(
    betSlipData => {
      try {
        setData('betSlip_data', JSON.stringify(betSlipData));
      } catch (e) {}
    },
    [setData],
  );

  const getBetSlipDataFromStorage = useCallback(() => {
    try {
      const betSlipData = getObjectData('betSlip_data');
      return JSON.parse(betSlipData);
    } catch (e) {}
  }, [getObjectData]);

  const removeBetSlipDataFromStorage = useCallback(() => {
    try {
      return removeData('betSlip_data');
    } catch (e) {}
  }, [removeData]);

  const getFavouriteMarketsFromStorage = useCallback(() => {
    try {
      const data = getObjectData('favouriteMarkets') || '[]';
      return JSON.parse(data);
    } catch (e) {
      return [];
    }
  }, [getObjectData]);

  const setFavouriteMarketsToStorage = useCallback(
    item => {
      try {
        const previousData = getFavouriteMarketsFromStorage() || [];
        const index = previousData.indexOf(item);
        if (index > -1) {
          previousData.splice(index, 1);
        } else {
          previousData?.push(item);
        }
        return setData('favouriteMarkets', JSON.stringify(previousData));
      } catch (e) {
        return [];
      }
    },
    [getFavouriteMarketsFromStorage, setData],
  );

  const getSearchedValues = useCallback(
    isCasino => {
      try {
        const data =
          getObjectData(isCasino ? 'casinoSearchedValues' : 'searchedValues') ||
          '[]';
        return JSON.parse(data);
      } catch (e) {
        return [];
      }
    },
    [getObjectData],
  );

  const setSearchedValuesToStorage = useCallback(
    (text, deleteValue, isCasino) => {
      try {
        const previousData = getSearchedValues(isCasino) || [];

        const itemIndex = previousData.findIndex(
          item =>
            item?.val?.trim()?.toLowerCase() === text?.trim?.()?.toLowerCase(),
        );

        if (itemIndex < 0 && text?.length > 0) {
          previousData?.push({
            val: text,
            date: Date.now(),
          });
        } else if (!!deleteValue) {
          previousData?.splice(itemIndex, 1);
        }

        return setData(
          isCasino ? 'casinoSearchedValues' : 'searchedValues',
          JSON.stringify(previousData),
        );
      } catch (e) {
        return [];
      }
    },
    [getSearchedValues, setData],
  );

  const getLastPlayedGamesFromStorage = useCallback(() => {
    try {
      const data = getObjectData('lastPlayedGames') || '[]';
      return JSON.parse(data);
    } catch (e) {
      return [];
    }
  }, [getObjectData]);

  const setLastPlayedGamesToStorage = useCallback(
    async (gameExternalId, categoryId) => {
      let lastPlayedGames = getLastPlayedGamesFromStorage() || [];
      const gameIndex = +lastPlayedGames?.findIndex(
        item => +item?.gameId === +gameExternalId,
      );

      if (gameIndex > -1) {
        lastPlayedGames[gameIndex] = {
          ...lastPlayedGames[gameIndex],
          lastPlayedTime: +Date.now(),
          playedCount: +(+lastPlayedGames[gameIndex].playedCount + 1),
          ...(categoryId ? {categoryId: +categoryId} : {}),
        };
      } else {
        const createdItem = {
          playedCount: 1,
          gameId: +gameExternalId,
          lastPlayedTime: +Date.now(),
          ...(categoryId ? {categoryId: +categoryId} : {}),
        };
        if (lastPlayedGames?.length < 30) {
          lastPlayedGames = [...lastPlayedGames, createdItem];
        } else {
          lastPlayedGames[lastPlayedGames.length - 1] = createdItem;
        }
      }

      lastPlayedGames?.sort(
        (a, b) =>
          b?.playedCount - a?.playedCount ||
          b?.lastPlayedTime - a?.lastPlayedTime,
      );

      setData('lastPlayedGames', JSON.stringify(lastPlayedGames));
    },
    [getLastPlayedGamesFromStorage, setData],
  );

  return {
    getSearchedValues,
    setLoginDataToStorage,
    getGroupedByCompetition,
    getLoginDataFromStorage,
    setBetSlipDataToStorage,
    setGroupedByCompetition,
    getBetSlipDataFromStorage,
    removeLoginDataFromStorage,
    setSearchedValuesToStorage,
    removeBetSlipDataFromStorage,
    setLastPlayedGamesToStorage,
    getLastPlayedGamesFromStorage,
    setFavouriteMarketsToStorage,
    getFavouriteMarketsFromStorage,
  };
};

export default useStorage;
