export const SportIcons = {
  Soccer: require('../Assets/Icons/Sport/Soccer.svg').default,
  IceHockey: require('../Assets/Icons/Sport/IceHockey.svg').default,
  Basketball: require('../Assets/Icons/Sport/Basketball.svg').default,
  Tennis: require('../Assets/Icons/Sport/Tennis.svg').default,
  Volleyball: require('../Assets/Icons/Sport/Volleyball.svg').default,
  AmericanFootball: require('../Assets/Icons/Sport/AmericanFootball.svg')
    .default,
  AustralianFootball: require('../Assets/Icons/Sport/AustralianFootball.svg')
    .default,
  BallHockey: require('../Assets/Icons/Sport/BallHockey.svg').default,
  Baseball: require('../Assets/Icons/Sport/Baseball.svg').default,
  Boxing: require('../Assets/Icons/Sport/Boxing.svg').default,
  Chess: require('../Assets/Icons/Sport/Chess.svg').default,
  Cricket: require('../Assets/Icons/Sport/Cricket.svg').default,
  Cycling: require('../Assets/Icons/Sport/Cycling.svg').default,
  Darts: require('../Assets/Icons/Sport/Darts.svg').default,
  Formula1: require('../Assets/Icons/Sport/Formula1.svg').default,
  Futsal: require('../Assets/Icons/Sport/Futsal.svg').default,
  Curling: require('../Assets/Icons/Sport/Curling.svg').default,
  Rally: require('../Assets/Icons/Sport/Rally.svg').default,
  CrossFire: require('../Assets/Icons/Sport/CrossFire.svg').default,
  RainbowSix: require('../Assets/Icons/Sport/RainbowSix.svg').default,
  CompoundArchery: require('../Assets/Icons/Sport/CompoundArchery.svg').default,
  GaelicFootball: require('../Assets/Icons/Sport/GaelicFootball.svg').default,
  BeachFootball: require('../Assets/Icons/Sport/BeachFootball.svg').default,
  Athletics: require('../Assets/Icons/Sport/Athletics.svg').default,
  StarCraft2: require('../Assets/Icons/Sport/StarCraft2.svg').default,
  FutureHorseRacing: require('../Assets/Icons/Sport/FutureHorseRacing.svg')
    .default,
  SISGreyhound: require('../Assets/Icons/Sport/SISGreyhound.svg').default,
  Golf: require('../Assets/Icons/Sport/Golf.svg').default,
  Hockey: require('../Assets/Icons/Sport/Hockey.svg').default,
  RinkHockey: require('../Assets/Icons/Sport/RinckHockey.svg').default,
  Softball: require('../Assets/Icons/Sport/Softball.svg').default,
  Pool: require('../Assets/Icons/Sport/Pool.svg').default,
  Sumo: require('../Assets/Icons/Sport/Sumo.svg').default,
  Pesapallo: require('../Assets/Icons/Sport/Pesapallo.svg').default,
  Teqball: require('../Assets/Icons/Sport/Teqball.svg').default,
  Bowls: require('../Assets/Icons/Sport/Bowls.svg').default,
  CrossCountrySkiing: require('../Assets/Icons/Sport/CrossCountrySkiing.svg')
    .default,
  ArcheryH2H: require('../Assets/Icons/Sport/ArcheryH2H.svg').default,
  BeachVolleyball: require('../Assets/Icons/Sport/BeachVolleyball.svg').default,
  KingOfGlory: require('../Assets/Icons/Sport/KingOfGlory.svg').default,
  VirtualDragRacing: require('../Assets/Icons/Sport/VirtualDragRacing.svg')
    .default,
  Handball: require('../Assets/Icons/Sport/Handball.svg').default,
  Lacross: require('../Assets/Icons/Sport/Lacross.svg').default,
  StarCraft: require('../Assets/Icons/Sport/StarCraft.svg').default,
  Netball: require('../Assets/Icons/Sport/Netball.svg').default,
  Weightlifting: require('../Assets/Icons/Sport/Weightlifting.svg').default,
  Squash: require('../Assets/Icons/Sport/Squash.svg').default,
  WarcraftIII: require('../Assets/Icons/Sport/WarcraftIII.svg').default,
  ApexLegends: require('../Assets/Icons/Sport/ApexLegends.svg').default,
  VirtualGreyhoundRacing:
    require('../Assets/Icons/Sport/VirtualGreyhoundRacing.svg').default,
  VirtualMarbleRacing: require('../Assets/Icons/Sport/VirtualMarbleRacing.svg')
    .default,
  VirtualHorseRacing: require('../Assets/Icons/Sport/VirtualHorseRacing.svg')
    .default,
  VirtualFootballPro: require('../Assets/Icons/Sport/VirtualFootballPro.svg')
    .default,
  VirtualBicycle: require('../Assets/Icons/Sport/VirtualBicycle.svg').default,
  VirtualFootballLeague:
    require('../Assets/Icons/Sport/VirtualFootballLeague.svg').default,
  Archery: require('../Assets/Icons/Sport/Archery.svg').default,
  Speedway: require('../Assets/Icons/Sport/Speedway.svg').default,
  Yachting: require('../Assets/Icons/Sport/Sailing.svg').default,
  Eurovision: require('../Assets/Icons/Sport/Eurovision.svg').default,
  GrecoRomanWrestling: require('../Assets/Icons/Sport/GrecoRomanWrestling.svg')
    .default,
  FreestyleWrestling: require('../Assets/Icons/Sport/FreestyleWrestling.svg')
    .default,
  PistolShooting: require('../Assets/Icons/Sport/PistolShooting.svg').default,
  HorseRacing: require('../Assets/Icons/Sport/HorseRacing.svg').default,
  ThePenaltyKicks: require('../Assets/Icons/Sport/ThePenaltyKicks.svg').default,
  Nascar: require('../Assets/Icons/Sport/Nascar.svg').default,
  Badminton: require('../Assets/Icons/Sport/Badminton.svg').default,
  Valorant: require('../Assets/Icons/Sport/Valorant.svg').default,
  Floorball: require('../Assets/Icons/Sport/Floorball.svg').default,
  Dota2: require('../Assets/Icons/Sport/Dota2.svg').default,
  RocketLeague: require('../Assets/Icons/Sport/RocketLeague.svg').default,
  Surfing: require('../Assets/Icons/Sport/Surfing.svg').default,
  PistolHead2Head: require('../Assets/Icons/Sport/Shooting.svg').default,
  CounterStrike: require('../Assets/Icons/Sport/CounterStrike.svg').default,
  MobileLegends: require('../Assets/Icons/Sport/MobileLegends.svg').default,
  LeagueofLegendsWildRift:
    require('../Assets/Icons/Sport/LeagueofLegendsWildRift.svg').default,
  CallOfDuty: require('../Assets/Icons/Sport/CallofDuty.svg').default,
  ArenaofValor: require('../Assets/Icons/Sport/ArenaOfValor.svg').default,
  '3x3Basketball': require('../Assets/Icons/Sport/3x3Basketball.svg').default,
  AutoRacing: require('../Assets/Icons/Sport/AutoRacing.svg').default,
  Triathlon: require('../Assets/Icons/Sport/Triathlon.svg').default,
  BrawlStars: require('../Assets/Icons/Sport/BrawlStars.svg').default,
  Motorsport: require('../Assets/Icons/Sport/Motorbikes.svg').default,
  Overwatch: require('../Assets/Icons/Sport/OverWatch.svg').default,
  BasketballShots: require('../Assets/Icons/Sport/BasketballShots.svg').default,
  RugbyLeague: require('../Assets/Icons/Sport/RugbyLeague.svg').default,
  RugbyUnion: require('../Assets/Icons/Sport/RugbyUnion.svg').default,
  RugbySevens: require('../Assets/Icons/Sport/RugbyUnion.svg').default,
  Snooker: require('../Assets/Icons/Sport/Snooker.svg').default,
  AgeofEmpires: require('../Assets/Icons/Sport/AgeOfEmpires.svg').default,
  TableTennis: require('../Assets/Icons/Sport/TableTennis.svg').default,
  WaterPolo: require('../Assets/Icons/Sport/WaterPolo.svg').default,
  Mma: require('../Assets/Icons/Sport/Mma.svg').default,
  CyberFootball: require('../Assets/Icons/Sport/CyberFootball.svg').default,
  EBasketball: require('../Assets/Icons/Sport/EBasketball.svg').default,
  LeagueOfLegends: require('../Assets/Icons/Sport/LeagueOfLegends.svg').default,
  Politics: require('../Assets/Icons/Sport/Politics.svg').default,
  Biathlon: require('../Assets/Icons/Sport/Biathlon.svg').default,
  SpecialBets: require('../Assets/Icons/Sport/SpecialBets.svg').default,
  Oscar: require('../Assets/Icons/Sport/Oscar.svg').default,
  Pelota: require('../Assets/Icons/Sport/Pelota.svg').default,
  Olympics: require('../Assets/Icons/Sport/olympics.svg').default,
  Swimming: require('../Assets/Icons/Sport/Swimming.svg').default,
  TVShowsAndMovies: require('../Assets/Icons/Sport/TVShowsAndMovies.svg')
    .default,
  Sample: require('../Assets/Icons/BottomTabs/sample.svg').default,
};
