import {createSlice} from '@reduxjs/toolkit';
import {MAIN_MENU} from '../../Constants/MainMenu';

const initialState = {
  sid: null,
  wssSocket: null,
  language: 'eng',
  showIntro: true,
  isLoggedIn: null,
  showBetSlip: false,
  betSlipCount: null,
  showLiveChat: false,
  showSearchModal: false,
  isContentScrolled: false,
  showMobileBetSlip: false,
  showMobileRegionsModal: false,
  recaptcha: {
    action: '',
    site_key: null,
    isVisible: false,
  },
  authModal: {
    tabIndex: 0,
    isVisible: false,
    privacyTabIndex: null,
    isCloseDisabled: false,
  },
  mainMenuModal: {
    tabIndex: 0,
    tab: MAIN_MENU,
    isDesktop: false,
    isVisible: false,
    isCloseDisabled: false,
    balanceManagementTabIndex: 0,
    forceToOpenProfileIndex: null,
    forceToOpenCasinoGameId: null,
  },
  messagesModal: {
    isVisible: false,
    isCloseDisabled: false,
  },
  successModal: {
    isVisible: false,
    successTitle: '',
    successDescription: '',
    isCloseDisabled: false,
  },
  myBetsModal: {
    tabIndex: 0,
    isVisible: false,
    isCloseDisabled: false,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setWssSocket: (state, {payload}) => {
      state.wssSocket = payload;
    },
    setIsLoggedIn: (state, {payload}) => {
      state.isLoggedIn = payload;
    },
    setIsContentScrolled: (state, {payload}) => {
      state.isContentScrolled = payload;
    },
    setLanguage: (state, {payload}) => {
      state.language = payload;
    },
    setSid: (state, {payload}) => {
      state.sid = payload;
    },
    setShowIntro: (state, {payload}) => {
      state.showIntro = payload;
    },
    setShowLiveChat: (state, {payload}) => {
      state.showLiveChat = payload;
    },
    setShowBetSlip: (state, {payload}) => {
      state.showBetSlip = payload;
    },
    setShowMobileRegionsModal: (state, {payload}) => {
      state.showMobileRegionsModal = payload;
    },
    setShowMobileBetSlip: (state, {payload}) => {
      state.showMobileBetSlip = payload;
    },
    setBetSlipCount: (state, {payload}) => {
      state.betSlipCount = payload;
    },
    setShowSearchModal: (state, {payload}) => {
      state.showSearchModal = payload;
    },
    setRecaptcha: (state, {payload}) => {
      state.recaptcha = {
        ...state.recaptcha,
        ...payload,
      };
    },
    setAuthModal: (state, {payload}) => {
      state.authModal = {
        ...state.authModal,
        ...payload,
      };
    },
    setMainMenuModal: (state, {payload}) => {
      state.mainMenuModal = {
        ...state.mainMenuModal,
        ...payload,
      };
    },
    setMessagesModal: (state, {payload}) => {
      state.messagesModal = {
        ...state.messagesModal,
        ...payload,
      };
    },
    setSuccessModal: (state, {payload}) => {
      state.successModal = {
        ...state.successModal,
        ...payload,
      };
    },
    setMyBetsModal: (state, {payload}) => {
      state.myBetsModal = {
        ...state.myBetsModal,
        ...payload,
      };
    },
  },
});

export const {
  setSid,
  setLanguage,
  setAuthModal,
  setWssSocket,
  setShowIntro,
  setRecaptcha,
  setIsLoggedIn,
  setMyBetsModal,
  setShowBetSlip,
  setSuccessModal,
  setShowLiveChat,
  setBetSlipCount,
  setMainMenuModal,
  setMessagesModal,
  setShowSearchModal,
  setShowMobileBetSlip,
  setIsContentScrolled,
  setShowMobileRegionsModal,
} = appSlice.actions;

export default appSlice.reducer;
