import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {resources} from './Translations';

import {APP_LANGUAGES} from './Constants/Languages';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: APP_LANGUAGES.ENG,
  })
  .then(() => {});

export default i18n;
