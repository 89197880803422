import React, {memo, useMemo} from 'react';

import {getDateColor} from '../../../../Utils/DateFormatter';
import {
  getGameStartTime,
  convertToTimestamp,
} from '../../../../Utils/TimeConvertors';
import {formatEventName} from '../../../../Utils/EventNamesFormatter';

import EventItem from '../../../Home/Games/EventItem';

import './index.scss';

const BetItemRow = ({betItem, index}) => {
  const startTime = useMemo(
    () => getGameStartTime(convertToTimestamp(betItem?.StartTime)),
    [betItem?.StartTime],
  );

  return (
    <div className="betItemRowContainer">
      <div
        className={`px-md py-sm flex items-center justify-between semiLightGrayBackground ${
          index === 0 && 'topRadius'
        }`}>
        <div className="flex items-center">
          {!!betItem?.SportAlias && (
            <img
              width={16}
              className="flex-shrink"
              alt={betItem?.SportAlias}
              src={require(
                `../../../../Assets/Icons/Sport/${betItem?.SportAlias}.svg`,
              )}
            />
          )}
          <div className="flex column ml-sm">
            <span className="font-12 blueText bold-600 textWithDotes teamName">
              {betItem?.HomeTeamName}
            </span>
            <span className="font-12 blueText bold-600 textWithDotes teamName">
              {betItem?.AwayTeamName}
            </span>
          </div>
        </div>
        <span className="text-caption-small" style={getDateColor(startTime)}>
          {startTime}
        </span>
      </div>
      <div
        className="px-md py-sm flex justify-between items-center"
        style={{backgroundColor: 'var(--lightGray)'}}>
        <div className="flex column">
          <span className="font-11 bold-600 grayText">
            {betItem?.DisplayMarketName}
          </span>
          <span className="font-12 blueText bold-600">
            {formatEventName({
              team1Name: betItem?.HomeTeamName,
              team2Name: betItem?.AwayTeamName,
              name: betItem?.DisplaySelectionName,
            })}
          </span>
        </div>
        <EventItem
          type={2}
          disabled
          price={betItem?.Price}
          eventItemContainerStyles={{height: 35}}
        />
      </div>
    </div>
  );
};

export default memo(BetItemRow);
