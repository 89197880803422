import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {setMainMenuModal} from '../../../Redux/AppSlice';

import './index.scss';

import {useOutSideClickHandler} from '../../../Hooks';

import {
  BALANCE_DROPDOWN_ITEMS,
  MAIN_MENU_DROPDOWN_ITEMS,
} from '../../../Constants/Menu';

import {AppSwitcher} from '../../UI';

const BalanceDropDown = ({
  contentRef,
  hideBalance,
  setHideBalance,
  setShowBalanceDropDown,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useOutSideClickHandler(contentRef, () => setShowBalanceDropDown(false));

  const menuItemClickHandler = useCallback(
    async action => {
      switch (action) {
        case 'deposit':
          dispatch(
            setMainMenuModal({
              tabIndex: 0,
              isVisible: true,
              balanceManagementTabIndex: 0,
            }),
          );
          break;
        case 'withdraw':
          dispatch(
            setMainMenuModal({
              tabIndex: 0,
              isVisible: true,
              balanceManagementTabIndex: 1,
            }),
          );
          break;
        case 'transactionHistory':
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 1}));
          break;
        case 'withdrawStatus':
          break;
        default:
          break;
      }
      setShowBalanceDropDown(false);
    },
    [dispatch, setShowBalanceDropDown],
  );

  return (
    <div className="absolute flex column justify-center gap-5 balanceDropDownContainer">
      <div className="flex row gap-5 balanceDropDownContainerHeader py-sm px-md">
        <AppSwitcher
          isToggled={hideBalance}
          label={t('hideBalance')?.toUpperCase?.()}
          labelClassName="blueText text-caption bold-500"
          onToggle={() => setHideBalance(prevState => !prevState)}
        />
      </div>
      <div className="flex column gap-5 px-md pb-sm">
        {BALANCE_DROPDOWN_ITEMS.map((item, index) => (
          <div className="flex column cursor-pointer" key={item?.name}>
            <div
              className="flex row  gap-10 items-center menuItem"
              onClick={() => menuItemClickHandler(item?.action)}>
              <span className="blueText text-caption bold-500">
                {t(item?.name)}
              </span>
            </div>
            {index !== MAIN_MENU_DROPDOWN_ITEMS?.length - 1 && (
              <div className="line" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(BalanceDropDown);
