import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {getUser} from '../../../../../Redux/UserSlice/UserSelectors';
import {getWssSocket} from '../../../../../Redux/AppSlice/AppSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../../Redux/AppSlice';

import {useScreenSize, useSocket, useStorage} from '../../../../../Hooks';

import {
  SOCKET_RESPONSES,
  UPDATE_USER_PASSWORD,
  SEND_SMS_WITH_USERNAME,
} from '../../../../../Constants/Socket';
import {
  APP_MODAL_CLOSE_TIMEOUT_MS,
  BREAKPOINT_XS,
  PHONE_INPUT_PLACEHOLDER,
  PHONE_NUMBER_PREFIX,
} from '../../../../../Constants/Globals';

import {AppButton, AppError, AppInput} from '../../../../UI';

const ChangePassword = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {updateUserPassword, sendSmsWithUsername} = useSocket();
  const {getLoginDataFromStorage, setLoginDataToStorage} = useStorage();

  const user = useSelector(getUser);
  const wssSocket = useSelector(getWssSocket);

  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(null);
  const [isGetCodeLoading, setIsGetCodeLoading] = useState(false);
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);

  const isChangePasswordDisabled = useMemo(
    () =>
      newPassword?.trim?.()?.length < 4 ||
      currentPassword?.trim?.()?.length < 4 ||
      confirmNewPassword?.trim?.()?.length < 4 ||
      newPassword?.trim?.() !== confirmNewPassword?.trim?.() ||
      !!oldPasswordError,
    [confirmNewPassword, currentPassword, newPassword, oldPasswordError],
  );

  const isPasswordsMatch = useMemo(
    () =>
      newPassword?.trim?.()?.length > 4 &&
      confirmNewPassword?.trim?.()?.length > 4 &&
      newPassword?.trim?.() !== confirmNewPassword?.trim?.()
        ? t('passwordsMismatch')
        : '',
    [confirmNewPassword, newPassword, t],
  );

  const isOldPassShort = useMemo(
    () =>
      !!currentPassword?.trim?.() && currentPassword?.trim?.()?.length < 4
        ? t('passwordErrorMsg')
        : '',
    [currentPassword, t],
  );

  const isNewPassShort = useMemo(
    () =>
      !!newPassword?.trim?.() && newPassword?.trim?.()?.length < 4
        ? t('passwordErrorMsg')
        : '',
    [newPassword, t],
  );

  const isConfirmPassShort = useMemo(
    () =>
      !!confirmNewPassword?.trim?.() && confirmNewPassword?.trim?.()?.length < 4
        ? t('passwordErrorMsg')
        : '',
    [confirmNewPassword, t],
  );

  const updatePasswordHandler = useCallback(async () => {
    setIsCodeSent(false);
    setIsChangePasswordLoading(true);
    const loginData = await getLoginDataFromStorage();
    updateUserPassword({
      password: currentPassword,
      new_password: newPassword,
      encrypted_token: !!loginData?.jwe_token,
      ...(!!verificationCode ? {confirmation_code: verificationCode} : {}),
    });
  }, [
    newPassword,
    currentPassword,
    verificationCode,
    updateUserPassword,
    getLoginDataFromStorage,
  ]);

  const getCodeHandler = useCallback(() => {
    setIsGetCodeLoading(true);
    if (isCodeSent) {
      setIsCodeSent(false);
    }
    sendSmsWithUsername({username: user?.username});
  }, [isCodeSent, sendSmsWithUsername, user?.username]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case UPDATE_USER_PASSWORD:
          if (!data?.data?.auth_token) {
            if (
              data?.data?.result === SOCKET_RESPONSES?.INCORRECT_OLD_PASSWORD ||
              data?.data?.result === SOCKET_RESPONSES.CHANGE_PASSWORD_ERROR
            ) {
              setOldPasswordError(data?.data?.details);
            }
          } else {
            const loginData = await getLoginDataFromStorage();
            await setLoginDataToStorage({
              ...loginData,
              auth_token: data?.data?.auth_token,
              ...(!!data?.data?.jwe_token
                ? {jwe_token: data?.data?.jwe_token}
                : {}),
            });
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            setTimeout(() => {
              dispatch(
                setSuccessModal({
                  isVisible: true,
                  successTitle: t('passwordWasChangedSuccessfully'),
                }),
              );
            }, APP_MODAL_CLOSE_TIMEOUT_MS);
          }
          setIsChangePasswordLoading(false);
          break;
        case SEND_SMS_WITH_USERNAME:
          if (data?.data?.result === 0) {
            setIsCodeSent(true);
          }
          setIsGetCodeLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, getLoginDataFromStorage, setLoginDataToStorage, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`${
        !(width < BREAKPOINT_XS) && 'py-lg'
      } flex column items-center changePasswordTabContainer pt-xxxl justify-between`}>
      {/*<div className="column pt-xs gap-4" style={{width: 360}}>*/}
      {/*  <span className="font-12 bold-500 whiteText" style={{marginLeft: 55}}>*/}
      {/*    {t('enterYourPhoneNumber')}*/}
      {/*  </span>*/}
      {/*  <div className="column gap-10">*/}
      {/*    <div className="row items-center gap-10">*/}
      {/*      <div*/}
      {/*        className="column items-center yellowBackground rounded-borders justify-center px-md paddingVertical-4"*/}
      {/*        style={{height: 35, width: 47}}>*/}
      {/*        <span className="bold-600 font-6 blueText text-uppercase resetLineHeight mt-xs">*/}
      {/*          {t('code')}:*/}
      {/*        </span>*/}
      {/*        <span*/}
      {/*          className="blueText bold-600 font-15"*/}
      {/*          style={{lineHeight: 1.25}}>*/}
      {/*          {PHONE_NUMBER_PREFIX}*/}
      {/*        </span>*/}
      {/*      </div>*/}

      {/*      <AppInput*/}
      {/*        type={9}*/}
      {/*        height={35}*/}
      {/*        inputValue={phone}*/}
      {/*        onChange={setPhone}*/}
      {/*        placeholder={PHONE_INPUT_PLACEHOLDER}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="row items-center gap-10">*/}
      {/*      <div*/}
      {/*        className="column items-center yellowBackground rounded-borders justify-center px-md paddingVertical-4"*/}
      {/*        style={{height: 35, width: 47}}>*/}
      {/*        <span*/}
      {/*          className="blueText bold-600 font-15"*/}
      {/*          style={{lineHeight: 1.25}}>*/}
      {/*          OTP*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*      <AppInput*/}
      {/*        type={9}*/}
      {/*        height={35}*/}
      {/*        inputValue={verificationCode}*/}
      {/*        onChange={setVerificationCode}*/}
      {/*        placeholder={t('enterYourOTP')}*/}
      {/*      />*/}
      {/*      <div>*/}
      {/*        <AppButton*/}
      {/*          type={3}*/}
      {/*          width={101}*/}
      {/*          height={35}*/}
      {/*          fontSize={15}*/}
      {/*          onClick={getCodeHandler}*/}
      {/*          titleClassName="bold-600"*/}
      {/*          loading={isGetCodeLoading}*/}
      {/*          className="px-md paddingHorizontal-9"*/}
      {/*          title={t('sendOtp').toUpperCase()}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="pt-lg gap-4 column">*/}
      {/*    <span className="font-12 bold-500 whiteText">*/}
      {/*      {t('confirmNewPassword')}*/}
      {/*    </span>*/}
      {/*    <AppInput*/}
      {/*      type={9}*/}
      {/*      height={35}*/}
      {/*      error={isNewPassShort}*/}
      {/*      inputValue={newPassword}*/}
      {/*      onChange={setNewPassword}*/}
      {/*      placeholder={t('enterNewPassword')}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="column gap-10" style={{width: 360}}>
        <AppInput
          password
          type={9}
          height={35}
          errorTextClass="font-9"
          inputValue={currentPassword}
          onChange={setCurrentPassword}
          setErrorMsg={setOldPasswordError}
          error={isOldPassShort || oldPasswordError}
          placeholder={t('enterCurrentPassword')}
        />
        <AppInput
          password
          type={9}
          height={35}
          error={isNewPassShort}
          errorTextClass="font-9"
          inputValue={newPassword}
          onChange={setNewPassword}
          placeholder={t('enterNewPassword')}
        />
        <AppInput
          password
          type={9}
          height={35}
          errorTextClass="font-9"
          inputValue={confirmNewPassword}
          onChange={setConfirmNewPassword}
          placeholder={t('confirmNewPassword')}
          error={isConfirmPassShort || isPasswordsMatch}
        />
      </div>
      <div className="full-width px-md self-end">
        <AppButton
          type={3}
          titleClassName="bold-600"
          onClick={updatePasswordHandler}
          loading={isChangePasswordLoading}
          disabled={isChangePasswordDisabled}
          title={t('confirm').toUpperCase()}
        />
      </div>
    </div>
  );
};

export default memo(ChangePassword);
