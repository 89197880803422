import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import StartTime from '../StartTime';

const VolleyballGlobalScore = ({game}) => {
  const {t} = useTranslation();

  const setValues = game?.stats?.[`score_${game?.info?.current_game_state}`];

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;

    gameHalf = gameHalf?.replace?.(/(\D)(\d)/, '$1 $2');

    return gameHalf;
  }, [game?.info?.current_game_state]);

  return (
    <div
      className={`flex column items-center justify-${
        !!game?.is_live || game?.info?.current_game_state === 'finished'
          ? 'center'
          : 'between'
      }`}>
      {game?.info?.current_game_state === 'finished' ? (
        <div></div>
      ) : (
        <StartTime game={game} />
      )}
      <div className="flex row items-center gap-5">
        {!!game?.is_live && (
          <span
            className={`bold-500 font-30 ${
              game?.info?.current_game_state === 'finished'
                ? 'whiteText'
                : 'orangeText'
            }`}>
            {game?.info?.score1}
          </span>
        )}
        <div style={{width: 10, height: 2}} className="whiteBackground">
          -
        </div>
        {!!game?.is_live && (
          <span
            className={`bold-500 font-30 ${
              game?.info?.current_game_state === 'finished'
                ? 'whiteText'
                : 'orangeText'
            }`}>
            {game?.info?.score2}
          </span>
        )}
      </div>
      {game?.info?.current_game_state === 'finished' ? (
        <span className="font-12 bold-600 whiteText" style={{marginTop: 10}}>
          {t('finished')}
        </span>
      ) : (
        !!game?.is_live && (
          <div className="flex column items-center">
            <span
              className={`bold-600 font-12 text-capitalize ${
                game?.info?.current_game_state === 'finished' ||
                game?.info?.current_game_state === 'Half End'
                  ? 'whiteText'
                  : 'orangeText'
              }`}>
              {halfValue}
            </span>
            <div className="font-12 whiteText">
              <span className="">{setValues?.team1_value || 0}</span>
              <span className=""> - </span>
              <span className="">{setValues?.team2_value || 0}</span>
            </div>
          </div>
        )
      )}
      {!game?.is_live && <div></div>}
    </div>
  );
};

export default VolleyballGlobalScore;
