import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import './index.scss';

import {getLanguage} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../Hooks';

import {BREAKPOINT_XS} from '../../Constants/Globals';
import {APP_LANGUAGES} from '../../Constants/Languages';
import {CMS_URL, SITE_ID, SOCKET_RESPONSES} from '../../Constants/Socket';

import Details from './Details';

import {AppButton, AppModal} from '../UI';

const Promotions = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const language = useSelector(getLanguage);

  const [categories, setCategories] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all');
  const [activeBonusId, setActiveBonusId] = useState(
    getParam?.get('showPromotions'),
  );
  const [isPromotionsLoading, setIsPromotionsLoading] = useState(false);

  const Header = useMemo(
    () => (
      <div className="flex items-center pa-lg">
        <span className="text-subtitle2 blueText bold-600">
          {t('promotions')?.toUpperCase?.()}
        </span>
      </div>
    ),
    [t],
  );

  const onRequestClose = useCallback(() => {
    setGetParam({});
    setActiveBonusId('all');
  }, []);

  useEffect(() => {
    fetch(
      `${CMS_URL}/api/public/v1/${
        language === APP_LANGUAGES.CHN ? 'chi' : language
      }/partners/${SITE_ID}/categories/promotion?platform=0`,
    )
      .then(response => response.json())
      .then(data => {
        if (data?.text === SOCKET_RESPONSES?.OK) {
          setCategories(data?.data);
        }
      })
      .catch(() => {});
  }, [language]);

  useEffect(() => {
    setIsPromotionsLoading(true);
    fetch(
      `${CMS_URL}/api/public/v1/${
        language === APP_LANGUAGES.CHN ? 'chi' : language
      }/partners/${SITE_ID}/promotions?platform=0&category=${activeFilter}&exclude=content`,
    )
      .then(response => response.json())
      .then(data => {
        if (data?.text === SOCKET_RESPONSES?.OK) {
          setPromotions(data?.data);
        }
      })
      .catch(() => {})
      .finally(() => setIsPromotionsLoading(false));
  }, [activeFilter, language]);

  return (
    <AppModal
      header={Header}
      maxHeight="80vh"
      onRequestClose={onRequestClose}
      isOpen={!!getParam.get('showPromotions')}
      maxWidth={width < BREAKPOINT_XS ? '95vw' : undefined}>
      <div
        className={`flex column fit px-lg promotionsContainer gap-10 ${
          isPromotionsLoading ? 'loading' : ''
        }`}>
        {activeBonusId !== 'all' ? (
          <Details
            activeBonusId={activeBonusId}
            setActiveBonusId={setActiveBonusId}
          />
        ) : (
          <>
            <div className="row items-center gap-10">
              {categories?.map(item => (
                <AppButton
                  small
                  type={2}
                  width={100}
                  key={item?.title}
                  title={item?.title}
                  active={item?.src === activeFilter}
                  onClick={() => setActiveFilter(item?.src)}
                />
              ))}
            </div>
            <div className="row items-center col-gutter-sm wrap">
              {promotions?.map(item => (
                <div
                  key={item?.id}
                  className="col-xs-12 col-sm-6 col-md-4"
                  onClick={() => setActiveBonusId(item?.id)}>
                  <img
                    width="100%"
                    alt={item?.title}
                    src={`${CMS_URL}${item?.src}`}
                    className="promotionItem cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </AppModal>
  );
};

export default memo(Promotions);
