import React, {memo, useCallback, useState} from 'react';

import './index.scss';

const TextArea = ({
  placeholder,
  onChange,
  value,
  error,
  setErrorMsg,
  height = 165,
}) => {
  const [isBlurred, setIsBlurred] = useState(true);

  const onChangeText = useCallback(() => {
    if (error && setErrorMsg && typeof setErrorMsg === 'function') {
      setErrorMsg(null);
    }
  }, [error, setErrorMsg]);

  return (
    <>
      <textarea
        value={value}
        style={{height}}
        placeholder={placeholder}
        className="appTextArea scroll-y scroll-1"
        onChange={e => {
          onChange(e?.target?.value);
          onChangeText(e?.target?.value);
        }}
        onBlur={() => setIsBlurred(true)}
        onFocus={() => setIsBlurred(false)}
      />

      {error && isBlurred && (
        <span className="redText font-10 ellipsis">{error}</span>
      )}
    </>
  );
};

export default memo(TextArea);
